import React, { useEffect } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
  useMediaQuery,
} from '@mui/material'
import * as Sentry from '@sentry/react'

import ThumbsUp from 'assets/student/thumbs_up.svg'
import Countdown from 'student/common/countdown'
import useStudentTask from 'student/common/useStudentTask'
import { useStudentContext } from 'common/context/StudentContext'

import SignoutModal from '../common/signoutModal'
import {
  ANSWER_ICONS,
  CalculatorButton,
  OperationErrorText,
  OperationText,
  PaperContainer,
} from '../common/taskUI'

function Practice() {
  const { disableAnimation } = useStudentContext()
  const isLandscape = useMediaQuery('(orientation: landscape)')

  const {
    countdownTimer,
    ActivitySettings,
    streak,
    secondsUntilLogout,
    flippedKeypad,
    keypadShown,
    wrongAnswer,
    currentQuestion,
    studentAnswer,
    remainingAnswer,
    dontSignoutContinueClicked,
    digitEntered,
    hasFinished,
    answeredRight,
  } = useStudentTask('practice')

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'activity',
      message: 'Started activity',
      level: 'info',
    })
  }, [])

  return (
    <Container>
      <Countdown timer={countdownTimer} />
      <PaperContainer sx={{ height: '83vh !important', p: 2 }}>
        {hasFinished && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          display='flex'
          alignItems='center'
          sx={{ mb: { xs: 1, md: 3 } }}
          flexDirection={flippedKeypad ? 'row-reverse' : 'row'}
          justifyContent='space-between'
        >
          <Box>
            <ActivitySettings />
          </Box>
          <Box>
            <Box display='flex' alignItems='center'>
              <Typography sx={{ fontWeight: 400, fontSize: '1.8rem' }}>
                {streak}
              </Typography>
              <Box
                sx={{ ml: { xs: 3, md: 1 } }}
                component='img'
                alt='Icon for Thumbs Up'
                src={ThumbsUp}
                height={40}
                width={40}
              />
            </Box>
          </Box>
        </Box>
        <SignoutModal
          secondsUntilLogout={secondsUntilLogout}
          onContinue={dontSignoutContinueClicked}
        />
        {hasFinished ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 4,
              flexDirection: flippedKeypad ? 'row-reverse' : 'row',
            }}
          >
            <Box
              flex='1'
              sx={{
                // eslint-disable-next-line no-nested-ternary
                textAlign: keypadShown
                  ? flippedKeypad
                    ? 'left'
                    : 'right'
                  : 'center',
                px: { xs: 0, md: 3 },
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: flippedKeypad || !keypadShown ? '301px' : 'auto',
                  textAlign: 'right',
                  // eslint-disable-next-line no-nested-ternary
                  mr: {
                    xs: '85px',
                    // eslint-disable-next-line no-nested-ternary
                    md: keypadShown
                      ? flippedKeypad
                        ? 'auto'
                        : '50px'
                      : 'auto',
                  },
                  // eslint-disable-next-line no-nested-ternary
                  ml: keypadShown ? (flippedKeypad ? '50px' : 'auto') : 'auto',
                  position: 'relative',
                }}
              >
                {wrongAnswer && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', md: 'column' },
                      ...(flippedKeypad
                        ? { left: '-20%' }
                        : { right: { xs: '-30%', md: '-10%' } }),
                    }}
                  >
                    <OperationErrorText
                      sx={{
                        fontSize: wrongAnswer.length === 3 ? '40px' : '64px',
                      }}
                    >
                      {wrongAnswer}
                    </OperationErrorText>
                    <OperationErrorText>
                      <Box
                        component='img'
                        src={ANSWER_ICONS.wrong}
                        alt='Icon for Wrong Answer'
                        height={40}
                        width={40}
                      />
                    </OperationErrorText>
                  </Box>
                )}
                {answeredRight && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: { xs: '30%', md: '20%' },
                      display: 'flex',
                      flexDirection: 'column',
                      ...(flippedKeypad
                        ? { left: '-20%' }
                        : { right: { xs: '-30%', md: '-10%' } }),
                    }}
                  >
                    <Box
                      component='img'
                      alt='Icon for Thumbs Up'
                      src={ThumbsUp}
                      height={40}
                      width={40}
                    />
                  </Box>
                )}
                {currentQuestion && (
                  <Box>
                    <Box sx={{ px: 4 }}>
                      <OperationText>{currentQuestion.a}</OperationText>
                    </Box>
                    <Box
                      sx={{
                        pb: 2,
                        mb: 2,
                        display: 'inline',
                        borderBottom: { xs: '6px solid', md: '13px solid' },
                        borderColor: 'common.black',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          px: 4,
                        }}
                      >
                        <OperationText
                          sx={{
                            mr: 3,
                          }}
                        >
                          {currentQuestion.o}
                        </OperationText>
                        <OperationText>{currentQuestion.b}</OperationText>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{ px: 4, mt: 3, display: 'flex', justifyContent: 'end' }}
                >
                  <OperationText
                    sx={!studentAnswer && { height: '0 !important' }}
                  >
                    {studentAnswer}
                  </OperationText>
                  {remainingAnswer ? (
                    <OperationText
                      sx={{
                        color: disableAnimation ? '#9D33D7' : 'grey.50',
                      }}
                      className={
                        !hasFinished && !disableAnimation ? 'pulsate' : ''
                      }
                    >
                      {remainingAnswer}
                    </OperationText>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
            {keypadShown && (
              <Box
                sx={{
                  display: 'grid',
                  marginLeft: 'auto',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridTemplateRows: 'repeat(4, 1fr)',
                  gap: { xs: 1, md: 2 },

                  backgroundColor: isLandscape
                    ? 'transparent'
                    : { xs: 'grey.50', md: 'transparent' },
                  position: isLandscape
                    ? 'relative'
                    : { xs: 'fixed', md: 'relative' },
                  p: { xs: 2, md: 0 },
                  bottom: 0,
                  left: 0,
                  zIndex: 2,
                  width: isLandscape ? 'auto' : { xs: '100%', md: 'auto' },
                }}
              >
                {[7, 8, 9, 4, 5, 6, 1, 2, 3, 0].map((val) => (
                  <CalculatorButton
                    key={val}
                    onClick={() => digitEntered(val)}
                    onTouchStart={() => digitEntered(val)}
                    onTouchEndCapture={(e) => e.preventDefault()}
                    sx={val === 0 && { gridColumn: '1 / span 2' }}
                    disabled={hasFinished}
                  >
                    {val}
                  </CalculatorButton>
                ))}
              </Box>
            )}
          </Box>
        )}
      </PaperContainer>
    </Container>
  )
}

export default Practice
