const TEACHER_RE = /teacher\//
const STUDENT_RE = /student\//

export function configurePost(
  url,
  options?: { timeout: number }
): XMLHttpRequest {
  const req = new XMLHttpRequest()
  req.open('POST', url, true)
  try {
    req.timeout = (options && options.timeout) || 30000
    req.responseType = 'json'
  } catch (err) {
    // ignore
  }
  req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
  req.setRequestHeader('Cache-Control', 'no-cache')
  return req
}

export function configureGet(
  url: string,
  options?: { timeout?: number }
): XMLHttpRequest {
  const req = new XMLHttpRequest()
  req.open('GET', url)
  try {
    req.timeout = (options && options.timeout) || 30000
  } catch (err) {
    // ignore
  }
  return req
}

export function pageType(url: string): 'teacher' | 'student' | 'home' {
  const noQS = url.split('?')[0]
  if (TEACHER_RE.test(noQS)) {
    return 'teacher'
  }
  if (STUDENT_RE.test(noQS)) {
    return 'student'
  }
  return 'home'
}

// export function controllerSuffix(url: string): string {
//   const type = pageType(url)
//   return `${type}-${config.hashes[type]}`
// }
