import React, { useEffect, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'
import { motion } from 'framer-motion'
import _ from 'lodash'

const MotionBox = motion(Box)

const CustomLinearProgress = styled(MotionBox)(({ theme }) => ({
  height: 16,
  display: 'inline-block',
  width: 500,
  boxShadow: '0px 2px 2px 0px #00000040',
}))

function TimerBar({
  tickLength,
  currentQuestion,
  isStopped,
}: {
  tickLength: number
  currentQuestion: any
  isStopped: boolean
}) {
  const [currentValue, setCurrentValue] = useState(0)
  const _currentValueRef = useRef(0)
  const _isStoppedRef = useRef(isStopped)
  const _interval = useRef(null)
  const totalTicks = 100
  const totalLength = tickLength * 10 // Total duration for the timer in milliseconds
  const intervalDuration = totalLength / totalTicks // Duration for each tick

  const _userWentAway = useRef(null)

  const startInterval = (initialValue) => {
    setCurrentValue(initialValue)
    _interval.current = setInterval(() => {
      setCurrentValue((prevValue) => {
        if (prevValue >= 100) {
          clearInterval(_interval.current)
          return isStopped ? prevValue : 100
        }

        const value = prevValue + 1
        _currentValueRef.current = value
        return value
      })
    }, intervalDuration)
  }

  useEffect(() => {
    startInterval(0)

    return () => clearInterval(_interval.current)
  }, [currentQuestion])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      clearInterval(_interval.current)
      _userWentAway.current = Date.now()
    }
    if (document.visibilityState === 'visible') {
      const timeAway = Date.now() - _userWentAway.current
      const ticksAway = timeAway / intervalDuration

      if (_currentValueRef.current < 100 && !_isStoppedRef.current)
        startInterval(Math.min(100, _currentValueRef.current + ticksAway))
      _userWentAway.current = null
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [intervalDuration])

  useEffect(() => {
    _isStoppedRef.current = isStopped
    if (isStopped) clearInterval(_interval.current)
  }, [isStopped])

  const getPointerColor = () => {
    if (currentValue < 30) return '#94CA4D'
    if (currentValue < 99) return '#FFD05D'
    return '#8CB7EA'
  }

  return (
    <Box
      position='relative'
      sx={{ width: { xs: 200, md: 650 }, mx: 'auto', mt: 'auto' }}
    >
      <CustomLinearProgress
        sx={{
          background: '#C6F38B',
          width: '30%',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      />
      <CustomLinearProgress
        initial={{ width: 0 }}
        animate={{ width: `${Math.min(currentValue + 2, 30)}%` }}
        transition={{ duration: intervalDuration / 1000, ease: 'linear' }}
        sx={{
          background: '#94CA4D',
          position: 'absolute',
          top: 1,
          left: 0,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      />
      <CustomLinearProgress
        sx={{
          background: '#FFF3AB',
          width: '70%',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      />
      <CustomLinearProgress
        initial={{ width: 0 }}
        animate={{ width: `${currentValue > 30 ? currentValue - 28 : 0}%` }}
        transition={{ duration: intervalDuration / 1000, ease: 'linear' }}
        sx={{
          background: '#FFD05D',
          position: 'absolute',
          top: 1,
          left: '30%',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      />
      <MotionBox
        role='progressbar'
        position='absolute'
        initial={{ left: 0 }}
        animate={{ left: `${currentValue}%` }}
        transition={{ duration: intervalDuration / 1000, ease: 'linear' }}
        sx={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          top: '-2px',
          background: getPointerColor(),
          boxShadow: '0px 4px 4px 0px #00000040',
          zIndex: 1,
        }}
      />
    </Box>
  )
}

export default TimerBar
