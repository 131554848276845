// REQUIREMENTS

import { AccountInstance, CodeMap, AccountCode } from './account-types'

// CONSTANTS

export class Cache<T extends AccountInstance> {
  // CLASS METHODS

  static create<U extends AccountInstance>(contents?: CodeMap<U>): Cache<U> {
    return new this(contents)
  }

  // CONSTRUCTOR
  constructor(contents?: CodeMap<T>) {
    this.cached = contents || {}
  }

  // INSTANCE PROPERTIES

  name?: string // REVIEW: What is this used for?

  private cached: CodeMap<T>

  // INSTANCE METHODS

  add(account: T): void {
    if (!account.code) {
      throw new Error('No index for item!')
    }
    this.cached[account.code] = account
  }

  find(code: AccountCode): T | undefined {
    return this.cached[code]
  }

  has(code: AccountCode): boolean {
    return !!this.cached[code]
  }

  remove(account: T | string): void {
    if (typeof account === 'string') {
      delete this.cached[account]
    } else {
      delete this.cached[account.code]
    }
  }

  contents(): CodeMap<T> {
    return this.cached
  }
}
