/* eslint-disable no-restricted-globals */
/* eslint-disable no-bitwise */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
// TODO: Split this into two classes, a base class that is both client and server, and a derived class that is server only.

// REQUIREMENTS

import _ from 'lodash'
import { Timestamp, assert, PlainObject, Seconds } from './common'
import { XmDate } from './xm-date/shared'

import {
  CellCategory,
  AnswerCategory,
  ProgressGraph,
  Matrix,
  Answers,
  ProgressEntry,
  Quality,
  Answer,
  ElapsedMs,
  ActivityId,
  FluencyThreshold,
  MatrixCellAnswer,
  MatrixCell,
  StudentOperationItem,
} from './account-types'
import { Operation, Problem } from './operation'

const logger = console.log

// TYPES

export type AnnotatedMatrix = (AnnotatedProblem | null)[][]

interface AnnotatedProblem extends Problem {
  q: CellCategory
  icon?: AnswerCategory
}

type CellPriority = number

// CONSTANTS

const NUM_MIXED_FACTS_PRACTICE = 10

// EXPORTED CLASS

/*

Call .isKnown() on studentOperation instance before making any other calls.
If .isKnown() returns falsy, then don’t access any fields or make any other calls
besides .isKnown() on that object.
Present the user with a message to refresh or upgrade their app.

If .isKnown() returns truthy, then you can also call .isValid().
If .isValid() returns falsy, then don't access any fields or make any other calls
besides .isKnown() or .isValid() on that object.
Present the user with a message that their data is corrupt.

If .isKnown() and .isValid() both return truthy, then you make access other fields
and make other calls.

*/
export class StudentOperation implements StudentOperationItem {
  // CLASS PROPERTIES

  // CLASS METHODS

  // CONSTRUCTOR

  public constructor(state: StudentOperationItem) {
    const opId = state.operationId
    assert(_.isNumber(opId))

    // If the operation is unknown or invalid then only store the operation id
    if (!Operation.isKnownId(opId) || !Operation.isValidId(opId)) {
      this.operationId = opId
      return
    }

    const operation = Operation.fromId(opId)
    state = fixupBadJsonSerialization(state, operation.maxIndex())
    _.assign(this, state)
    // this.operationId
    // this.mastery
    // this.progress
    // this.matrix
    // this.placementMastery
    // this.placementAt
    // this.finishedAt
    // this.printedAt
  }

  // INSTANCE PROPERTIES

  public operationId!: number

  public mastery?: number

  public progress?: ProgressGraph

  public matrix?: Matrix

  public placementMastery?: number

  public placementAt?: Timestamp

  public finishedAt?: Timestamp

  public printedAt?: Timestamp

  // INSTANCE PROPERTY FUNCTIONS

  /*
  Params:
    answers (optional) list of quiz answers for annotating the matrix with .icon value.

  Returns:
    An array of rows.
    Each row is an array of columns for that row.
    Each column entry is either null or an object from the operation fact generator,
    with the addition of a .q field giving the quality (cell category)
    and an optional .icon field giving the answer category.
    (e.g. { i: 45, a: 4, b: 5, c: 20, o:'+', s: '4+4', q: 1, icon*:  }).
    Column entries are null for facts that are not part of the
    operation. This happens in beginning addition and subtraction.
  */
  public factMatrix(answers?: Answers): AnnotatedMatrix {
    const operation = this.operation()
    const { matrix } = this
    const { filter } = operation
    const { generator } = operation
    const { threshold } = operation
    const d = operation.matrixDimension()
    const isDivision = operation.mathOperationKey === 'division'
    const rval = _.map(_.range(d), function (row) {
      return _.map(_.range(d), function (col) {
        const i = Operation.indexFromRowCol(row, col)
        if (!filter(i)) {
          return null
        }
        const problem = generator(i)
        if (isDivision && problem.b === 0) {
          return null
        }
        const annotatedProblem: AnnotatedProblem = {
          q: matrix ? cellCategory(matrix[i], threshold) : 3,
          ...problem,
        }
        return annotatedProblem
      })
    })
    _.forEach(answers, function (answer) {
      const i = answer[0]
      const row = Operation.rowFromIndex(i)
      const col = Operation.colFromIndex(i)
      const fact = rval[row][col]
      if (fact) fact.icon = answerCategory(answer, threshold)
    })
    return rval
  }

  public isKnown(): boolean {
    return Operation.isKnownId(this.operationId)
  }

  public isMastered(): boolean {
    return !!this.finishedAt
  }

  public isUnprinted(): boolean {
    return !!(this.finishedAt && !this.printedAt)
  }

  public isValid(): boolean {
    assert(this.isKnown())
    if (!Operation.isValidId(this.operationId)) {
      return false
    }
    // TODO: check other data fields for validity.
    // TODO: check matrix is of correct length.
    return true
  }

  public operation(): Operation {
    return Operation.fromId(this.operationId)
  }

  public placementIsComplete(): boolean {
    return _.isNumber(this.mastery)
  }

  public progressQuizDone(xd: XmDate): boolean {
    return !!this.progressOn(xd)
  }

  // INSTANCE METHODS

  // LATER: Move this to shared/accounts-server/server-student-operation
  public practiceQuestionIds(set: number /* TYPESCRIPT */) /* TYPESCRIPT */ {
    const operation = this.operation()
    const { groups } = operation
    const { matrix } = this
    const { threshold } = operation
    let i = set * NUM_MIXED_FACTS_PRACTICE
    let qids = _.shuffle(
      _.filter(_.flatten(groups), function (qid: number) {
        return cellCategory(matrix[qid], threshold) > 0
      })
    )

    // pick out the first or second block of practice questions
    if (i >= qids.length) {
      i = 0
    }
    qids = qids.slice(i, i + NUM_MIXED_FACTS_PRACTICE)
    assert(qids.length > 0, 'No facts available to practice.')

    // if we are short of facts to practice then fill up the practice set
    // with already-mastered questions.
    // LATER: fact practice should allow *any* mastered fact to get mixed in, not just a small set.
    if (qids.length < NUM_MIXED_FACTS_PRACTICE) {
      let extraQuestionIds = _.filter(
        _.flatten(groups),
        function (qid: number) {
          return cellCategory(matrix[qid], threshold) === 0
        }
      )
      extraQuestionIds = _.sortBy(extraQuestionIds, function () {
        return Math.random()
      })
      extraQuestionIds = extraQuestionIds.slice(
        0,
        NUM_MIXED_FACTS_PRACTICE - qids.length
      )
      qids = qids.concat(extraQuestionIds)
    }
    return qids
  }

  // LATER: Move this to shared/accounts-server/server-student-operation
  public quizQuestionGroups(
    placement: boolean /* TYPESCRIPT */
  ) /* TYPESCRIPT */ {
    const rval = []
    const { matrix } = this
    const operation = this.operation()
    const { threshold } = operation
    let qids
    if (placement) {
      // student taking placement quiz
      if (!matrix) {
        // student taking first placement quiz
        const { groups } = operation
        for (let i = 0; i < groups.length; i++) {
          rval.push(groups[i].slice().sort(randomSort))
        }
      } else {
        // student taking additional placement quiz
        // ask any manageable questions that haven't been asked
        // or were answered incorrectly the first time
        // to give the student an opportunity to correct typos.
        qids = operation.possibleIndexes
        qids = _.filter(qids, function (i) {
          const cell = matrix[i]
          return (
            cell &&
            (cell.length === 0 ||
              (cell.length === 1 &&
                cellAnswerCategory(cell[0], threshold) === 2))
          )
        })
        if (qids.length === 0) {
          logger('Student has no problems enabled.')
          qids = operation.groups[0].slice()
        }
        qids = qids.sort(randomSort)
        rval.push(qids)
      }
    } else {
      // progress quiz
      qids = _.filter(operation.possibleIndexes, function (i) {
        return matrix[i]
      })
      rval.push(
        _.sortBy(qids, function (_a, i) {
          return cellPriority(matrix[_a], threshold) + Math.random()
        })
      )
    }
    return rval
  }

  // REVIEW: Is this used anywhere?
  public reset(): void {
    this.mastery = undefined
    this.matrix = undefined
    this.placementMastery = undefined
    this.placementAt = undefined
    this.finishedAt = undefined
    this.printedAt = undefined
  }

  // PRIVATE INSTANCE PROPERTIES

  protected progressOn(xd: number): ProgressEntry {
    return _.find(this.progress, function (entry) {
      return entry[0] === xd
    })
  }
}

// EXPORTED FUNCTIONS

export function answerCategory(
  answer: Answer,
  threshold: ElapsedMs
): AnswerCategory {
  // Categorize an answer as "fluent", "correct", "incorrect", or "timeout"
  const cellAnswer = convertAnswerToCellAnswer(answer)
  return cellAnswerCategory(cellAnswer, threshold)
}

// LATER: Move this to shared/accounts-server/server-student-operation
export function answersQuality(
  _activityId: ActivityId,
  answers: Answer[],
  elapsed: Seconds,
  threshold: FluencyThreshold
): Quality {
  // Given the results of an activity, give a quality rating (good, fair, or poor) for the student's performance.
  // A good (green) performance is 90%+ not-wrong, and less than 10 seconds per question on average.
  // A fair (yellow) performance is 75% not-wrong, and less than 15 seconds per question on average.
  // Percentages selected so approx 50% of quizzes would be 'good', 33% would be 'fair', and 17% poor.
  // REVIEW: Should seconds per question threshold be adjusted depending on fluency threshold?
  const categories = _.map(answers, (a) => answerCategory(a, threshold))
  const goodCategories = _.filter(
    categories,
    (c) =>
      c === AnswerCategory.Fluent ||
      c === AnswerCategory.Correct ||
      c === AnswerCategory.Timeout
  )
  const numGood = goodCategories.length
  const numAnswers = answers.length
  const percentGood = Math.round((numGood * 100) / numAnswers)
  const secondsPerQuestion = elapsed / numAnswers
  let rval: Quality
  if (percentGood >= 90 && secondsPerQuestion <= 10) {
    rval = Quality.Good
  } else if (percentGood >= 75 && secondsPerQuestion <= 15) {
    rval = Quality.Fair
  } else {
    rval = Quality.Poor
  }
  // console.log(`${percentGood} % good; ${secondsPerQuestion} sec/q; ${rval} quality`);
  return rval
}

export function cellCategory(
  cell: MatrixCell,
  threshold: ElapsedMs
): CellCategory {
  // REVIEW: why would threshold not be a number???
  let category
  if (!_.isNumber(threshold)) {
    return CellCategory.Later
  }
  if (!cell) {
    return CellCategory.Later
  }
  switch (cell.length) {
    case 0:
      return CellCategory.Practicing
    case 1:
      category = cellAnswerCategory(cell[0], threshold)
      return (
        category <= 1 ? category : CellCategory.Practicing
      ) as CellCategory
    case 2:
      const category1 = cellAnswerCategory(cell[0], threshold)
      const category2 = cellAnswerCategory(cell[1], threshold)
      if (category1 === 0 || category2 === 0) {
        return CellCategory.Fluent
      }
      if (category1 === 1 || category2 === 1) {
        return CellCategory.Correct
      }
      return CellCategory.Practicing

    default:
      const recent = cell.slice(-3)
      const len = recent.length
      const stats = [0, 0, 0, 0]
      for (let i = 0; i < len; i++) {
        category = cellAnswerCategory(recent[i], threshold)
        stats[category] += 1
      }
      if (stats[0] >= 2) {
        return CellCategory.Fluent
      }
      if (stats[0] + stats[1] >= 2) {
        return CellCategory.Correct
      }
      return CellCategory.Practicing
  }
}

export function convertAnswerToCellAnswer(answer: Answer): MatrixCellAnswer {
  // A "cell answer" is a more compact representation of an answer:
  //   Correct answer: number of milliseconds elapsed.
  //   Incorrect answer: two element array of the milliseconds elapsed and incorrect answer.
  //   Timeout: null
  // Note that the cell answer does not have the problem id. This is implicit in the
  // matrix cell position where the answer is stored.
  switch (answer.length) {
    case 1:
      return null // timeout
    case 2:
      return answer[1] // correct answer
    case 3:
      return [answer[1], answer[2]] // incorrect answer
    default:
      return <never>assert(false)
  }
}

// HELPER FUNCTIONS

function cellAnswerCategory(
  answer: MatrixCellAnswer,
  threshold: ElapsedMs
): AnswerCategory {
  if (typeof answer === 'number') {
    return threshold === 0 || answer <= threshold
      ? AnswerCategory.Fluent
      : AnswerCategory.Correct
  }
  return answer ? AnswerCategory.Incorrect : AnswerCategory.Timeout
}

//  0 - question never asked
//  1 - question asked only once
//  2 - next answer determines fluency, probably no
//  3 - next answer determines fluency, probably yes
//  4 - fluent a couple of times ago
//  5 - never fluent last three times
//  6 - not fluent a couple of times ago
//  7 - always fluent last three times
function cellPriority(cell: MatrixCell, threshold: ElapsedMs): CellPriority {
  let i
  if (cell.length < 2) {
    return cell.length
  }
  if (cell.length === 2) {
    i = _.reduce(
      _.takeRight(cell, 2),
      function (memo, answer) {
        return (
          (memo << 1) | (cellAnswerCategory(answer, threshold) === 0 ? 1 : 0)
        )
      },
      0
    )
    return [4, 2, 2, 7][i]
  }
  i = _.reduce(
    _.takeRight(cell, 3),
    function (memo, answer) {
      return (memo << 1) | (cellAnswerCategory(answer, threshold) === 0 ? 1 : 0)
    },
    0
  )
  return [5, 2, 2, 6, 4, 3, 3, 7][i]
}

// TYPESCRIPT: Keys of object must be array index numbers
export function convertObjectToArray(obj: PlainObject): any[] {
  const rval: any[] = []
  _.forEach(obj, function (val, key) {
    const i = parseInt(key, 10)
    if (isNaN(i)) {
      throw new Error('Object cannot be converted to array.')
    }
    rval[i] = val
  })
  return rval
}

function fixupBadMatrix(arr: Matrix): void {
  for (let i = 0; i < arr.length; i++) {
    const entry = arr[i]
    if (_.isObject(entry) && !_.isArray(entry)) {
      arr[i] = convertObjectToArray(entry)
    }
  }
}

// Some JSON implementations appear to be broken.
function fixupBadJsonSerialization(
  state: any,
  expectedMatrixLength: number
): StudentOperationItem {
  // LATER: notify server with warning or error if bad json serialization.

  // delete methods if they were serialized
  _.forEach(state, function (_val, key) {
    if (_.has(StudentOperation.prototype, key)) {
      delete state[key]
    }
  })

  const { matrix } = state
  if (matrix) {
    // if array entries of matrix got converted to objects then convert them back to arrays.
    fixupBadMatrix(matrix)
    // if nulls at end of the array were truncated then restore them.
    for (let i = matrix.length; i < expectedMatrixLength; i++) {
      matrix[i] = null
    }
  }

  if (state.progress) {
    // if array entries of progress got converted to objects then convert them back to arrays.
    fixupBadMatrix(state.progress)
  }
  return state
}

// REVIEW: Perhaps this belongs in common?
function randomSort<T>(_a: T, _b: T): number {
  return Math.random() - 0.5
}
