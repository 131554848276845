// REQUIREMENTS
import _ from 'lodash'

import { ProgressGraph } from '../types/account-types'
import { StudentOperation } from '../types/student-operation'

import { Spec, MatrixOptions, ProgressOptions } from './specify-graph'
import { SvgBuilder, SvgString } from './svg-builder'
import { Locale } from '../types/common'

// TYPES

// CONSTANTS

const DAYS_IN_PROGRESS_GRAPH = 62

// EXPORTED FUNCTIONS

export function specifyProgressGraph(
  data: ProgressGraph,
  size: number,
  locale: Locale,
  options?: ProgressOptions
) {
  const safeOptions = _.assign(options || {}, { locale })
  const spec = new Spec(size, size, 'progress')
    .addBackdrop()
    .addPaper()
    .setGrid()
    .addGridlines()
  if (data) {
    spec.addProgressGraph(data, safeOptions)
  }
  return spec
}

// HELPERS

function condensePoints(progress: ProgressGraph): number[] {
  const inputData = _.cloneDeep(progress)
  const rval = new Array(DAYS_IN_PROGRESS_GRAPH)
  const startXd = Math.max(
    inputData[0][0],
    _.last(inputData)[0] - DAYS_IN_PROGRESS_GRAPH + 1
  )
  _.forEach(inputData, function (point) {
    const index = point[0] - startXd
    if (DAYS_IN_PROGRESS_GRAPH > index && index >= 0) {
      const [, value] = point
      rval[index] = value
    }
  })
  return rval
}

export function specifyMatrix(
  studentOp: StudentOperation,
  size: number,
  options?: MatrixOptions
) {
  const safeOptions = options || {}
  const operation = studentOp.operation()
  _.assignIn(safeOptions, {
    matrixType: operation.matrixDimension() < 13 ? 'regular' : 'extended',
  })
  const spec = new Spec(size, size, safeOptions.matrixType)
  if (!safeOptions.standalone) {
    spec.addBackdrop()
  }
  return spec
    .addPaper()
    .setGrid()
    .addMatrix(studentOp, safeOptions)
    .addGridlines()
}

export function getMatrix(
  builder: SvgBuilder,
  studentOp: StudentOperation,
  size: number,
  options?: MatrixOptions
): SvgString {
  const safeOptions = options || {}
  const spec = specifyMatrix(studentOp, size, options)

  let svg = builder.header(size, size, size, size, safeOptions.dynamic, true)
  svg += `<defs>${builder.matrixDefs()}</defs>`
  svg += `<g>${builder.render(spec)}</g>`
  svg += builder.close()
  return svg
}

export function getProgressGraph(
  builder: SvgBuilder,
  data: ProgressGraph,
  size: any,
  locale: any,
  options?: ProgressOptions
): SvgString {
  const spec = specifyProgressGraph(data, size, locale, options)
  let svg = builder.header(size, size, size, size)
  svg += `<g>${builder.render(spec)}</g>`
  svg += builder.close()
  return svg
}

export function getSparklineGraph(
  builder: SvgBuilder,
  progress: ProgressGraph
) {
  const points = condensePoints(progress)
  const paths = builder.generateSvgPaths(
    points,
    240,
    240,
    DAYS_IN_PROGRESS_GRAPH
  )
  const svgHeader =
    '<svg viewBox="0 0 240 240" preserveAspectRatio="none"' +
    ' version="1.1" xmlns="http://www.w3.org/2000/svg" width="240" height="240">'
  const rval = `${svgHeader + paths}</svg>`
  return rval
}
