import _ from 'lodash'
import {
  AwardInfos,
  AwardInfo,
  AwardType,
  AwardsMap,
} from './types/account-types'

const MAX_PRIORITY = 2000
export const AWARD_INFOS: AwardInfos = {
  XE15: { priority: 1, imgId: 'XE15', suppresses: { XE: true } },
  XE: { priority: 2, imgId: 'XE' },
  N15: { priority: 3, imgId: 'N15' },
  RE30: { priority: 4, imgId: 'RE30', suppresses: { RE60: true } },
  RE60: { priority: 5, imgId: 'RE60' },
  XD: { priority: 6, imgId: 'XD-gold' },
  XM: { priority: 7, imgId: 'XM-gold' },
  XS: { priority: 8, imgId: 'XS-gold' },
  XA: { priority: 9, imgId: 'XA-gold' },

  'RD-30-100': {
    priority: 10,
    imgId: 'RD30-gold',
    suppresses: { 'RD-30-95': true, 'RD-30-75': true, 'RD-30-50': true },
  },
  'RD-30-95': {
    priority: 11,
    imgId: 'RD30-silver',
    suppresses: { 'RD-30-75': true, 'RD-30-50': true },
  },
  'RD-30-75': {
    priority: 12,
    imgId: 'RD30-bronze',
    suppresses: { 'RD-30-50': true },
  },
  'RD-30-50': { priority: 13, imgId: 'RD30-star' },

  'RM-30-100': {
    priority: 14,
    imgId: 'RM30-gold',
    suppresses: { 'RM-30-95': true, 'RM-30-75': true, 'RM-30-50': true },
  },
  'RM-30-95': {
    priority: 15,
    imgId: 'RM30-silver',
    suppresses: { 'RM-30-75': true, 'RM-30-50': true },
  },
  'RM-30-75': {
    priority: 16,
    imgId: 'RM30-bronze',
    suppresses: { 'RM-30-50': true },
  },
  'RM-30-50': { priority: 17, imgId: 'RM30-star' },

  'RS-30-100': {
    priority: 18,
    imgId: 'RS30-gold',
    suppresses: { 'RS-30-95': true, 'RS-30-75': true, 'RS-30-50': true },
  },
  'RS-30-95': {
    priority: 19,
    imgId: 'RS30-silver',
    suppresses: { 'RS-30-75': true, 'RS-30-50': true },
  },
  'RS-30-75': {
    priority: 20,
    imgId: 'RS30-bronze',
    suppresses: { 'RS-30-50': true },
  },
  'RS-30-50': { priority: 21, imgId: 'RS30-star' },

  'RA-30-100': {
    priority: 22,
    imgId: 'RA30-gold',
    suppresses: { 'RA-30-95': true, 'RA-30-75': true, 'RA-30-50': true },
  },
  'RA-30-95': {
    priority: 23,
    imgId: 'RA30-silver',
    suppresses: { 'RA-30-75': true, 'RA-30-50': true },
  },
  'RA-30-75': {
    priority: 24,
    imgId: 'RA30-bronze',
    suppresses: { 'RA-30-50': true },
  },
  'RA-30-50': { priority: 25, imgId: 'RA30-star' },

  'RD-60-100': {
    priority: 26,
    imgId: 'RD60-gold',
    suppresses: { 'RD-60-95': true, 'RD-60-75': true, 'RD-60-50': true },
  },
  'RD-60-95': {
    priority: 27,
    imgId: 'RD60-silver',
    suppresses: { 'RD-60-75': true, 'RD-60-50': true },
  },
  'RD-60-75': {
    priority: 28,
    imgId: 'RD60-bronze',
    suppresses: { 'RD-60-50': true },
  },
  'RD-60-50': { priority: 29, imgId: 'RD60-star' },

  'RM-60-100': {
    priority: 30,
    imgId: 'RM60-gold',
    suppresses: { 'RM-60-95': true, 'RM-60-75': true, 'RM-60-50': true },
  },
  'RM-60-95': {
    priority: 31,
    imgId: 'RM60-silver',
    suppresses: { 'RM-60-75': true, 'RM-60-50': true },
  },
  'RM-60-75': {
    priority: 32,
    imgId: 'RM60-bronze',
    suppresses: { 'RM-60-50': true },
  },
  'RM-60-50': { priority: 33, imgId: 'RM60-star' },

  'RS-60-100': {
    priority: 34,
    imgId: 'RS60-gold',
    suppresses: { 'RS-60-95': true, 'RS-60-75': true, 'RS-60-50': true },
  },
  'RS-60-95': {
    priority: 35,
    imgId: 'RS60-silver',
    suppresses: { 'RS-60-75': true, 'RS-60-50': true },
  },
  'RS-60-75': {
    priority: 36,
    imgId: 'RS60-bronze',
    suppresses: { 'RS-60-50': true },
  },
  'RS-60-50': { priority: 37, imgId: 'RS60-star' },

  'RA-60-100': {
    priority: 38,
    imgId: 'RA60-gold',
    suppresses: { 'RA-60-95': true, 'RA-60-75': true, 'RA-60-50': true },
  },
  'RA-60-95': {
    priority: 39,
    imgId: 'RA60-silver',
    suppresses: { 'RA-60-75': true, 'RA-60-50': true },
  },
  'RA-60-75': {
    priority: 40,
    imgId: 'RA60-bronze',
    suppresses: { 'RA-60-50': true },
  },
  'RA-60-50': { priority: 41, imgId: 'RA60-star' },

  'BS-30-100': {
    priority: 42,
    imgId: 'BS30-gold',
    suppresses: { 'BS-30-95': true, 'BS-30-75': true, 'BS-30-50': true },
  },
  'BS-30-95': {
    priority: 43,
    imgId: 'BS30-silver',
    suppresses: { 'BS-30-75': true, 'BS-30-50': true },
  },
  'BS-30-75': {
    priority: 44,
    imgId: 'BS30-bronze',
    suppresses: { 'BS-30-50': true },
  },
  'BS-30-50': { priority: 45, imgId: 'BS30-star' },

  'BA-30-100': {
    priority: 46,
    imgId: 'BA30-gold',
    suppresses: { 'BA-30-95': true, 'BA-30-75': true, 'BA-30-50': true },
  },
  'BA-30-95': {
    priority: 47,
    imgId: 'BA30-silver',
    suppresses: { 'BA-30-75': true, 'BA-30-50': true },
  },
  'BA-30-75': {
    priority: 48,
    imgId: 'BA30-bronze',
    suppresses: { 'BA-30-50': true },
  },
  'BA-30-50': { priority: 49, imgId: 'BA30-star' },

  'BS-60-100': {
    priority: 50,
    imgId: 'BS60-gold',
    suppresses: { 'BS-60-95': true, 'BS-60-75': true, 'BS-60-50': true },
  },
  'BS-60-95': {
    priority: 51,
    imgId: 'BS60-silver',
    suppresses: { 'BS-60-75': true, 'BA-60-50': true },
  },
  'BS-60-75': {
    priority: 52,
    imgId: 'BS60-bronze',
    suppresses: { 'BS-60-50': true },
  },
  'BS-60-50': { priority: 53, imgId: 'BS60-star' },

  'BA-60-100': {
    priority: 54,
    imgId: 'BA60-gold',
    suppresses: { 'BA-60-95': true, 'BA-60-75': true, 'BA-60-50': true },
  },
  'BA-60-95': {
    priority: 55,
    imgId: 'BA60-silver',
    suppresses: { 'BA-60-75': true, 'BA-60-50': true },
  },
  'BA-60-75': {
    priority: 56,
    imgId: 'BA60-bronze',
    suppresses: { 'BA-60-50': true },
  },
  'BA-60-50': { priority: 57, imgId: 'BA60-star' },
  unknown: { priority: MAX_PRIORITY, imgId: 'unknown' },
}

export function getAwardInfo(award: AwardType): AwardInfo {
  return AWARD_INFOS[award]
}

export function sortByAwardKey(awards: { [k in keyof AwardsMap]: any }): any[] {
  let prevPrio = 0
  return _.reduce(
    awards,
    (memo, award, key) => {
      const prio = AWARD_INFOS[key] ? AWARD_INFOS[key].priority : MAX_PRIORITY
      if (prio > prevPrio) {
        memo.push(award)
      } else {
        memo.unshift(award)
      }
      prevPrio = prio
      return memo
    },
    []
  )
}

export function sortAndPruneAwards(awards: AwardsMap): AwardType[] {
  const ignored = Object.keys(
    _.reduce(
      awards,
      (memo, _award, key) => {
        if (AWARD_INFOS[key] && AWARD_INFOS[key].suppresses) {
          memo = Object.assign(memo, AWARD_INFOS[key].suppresses)
        }
        return memo
      },
      {}
    )
  )
  const display = Object.keys(_.omit(awards, ignored)) as AwardType[]
  return sortAwardsList(display)
}

function sortAwardsList(awards: AwardType[]) {
  return _.sortBy(awards, (key) => {
    return AWARD_INFOS[key] ? AWARD_INFOS[key].priority : MAX_PRIORITY
  })
}
