import React, { useEffect, useState } from 'react'
import {
  Box,
  styled,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material'
import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { useStudentContext } from 'common/context/StudentContext'
import { AwardType } from 'common/utils/types/account-types'
import { awardPanel } from 'common/utils/awardPanel'
import Confetti from 'react-confetti'

import TrophyArrow from 'assets/student/trophy-arrow.svg'
import TrophyBackground from 'assets/student/trophy_background.svg'
import BannerCongrats from 'assets/student/banner-congrats.svg'
import NewTrophy from 'assets/student/new-trophy.svg'

const MotionBox = motion(Box)

const TransparentButton = styled(Button)``

const AWARDS_LIMIT = 4

function EarnedAwards() {
  const { studentSession } = useStudentContext()
  const theme = useTheme()
  const [earnedAwards, setEarnedAwards] = useState<AwardType[]>()
  const [initialAnimation, setInitialAnimation] = useState<boolean>(true)
  const [currentAward, setCurrentAward] = useState<number>(0)

  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    // activity.earnedAwards = ['XE', 'XE15']
    if (activity.earnedAwards) {
      setEarnedAwards(activity.earnedAwards.slice(0, AWARDS_LIMIT))
    }
  }, [studentSession])

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialAnimation(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  if (!earnedAwards) return null

  const awardsImages = awardPanel.awardsImagesURLs(earnedAwards)
  const awardsInfo = awardPanel.loadAwards(earnedAwards)
  const displayName = studentSession?.student.displayName

  const nextAward = () =>
    setCurrentAward((prev) => (prev + 1) % earnedAwards.length)
  const previousAward = () =>
    setCurrentAward(
      (prev) => (prev - 1 + earnedAwards.length) % earnedAwards.length
    )
  const handleClose = () => setEarnedAwards(null)

  return (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.5)',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Confetti />
      <AnimatePresence>
        {initialAnimation ? (
          <MotionBox
            key='congrats'
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0, scale: 1.5 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            exit={{ opacity: 1, y: 0, scale: 1 }}
            sx={{
              marginBottom: {
                xs: '140px',
                md: '20px',
              },
            }}
          >
            <Box
              component='img'
              alt='Congrats Banner'
              src={BannerCongrats}
              marginBottom={-15}
              sx={{ width: { xs: 200, md: 500 } }}
            />
          </MotionBox>
        ) : (
          <MotionBox
            key='congrats'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: isSm ? 1.6 : 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            sx={{
              marginBottom: {
                xs: '140px',
                md: '20px',
              },
            }}
          >
            <Box
              component='img'
              alt='Congrats Banner'
              src={BannerCongrats}
              sx={{ width: { xs: 200, md: 500 } }}
              marginBottom={-15}
            />
          </MotionBox>
        )}
        {initialAnimation && (
          <MotionBox
            key='trophy'
            initial={{
              opacity: 0,
              y: 200,
              scale: 0.01,
              position: 'absolute',
            }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            exit={{ opacity: 0, y: -200 }}
          >
            <Box
              component='img'
              mt={4}
              src={NewTrophy}
              alt='New Trophy Image'
            />
          </MotionBox>
        )}

        <MotionBox
          key='studentFrame'
          initial={{ opacity: 0, y: 200, scale: 0.01 }}
          animate={{ opacity: 1, y: 0, scale: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 3.5 }}
          sx={{
            width: { xs: 100, md: 200 },
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            backgroundColor: 'common.white',
            borderRadius: '4px',
            border: `5px solid ${theme.palette.secondary['300']}`,
            p: 1,
            mt: { xs: 1, md: 3 },
          }}
        >
          {_.capitalize(displayName)}
        </MotionBox>

        <MotionBox
          initial={{ opacity: 0, y: 200, scale: 0.01 }}
          animate={{ opacity: 1, y: 0, scale: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 3.5 }}
          sx={{
            backgroundImage: `url(${TrophyBackground})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            pb: 5,
          }}
        >
          <Box
            component='img'
            alt='Image of a trophy'
            src={awardsImages[currentAward]}
            sx={{ width: { xs: 200, md: 300 } }}
          />
        </MotionBox>
      </AnimatePresence>
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 4 }}
        display='flex'
        alignItems='center'
      >
        {earnedAwards.length > 1 && (
          <IconButton
            onClick={previousAward}
            aria-label='Previous award'
            sx={{
              mr: 2,
              backgroundColor: theme.palette.secondary['300'],
              transform: 'rotate(180deg)',
              '&:hover': {
                backgroundColor: 'common.white',
              },
            }}
          >
            <Box component='img' src={TrophyArrow} alt='Image of an arrow' />
          </IconButton>
        )}
        <Box
          sx={{
            backgroundColor: 'common.white',
            borderRadius: '4px',
            border: `5px solid ${theme.palette.secondary['300']}`,
            width: { xs: 200, md: 344 },
            fontSize: { xs: '0.7rem', md: '1rem' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2,
          }}
        >
          {currentAward === AWARDS_LIMIT - 1
            ? 'And more!'
            : awardsInfo[currentAward].tdesc}
        </Box>
        {earnedAwards.length > 1 && (
          <IconButton
            onClick={nextAward}
            aria-label='Next award'
            sx={{
              ml: 2,
              backgroundColor: theme.palette.secondary['300'],
              '&:hover': {
                backgroundColor: 'common.white',
              },
            }}
          >
            <Box component='img' src={TrophyArrow} alt='Image of an arrow' />
          </IconButton>
        )}
      </MotionBox>
      <Button
        color='secondary'
        variant='contained'
        sx={{ mt: 4 }}
        onClick={handleClose}
        aria-label='Close'
      >
        Close
      </Button>
    </Box>
  )
}

export default EarnedAwards
