import React, { useEffect, useRef, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { theme } from 'common/theme'
import { useStudentContext } from 'common/context/StudentContext'

interface VideoProps {
  video: string
  autoplay?: boolean
  [key: string]: unknown
}

function Video({ video, autoplay, ...props }: VideoProps) {
  const { enableCaptions } = useStudentContext()
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))
  const videoRef = useRef<HTMLVideoElement>()
  const { pathname } = useLocation()
  const path: string = pathname.split('/').pop()

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      return
    }
    videoRef.current?.load()
    setTimeout(() => {
      videoRef.current?.play()
    }, 100)
  }, [enableCaptions])

  if (!video) {
    return (
      <Box
        component='img'
        // eslint-disable-next-line import/no-dynamic-require, global-require
        src={require(`assets/student/${path}.svg`)}
        alt='Image of Mr. C'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    )
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
      }}
    >
      <Box
        component='video'
        ref={videoRef}
        playsInline
        autoPlay={isSM ? false : autoplay}
        onClick={() =>
          videoRef.current?.paused
            ? videoRef.current?.play()
            : videoRef.current?.pause()
        }
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
          objectFit: 'contain',
          display: 'block',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {video && (
          <>
            <source
              key={`webm-${enableCaptions}`}
              src={`https://cdn0.xtramath.org/videos/tablet/en/activity-animated/${video}${
                enableCaptions ? '-subtitled' : ''
              }.webm`}
              type='video/webm'
            />
            <source
              key={`mp4-${enableCaptions}`}
              src={`https://cdn0.xtramath.org/videos/tablet/en/activity-animated/${video}${
                enableCaptions ? '-subtitled' : ''
              }.mp4`}
              type='video/mp4'
            />
          </>
        )}
        <track kind='captions' />
      </Box>
    </Box>
  )
}

Video.defaultProps = {
  autoplay: true,
}

export default Video
