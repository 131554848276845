// StudentContext.js
import { StudentSession } from 'common/utils/types/student-session'
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'

export const StudentContext = createContext<{
  studentSession: StudentSession
  disableAnimation: boolean
  setDisableAnimation: React.Dispatch<React.SetStateAction<boolean>>
  enableCaptions: boolean
  setEnableCaptions: React.Dispatch<React.SetStateAction<boolean>>
}>(null)

export const useStudentContext = () => useContext(StudentContext)

export function StudentProvider({ children }) {
  const [studentSession, setStudentSession] = useState<StudentSession>()
  const [disableAnimation, setDisableAnimation] = useState<boolean>(
    localStorage.getItem('disableAnimations') === 'true'
  )
  const [enableCaptions, setEnableCaptions] = useState<boolean>(
    localStorage.getItem('enableCaptions') === 'true'
  )

  useEffect(() => {
    try {
      StudentSession.load()
        .then((student) => {
          if (!student) window.location.href = '/#/signin/student'
          setStudentSession(student)
        })
        .catch((err) => console.log(err))
    } catch (err) {
      console.log(err)
      window.location.href = '/#/signin/student'
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'disableAnimations',
      disableAnimation ? 'true' : 'false'
    )
    localStorage.setItem('enableCaptions', enableCaptions ? 'true' : 'false')
  }, [disableAnimation, enableCaptions])

  const value = useMemo(
    () => ({
      studentSession,
      disableAnimation,
      setDisableAnimation,
      enableCaptions,
      setEnableCaptions,
    }),
    [
      studentSession,
      disableAnimation,
      setDisableAnimation,
      enableCaptions,
      setEnableCaptions,
    ]
  )

  return (
    <StudentContext.Provider value={value}>{children}</StudentContext.Provider>
  )
}
