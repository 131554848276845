/* eslint-disable @typescript-eslint/no-empty-interface */

// REQUIREMENTS

import {
  PlainObject,
  Timestamp,
  IpAddress,
  TimestampSec,
  Locale,
  Milliseconds,
  Seconds,
  Timezone,
  EmailAddress,
} from './common'
import { XmDate } from './xm-date/shared'

import { Cache } from './cache'
import { Program } from './program'
import { StudentOperation } from './student-operation'

// TYPES - in alphabetical order

export type AccountCode = string
export type AccountCollection = AccountInstance[] | AccountMap
export type AccountDeltaMap = CodeMap<Delta>
export type AccountItemMap = CodeMap<AccountItem>
export type AccountMap = CodeMap<AccountInstance>
// C: Classroom, D: Device, S: Student, T: Teacher, X: deleted, L: License, V: venue (school), O: organization (district)
export type AccountPrefix = 'C' | 'D' | 'S' | 'T' | 'X' | 'L' | 'V' | 'O'
export type AccountSecret = string
export type AccountType =
  | 'teacher'
  | 'classroom'
  | 'student'
  | 'license'
  | 'school'
  | 'district'
export type AccountVersion = number
export type AmazonId = string
export type Answer = [number, number?, string?]
export type Answers = Answer[]
export type AppleId = string
export type AwardProgressionLevel = 1 | 2 | 3 | 4
export type AwardType = keyof typeof AwardTypes
export type Award = { earnedAt: Timestamp }
export type Awards = { [i in AwardType]?: Award } // REVIEW: validate award ids?
export type ClasslinkId = string
export type ClassroomCollection = ClassroomInstance[] | ClassroomMap
export type ClassroomItemMap = CodeMap<ClassroomItem>
export type ClassroomMap = CodeMap<ClassroomInstance>
export type ClassroomName = string
export type ClassroomSecret = string
export type CleverId = string
export type ClientCode = string
export type CodeCollection = CodeMap<any> | AccountCode[]
export type CodeSecretMap = CodeMap<AccountSecret>
export type CodeExistsMap = CodeMap<boolean>
export type CodeType =
  | 'classroomCodes'
  | 'expiredClassroomCodes'
  | 'studentCodes'
  | 'teacherCodes'
export type Delta = /* TYPESCRIPT: */ PlainObject
export type DeviceCode = string
export type EnrollCode = string
export type ElapsedMs = number // Elapsed milliseconds until student answers the question.
export type Entitlements =
  | ClassroomEntitlements
  | DistrictEntitlements
  | HomeschoolEntitlements
  | SchoolEntitlements
export type EmailDomain = string
export type FluencyThreshold = 0 | 1500 | 2000 | 3000 | 6000
export type FacebookId = string
export type GoogleId = string
export type LicensePublicInfoMap = CodeMap<TeacherLicenseInfo>
export type LicensePrivateInfoMap = CodeMap<AdminLicenseInfo>
export type LicenseType = 'district' | 'classroom' | 'homeschool' | 'school'
export type LicenseMap = CodeMap<LicenseInstance>
export type MasteryScore = number
export type MatrixCellAnswer = ElapsedMs | [ElapsedMs, WrongAnswer] | null
export type MatrixCell = MatrixCellAnswer[]
export type Matrix = MatrixCell[]
export type OAuthId = string // Letter indicating provider followed by provider-specific ID
export type OneRosterId = string
export type OperationId = number
export type ProgramDescription = {
  name: string
  listName?: string
  description?: string
  id: string
  hidden?: boolean
  paid?: boolean
}
export type ProgramsList = ProgramDescription[]
export type ProgressEntry = [XmDate, MasteryScore, Quality]
export type ProgressGraph = ProgressEntry[]
export type SigninMap = CodeMap<SigninAspect>
export type SsoId = string
// If you add a provider, then also add it to shared/accounts/accounts.ts/OAUTH_PROVIDER_TO_PREFIX and OAUTH_PREFIX_TO_PROVIDER.
export type SsoProvider =
  | 'amazon'
  | 'apple'
  | 'classlink'
  | 'clever'
  | 'facebook'
  | 'google'
export type StudentCollection = StudentInstance[] | StudentMap
export type StudentDisplayName = string
export type StudentItemMap = CodeMap<StudentItem>
export type StudentGrade = number //  // 0(K), 1-12
export type StudentMap = CodeMap<StudentInstance>
export type StudentName = string
export type StudentProgramId = number
export type StudentSecret = string
export type StudentStatus = 'done' | 'finished' | 'incomplete' | 'ready'
export type TeacherAccessSecret = string
export type TeacherAddressedAs = string
export type TeacherCollection = TeacherInstance[] | TeacherMap
export type TeacherItemMap = CodeMap<TeacherItem>
export type TeacherMap = CodeMap<TeacherInstance>
export type TeacherName = string
export type TeacherSecret = string
export type VerificationCode = string
export type WrongAnswer = string // Incorrect answer that the user typed in.

export type LinkEntry = {
  title: string
  isPdf?: boolean
  inlinePdf?: string
  href?: string
  video?: string
  mediaType?: string
  'href-alt'?: string
  'title-alt'?: string
  thumbnail?: string
  author?: string
}

export type CategoryEntry = {
  title: string
  entries?: LinkEntry[]
}

export type SubsectionEntry = {
  title: string
  href?: string
  'href-alt'?: string
  'title-alt'?: string
  description: string
  entries?: LinkEntry[] | CategoryEntry[]
}

export type Section = {
  title: string
  nested?: boolean
  anchor: string
  entries?: SubsectionEntry[]
}

export type ResourcesList = Section[]

export type PurchaseTypes =
  | 'homeschool'
  | 'classroom'
  | 'fifty-seats'
  | 'one-seat'
  | 'school'

export type PricesList = {
  [k in PurchaseTypes]?: {
    item: string
    price: string
    numSeats: number
    sku: string
    endsAt?: Timestamp
    discountedFrom?: string
    discountMessage?: string
    termLength?: number
  }
}

export type PricesLists = { [k in Locale]: PricesList }

// ENUMERATIONS

export enum ActivityId {
  PlacementQuiz = 13,
  ProgressQuiz = 14,
  Practice = 15,
}

export enum AnswerCategory {
  Fluent = 0,
  Correct = 1,
  Incorrect = 2,
  Timeout = 3,
}

export type AwardInfo = {
  priority: number
  imgId: string
  suppresses?: { [key in AwardType]?: true }
}

export type AwardInfos = {
  [key in AwardType]: AwardInfo
}

export enum AwardTypes {
  'BA-60-50',
  'BA-60-75',
  'BA-60-95',
  'BA-60-100',
  'BS-60-50',
  'BS-60-75',
  'BS-60-95',
  'BS-60-100',
  'BA-30-50',
  'BA-30-75',
  'BA-30-95',
  'BA-30-100',
  'BS-30-50',
  'BS-30-75',
  'BS-30-95',
  'BS-30-100',
  'RA-60-50',
  'RA-60-75',
  'RA-60-95',
  'RA-60-100',
  'RS-60-50',
  'RS-60-75',
  'RS-60-95',
  'RS-60-100',
  'RM-60-50',
  'RM-60-75',
  'RM-60-95',
  'RM-60-100',
  'RD-60-50',
  'RD-60-75',
  'RD-60-95',
  'RD-60-100',
  'RA-30-50',
  'RA-30-75',
  'RA-30-95',
  'RA-30-100',
  'RS-30-50',
  'RS-30-75',
  'RS-30-95',
  'RS-30-100',
  'RM-30-50',
  'RM-30-75',
  'RM-30-95',
  'RM-30-100',
  'RD-30-50',
  'RD-30-75',
  'RD-30-95',
  'RD-30-100',
  'RE30',
  'RE60',
  'N15',
  'XA',
  'XS',
  'XM',
  'XD',
  'XE',
  'XE15',
  'unknown',
}

export type AwardsMap = {
  [key in AwardType]?: Award
}

export enum CellCategory {
  Fluent = 0, // green
  Correct = 1, // yellow
  Practicing = 2, // gray
  Later = 3, // white
}

export enum ExpirationStatus {
  NOT_EXPIRED = 0,
  VISIBLY_EXPIRED,
  INVISIBLY_EXPIRED,
  EXPIRED,
}

export enum Quality {
  Incomplete = 0,
  Poor = 1,
  Fair = 2,
  Good = 3,
}

type ClassroomEntitlements = {
  adminAccounts: false
  classResources: true
  contracts: true
  contests: true
  defaultSeatLimit: 50
  emailReports: true
  liveSupport: true
  multipleTeachers: false
  offlineWorksheets: true
  roster: string[]
  sso: string[]
  training: true
}

type DistrictEntitlements = {
  adminAccounts: true
  classResources: true
  contracts: true
  contests: true
  defaultSeatLimit: undefined
  emailReports: true
  liveSupport: true
  multipleTeachers: true
  offlineWorksheets: true
  roster: string[]
  sso: string[]
  training: true
}

type SchoolEntitlements = {
  adminAccounts: true
  classResources: true
  contracts: true
  contests: true
  defaultSeatLimit: undefined
  emailReports: true
  liveSupport: true
  multipleTeachers: true
  offlineWorksheets: true
  roster: string[]
  sso: string[]
  training: true
}

type HomeschoolEntitlements = {
  adminAccounts: false
  classResources: true
  contracts: false
  contests: false
  defaultSeatLimit: 1
  emailReports: true
  liveSupport: true
  multipleTeachers: false
  offlineWorksheets: true
  roster: string[]
  sso: []
  training: true
}

// INTERFACES - in alphabetical order

export interface AccountClass {
  TYPE: AccountType
  PREFIX: AccountPrefix
  CODE_RE: RegExp

  // CONSTRUCTOR
  new (item: AccountItem): AccountInstance

  // METHODS
  accountCodes: (
    codeType: CodeType,
    accounts: AccountCollection,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  classroomCodes: (
    accounts: AccountCollection,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  studentCodes: (
    accounts: AccountCollection,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  teacherCodes: (
    accounts: AccountCollection,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  parentCodes: (
    accounts: AccountCollection,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  accountValuesOf: (
    prefix: AccountPrefix,
    account: AccountInstance,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  deltaFromOAuthId: (oAuthId: OAuthId) => Delta
  instantiate(
    item: AccountItem,
    cache?: Cache<any /* AccountInstance */>,
    _notSynced?: boolean
  ): AccountInstance // OVERLOADED: the ClientModels override this method but the DynamoModel methods do not
  findInCache: (
    code: AccountCode,
    cache?: Cache<any /* AccountInstance */>
  ) => AccountInstance | undefined
  accountsFromSimpleCache: <M>(
    codeType: CodeType,
    accounts: AccountCollection,
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  classroomsFromSimpleCache: <M>(
    accounts: AccountCollection,
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  studentsFromSimpleCache: <M>(
    accounts: AccountCollection,
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  teachersFromSimpleCache: <M>(
    accounts: AccountCollection,
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  prefixedCode: (code: AccountCode, prefix?: AccountPrefix) => string
}

export interface AccountDependencies {
  cCodes: CodeSecretMap
  sCodes: CodeSecretMap
  tCodes: CodeSecretMap
  xCodes: CodeSecretMap
}

export interface AccountsFromSimpleCacheOptions {
  noWarn?: boolean
  oblivious?: boolean
}

export interface AccountInstance extends AccountItem {
  accountCodes: (
    prefix: AccountPrefix,
    initialCodes?: CodeSecretMap
  ) => CodeSecretMap
  classroomCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap
  expiredClassroomCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap
  studentCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap
  childCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap
  teacherCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap
  parentCodes: (initialCodes?: CodeSecretMap) => CodeSecretMap

  dependencies: () => AccountDependencies

  deviceCodes: (initialCodes?: DeviceCodeMap) => DeviceCodeMap

  getName: () => string

  hasCodes: (prefix: AccountPrefix) => boolean
  hasChildren: () => boolean
  hasClassrooms: () => boolean
  hasExpiredClassrooms: () => boolean
  hasParents: () => boolean
  hasStudents: () => boolean
  hasTeachers: () => boolean

  hasAccountCode: (prefix: AccountPrefix, code: AccountCode) => boolean
  hasClassroomCode: (code: AccountCode) => boolean
  hasStudentCode: (code: AccountCode) => boolean
  hasChildCode: (code: AccountCode) => boolean
  hasTeacherCode: (code: AccountCode) => boolean

  hasAccount: (prefix: AccountPrefix, account: AccountInstance) => boolean
  hasClassroom: (account: AccountInstance) => boolean
  hasStudent: (account: AccountInstance) => boolean
  hasChild: (account: AccountInstance) => boolean
  hasTeacher: (account: AccountInstance) => boolean

  isAccountType: (type: AccountType) => boolean
  isClassroomAccount: () => boolean
  isStudentAccount: () => boolean
  isTeacherAccount: () => boolean

  numCodes: (prefix: AccountPrefix) => number
  numChildren: () => number
  numClassrooms: () => number
  numExpiredClassrooms: () => number
  numParents: () => number
  numStudents: () => number
  numTeachers: () => number

  oAuthId: () => OAuthId | undefined
  prefixedCode: (prefix?: AccountPrefix) => string
  ssoId: () => SsoId | undefined
  ssoProvider: () => SsoProvider | undefined

  bare: () => AccountItem

  accountsFromSimpleCache: <M>(
    codeType: CodeType,
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  classroomsFromSimpleCache: <M>(
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  expiredClassroomsFromSimpleCache: <M>(
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  studentsFromSimpleCache: <M>(
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
  teachersFromSimpleCache: <M>(
    cache: CodeMap<M>,
    initial?: CodeMap<M>,
    options?: AccountsFromSimpleCacheOptions
  ) => CodeMap<M>
}

// IMPORTANT: Keep synchronized with ACCOUNT_ITEM_SPEC in account-specs.
export interface AccountItem extends AccountProps, CreateAccountItem {
  code: AccountCode
  version: AccountVersion
  createdAt: Timestamp
  updatedAt: Timestamp
}

export interface AccountProps {
  appleId?: CleverId // REVIEW: teacher only?
  oneRosterId?: OneRosterId
  classlinkId?: ClasslinkId
  cleverId?: CleverId
  cleverName?: string
  socialId?: SsoId
  socialProvider?: SsoProvider
}

export interface AssignmentProps {
  activityId: ActivityId
  answers: Answer[]
  elapsed: Seconds
  elapsedActive?: Seconds // LATER: Make required when all clients supply it.
  operationId: number
  pauses?: Pauses // LATER: Make required when all clients supply it.
  quality: Quality // LATER: Move quality to AssignmentItem when client no longer supplies it.

  // REVIEW: Are these fields still relevant? They don't exist on most entries.
  deviceCode?: AccountCode
  ip?: IpAddress
  tally?: number
  xd?: number
}

// IMPORTANT: Keep synchronized with ASSIGNMENT_ITEM_SPEC in account-specs.
export interface AssignmentItem extends AssignmentProps {
  code: AccountCode
  timestamp: Timestamp
  ttl: TimestampSec
}

export interface ClassroomClass extends AccountClass {
  // INHERITED METHODS
  // findInCache: (code: AccountCode, cache: Cache) => ClassroomInstance|undefined;

  // PROPERTIES
  defaultExpirationTimestamp: () => Timestamp

  // METHODS
  compare: (
    classroomA: ClassroomInstance,
    classroomB: ClassroomInstance
  ) => number
  expirationStatus: (expires: Timestamp) => ExpirationStatus
  findByName: (classrooms: ClassroomMap, name: string) => ClassroomInstance
  sortedCodes: (
    cCodes: AccountCode[],
    classrooms: ClassroomMap
  ) => AccountCode[]
}

export interface ClassroomInstance extends AccountInstance, ClassroomItem {
  // PROPERTIES
  expirationStatus: () => ExpirationStatus
  isExpired: () => boolean
  isOrphan: () => boolean
  sortedStudentCodes: (students: StudentMap) => AccountCode[]
}

// IMPORTANT: Keep synchronized with CLASSROOM_ITEM_SPEC in account-specs.
export interface ClassroomItem extends ClassroomProps, AccountItem {
  expired?: boolean // True iff dynamo scan has converted all C links to X links.
  preparedClassroom?: boolean // Part of class checklist.
  printedFlyers?: boolean // Part of class checklist.
  printedPINs?: boolean // Part of class checklist.
  showedVideo?: boolean // Part of class checklist.
}

// IMPORTANT: Keep synchronized with CLASSROOM_PROPS_SPEC in account-specs.
export interface ClassroomProps extends AccountProps {
  addedStudents?: boolean
  hideChecklist?: boolean // True if checklist should not be shown in class report.
  expires: Timestamp // LATER: expires should be optional, and Clever provisioned classes should have no expires.
  hidden?: boolean // True if classroom is hidden due to being a non-XM related classroom imported from Clever.
  name: string
}

export type ClassroomSigninAspect = SigninAspect

export interface CodeMap<T> {
  [code: string /* AccountCode */]: T
}

export interface CodeSecret {
  code: AccountCode
  secret: AccountSecret
}

// IMPORTANT: Keep synchronized with CREATE_ACCOUNT_ITEM_SPEC in account-specs.
export interface CreateAccountItem {
  secret: AccountSecret

  locale?: Locale
  timezoneName?: Timezone
}

export interface CreateClassroomItem
  extends ClassroomProps,
    CreateAccountItem {}

export interface CreateStudentItem extends StudentProps, CreateAccountItem {}

export interface CreateTeacherItem extends TeacherProps, CreateAccountItem {}

export interface DeviceCodeMap {
  [code: string]: { code: string; timestamp: number }
}

export type LicenseClass = AccountClass

export interface LicenseInstance extends AccountInstance, LicenseItem {
  // PROPERTIES
}

export interface LicenseItem
  extends LicensePropsAdmin,
    LicensePropsTeacher,
    AccountItem {
  code: AccountCode
  createdAt: Timestamp
  district?: AccountCode
  // schools: AccountCode[];
}

export interface ContactInfo {
  name: string
  email: EmailAddress
}

export interface LicensePropsTeacher {
  admins: AccountCode[]
  contactEmail: EmailAddress
  contactName: string
  districtName?: string
  district?: string // TODO:
  endsAt: Timestamp
  name: string
  purgeAt: Timestamp
  stripePaymentId?: string
  schoolName?: string
  school?: string // TODO
  startsAt: Timestamp
  termLength?: number
  licenseType: LicenseType
}

export interface LicensePropsAdmin extends LicensePropsTeacher {
  teachers: AccountCode[]
  teacherAccessCode: TeacherAccessSecret
  teacherAccessExpires: Timestamp
}

export interface LicensePublicItem extends LicensePropsTeacher, AccountItem {
  code: AccountCode
  createdAt: Timestamp
  district?: AccountCode
  // schools: AccountCode[];
}

export interface LicensePrivateItem
  extends LicensePropsTeacher,
    LicensePropsAdmin,
    AccountItem {
  code: AccountCode
  createdAt: Timestamp
  district?: AccountCode
  // schools: AccountCode[];
}

export interface Pauses {
  [index: number]: Milliseconds
}

export interface SigninAspect {
  /* TYPESCRIPT: */
}

export interface StudentClass extends AccountClass {
  OPERATION_RE: RegExp

  // CONSTRUCTOR
  new (item: StudentItem): StudentInstance

  // INHERITED METHODS
  // findInCache: (code: AccountCode, cache: Cache) => ClassroomInstance|undefined;

  // CLASS METHODS
  compare: (studentA: StudentInstance, studentB: StudentInstance) => number
  findByName: (
    students: StudentMap,
    name: string
  ) => StudentInstance | undefined
  isActivityPractice: (activityId: ActivityId) => boolean
  isKnownProgram: (programId: number) => boolean
  isValidProgram: (programId: number) => boolean
  sortedCodes: (sCodes: AccountCode[], students: StudentMap) => AccountCode[]
}

export interface StudentInstance extends AccountInstance, StudentItem {
  // PROPERTIES
  currentStudentOperation: () => StudentOperation
  finishedWithStudentOp: (studentOperation: StudentOperation) => boolean
  hasUsage: () => boolean
  isDone: () => boolean
  isDoneWithProgram: (programId: number) => boolean
  isEnrolled: () => boolean
  isOrphan: () => boolean
  isKnownProgram: () => boolean
  isValidProgram: () => boolean
  program: () => Program
  operationIds: () => number[]
  studentOperation: (
    operationId: number,
    createIfMissing?: boolean
  ) => StudentOperation
  studentOperations: () => StudentOperation[]
  createStudentOperation: (operationId: number) => StudentOperation
}

// IMPORTANT: Keep synchronized with STUDENT_ITEM_SPEC in account-specs.
export interface StudentItem extends StudentProps, AccountItem {
  // OPTIONAL
  absentAt?: Timestamp
  busyAt?: Timestamp
  deleted?: boolean
  done?: boolean
  finishedAt?: Timestamp
  hideTeacher?: boolean
  hideTimer?: boolean
  lastActiveAt?: Timestamp
  lastActiveOn?: XmDate // TODO: Eliminate! Sometimes string in database! But: Report server uses this.
  lefty?: boolean
  mergedFrom?: CodeSecret[]
  mergedAt?: number
  mergedOn?: number // TODO: Eliminate mergedOn from the database.
  mergedTo?: CodeSecret
  noFix?: boolean
  numAdditionPlacementQuiz?: number
  numDivisionPlacementQuiz?: number
  numGoodbye?: number
  numMultiplicationPlacementQuiz?: number
  numPause?: number
  numPlacementQuizR?: number
  numPlacementAdditionQuizR?: number
  numPractice?: number
  numPracticeR?: number // REVIEW: What does the 'R' suffix mean?
  numProgressQuiz?: number
  numProgressQuizR?: number
  numSubtractionPlacementQuiz?: number
  numWelcome?: number
  videoTest?: string
  oldGoogleSocialId?: string
  preMergeOAuthId?: OAuthId
  showKeypad?: boolean
  startedAt?: Timestamp
  unMergedFrom?: CodeSecret[]

  // also:
  // op<op-num>
  // T<tCode>
  // C<cCode>
  // X<cCode>
}

export interface StudentOperationItem {
  operationId: number
  mastery?: number
  progress?: ProgressGraph
  matrix?: Matrix
  placementMastery?: number
  placementAt?: Timestamp
  finishedAt?: Timestamp
  printedAt?: Timestamp
}

// IMPORTANT: Keep synchronized with STUDENT_PROPS_SPEC in account-specs.
interface StudentProps extends AccountProps {
  awards?: Awards
  displayName: StudentDisplayName
  grade: StudentGrade // TODO: Make grade optional.
  localeOverride?: Locale
  name: string
  programId: StudentProgramId
  usage: Usage // REVIEW: Why is this required? Remove it.
}

export type StudentSigninAspect = SigninAspect

export interface TeacherClass extends AccountClass {
  // INHERITED METHODS
  // findInCache: (code: AccountCode, cache: Cache) => TeacherInstance|undefined;

  // CLASS METHODS
  compare: (teacherA: TeacherInstance, teacherB: TeacherInstance) => number
}

export interface TeacherInstance extends AccountInstance, TeacherItem {
  // PROPERTIES
  // INSTANCE PROPERTIES
  licenseInfos: { [code: string]: TeacherLicenseInfo }
  adminLicenseInfos: { [code: string]: AdminLicenseInfo }
  deltaForSignin: (timestamp: Timestamp) => Delta
  getAddressedAs: () => string
  hasLicenseAccess: () => boolean
  isLicenseAdmin: () => boolean
  isLicensed: () => boolean
  licenseCodes: () => AccountCode[]
  licenseAdminCodes: () => AccountCode[]
  sortedClassroomCodes: (classrooms: ClassroomMap) => AccountCode[]
}

// IMPORTANT: Keep synchronized with TEACHER_ITEM_SPEC in account-specs.
export interface TeacherItem extends TeacherProps, AccountItem {
  acceptsTerms?: boolean
  admin?: boolean
  bouncing?: boolean
  city?: string
  cityId?: number
  cleverSyncedAt?: Timestamp
  country?: string
  administersLicenses?: AccountCode[]
  lastActiveAt?: Timestamp
  licenses?: AccountCode[]
  mergedFromTeachers?: AccountCode[]
  noEmail?: boolean
  over16?: boolean
  previousIds?: {
    email: string
    socialId: string
    cleverid: string
    oneRosterId: string
    classlinkTenantid: string
    socialProvider: string
  }
  schools?: AccountCode[]
  signupIp?: IpAddress
  spam?: boolean
  state?: string
  terms?: TermsAgreement
  shouldFinishSignup: boolean
  under16?: boolean
  verificationCode?: string
  verifiedCode?: boolean
  wasDupe?: boolean
  zipCode?: string
}

export interface AdminLicenseInfo extends LicensePropsAdmin {
  code: AccountCode
  seatLimit?: number
  entitlements: Entitlements
  adminContactInfos?: ContactInfo[]
}

export interface TeacherLicenseInfo extends LicensePropsTeacher {
  code: AccountCode
  seatLimit?: number
  entitlements: Entitlements
  adminContactInfos?: ContactInfo[]
}

export interface TeacherNameAndEmail {
  name?: string
  email?: string
  addressedAs?: string
}

export interface TeacherNameEmailAndCode {
  code?: AccountCode
  name?: string
  email?: string
  addressedAs?: string
}

// IMPORTANT: Keep synchronized with TEACHER_PROPS_SPEC in account-specs.
interface TeacherProps extends AccountProps {
  addressedAs?: TeacherAddressedAs
  alerts?: boolean // Email preference: receive alerts?
  announcements?: boolean // Email preference: receive announcements?
  changePassword?: boolean
  email: EmailAddress
  emailDomain: EmailDomain
  isClasslinkAdmin?: boolean
  isParent?: boolean
  isTeacher?: boolean
  name?: TeacherName // TODO: Name is missing on some items. Enrollment signup or somethign?
  reminders2?: boolean // Email preference: receive reminders?
  reports?: boolean // Email preference: receive reports?
}

export type TeacherSigninAspect = SigninAspect

interface TermsAgreement {
  v: number
  at: Timestamp
}

export interface Usage {
  [xmDate: number /* XmDate */]: Quality
}
