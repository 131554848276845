const AWARD_DESCRIPTIONS = {
  XE15: {
    teacher:
      'Completed all four Expanded operations with a threshold of 1.5 seconds',
    student: 'Math Fact Super Star!',
  },
  XE: {
    teacher: 'Completed all four Expanded operations',
    student: 'Math Fact Hero!',
  },
  N15: {
    teacher: 'Completed an operation with a threshold of 1.5 seconds',
    student: "Wow! You're Super Fast!",
  },
  RE30: {
    teacher:
      'Completed all four operations with a threshold of 3 seconds or less',
    student: 'Math Fact Wizard!',
  },
  RE60: {
    teacher:
      'Completed all four operations with a threshold of 6 seconds or less',
    student: "You've done ALL the operations! Ready for a new challenge?",
  },
  XD: {
    teacher: 'Completed Expanded Division with a fluency score of 100',
    student: 'Amazing Work on division!',
  },
  XM: {
    teacher: 'Completed Expanded Multiplication with a fluency score of 100',
    student: 'Super Multiplier!',
  },
  XS: {
    teacher: 'Completed Expanded Subtraction with a fluency score of 100',
    student: 'Fantastic work on subtraction!',
  },
  XA: {
    teacher: 'Completed Expanded Addition with a fluency score of 100',
    student: 'Awesome Job on addition!',
  },
  'RD-30-100': {
    teacher: 'Completed Division (3 sec) with a fluency score of 100',
    student: 'Outstanding! You have completed division!',
  },
  'RD-30-95': {
    teacher: 'Reached a fluency score of 95 in Division (3 sec)',
    student: 'You got this! Just a bit more...',
  },
  'RD-30-75': {
    teacher: 'Reached a fluency score of 75 in Division (3 sec)',
    student: 'Looking sharp!',
  },
  'RD-30-50': {
    teacher: 'Reached a fluency score of 50 in Division (3 sec)',
    student: 'Keep going! You are doing great!',
  },
  'RM-30-100': {
    teacher: 'Completed Multiplication (3 sec) with a fluency score of 100',
    student: 'Outstanding! You have completed multiplication!',
  },
  'RM-30-95': {
    teacher: 'Reached a fluency score of 95 in Multiplication (3 sec)',
    student: 'You got this! Just a bit more...',
  },
  'RM-30-75': {
    teacher: 'Reached a fluency score of 75 in Multiplication (3 sec)',
    student: 'Looking sharp!',
  },
  'RM-30-50': {
    teacher: 'Reached a fluency score of 50 in Multiplication (3 sec)',
    student: 'Keep going! You are doing great!',
  },
  'RS-30-100': {
    teacher: 'Completed Subtraction (3 sec) with a fluency score of 100',
    student: 'Outstanding! You have completed subtraction!',
  },
  'RS-30-95': {
    teacher: 'Reached a fluency score of 95 in Subtraction (3 sec)',
    student: 'You got this! Just a bit more...',
  },
  'RS-30-75': {
    teacher: 'Reached a fluency score of 75 in Subtraction (3 sec)',
    student: 'Looking sharp!',
  },
  'RS-30-50': {
    teacher: 'Reached a fluency score of 50 in Subtraction (3 sec)',
    student: 'Keep going! You are doing great!',
  },
  'RA-30-100': {
    teacher: 'Completed Addition (3 sec) with a fluency score of 100',
    student: 'Outstanding! You have completed addition!',
  },
  'RA-30-95': {
    teacher: 'Reached a fluency score of 95 in Addition (3 sec)',
    student: 'You got this! Just a bit more...',
  },
  'RA-30-75': {
    teacher: 'Reached a fluency score of 75 in Addition (3 sec)',
    student: 'Looking sharp!',
  },
  'RA-30-50': {
    teacher: 'Reached a fluency score of 50 in Addition (3 sec)',
    student: 'Keep going! You are doing great!',
  },
  'RD-60-100': {
    teacher: 'Completed Division (6 sec) with a fluency score of 100',
    student: 'Success! You finished division!',
  },
  'RD-60-95': {
    teacher: 'Reached a fluency score of 95 in Division (6 sec)',
    student: 'Amazing job! Just a few more facts...',
  },
  'RD-60-75': {
    teacher: 'Reached a fluency score of 75 in Division (6 sec)',
    student: 'Looking sharp! Making great progress...',
  },
  'RD-60-50': {
    teacher: 'Reached a fluency score of 50 in Division (6 sec)',
    student: 'Keep it up! You are doing good!',
  },
  'RM-60-100': {
    teacher: 'Completed Multiplication (6 sec) with a fluency score of 100',
    student: 'Success! You finished multiplication!',
  },
  'RM-60-95': {
    teacher: 'Reached a fluency score of 95 in Multiplication (6 sec)',
    student: 'Amazing job! Just a few more facts...',
  },
  'RM-60-75': {
    teacher: 'Reached a fluency score of 75 in Multiplication (6 sec)',
    student: 'Looking sharp! Making great progress...',
  },
  'RM-60-50': {
    teacher: 'Reached a fluency score of 50 in Multiplication (6 sec)',
    student: 'Keep it up! You are doing good!',
  },
  'RS-60-100': {
    teacher: 'Completed Subtraction (6 sec) with a fluency score of 100',
    student: 'Success! You finished subtraction!',
  },
  'RS-60-95': {
    teacher: 'Reached a fluency score of 95 in Subtraction (6 sec)',
    student: 'Amazing job! Just a few more facts...',
  },
  'RS-60-75': {
    teacher: 'Reached a fluency score of 75 in Subtraction (6 sec)',
    student: 'Looking sharp! Making great progress...',
  },
  'RS-60-50': {
    teacher: 'Reached a fluency score of 50 in Subtraction (6 sec)',
    student: 'Keep it up! You are doing good!',
  },
  'RA-60-100': {
    teacher: 'Completed Addition (6 sec) with a fluency score of 100',
    student: 'Success! You finished addition!',
  },
  'RA-60-95': {
    teacher: 'Reached a fluency score of 95 in Addition (6 sec)',
    student: 'Amazing job! Just a few more facts...',
  },
  'RA-60-75': {
    teacher: 'Reached a fluency score of 75 in Addition (6 sec)',
    student: 'Looking sharp! Making great progress...',
  },
  'RA-60-50': {
    teacher: 'Reached a fluency score of 50 in Addition (6 sec)',
    student: 'Keep it up! You are doing good!',
  },
  'BS-30-100': {
    teacher:
      'Completed Beginning Subtraction (3 sec) with a fluency score of 100',
    student: 'Great work! You finished subtraction!',
  },
  'BS-30-95': {
    teacher: 'Reached a fluency score of 95 in Beginning Subtraction (3 sec)',
    student: 'Super! Almost Done!',
  },
  'BS-30-75': {
    teacher: 'Reached a fluency score of 75 in Beginning Subtraction (3 sec)',
    student: 'Doing Great!',
  },
  'BS-30-50': {
    teacher: 'Reached a fluency score of 50 in Beginning Subtraction (3 sec)',
    student: 'Looking sharp! Keep going!',
  },
  'BA-30-100': {
    teacher: 'Completed Beginning Addition (3 sec) with a fluency score of 100',
    student: 'Great work! You finished addition!',
  },
  'BA-30-95': {
    teacher: 'Reached a fluency score of 95 in Beginning Addition (3 sec)',
    student: 'Super! Almost Done!',
  },
  'BA-30-75': {
    teacher: 'Reached a fluency score of 75 in Beginning Addition (3 sec)',
    student: 'Doing Great!',
  },
  'BA-30-50': {
    teacher: 'Reached a fluency score of 50 in Beginning Addition (3 sec)',
    student: 'Looking sharp! Keep going!',
  },
  'BS-60-100': {
    teacher:
      'Completed Beginning Subtraction (6 sec) with a fluency score of 100',
    student: 'Awesome! You finished subtraction!',
  },
  'BS-60-95': {
    teacher: 'Reached a fluency score of 95 in Beginning Subtraction (6 sec)',
    student: 'Super! Almost Done!',
  },
  'BS-60-75': {
    teacher: 'Reached a fluency score of 75 in Beginning Subtraction (6 sec)',
    student: 'Doing Great!',
  },
  'BS-60-50': {
    teacher: 'Reached a fluency score of 50 in Beginning Subtraction (6 sec)',
    student: 'Looking sharp! Keep going!',
  },
  'BA-60-100': {
    teacher: 'Completed Beginning Addition (6 sec) with a fluency score of 100',
    student: 'Awesome! You finished addition!',
  },
  'BA-60-95': {
    teacher: 'Reached a fluency score of 95 in Beginning Addition (6 sec)',
    student: 'Super! Almost Done!',
  },
  'BA-60-75': {
    teacher: 'Reached a fluency score of 75 in Beginning Addition (6 sec)',
    student: 'Doing Great!',
  },
  'BA-60-50': {
    teacher: 'Reached a fluency score of 50 in Beginning Addition (6 sec)',
    student: 'Looking sharp! Keep going!',
  },
}

export default AWARD_DESCRIPTIONS
