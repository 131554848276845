// Safari throws an exception setting localStorage in private browsing mode.

import { AjaxConnection2 } from '.'

let establishedConnection: AjaxConnection2 = null

// GLOBALS

// returns <a promise for> the connection
function connect(domain, clientConfig, reconnect?): AjaxConnection2 {
  if (!reconnect && establishedConnection) {
    return establishedConnection
  }
  domain = domain || `${window.location.protocol}//${window.location.host}`
  establishedConnection = AjaxConnection2.connect(domain, clientConfig)
  return establishedConnection
}

function connection() {
  return establishedConnection
}

export const server = { connect, connection }

// HELPER FUNCTIONS
