import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getMatrix } from 'common/utils/graph-generator'
import { svgBuilder } from 'common/utils/graph-generator/svg-builder'
import { useStudentContext } from 'common/context/StudentContext'
import Matrix from 'student/common/matrix'
import Video from 'student/common/video'
import { PaperContainer } from 'student/common/taskUI'

const VIDEOS = {
  0: '0027',
  1: '0029',
  2: '0031',
  3: '0033',
}

const VIDEO_CATEGORY = 'numPractice'

const FADE_CLASS = 'fade-in'

function PraciceIntro() {
  const [matrixSVG, setMatrixSVG] = useState<string>('')
  const { studentSession } = useStudentContext()
  const [currentOperation, setCurrentOperation] = useState<string>('')
  const [video, setVideo] = useState<string>('')
  const [fadedClasses, setFadedClasses] = useState<string>('')
  const [studentName, setStudentName] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setFadedClasses(FADE_CLASS)
    }, 3000)
    return () => clearTimeout(fadeTimeout)
  }, [])

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    setStudentName(studentSession.student.displayName)

    const studentOperation = studentSession.student.studentOperation(
      activity.operationId,
      true
    )
    const matrix = getMatrix(svgBuilder, studentOperation, 410, {
      practice: activity,
      standalone: true,
    })
    const operationName = _.capitalize(activity.operation().mathOperationKey)
    setCurrentOperation(operationName)
    setMatrixSVG(matrix)
    const videoSrc = VIDEOS[studentSession.student.getVideo(VIDEO_CATEGORY)]
    setVideo(videoSrc)
  }, [studentSession])

  const handleContinue = () => {
    studentSession.screenFinished('practice_intro').then(() => {
      const { screen } = studentSession.currentState()
      navigate(`/app/student/${screen}`)
    })
  }

  return (
    <Container>
      <PaperContainer sx={{ px: 2 }}>
        <Box py={2}>
          <Typography variant='h3' sx={{ fontWeight: 600 }}>
            Practice: {currentOperation}
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontSize: { xs: '1rem', md: '1.5rem' },
              my: 2,
            }}
          >
            You&apos;re doing fantastic, <b>{studentName}</b>! Time for
            practice. You&apos;ll be working on some math problems to improve
            your skills, and this will make you even better next&nbsp;time!
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='end'
          justifyContent='space-between'
          mt='auto'
        >
          <Box
            flex='0 0 50%'
            mb={-1}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Video video={video} />
          </Box>
          <Box sx={{ flex: { xs: '1', md: '0 0 50%' } }} height='100%'>
            <Matrix svg={matrixSVG} className={fadedClasses} />
          </Box>
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default PraciceIntro
