// REQUIREMENTS

import { inherits } from './common'

import {
  TeacherClass,
  TeacherItem,
  TeacherInstance,
  ClassroomMap,
  AccountCode,
} from './account-types'
import { Account } from './account'
import { Classroom } from './classroom'

// EXPORTS

export const Teacher: TeacherClass = TeacherConstructor as any

// CONSTRUCTOR

const superclass = Account
function TeacherConstructor(item: TeacherItem) {
  superclass.call(this, item)
}
const constructor: TeacherClass = <
  { (item: TeacherItem): void } & TeacherClass
>TeacherConstructor
inherits(constructor, superclass)
const proto: TeacherInstance = constructor.prototype

// CLASS CONSTANTS

constructor.TYPE = 'teacher'
constructor.PREFIX = 'T'

// CLASS PROPERTIES

// CLASS METHODS

// Compare two teachers for sorting order.
// Returns:
//   -1 if teacherA should be sorted before teacherB,
//   0 if teacherA and teacherB sort the same,
//   1 if teacherA should be sorted after teacherB.
constructor.compare = function (
  this: TeacherClass,
  teacherA: TeacherInstance,
  teacherB: TeacherInstance
): number {
  if (teacherA === teacherB) {
    return 0
  }

  // Sort missing classrooms below all others
  if (!teacherA) {
    return 1
  }
  if (!teacherB) {
    return -1
  }

  // Sort by lowercase name
  // REVIEW: I18N?
  const aName = teacherA.name ? teacherA.name.toLowerCase() : '~'
  const bName = teacherB.name ? teacherB.name.toLowerCase() : '~'
  if (aName < bName) {
    return -1
  }
  if (aName === bName) {
    return 0
  }
  return 1
}

// INSTANCE PROPERTIES

proto.getAddressedAs = function (this: TeacherInstance): string {
  return this.addressedAs || this.getName() || this.email
}

proto.hasLicenseAccess = function (this: TeacherInstance): boolean {
  return this.isLicensed() || this.isLicenseAdmin()
}

proto.isLicenseAdmin = function (this: TeacherInstance): boolean {
  if (this.administersLicenses && this.administersLicenses.length > 0) {
    return true
  }
  return false
}

proto.isLicensed = function (this: TeacherInstance): boolean {
  if (this.licenses && this.licenses.length > 0) {
    return true
  }
  return false
}

proto.licenseCodes = function (this: TeacherInstance): AccountCode[] {
  return this.licenses || []
}

proto.licenseAdminCodes = function (this: TeacherInstance): AccountCode[] {
  return this.administersLicenses || []
}

proto.sortedClassroomCodes = function (
  this: TeacherInstance,
  classrooms: ClassroomMap
): AccountCode[] {
  const cCodes = Object.keys(this.classroomCodes())
  return Classroom.sortedCodes(cCodes, classrooms)
}
