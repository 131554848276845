/* eslint-disable prefer-destructuring */
// REQUIREMENTS

import _ from 'lodash'
import q from 'q'

import {
  Timestamp,
  inherits,
  mixin,
  makeRandomString,
  newObject,
  assert,
} from './common'
import { XmDate } from './xm-date/shared'

import {
  AddChildParams,
  AddStudentFromSigninParams,
  AddStudentParams,
  CreateChildParams,
  CreateStudentParams,
  StudentSignupParams,
  StudentRememberedSigninParams,
  StudentSigninParams,
  EditStudentParams,
  GetAssignmentsParams,
  ClassroomRemoveStudentParams,
  TeacherRemoveStudentParams,
  StudentRemoveSsoParams,
  StudentSignoutParams,
  StudentSignupRval,
  SubmitAssignmentParams,
  UpdateStatusParams,
  SubmitAssignmentRval,
} from './web-server/api-types'

import {
  StudentItem,
  CreateStudentItem,
  AccountSecret,
  Delta,
  StudentStatus,
  AssignmentItem,
  Quality,
  AssignmentProps,
  SsoProvider,
} from './account-types'
import {
  ClientStudentClass,
  ClientStudentInstance,
  AddChildToParentFields,
  ClientTeacherInstance,
  AddStudentToClassroomFields,
  ClientClassroomInstance,
  AddStudentFromSigninFields,
  AddStudentFields,
  CreateChildFields,
  CreateStudentFields,
  ClientTeacherClass,
  StudentSigninRval,
} from './account-types-client'
import * as xmDate from './xm-date/client-side'
import { Cache } from './cache'
import { ClientMixin } from './client-mixin'
import { Student } from './student'
import { StudentOperation } from './student-operation'

const logger = console.log

// EXPORTS

export const ClientStudent: ClientStudentClass = ClientStudentConstructor as any

// CONSTANTS

const PIN_CHARS = '0123456789'
const PIN_LENGTH = 4

// Legal:
// A-Z, a-z, 0-9, spaces, periods, hyphens
// various "apsotrophes": single quotes, right single quotes, modifier letter apostrophes
// Latin-1 supplementary alphabetical characters with diacritical marks.

// CONSTRUCTOR
const superclass = Student
function ClientStudentConstructor(item: StudentItem) {
  superclass.call(this, item)
  ClientMixin.call(this, item)
}
// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_RE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: ClientStudentClass = <
  { (item: StudentItem): void } & ClientStudentClass
>ClientStudentConstructor
ClientMixin.referenceDerivedClass('students', constructor)
inherits(constructor, superclass)
mixin(constructor, ClientMixin)
const proto: ClientStudentInstance = constructor.prototype

// CLASS CONSTANTS

constructor.signinFieldsRE =
  /^(absentAt|busyAt|code|displayName|finishedAt|name|startedAt|done|deleted|version)$|^T|^C/
constructor.secretFieldsRE = /^C|^S/
constructor.typePlural = 'students'

// CLASS PROPERTIES

// CLASS METHODS

constructor.addToParent = function (
  this: ClientStudentClass,
  fields: AddChildToParentFields,
  teacher: ClientTeacherInstance
): q.Promise<{ student: ClientStudentInstance; status: 'already-enrolled' }> {
  // REVIEW: should this just be a constructor method that can also return a new student?
  const that = this
  const item = {
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
    tVersion: teacher.version,
  }
  assert(fields.sCode && fields.sSecret)
  const req = _.assign(item, fields)
  return (<ClientStudentClass>this).connection
    .emitToServer<AddChildParams>('teacher/add-child', req, 'blocking')
    .then(function (result) {
      if (result.status === 'already-enrolled') {
        return result
      }
      const student = that.create(result.student, that.caches.students)
      teacher.applyDelta(result.teacherDelta)
      return student
    })
}

constructor.addToClassroom = function (
  this: ClientStudentClass,
  fields: AddStudentToClassroomFields,
  teacher: ClientTeacherInstance,
  classroom: ClientClassroomInstance
): q.Promise<ClientStudentInstance> {
  const that = this
  const item = {
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
    cCode: classroom.code,
    cVersion: classroom.version,
  }
  let promise
  if (fields.email !== undefined) {
    const req = _.assign(item, <AddStudentFromSigninFields>fields)
    promise = (<ClientStudentClass>(
      this
    )).connection.emitToServer<AddStudentFromSigninParams>(
      'classroom/add-student-from-signin',
      req,
      'blocking'
    )
  } else {
    const req = _.assign(item, <AddStudentFields>fields)
    promise = (<ClientStudentClass>(
      this
    )).connection.emitToServer<AddStudentParams>(
      'classroom/add-student',
      req,
      'blocking'
    )
  }
  return promise.then(function (result) {
    const student = that.create(result.student, that.caches.students)
    classroom.applyDelta(result.classroomDelta)
    return student
  })
}

constructor.create = function (
  this: ClientStudentClass,
  item: any,
  cache?: Cache<ClientStudentInstance>,
  notSynced?: boolean
): any {
  item = _.assign({}, item)
  return ClientMixin.create.call(this, item, cache, notSynced)
}

constructor.localCreate = function (this: ClientStudentClass, item: any): any {
  return ClientMixin.create.call(this, item, this.caches.students)
}

constructor.signinCreate = function (this: ClientStudentClass, item: any): any {
  item = this.filterSigninFields(item)
  return ClientMixin.create.call(this, item, this.caches.studentSignins)
}

constructor.instantiate = function (
  this: ClientStudentClass,
  item: any,
  cache?: Cache<ClientStudentInstance>,
  notSynced?: boolean
): any {
  return superclass.instantiate.call(this, item, cache, notSynced)
}

constructor.createInParent = function (
  this: ClientStudentClass,
  fields: CreateChildFields,
  teacher: ClientTeacherInstance
): q.Promise<ClientStudentInstance> {
  const that = this
  const args = _.assign(
    {
      tCode: teacher.code,
      tSecret: teacher.getSecret(),
      tVersion: teacher.version,
    },
    fields
  )
  return (<ClientStudentClass>that).connection
    .emitToServer<CreateChildParams>('teacher/create-student', args, 'blocking')
    .then(function (results) {
      teacher.applyResult(results)
      return that.create(results.student, that.caches.students)
    })
}

constructor.createInClassroom = function (
  this: ClientStudentClass,
  fields: CreateStudentFields,
  teacher: ClientTeacherInstance,
  classroom: ClientClassroomInstance
): q.Promise<ClientStudentInstance> {
  const that = this
  const args = _.assign(
    {
      tCode: teacher.code,
      tSecret: teacher.getSecret(),
      cCode: classroom.code,
      cVersion: classroom.version,
    },
    fields
  )
  return (<ClientStudentClass>that).connection
    .emitToServer<CreateStudentParams>(
      'classroom/create-student',
      args,
      'blocking'
    )
    .then(function (results) {
      classroom.applyResult(results)
      return that.create(results.student, that.caches.students)
    })
}

constructor.generatePin = function (this: ClientStudentClass): AccountSecret {
  return makeRandomString(PIN_CHARS, PIN_LENGTH)
}

constructor.signup = function (
  this: ClientStudentClass,
  fields
): q.Promise<StudentSignupRval & { student: ClientStudentInstance }> {
  const that = this
  logger(fields, 'Creating')
  return (<ClientStudentClass>this).connection
    .emitToServer<StudentSignupParams>('student/signup', fields, 'blocking')
    .then(function (result: StudentSignupRval) {
      // TODO: we appear to be sporadically getting an error where result[that.typePlural] is undefined.
      const instantiated = {
        student: that.create(result.student, that.caches.students),
      }
      return _.assign(result, instantiated)
    })
}

constructor.signInWithCode = function (
  this: ClientStudentClass,
  fields: Omit<StudentRememberedSigninParams, 'client' | 'sVersion'>,
  isClassroomSignin: boolean
): q.Promise<StudentSigninRval> {
  const that = this
  const remembered = this.findInCache(
    fields.sCode,
    this.caches.students
  ) as ClientStudentInstance
  const version = { sVersion: remembered ? remembered.version : 0 }
  let promise
  if (isClassroomSignin) {
    promise = (<ClientStudentClass>(
      this
    )).connection.emitToServer<StudentRememberedSigninParams>(
      'student/classroom-signin-2',
      _.assign(fields, version),
      'blocking'
    )
  } else {
    promise = (<ClientStudentClass>(
      this
    )).connection.emitToServer<StudentRememberedSigninParams>(
      'student/remembered-signin',
      _.assign(fields, version),
      'blocking'
    )
  }
  return promise.then(function (result) {
    if (result.studentDelta) {
      remembered.applyDelta(result.studentDelta)
      return _.assign(result, { student: remembered })
    }
    return _.assign(result, {
      student: that.create(result.student, that.caches.students),
    })
  })
}

constructor.signIn = function (
  this: ClientStudentClass,
  fields
): q.Promise<StudentSigninRval> {
  const that = this
  return (<ClientStudentClass>this).connection
    .emitToServer<StudentSigninParams>('student/signin', fields, 'blocking')
    .then(function (result) {
      return _.assign(result, {
        student: that.create(result.student, that.caches.students),
      })
    })
}

// INSTANCE PROPERTIES

proto.currentStatus = function (this: ClientStudentInstance): StudentStatus {
  if (this.secret ? this.isDone() : this.isDonePublic()) {
    return 'done'
  }
  if (this.isFinished()) {
    return 'finished'
  }
  if (this.isIncomplete()) {
    return 'incomplete'
  }
  return 'ready'
}

proto.edit = function (
  this: ClientStudentInstance,
  fields,
  teacher: ClientTeacherInstance
): q.Promise<ClientStudentInstance | void> {
  const that = this
  const delta = this.generateDelta(fields)
  if (!delta) {
    return q()
  }
  const args = _.assign(
    {
      tCode: teacher.code,
      tSecret: teacher.getSecret(),
      sCode: that.code,
      sVersion: that.version,
    },
    delta
  )
  const constructor = that.constructor as ClientStudentClass
  return constructor.connection
    .emitToServer<EditStudentParams>('student/edit', args, 'blocking')
    .then(function (result) {
      that.applyResult(result, constructor.caches.students)
      return result
    })
}

proto.firstXdWithUsage = function (this: ClientStudentInstance): XmDate {
  let rval = !_.isEmpty(this.usage) && xmDate.today(this.getTimezoneOffset())
  _.forEach(this.usage, function (_val, key) {
    const thisUsage = parseInt(key, 10)
    if (rval > thisUsage) {
      rval = thisUsage
    }
  })
  return rval
}

proto.getAssignments = function (
  this: ClientStudentInstance,
  xd: XmDate,
  offset: number
): q.Promise<AssignmentItem[]> {
  const after = xmDate.timestampFromXd(xd, offset)
  const before = xmDate.timestampFromXd(xd, offset) + 24 * 60 * 60 * 1000 - 1 // add one day minus one ms
  const req = {
    after,
    before,
    sCode: this.code,
    sSecret: this.getSecret(),
  }
  const constructor = this.constructor as ClientStudentClass
  return constructor.connection
    .emitToServer<GetAssignmentsParams>(
      'student/get-assignments',
      req,
      'blocking'
    )
    .then(function (result) {
      return result.assignments
    })
}

proto.getTimezone = function (this: ClientStudentInstance): string {
  return this.timezoneName || 'America/Los_Angeles'
}

proto.getVideo = function (
  this: ClientStudentInstance,
  category: string
): number {
  return this[category] || 0
}

proto.hasParent = function (
  this: ClientStudentInstance,
  code: string
): AccountSecret | undefined {
  return this.teacherCodes()[code]
}

proto.hideTeacherSetter = function (
  this: ClientStudentInstance,
  val?: boolean
): boolean {
  if (arguments.length > 0) {
    const delta = newObject('hideTeacher', val)
    this.postponeDelta('updatePreferences', delta)
  }
  return !!this.hideTeacher
}

proto.hideTimerSetter = function (
  this: ClientStudentInstance,
  val?: boolean
): boolean {
  if (arguments.length > 0) {
    const delta = newObject('hideTimer', val)
    this.postponeDelta('updatePreferences', delta)
  }
  return !!this.hideTimer
}

proto.isAbsent = function (this: ClientStudentInstance): boolean {
  const now = xmDate.now()
  return this.absentAt && (now - this.absentAt) / 1000 / 60 / 60 < 8
}

proto.isBusy = function (this: ClientStudentInstance): boolean {
  const now = xmDate.now()
  return this.busyAt && (now - this.busyAt) / 1000 / 60 < 30
}

proto.isDonePublic = function (this: ClientStudentInstance): boolean {
  // TODO: this should be handled on the server anyhow.
  return this.done
}

proto.isFinished = function (
  this: ClientStudentInstance /* offset??? */
): boolean {
  const now = xmDate.now()
  return this.finishedAt && isSameDay(now, this.finishedAt, this.getTimezone())
}

proto.isIncomplete = function (
  this: ClientStudentInstance /* offset??? */
): boolean {
  const now = xmDate.now()
  const startedToday =
    this.startedAt && isSameDay(now, this.startedAt, this.getTimezone())
  return startedToday && !this.isFinished()
}

proto.lastXdWithUsage = function (this: ClientStudentInstance): XmDate {
  let rval
  if (!_.isEmpty(this.usage)) {
    rval = 0
  }
  _.forEach(this.usage, function (_val, key) {
    const thisUsage = parseInt(key, 10)
    if (rval < thisUsage) {
      rval = thisUsage
    }
  })
  return rval
}

proto.leftySetter = function (
  this: ClientStudentInstance,
  val?: boolean
): boolean {
  if (arguments.length > 0) {
    const delta = newObject('lefty', val)
    this.postponeDelta('updatePreferences', delta)
  }
  return this.lefty
}

proto.numUnprinted = function (
  this: ClientStudentInstance,
  cutoff: Timestamp
): number {
  return _.reduce(
    this.studentOperations(),
    function (sum, operation) {
      const operationIsCurrent = cutoff ? operation.finishedAt >= cutoff : true
      if (operationIsCurrent && operation.isUnprinted()) {
        sum += 1
      }
      return sum
    },
    0
  )
}

proto.qualityOnXd = function (
  this: ClientStudentInstance,
  xd: XmDate
): Quality {
  let rval
  if (this.usage) {
    const entry = this.usage[xd]
    if (entry !== undefined) {
      // could be 0 which is falsey.
      if (_.isArray(entry)) {
        rval = entry[0]
      } else {
        rval = entry
      }
    }
  }
  return rval
}

proto.removeFromClassroom = function (
  this: ClientStudentInstance,
  teacher: ClientTeacherInstance,
  classroom: ClientClassroomInstance
): q.Promise<void> {
  const that = this
  const fields = {
    sCode: that.code,
    cCode: classroom.code,
    cVersion: classroom.version,
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
  }
  return (<ClientStudentClass>that.constructor).connection
    .emitToServer<ClassroomRemoveStudentParams>(
      'classroom/remove-student',
      fields,
      'blocking'
    )
    .then(function (results) {
      // NOTE: we currently don't have a studentDelta, but will need one for offline
      // operation. Asana task: https://app.asana.com/0/98084285153221/111089789544063
      const studentDelta = newObject(`C${classroom.code}`, null)
      that.applyDelta(studentDelta)
      classroom.applyResult(results)
      return results
    })
}

proto.removeFromParent = function (
  this: ClientStudentInstance,
  teacher: ClientTeacherInstance
): q.Promise<void> {
  const that = this
  const fields = {
    sCode: that.code,
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
    tVersion: teacher.version,
  }
  return (<ClientStudentClass>that.constructor).connection
    .emitToServer<TeacherRemoveStudentParams>(
      'teacher/remove-student',
      fields,
      'blocking'
    )
    .then(function (results) {
      // NOTE: we currently don't have a studentDelta, but will need one for offline
      // operation. Asana task: https://app.asana.com/0/98084285153221/111089789544063
      const studentDelta = newObject(`T${teacher.code}`, null)
      that.applyDelta(studentDelta)
      teacher.applyResult(results)
      return results
    })
}

proto.removeSSO = function (
  this: ClientStudentInstance,
  params: { provider: SsoProvider }
): q.Promise<ClientStudentInstance | void> {
  const that = this
  const args = {
    provider: params.provider,
    sSecret: that.secret,
    sCode: that.code,
    sVersion: that.version,
  }
  const constructor = that.constructor as ClientStudentClass
  return constructor.connection
    .emitToServer<StudentRemoveSsoParams>(
      'student/remove-sso',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result, constructor.caches.students)
      return result
    })
}

proto.showKeypadSetter = function (
  this: ClientStudentInstance,
  val?: boolean
): boolean {
  if (arguments.length > 0) {
    const delta = newObject('showKeypad', val)
    this.postponeDelta('updatePreferences', delta)
  }
  return this.showKeypad
}

proto.signout = function (
  this: ClientStudentInstance,
  quality: Quality
): q.Promise<void> {
  const that = this
  let videosDelta
  try {
    videosDelta = that.popPostponedDeltas('watchedVideos')
  } catch (err) {
    logger(err)
    videosDelta = {}
  }
  const prefsDelta = that.popPostponedDeltas('updatePreferences')
  const args = _.assign(prefsDelta, {
    sCode: that.code,
    sSecret: that.getSecret(),
    quality,
    sVersion: that.version,
    watchedVideosDelta: videosDelta,
  })
  const constructor = that.constructor as ClientStudentClass
  return constructor.connection
    .emitToServer<StudentSignoutParams>('student/signout', args, 'blocking')
    .then(function (result) {
      return that.applyResult(result, constructor.caches.students)
    })
}

proto.submitAssignment = function (
  this: ClientStudentInstance,
  assignment: AssignmentProps,
  quality: Quality,
  timeReached: boolean
): q.Promise<SubmitAssignmentRval> {
  const that = this
  const fields = {
    assignment,
    timeReached,
    quality,
    sCode: that.code,
    sSecret: that.getSecret(),
    sVersion: that.version,
  }
  const constructor = that.constructor as ClientStudentClass
  return constructor.connection
    .emitToServer<SubmitAssignmentParams>(
      'student/submit-assignment',
      fields,
      'blocking',
      true
    )
    .then(function (result: SubmitAssignmentRval) {
      that.applyResult(result, constructor.caches.students)
      return result
    })
}

proto.updateStatus = function (
  this: ClientStudentInstance,
  delta: Delta
): q.Promise<void> {
  const that = this
  const req = _.assign({ sCode: this.code }, delta)
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<UpdateStatusParams>(
      'student/update-status',
      req,
      'nonblocking'
    )
    .then(function (result) {
      that.applyResult(result, constructor.caches.studentSignins)
    })
}

proto.watchedVideo = function (
  this: ClientStudentInstance,
  category: string
): void {
  const totalWatches = (this[category] || 0) + 1
  const delta = newObject(category, totalWatches)
  this.postponeDelta('watchedVideos', delta)
}

proto.applyResult = function (
  this: ClientStudentInstance,
  result: { student?: StudentItem; studentDelta?: Delta }
): void {
  if (result.student) {
    this.transform(result.student)
  } else {
    this.applyDelta(result.studentDelta)
  }
}

proto.applyDelta = function (this: ClientStudentInstance, delta: Delta): void {
  cookDeltaOps(delta)
  ClientMixin.prototype.applyDelta.call(this, delta)
}

proto.transform = function (
  this: ClientStudentInstance,
  result: StudentItem
): void {
  const that = this
  // variables created on local object, won't ever exist on result but shouldn't be removed:
  const dontRemove = ['changes', '@lastUpdated', '@lastSynced']
  _.forEach(Object.getOwnPropertyNames(this), function (key) {
    if (!result[key] && dontRemove.indexOf(key) < 0) {
      delete that[key]
    }
  })
  cookDeltaOps(result)
  _.assign(this, result)
  this['@lastUpdated'] = Date.now()
  this['@lastSynced'] = Date.now()
}

// HELPER FUNCTIONS

function cookDeltaOps(delta: Delta): Delta {
  const ops = _.pickBy(delta, function (_val, key) {
    return constructor.OPERATION_RE.test(key)
  })
  _.forEach(ops, function (rawOp) {
    const cookedOp = new StudentOperation(rawOp)
    delta[`op${cookedOp.operationId}`] = cookedOp
  })
  return delta
}

function isSameDay(ts1: number, ts2: number, timezone: string): boolean {
  // note: this duplicates functionality server-side i18n.isSameDay() but does not use moment-tz. Note that if Intl is not
  // supported, we assume we're using the *browser's* timezone
  let d1
  let d2
  try {
    d1 = new Intl.DateTimeFormat('default', { timeZone: timezone }).format(
      new Date(ts1)
    )
    d2 = new Intl.DateTimeFormat('default', { timeZone: timezone }).format(
      new Date(ts2)
    )
  } catch (err) {
    d1 = new Date(ts1).toISOString().split('T')[0]
    d2 = new Date(ts2).toISOString().split('T')[0]
  }
  return d1 === d2
}
