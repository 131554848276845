import { config } from '../config/config-shim'

export const deviceFeatures = {
  appInfo,
  versionIsAtLeast,
  isMobile,
  osType,
}

const APP_INFOS = {
  ios: {
    text: '.appleStore',
    store: 'appStore',
    href: 'https://geni.us/XtraMathApple',
    img: 'app-illustration',
  },
  'amazon-fireos': {
    text: '.amazonStore',
    store: 'amazonStore',
    href: 'https://geni.us/XtraMathAmazon',
    img: 'app-illustration',
  },
  android: {
    text: '.googleStore',
    store: 'playStore',
    href: 'https://geni.us/XtraMathAndroid',
    img: 'app-illustration',
  },
}

// EXPORTED FUNCTIONS

function appInfo() {
  return APP_INFOS[osType()]
}

function isMobile() {
  if (config.cordova.enabled) {
    return true
  }
  return false
}

// REVIEW: Use semver instead?
function versionGte(version: string, expected: string) {
  const checkAgainst = expected.split('.')
  const currentVersion = version.split('.')
  let rval = true
  for (let i = 0; i < checkAgainst.length; i++) {
    const checked = parseInt(checkAgainst[i], 10)
    const current = currentVersion[i] ? parseInt(currentVersion[i], 10) : 0
    if (current > checked) {
      break
    } else if (checked > current) {
      rval = false
      break
    }
  }
  return rval
}

function versionIsAtLeast(version: string) {
  return versionGte(window.device.version, version)
}

function osType() {
  if (navigator.userAgent.match(/silk/i) !== null) {
    return 'amazon-fireos'
  }
  if (navigator.userAgent.match(/Android/i)) {
    return 'android'
  }
  if (navigator.userAgent.match(/iPod|iPhone|iPad/i)) {
    return 'ios'
  }
  return 'other'
}
