import _ from 'lodash'
import q from 'q'
import { PlainObject } from '../common'

const logger = console.log

let getStorageRequested = 0

type AnyJson = JsonPrimitive | JsonArray | JsonMap
type JsonPrimitive = boolean | number | string | null
interface JsonMap {
  [key: string]: AnyJson
}
type JsonArray = Array<AnyJson>

export const persistentState = {
  window: {
    get: (key: string, force?: boolean) => getJsonData('session', key, force),
    getRaw: (key: string) => getRaw('session', key),
    getData: (key: string, force?: boolean) => getData('session', key, force),
    set: (key: string, obj: AnyJson) => setJsonItem('session', key, obj),
    setRaw: (key: string, str: string | boolean) => setRaw('local', key, str),
    update: (key: string, objMap: PlainObject) =>
      updateJsonItem('session', key, objMap),
    remove: (key: string) => removeJsonItem('session', key),
    cloneSessionStorage,
    setSessionReady,
    init,
    awaitRetrieval,
  },
  device: {
    get: (key: string, force?: boolean) => getJsonData('local', key, force),
    getRaw: (key: string) => getRaw('local', key),
    getData: (key: string, force?: boolean) => getData('local', key, force),
    set: (key: string, obj: AnyJson) => setJsonItem('local', key, obj),
    setRaw: (key: string, str: string | boolean) => setRaw('local', key, str),
    update: (key: string, objMap: PlainObject) =>
      updateJsonItem('local', key, objMap),
    remove: (key: string) => removeJsonItem('local', key),
  },
  getStorageRequested,
}

// We perform reads frequently enough that caching the return values makes sense; without
// this there is a human perceptible performance impact on the iPad 3, and probably
// other tablets.
const cached = {
  local: {},
  session: {},
}

const failedRetrievals = {}

let sessionReady = false

// HELPER FUNCTIONS

function awaitRetrieval(): q.Promise<void> {
  return q().then(function () {
    init()
    const deferred: q.Deferred<void> = q.defer()
    const started = Date.now()
    if (sessionReady) {
      deferred.resolve()
    } else {
      const int = setInterval(function () {
        const timeout = Date.now() - started > 250
        if (sessionReady || timeout) {
          if (timeout) {
            logger('Aborting sessionStorage replication (took more than 250ms)')
            sessionReady = true
          }
          clearInterval(int)
          deferred.resolve()
        }
      }, 50)
    }
    return deferred.promise
  })
}

function cloneSessionStorage(): void {
  // sends any sessionStorage data to other windows via localStorage
  try {
    const xfer = {}
    const keys = _.omit(Object.keys(sessionStorage), 'temp')
    keys.forEach((key) => {
      xfer[key] = sessionStorage.getItem(key)
    })
    localStorage.setItem('sessionStorageHandoff', JSON.stringify(xfer))
    localStorage.removeItem('sessionStorageHandoff')
  } catch (err) {
    logger(err, 'Error while cloning session storage.')
  }
}

function fromStorage(
  storageType: 'local' | 'session',
  key: string,
  force?: boolean
): PlainObject | string {
  const cache = cached[storageType]
  if (_.has(cache, key)) {
    logger('Getting %s from cache', key)
    if (force) {
      delete cache[key]
    } else {
      return cache[key]
    }
  }
  logger('Getting %s from storage', key)
  try {
    const storage = window[`${storageType}Storage`]
    const item = storage.getItem(key)
    let state
    if (!item) {
      state = null
    } else {
      try {
        state = JSON.parse(item)
      } catch (err2) {
        logger("Failed parsing JSON for '%s' in storage, removing item.", key)
        storage.removeItem(key)
      }
      cache[key] = state
    }
    return state
  } catch (err: any) {
    if (!failedRetrievals[key]) {
      failedRetrievals[key] = true
      logger('Error getting JSON item: %s', err.message)
    }
    return null
  }
}

function getRaw(storageType: 'local' | 'session', key: string) {
  let rval
  try {
    const storage = window[`${storageType}Storage`]
    const data = storage.getItem(key)
    try {
      rval = JSON.parse(data)
    } catch (err) {
      rval = data
    }
  } catch (err: any) {
    logger('Error getting JSON item: %s', err)
  }
  return rval
}

function getData(
  storageType: 'local' | 'session',
  key: string,
  force?: boolean
): AnyJson {
  let rval: string | boolean
  const data = fromStorage(storageType, key, force) as string
  if (data === 'true') {
    rval = true
  } else if (data === 'false') {
    rval = false
  } else {
    rval = data
  }
  return rval
}

function getJsonData(
  storageType: 'local' | 'session',
  key: string,
  force?: boolean
): PlainObject {
  return fromStorage(storageType, key, force) as PlainObject
}

function updateJsonItem(
  storageType: 'local' | 'session',
  key: string,
  objMap: PlainObject
): void {
  const items = getJsonData(storageType, key) || {}
  _.forEach(objMap, function (val, key) {
    if (val === null) {
      delete items[key]
    } else {
      items[key] = val
    }
  })
  setJsonItem(storageType, key, items)
}

function setSessionReady(): void {
  sessionReady = true
}

// OPTIMIZATION: Async option that batches together ~500ms of set calls to reduce localstorage usage.
function setJsonItem(
  storageType: 'local' | 'session',
  key: string,
  data: AnyJson
): void {
  const serialized = JSON.stringify(data)
  const cache = cached[storageType]
  cache[key] = data
  try {
    const storage = window[`${storageType}Storage`]
    storage.setItem(key, serialized)
  } catch (err: any) {
    logger('Error occurred while saving to storage')
    logger(err.stack)
  }
}

function setRaw(
  storageType: 'local' | 'session',
  key: string,
  data: string | boolean
): void {
  const cache = cached[storageType]
  cache[key] = data.toString()
  try {
    const storage = window[`${storageType}Storage`]
    storage.setItem(key, data.toString())
  } catch (err: any) {
    logger('Error occurred while saving to storage')
    logger(err.stack)
  }
}

function removeJsonItem(storageType: 'local' | 'session', key: string) {
  delete cached[storageType][key]
  try {
    const storage = window[`${storageType}Storage`]
    storage.removeItem(key)
  } catch (err) {
    // error
  }
}

// HELPERS

function init(): void {
  // leverages localStorage to request sessionStorage data from any other tabs as shown on this page:
  // https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/
  try {
    if (!sessionReady || !sessionStorage.length) {
      getStorageRequested = Date.now()
      localStorage.setItem('getSessionStorage', Date.now().toString())
    } else {
      setSessionReady()
    }
  } catch (err) {
    setSessionReady()
  }
}
