import React, { ReactNode, ErrorInfo, Component, ReactElement } from 'react'
import * as Sentry from '@sentry/react'

import report from 'common/utils/errorReporter'
import { LogLevel } from 'common/utils/types/log-entry'
import ErrorFallback from './ErrorFallback'

const logErrorToMyService = (error: Error, componentStack: string) => {
  console.error(error)

  const { message, stack, cause } = error
  Sentry.captureException(error)
  report(
    LogLevel.Error,
    { message, stack, cause, componentStack, href: window.location.href },
    `XMReact caught an error - ${message}`
  )
}

type ErrorBoundaryProps = {
  fallback?: ReactNode
  children: ReactElement | ReactElement[]
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps> = {
    fallback: <ErrorFallback />,
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { componentStack } = info
    logErrorToMyService(error, componentStack || '')
  }

  render(): ReactElement | ReactElement[] {
    const { hasError } = this.state
    const { fallback, children } = this.props
    if (hasError) {
      return fallback as ReactElement
    }
    return children
  }
}

export default ErrorBoundary
