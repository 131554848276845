import React from 'react'
import { Box, Modal, useTheme } from '@mui/material'

import useMediaQuery from '@mui/material/useMediaQuery'

import Magnify from 'assets/magnify.svg'

interface MatrixProps {
  svg: string
  className?: string
  [key: string]: any // for additional props, though it's better to explicitly define each prop
}

function Matrix({ svg, className, ...props }: MatrixProps) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height:
          // '100%' /* Ensures it takes the full height of the container */,
          overflow: 'hidden' /* Hide overflow */,
          cursor: 'pointer',
          position: 'relative',
        }}
        className='svg-wrapper'
        onClick={() => setModalOpen(true)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <div
          style={{ height: '100%', paddingBottom: '16px' }}
          className={className}
          dangerouslySetInnerHTML={{ __html: svg }}
        />
        <img
          src={Magnify}
          alt='magnify'
          style={{
            position: 'absolute',
            bottom: '0%',
            right: isSm ? '0%' : '15%',
            width: '10%',
          }}
        />
      </Box>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          outline: 0,
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '40vw', lg: '35vw' },
            height: { xs: '90%', sm: '40vh', lg: '35vh' },
            m: 'auto',
            mt: '10vh',
          }}
        >
          <div
            className={`${className} matrix-modal-svg`}
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </Box>
      </Modal>
    </>
  )
}

Matrix.defaultProps = {
  className: '',
}

export default Matrix
