import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { getMatrix } from 'common/utils/graph-generator'
import { svgBuilder } from 'common/utils/graph-generator/svg-builder'
import { useStudentContext } from 'common/context/StudentContext'
import { Practice } from 'common/utils/types/practice'
import { Quiz } from 'common/utils/types/quiz'
import Matrix from 'student/common/matrix'
import Video from 'student/common/video'
import { PaperContainer } from 'student/common/taskUI'
import { AwardType } from 'common/utils/types/account-types'
import { awardPanel } from 'common/utils/awardPanel'
import EarnedAwards from 'student/common/earnedAwards'

const PROGRESS_VIDEOS = {
  0: '0024',
}

const PLACEMENT_VIDEOS = {
  0: '0012',
  1: '0014',
  2: '0016',
}

function QuizResults() {
  const [matrixSVG, setMatrixSVG] = useState<string>('')
  const { studentSession } = useStudentContext()
  const [video, setVideo] = useState<string>('')
  const [isPlacement, setIsPlacement] = useState<boolean>(false)

  const [earnedAwards, setEarnedAwards] = useState<AwardType[]>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    const studentOperation = studentSession.student.studentOperation(
      activity.operationId,
      true
    )
    const matrix = getMatrix(svgBuilder, studentOperation, 410, {
      quiz: activity,
      standalone: true,
    })
    if (activity instanceof Practice) return
    setIsPlacement(activity.placement)
    setMatrixSVG(matrix)
    const videoCategory = activity.placement
      ? 'numPlacementQuizR'
      : 'numProgressQuizR'
    let videoSrc = ''
    if (activity.placement) {
      videoSrc =
        PLACEMENT_VIDEOS[studentSession.student.getVideo(videoCategory)]
    } else {
      videoSrc = PROGRESS_VIDEOS[studentSession.student.getVideo(videoCategory)]
    }
    setVideo(videoSrc)
    if (
      process.env.NODE_ENV === 'development' &&
      window.location.search.indexOf('trophies') > -1
    ) {
      activity.earnedAwards = ['BA-30-100', 'BA-30-50']
    }
    if (activity.earnedAwards) {
      setEarnedAwards(activity.earnedAwards)
    }
  }, [studentSession])

  const awardsImages = earnedAwards
    ? awardPanel.awardsImagesURLs(earnedAwards)
    : []

  const handleContinue = () => {
    const { activity } = studentSession.currentState()
    if (activity instanceof Quiz) {
      const videoCategory = activity.placement
        ? 'numPlacementQuizR'
        : 'numProgressQuizR'
      studentSession.student.watchedVideo(videoCategory)
    }
    Sentry.addBreadcrumb({
      category: 'breadcrumb',
      message: 'Clicked on continue in quiz results',
      level: 'info',
      data: { student: studentSession?.student, activity },
    })
    studentSession.screenFinished('quiz_results').then(() => {
      const { screen } = studentSession.currentState()
      navigate(`/app/student/${screen}`)
    })
  }

  const quizType = isPlacement ? 'Placement' : 'Progress'

  return (
    <Container>
      <EarnedAwards />
      <PaperContainer sx={{ px: 2 }}>
        <Box py={2}>
          <Typography variant='h3' sx={{ fontWeight: 600 }}>
            {quizType} Quiz: Results
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontSize: { xs: '1rem', md: '1.25rem' },
              my: { xs: 1, md: 2 },
            }}
          >
            Great job, you&apos;ve finished your {quizType.toLowerCase()} quiz!
            You can check your results in the&nbsp;matrix&nbsp;below.
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', md: 'grid' },
            gridTemplateColumns: `repeat(${earnedAwards ? '3' : '2'}, 1fr)`,
            gridTemplateRows: '1fr',
            mt: 'auto',
          }}
        >
          <Box
            mb={-2}
            textAlign='center'
            sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'end' }}
          >
            <Video video={video} autoplay={!earnedAwards} />
          </Box>
          {earnedAwards ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant='h6' sx={{ mb: { xs: 1, md: 2 } }}>
                Your Trophies
              </Typography>
              {awardsImages.map((img, i) => (
                <Box
                  component='img'
                  alt='Image of a trophy'
                  key={img}
                  src={img}
                  sx={{ width: { xs: 80, md: 130 } }}
                />
              ))}
            </Box>
          ) : (
            ''
          )}
          <Box sx={{ textAlign: 'center', px: { xs: 2, md: 0 } }}>
            <Typography variant='h6' sx={{ mb: { xs: 1, md: 2 } }}>
              Your results
            </Typography>
            <Matrix
              svg={matrixSVG}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden' /* Hide overflow */,
                cursor: 'pointer',
                position: 'relative',
              }}
            />
          </Box>
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
          aria-label='Continue'
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default QuizResults
