import * as q from 'q'
import { configurePost, configureGet } from './fetch-helper'
import { PlainObject } from '../common'

// CONSTANTS

export const aap = {
  post(
    url: string,
    data: string,
    options?: { timeout: number }
  ): Q.Promise<XMLHttpRequest> {
    const request = configurePost(url, options)
    const deferred = q.defer<XMLHttpRequest>()
    request.addEventListener('readystatechange', () => {
      if (request.readyState === 4) {
        if (request.status === 200 && getBody(request)) {
          // Requests that are aborted before send() is called can still have status 200, but null body
          // at the point this handler is called
          deferred.resolve(request)
        } else {
          deferred.reject(request)
        }
      }
    })
    request.addEventListener('error', () => {
      deferred.reject(request)
    })
    request.send(data)
    return deferred.promise
  },
  get(url: string, options?: PlainObject): Q.Promise<XMLHttpRequest> {
    const request = configureGet(url, options)
    const deferred = q.defer<XMLHttpRequest>()
    request.addEventListener('readystatechange', () => {
      if (request.readyState === 4) {
        if (request.status === 200 && getBody(request)) {
          // Requests that are aborted before send() is called can still have status 200, but null body
          // at the point this handler is called
          deferred.resolve(request)
        } else {
          deferred.reject(request)
        }
      }
    })
    request.addEventListener('error', () => {
      deferred.reject(request)
    })
    request.send()
    return deferred.promise
  },
}

function getBody(xhr: XMLHttpRequest) {
  if (!xhr.responseType || xhr.responseType === 'text') {
    return xhr.responseText
  }
  return xhr.response
}
