import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getMatrix } from 'common/utils/graph-generator'
import { svgBuilder } from 'common/utils/graph-generator/svg-builder'
import { useStudentContext } from 'common/context/StudentContext'
import { Practice } from 'common/utils/types/practice'
import Matrix from 'student/common/matrix'
import Video from 'student/common/video'
import { PaperContainer } from 'student/common/taskUI'

const PROGRESS_VIDEOS = {
  0: '0023',
  1: '0025',
  2: '0026',
}

const PLACEMENT_VIDEOS = {
  addition: {
    // Operation::ADDITION:
    0: '0011',
    1: '0013',
    2: '0015',
  },
  subtraction: {
    // Operation::SUBTRACTION
    0: '0017',
    1: '0018',
  },
  multiplication: {
    // Operation::MULTIPLICATION
    0: '0019',
    1: '0020',
  },
  division: {
    // Operation::DIVISION
    0: '0021',
    1: '0022',
  },
}

function QuizIntro() {
  const [matrixSVG, setMatrixSVG] = useState<string>('')
  const { studentSession } = useStudentContext()
  const [currentOperation, setCurrentOperation] = useState<string>('')
  const [video, setVideo] = useState<string>('')
  const [isPlacement, setIsPlacement] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    const studentOperation = studentSession.student.studentOperation(
      activity.operationId,
      true
    )
    const matrix = getMatrix(svgBuilder, studentOperation, 410, {
      quiz: activity,
      standalone: true,
    })
    if (activity instanceof Practice) return
    setIsPlacement(activity.placement)
    const opName = activity.operation().mathOperationKey
    setCurrentOperation(opName)
    setMatrixSVG(matrix)
    const videoCategory = activity.placement
      ? `num${opName}PlacementQuiz`
      : 'numProgressQuiz'
    let videoSrc = ''
    if (activity.placement) {
      videoSrc =
        PLACEMENT_VIDEOS[activity.operation().mathOperationKey][
          studentSession.student.getVideo(videoCategory)
        ]
    } else {
      videoSrc = PROGRESS_VIDEOS[studentSession.student.getVideo(videoCategory)]
    }
    setVideo(videoSrc)
  }, [studentSession])

  const handleContinue = () => {
    studentSession.screenFinished('quiz_intro').then(() => {
      navigate(`/app/student/quiz`)
    })
  }

  return (
    <Container>
      <PaperContainer sx={{ px: 2 }}>
        <Box py={2}>
          <Typography variant='h3' sx={{ fontWeight: 600 }}>
            {isPlacement ? 'Placement' : 'Progress'} Quiz
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontSize: { xs: '1rem', md: '1.5rem' },
              my: 2,
            }}
          >
            This quiz will help us assess your math level. Today we&apos;ll
            focus on <b>{currentOperation}</b>
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='end'
          justifyContent='space-between'
          mt='auto'
        >
          <Box
            flex='0 0 50%'
            mb={-1}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Video video={video} />
          </Box>
          <Box sx={{ flex: { xs: '1', md: '0 0 50%' } }} height='100%'>
            <Matrix svg={matrixSVG} />
          </Box>
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default QuizIntro
