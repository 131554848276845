import * as React from 'react'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; label?: string }
) {
  const { label, value } = props
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant='determinate'
        size={100}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          color='text.secondary'
          fontSize='1.125rem'
        >
          {label || value}
        </Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.defaultProps = {
  label: '',
}

export default CircularProgressWithLabel
