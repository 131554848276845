/* eslint-disable @typescript-eslint/no-empty-function */

import q from 'q'

import * as qe from '../extensions/q'
import { PlainObject } from '../common'
import { ClientError } from '../helpers'
import { config } from '../config/config-shim'
import { SoundName, PlaybackOptions } from './audio-player'

const logger = console.log
// GLOBAL VARIABLES
const paused = false

// GLOBAL EVENT HANDLERS

function bindGlobalHandlers() {
  // $(document).on('resign', logger.wrapEventHandler(function() { paused = true; }));
  // $(document).on('active', logger.wrapEventHandler(function() { paused = false; }));
}

export class LLAudio {
  private soundMap: PlainObject

  private initialized: boolean

  // CONSTRUCTOR
  constructor(map) {
    this.soundMap = map
  }

  // CLASS METHODS

  // INSTANCE METHODS

  initialize(): q.Promise<void> {
    const that = this
    bindGlobalHandlers()
    return qe
      .forEach(this.soundMap, function (location, key) {
        return unloadSound(key).then(function () {
          return loadSound(key, location)
        })
      })
      .then(function () {
        that.initialized = true
      })
  }

  precacheStudentSounds() {}

  emptyCache() {}

  playSound(sound: SoundName, options?: PlaybackOptions): void {
    if (paused || !(config.useSFX && this.initialized)) {
      return
    }
    const delay = (options && options.delay) || 0
    logger('Playing %s in %d ms', sound, delay)
    if (delay) {
      setTimeout(function () {
        window.plugins.NativeAudio.play(
          sound,
          function (_msg) {},
          function (err) {
            logger(err)
          }
        )
      }, delay)
    } else {
      window.plugins.NativeAudio.play(
        sound,
        function (_msg) {},
        function (err) {
          logger(err)
        }
      )
    }
  }
}

// HELPER FUNCTIONS

function loadSound(name: SoundName, location: string): q.Promise<unknown> {
  const deferred = q.defer()
  let err
  if (!location) {
    err = new ClientError(
      `Skipping fetch for unknown sound: ${name}`,
      'client-unexpected',
      'unknown-sound-type'
    )
    deferred.reject(err)
  } else {
    // NOTE: we use preloadComplex because preloadSimple hooks into the iOS
    // audio APIs at a level that doesn't allow the volume control/mute switch
    // to work.
    window.plugins.NativeAudio.preloadComplex(
      name,
      location,
      1,
      1,
      0,
      function (_msg) {
        deferred.resolve()
      },
      function (_err) {
        deferred.reject(err)
      }
    )
  }
  return deferred.promise
}

function unloadSound(name: SoundName): q.Promise<unknown> {
  const deferred = q.defer()
  window.plugins.NativeAudio.unload(
    name,
    function (_msg) {
      deferred.resolve()
    },
    function (_err) {
      /* no-op */ deferred.resolve()
    }
  )
  return deferred.promise
}
