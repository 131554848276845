import React, { useEffect } from 'react'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  DialogContent,
  Icon,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import _ from 'lodash'

import StudentInfo from 'assets/student/student_info.svg'
import Logout from 'assets/sign_out.svg'
import Logo from 'assets/logo-black.svg'

import CleverLogo from 'assets/sso/Clever.svg'
import ClassLinkLogo from 'assets/sso/ClassLink.svg'
import GoogleLogo from 'assets/sso/Google.png'
import FacebookLogo from 'assets/sso/Facebook.png'

import { useStudentContext } from 'common/context/StudentContext'
import { studentSignout } from 'common/utils/signout'
import { ClientStudent } from 'common/utils/types/client-student'

import EnableAnimation from 'assets/student/enable_animations.svg'
import DisableAnimation from 'assets/student/disable_animations.svg'

import EnableCaptions from 'assets/student/enable_captions.svg'
import DisableCaptions from 'assets/student/disable_captions.svg'

import Modal from '../Modal'

const SOCIAL_PROVIDER_LOGOS = {
  clever: CleverLogo,
  classlink: ClassLinkLogo,
  google: GoogleLogo,
  Facebook: FacebookLogo,
}

const LIST_ITEM_SX = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: { xs: 'start', sm: 'center' },
}

function StudentInformationDialog({ open, onClose }) {
  const { studentSession } = useStudentContext()
  const [studentData, setStudentData] = React.useState(null)
  const [ssoDisconnectModal, setSSODisconnectModal] = React.useState(false)

  useEffect(() => {
    if (!studentSession?.student) return
    const initialize = async () => {
      const student = studentSession?.student

      if (!student) return null

      const { name, code } = student
      const enrollment = `${code.toString().substring(0, 4)}-${code
        .toString()
        .substring(4, 8)}-${student.getSecret()}`

      const relatives = await ClientStudent.getRelatives([student?.code])
      const socialProvider = student.ssoProvider()
      setStudentData({
        name,
        enrollment,
        socialProvider,
        teachers: relatives[student.code].teachers,
        parents: relatives[student.code].parents,
      })
    }
    initialize()
  }, [studentSession])

  const removeSSO = async () => {
    await studentSession.student.removeSSO({
      provider: studentSession.student.ssoProvider(),
    })
    setStudentData({ ...studentData, socialProvider: null })
    setSSODisconnectModal(false)
  }

  return (
    <>
      <Modal
        title={`Disconnect ${_.capitalize(studentData?.socialProvider)}`}
        open={ssoDisconnectModal}
        onClose={() => setSSODisconnectModal(false)}
      >
        <DialogContent sx={{ pt: 0 }}>
          <Typography>
            Do you want to disconnect your XtraMath account from your{' '}
            {_.capitalize(studentData?.socialProvider)} account?
          </Typography>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
            justifyContent='flex-end'
          >
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setSSODisconnectModal(false)}
              aria-label='Cancel'
            >
              Cancel
            </Button>
            <Button
              color='error'
              sx={{ ml: { sm: 2 }, mt: { xs: 2, sm: 0 } }}
              onClick={removeSSO}
              aria-label='Disconnect'
            >
              Yes, disconnect
            </Button>
          </Box>
        </DialogContent>
      </Modal>
      <Modal
        title='Student information'
        open={open}
        onClose={onClose}
        fullWidth
      >
        <DialogContent sx={{ pt: 0 }}>
          <List disablePadding dense>
            <ListItem disableGutters sx={LIST_ITEM_SX}>
              <Typography fontWeight={600} mr={1}>
                Student name:
              </Typography>
              <Typography>{studentData?.name}</Typography>
            </ListItem>
            <ListItem disableGutters sx={LIST_ITEM_SX}>
              <Typography fontWeight={600} mr={1}>
                Enrollment code:
              </Typography>
              <Typography>{studentData?.enrollment}</Typography>
            </ListItem>
            {studentData?.socialProvider && (
              <ListItem disableGutters sx={LIST_ITEM_SX}>
                <Typography fontWeight={600} mr={1}>
                  Connected to:
                </Typography>
                <Box display='inline-flex' alignItems='center'>
                  {SOCIAL_PROVIDER_LOGOS[studentData?.socialProvider] && (
                    <Box
                      component='img'
                      src={SOCIAL_PROVIDER_LOGOS[studentData?.socialProvider]}
                      alt='SSO Logo'
                      height={40}
                      mr={1}
                    />
                  )}
                  {_.capitalize(studentData?.socialProvider)}
                  <Box ml={2}>
                    <Button
                      aria-label='Disconnect SSO'
                      color='error'
                      onClick={() => setSSODisconnectModal(true)}
                    >
                      Disconnect
                    </Button>
                  </Box>
                </Box>
              </ListItem>
            )}
            {studentData?.teachers?.map((teacher, index) => (
              <ListItem disableGutters key={teacher.email} sx={LIST_ITEM_SX}>
                <Typography fontWeight={600} mr={1}>
                  Teacher{index > 0 ? ` ${index + 1}` : ''}:
                </Typography>
                <Typography>
                  {teacher.addressedAs} - {teacher.email}
                </Typography>
              </ListItem>
            ))}
            {studentData?.parents?.map((parent, index) => (
              <ListItem disableGutters key={parent.email} sx={LIST_ITEM_SX}>
                <Typography fontWeight={600} mr={1}>
                  Parent{index > 0 ? ` ${index + 1}` : ''}:
                </Typography>
                <Typography>
                  {parent.name} - {parent.email}
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Modal>
    </>
  )
}

function Header() {
  const {
    studentSession,
    disableAnimation,
    setDisableAnimation,
    enableCaptions,
    setEnableCaptions,
  } = useStudentContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [studentInformationDialogOpen, setStudentInformationDialogOpen] =
    React.useState(false)

  const initials = studentSession?.student.displayName
    .split(' ')
    .map((n) => n[0])
    .join('')

  const handleSignout = () => studentSignout(studentSession)

  const toggleDisableAnimations = () => {
    setDisableAnimation(!disableAnimation)
  }

  const toggleEnableCaptions = () => {
    setEnableCaptions(!enableCaptions)
  }

  return (
    <>
      <AppBar
        position='static'
        sx={{
          backgroundColor: 'common.white',
          boxShadow: '0 5px 10px rgba(0,0,0,0.031)',
        }}
      >
        <Container sx={{ py: 2 }}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box
              onClick={handleSignout}
              sx={{ cursor: 'pointer' }}
              title='Sign out'
              aria-label='Sign out'
            >
              <Box component='img' src={Logo} alt='XtraMath Logo' width={160} />
            </Box>
            <Box>
              <Button
                color='inherit'
                onClick={handleClick}
                aria-label='Open menu'
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    mr: 1,
                    fontSize: 14,
                    backgroundColor: 'secondary.main',
                    color: 'common.black',
                  }}
                  alt='Student Avatar with the initials'
                >
                  {initials}
                </Avatar>
                <Box
                  component='span'
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {studentSession?.student.name}
                </Box>
              </Button>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                aria-label='Open menu'
              >
                <MenuItem
                  onClick={() => {
                    setStudentInformationDialogOpen(true)
                    handleClose()
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                    },
                  }}
                  aria-label='Open student information modal'
                >
                  <Icon
                    component='img'
                    src={StudentInfo}
                    sx={{ mr: 1, height: 18, width: 18 }}
                    alt='Icon of a Student'
                  />
                  Student information
                </MenuItem>
                <MenuItem
                  onClick={toggleDisableAnimations}
                  aria-label='Toggle animation'
                  sx={{
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                    },
                  }}
                >
                  <Icon
                    component='img'
                    src={disableAnimation ? EnableAnimation : DisableAnimation}
                    sx={{ mr: 1, height: 18, width: 18 }}
                    alt='Icon for toggling animation'
                  />
                  {disableAnimation
                    ? 'Enable animations'
                    : 'Disable animations'}
                </MenuItem>
                <MenuItem
                  onClick={toggleEnableCaptions}
                  aria-label='Toggle video captions'
                  sx={{
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                    },
                  }}
                >
                  <Icon
                    component='img'
                    src={enableCaptions ? EnableCaptions : DisableCaptions}
                    sx={{ mr: 1, height: 18, width: 18 }}
                    alt='Icon for toggling video captions'
                  />
                  {enableCaptions ? 'Disable captions' : 'Enable captions'}
                </MenuItem>
                <MenuItem
                  onClick={handleSignout}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                    },
                  }}
                  aria-label='Sign out'
                >
                  <Icon
                    component='img'
                    src={Logout}
                    sx={{ mr: 1, height: 18, width: 18 }}
                    alt='Icon representing signing out'
                  />
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <StudentInformationDialog
        open={studentInformationDialogOpen}
        onClose={() => setStudentInformationDialogOpen(false)}
      />
    </>
  )
}

export default Header
