// REQUIREMENTS

import { config } from '../config/config-shim'
import { WebAudio } from './web-audio'
import { LLAudio } from './ll-audio'
import { deviceFeatures } from '../helpers'
// REVIEW: Optimization: replace non-used audio modules with empty placeholders at compile time?

// TYPES

type Output = WebAudio | LLAudio
export type SoundName =
  | 'activity-complete'
  | 'app-load'
  | 'appear'
  | 'collapse'
  | 'correct-answer'
  | 'countdown-1'
  | 'countdown-3'
  | 'dismiss'
  | 'expand'
  | 'form-error'
  | 'keypad-press'
  | 'keypad-slide'
  | 'matrix-fade'
  | 'page-nav'
  | 'practice-timeout'
  | 'quick-answer'
  | 'quiz-timeout'
  | 'session-complete'
  | 'session-start'
  | 'smiley-tally'
  | 'timer-tick'
  | 'ui-click'
  | 'wrong-answer'
export type PlaybackOptions = { delay?: number; dontCache: boolean }

// CONSTANTS

const UI_PRIORITIES = {
  // lower value = higher prio
  // equivalent prios, first event fired wins.
  'form-error': 1,
  appear: 2,
  expand: 2,
  'page-nav': 3,
  dismiss: 4,
  collapse: 4,
  'keypad-press': 5,
  'keypad-slide': 5,
  'ui-click': 6,
}

const COOLDOWNS = {
  'page-nav': { lastPlayed: 0, duration: 500 },
}

// CONSTRUCTOR

// don't call constructor directly. Call connect instead.
export class AudioPlayer {
  static singleton: AudioPlayer

  static load() {
    if (!this.singleton) {
      let output
      // Check whether to use Cordova plugin rather than web audio because:
      // Android 4.x doesn't consistently implement the web audio API.
      if (config.platform === 'android') {
        if (!deviceFeatures.versionIsAtLeast('5')) {
          output = LLAudio
        } else {
          output = WebAudio
        }
      } else if (config.platform === 'ios') {
        output = LLAudio
      } else {
        output = WebAudio
      }
      this.singleton = new this(output)
    }
    return this.singleton
  }

  private output: Output

  private queuedSound: SoundName

  constructor(Player) {
    this.output = new Player({
      'activity-complete': 'sounds/activity-complete.aac',
      'app-load': 'sounds/app-load.aac',
      appear: 'sounds/appear.aac',
      collapse: 'sounds/collapse.aac',
      'correct-answer': 'sounds/quick-answer.aac',
      'countdown-1': 'sounds/countdown-1.aac',
      'countdown-3': 'sounds/countdown-3.aac',
      dismiss: 'sounds/dismiss.aac',
      expand: 'sounds/expand.aac',
      'form-error': 'sounds/form-error.aac',
      'keypad-press': 'sounds/keypad-press.aac',
      'keypad-slide': 'sounds/keypad-slide.aac',
      'matrix-fade': 'sounds/matrix-fade.aac',
      'page-nav': 'sounds/page-nav.aac',
      'practice-timeout': 'sounds/practice-timeout.aac',
      'quick-answer': 'sounds/correct-answer.aac',
      'quiz-timeout': 'sounds/quiz-timeout.aac',
      'session-complete': 'sounds/session-complete.aac',
      'session-start': 'sounds/session-start.aac',
      'smiley-tally': 'sounds/smiley-tally.aac',
      'timer-tick': 'sounds/timer-tick.aac',
      'ui-click': 'sounds/ui-click.aac',
      'wrong-answer': 'sounds/wrong-answer.aac',
    })
  }

  // CLASS METHODS

  initialize() {
    return this.output.initialize()
  }

  bindDomEvents() {
    // let that = this
    // var $panels = $('[data-xm-elt="panel"]')
    // var $popups = $('[data-xm-elt="popup"]')
    // $panels.on(
    //   'change',
    //   'input[type="checkbox"], input[type="radio"]',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('ui-click')
    //   })
    // )
    // $panels.on(
    //   'click',
    //   'a',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('ui-click')
    //   })
    // )
    // $panels.on(
    //   'panelopen',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('expand')
    //   })
    // )
    // $panels.on(
    //   'panelclose',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('collapse')
    //   })
    // )
    // $popups.on(
    //   'change',
    //   'input[type="checkbox"], input[type="radio"]',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('ui-click')
    //   })
    // )
    // $popups.on(
    //   'click',
    //   'a',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('ui-click')
    //   })
    // )
    // $popups.on(
    //   'popupafteropen',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('appear')
    //   })
    // )
    // $popups.on(
    //   'popupafterclose',
    //   logger.wrapEventHandler(function () {
    //     that.playUiSound('dismiss')
    //   })
    // )
  }

  emptyCache() {
    this.output.emptyCache()
  }

  precacheStudentSounds() {
    this.output.precacheStudentSounds()
  }

  playSound(sound: SoundName) {
    this.output.playSound(sound)
  }

  playUiSound(sound: SoundName) {
    const that = this
    const cooldown = COOLDOWNS[sound]
    if (cooldown) {
      const now = new Date().getTime()
      const elapsed = now - cooldown.lastPlayed
      if (elapsed < cooldown.duration) {
        return
      }
      cooldown.lastPlayed = now
    }
    that.reduceSound(sound)
    setTimeout(function () {
      that.playReducedSound()
    }, 25)
  }

  reduceSound(sound) {
    const replaceSound =
      !this.queuedSound ||
      UI_PRIORITIES[sound] < UI_PRIORITIES[this.queuedSound]
    if (replaceSound) {
      this.queuedSound = sound
    }
  }

  playReducedSound() {
    if (this.queuedSound) {
      this.output.playSound(this.queuedSound)
      delete this.queuedSound
    }
  }
}
