import { styled, Button, Typography, Paper } from '@mui/material'
import RedX from 'assets/student/red-x.svg'
import Checkmark from 'assets/student/checkmark.svg'

export const ANSWER_ICONS = {
  wrong: RedX,
  correct: Checkmark,
  quick: Checkmark,
}

export const CalculatorButton = styled(Button)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    minWidth: '50px',
    height: '40px',
    fontSize: '40px',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '116px',
    height: '116px',
    fontSize: '95px',
  },
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  padding: 0,
  '&:hover': {
    background: '#FFF3AB',
  },
  '&:disabled': {
    background: theme.palette.grey[300],
    color: theme.palette.grey[200],
    cursor: 'not-allowed',
  },
  border: '2px solid #C3C3C3',
  boxShadow:
    '0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F',
  elevation: 2,
}))

export const OperationText = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  textAlign: 'right',
  display: 'inline-block',
  color: theme.palette.common.black,
  [theme.breakpoints.down('md')]: {
    fontSize: '70px',
    height: '70px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '150px',
    height: '150px',
  },
}))

export const OperationErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '64px',
  lineHeight: 1,
  color: theme.palette.error.main,
  textDecorationThickness: '3px',
  textDecoration: 'line-through',
}))

export const PaperContainer = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  // height: ['924px', null, null, '366px'],
  [theme.breakpoints.up('md')]: {
    minHeight: 'calc(90vh - 7.5rem)',
    maxHeight: '924px',
  },
  overflow: 'hidden',
  // [theme.breakpoints.up('sm')]: {
  //   height: '535px', // height for screens wider than 'sm'
  // },
  // [theme.breakpoints.up('md')]: {
  //   height: '624px', // height for screens wider than 'md'
  // },
}))
