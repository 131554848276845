import React from 'react'

import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material'

import CircularProgressWithLabel from 'common/components/CircularProgressWithLabel'
import Modal from 'common/components/Modal'
import { studentSignout } from 'common/utils/signout'

import { useStudentContext } from 'common/context/StudentContext'

function SignoutModal({
  secondsUntilLogout,
  onContinue,
}: {
  secondsUntilLogout: number
  onContinue: () => void
}) {
  const { studentSession } = useStudentContext()

  const handleSignout = () => studentSignout(studentSession)

  return (
    <Modal
      title='Are you still there?'
      open={secondsUntilLogout > 0}
      onClose={onContinue}
      role='alertdialog'
    >
      <DialogContent sx={{ pt: 0, width: '300px' }}>
        <DialogContentText>You will be signed out in...</DialogContentText>
        <Box textAlign='center' mt={3}>
          <CircularProgressWithLabel
            color='secondary'
            value={(secondsUntilLogout / 60) * 100}
            label={`${secondsUntilLogout}s`}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: 'common.black' }}
          onClick={handleSignout}
          aria-label='Sign out'
        >
          Sign out
        </Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={onContinue}
          aria-label='Continue working'
        >
          Yes, continue
        </Button>
      </DialogActions>
    </Modal>
  )
}

export default SignoutModal
