import React, { useState, useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useStudentContext } from 'common/context/StudentContext'
import Video from 'student/common/video'
import { PaperContainer } from 'student/common/taskUI'

const VIDEOS = {
  0: '0001',
  1: '0003',
  2: '0005',
  3: '0007',
}

const VIDEO_CATEGORY = 'numWelcome'

function App() {
  const { studentSession } = useStudentContext()
  const [video, setVideo] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    if (!studentSession) return
    const videoSrc = VIDEOS[studentSession.student.getVideo(VIDEO_CATEGORY)]
    setVideo(videoSrc)
  }, [studentSession])

  const handleContinue = () => {
    if (video) studentSession.student.watchedVideo(VIDEO_CATEGORY)
    studentSession?.screenFinished('welcome').then(() => {
      const { screen } = studentSession.currentState()
      navigate(`/app/student/${screen}`)
    })
  }

  useEffect(() => {
    Sentry.setUser({ id: studentSession.student.code })
  }, [])

  return (
    <Container>
      <PaperContainer
        sx={{ px: { xs: 1, md: 0 }, height: { md: 'calc(90vh - 7.5rem)' } }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h2' sx={{ fontWeight: 600, mt: 3 }}>
            Welcome to XtraMath
          </Typography>
          <Typography variant='subtitle1' sx={{ fontSize: '1.5rem', mt: 2 }}>
            It&apos;s a great day to exercise your math&nbsp;brain!
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            position: 'relative',
            mt: 2,
            minHeight: 0,
          }}
        >
          <Video video={video} />
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default App
