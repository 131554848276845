import React from 'react'
import { Box } from '@mui/material'

import StudentHeader from 'common/components/layout/StudentHeader'
import { StudentProvider } from 'common/context/StudentContext'

import StudentTaskRouter from './StudentTaskRouter'

function StudentApp() {
  return (
    <StudentProvider>
      <Box
        bgcolor='grey.A100'
        sx={{ minHeight: { xs: '700px', md: '100vh' }, pb: 4 }}
      >
        <StudentHeader />
        <StudentTaskRouter />
      </Box>
    </StudentProvider>
  )
}

export default StudentApp
