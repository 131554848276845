// REQUIREMENTS

import _ from 'lodash'

import { assert, inherits } from './common'

import {
  AccountCode,
  ActivityId,
  StudentClass,
  StudentInstance,
  StudentItem,
  StudentMap,
} from './account-types'
import { Account } from './account'
import { Program } from './program'
import { StudentOperation } from './student-operation'

// EXPORTS

export const Student: StudentClass = StudentConstructor as any

// CONSTRUCTOR

const superclass = Account
function StudentConstructor(
  this: StudentInstance,
  item: StudentItem,
  noCook?: boolean
) {
  const that = this
  superclass.call(this, item)
  if (!noCook) {
    _.forEach(this.studentOperations(), function (rawOp) {
      const cookedOp = new StudentOperation(rawOp)
      that[`op${cookedOp.operationId}`] = cookedOp
    })
  }
  // Before 3.23.0 we would add the current student operation to the student if it did not exist.
  // This would not be saved to the database, though:
  // if (this.isValidProgram()) {
  //   this.currentStudentOperation();
  // }
}

// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_RE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: StudentClass = <
  { (item: StudentItem): void } & StudentClass
>StudentConstructor
inherits(constructor, superclass)
const proto: StudentInstance = constructor.prototype

// CLASS CONSTANTS

constructor.TYPE = 'student'
constructor.PREFIX = 'S'
constructor.OPERATION_RE = /^op\d+$/
const { OPERATION_RE } = constructor
// CLASS PROPERTIES

// Returns true if the program id refers to a generation of programs that we know about.
// Notes:
//   Use proto.isKnownProgram() to check if the program for a specific student instance is known.
constructor.isKnownProgram = function (
  this: StudentClass,
  programId: number
): boolean {
  return programId && Program.isKnownId(programId)
}

// Returns true if the program id refers to a generation of programs that we know about,
// and it is a valid program identifier for its generation.
// Notes:
//   Use proto.isValidProgram() to check if the program for a specific student instance is valid.
constructor.isValidProgram = function (
  this: StudentClass,
  programId: number
): boolean {
  return this.isKnownProgram(programId) && Program.isValidId(programId)
}

// CLASS METHODs

// Compare two classes for sorting order.
// Returns:
//   -1 if studentA should be sorted before studentB,
//   0 if studentA and studentB sort the same,
//   1 if studentA should be sorted after studentB.
constructor.compare = function (
  this: StudentClass,
  studentA: StudentInstance,
  studentB: StudentInstance
): number {
  if (studentA === studentB) {
    return 0
  }

  // Sort missing students below all other
  if (!studentA) {
    return 1
  }
  if (!studentB) {
    return -1
  }

  // Sort by lowercase name.
  const aName = studentA.getName().toLowerCase() // REVIEW: I18N
  const bName = studentB.getName().toLowerCase() // REVIEW: I18N
  if (aName < bName) {
    return -1
  }
  if (aName === bName) {
    return 0
  }
  return 1
}

constructor.findByName = function (
  this: StudentClass,
  students: StudentMap,
  name: string
): StudentInstance | undefined {
  return _.find(students, function (student) {
    return student.name === name
  })
}

// REVIEW - better place to put this
constructor.isActivityPractice = function (
  this: StudentClass,
  activityId: ActivityId
): boolean {
  return activityId === ActivityId.Practice
}

constructor.sortedCodes = function (
  this: StudentClass,
  sCodes: AccountCode[],
  students: StudentMap
): AccountCode[] {
  return sCodes.sort((sCodeA, sCodeB) => {
    return Student.compare(students[sCodeA], students[sCodeB])
  })
}

// INSTANCE PROPERTIES

// Returns the student's data on the operation that they are currently working on.
// Returns falsy if the student has completed all operations assigned in their current program.
// Notes:
//   Do not call unless student.isValidProgram() returns true.
proto.currentStudentOperation = function (
  this: StudentInstance
): StudentOperation {
  let rval
  const operationIds = this.operationIds()
  for (let i = 0; i < operationIds.length; i++) {
    const operationId = operationIds[i]
    const studentOperation = this.studentOperation(operationId, true)
    if (!this.finishedWithStudentOp(studentOperation)) {
      rval = studentOperation
      break
    }
  }
  return rval
}

// REVIEW: s/b *is*FinishedWithStudentOp
proto.finishedWithStudentOp = function (
  this: StudentInstance,
  studentOperation: StudentOperation
): boolean {
  if (!_.isNumber(studentOperation.mastery)) {
    return false
  }
  if (
    (this.program().isAssessmentOnly() &&
      studentOperation.placementIsComplete()) ||
    studentOperation.isMastered()
  ) {
    return true
  }
  return false
}

proto.hasUsage = function (this: StudentInstance): boolean {
  return !_.isEmpty(this.usage)
}

// Returns true iff the student is done with their assigned program.
proto.isDone = function (this: StudentInstance): boolean {
  return this.isDoneWithProgram(this.programId)
}

// This property asks a hypothetical question: if the student had the specified program assigned,
// would they be done with the program?
// Returns false if the student's program is unknown or invalid.
// TODO: Instead, should not call if student's program is unknown or invalid.
// LATER: should work for assessment-only.
proto.isDoneWithProgram = function (
  this: StudentInstance,
  programId: number
): boolean {
  const that = this
  const constructor = </* TYPESCRIPT: */ StudentClass>this.constructor
  if (!constructor.isValidProgram(programId)) {
    return false
  }
  const { operationIds } = Program.fromId(programId)
  if (_.isEmpty(operationIds)) {
    return false
  }
  return _.every(operationIds, function (operationId) {
    const studentOperation = that.studentOperation(operationId)
    return studentOperation && that.finishedWithStudentOp(studentOperation)
  })
}

// Returns true iff there is a parent account linked to this student account.
proto.isEnrolled = function (this: StudentInstance): boolean {
  return this.hasTeachers()
}

// Returns true iff the student does not belong to a teacher, classroom, or expired classroom.
proto.isOrphan = function (this: StudentInstance): boolean {
  return (
    !this.hasTeachers() && !this.hasClassrooms() && !this.hasExpiredClassrooms()
  )
}

// Returns true if the student's assigned program is known (but may not be valid).
// Notes:
//   You typically want to use isValidProgram instead, which is a stronger check.
proto.isKnownProgram = function (this: StudentInstance): boolean {
  const constructor = </* TYPESCRIPT: */ StudentClass>this.constructor
  return constructor.isKnownProgram(this.programId)
}

// Returns true if the student's assigned program is known and valid.
proto.isValidProgram = function (this: StudentInstance): boolean {
  const constructor = </* TYPESCRIPT: */ StudentClass>this.constructor
  return constructor.isValidProgram(this.programId)
}

// Notes:
//   Do not call unless student.isValidProgram() returns true.
proto.program = function (this: StudentInstance): Program {
  return Program.fromId(this.programId)
}

// Returns the list of operation ids in the student's assigned program.
// Notes:
//   Do not call unless student.isValidProgram() returns true.
proto.operationIds = function (this: StudentInstance): number[] {
  return this.program().operationIds
}

// Returns the student operation with the specified id,
// or null if the student operation doesn't exist,
proto.studentOperation = function (
  this: StudentInstance,
  operationId: number,
  createIfMissing?: boolean
): StudentOperation {
  let studentOperation = this[`op${operationId}`]
  if (!studentOperation && createIfMissing) {
    studentOperation = this.createStudentOperation(operationId)
  }
  return studentOperation
}

// Returns a list of all operations that the student has worked on,
// regardless of the operations that are in their currently assigned program.
proto.studentOperations = function (this: StudentInstance): StudentOperation[] {
  const rval: StudentOperation[] = _.filter(
    </* TYPESCRIPT */ any>this,
    function (_value, key) {
      return OPERATION_RE.test(key)
    }
  )
  return _.sortBy(rval, function (op) {
    return op.operationId
  })
}

// INSTANCE METHODS

proto.createStudentOperation = function (
  this: StudentInstance,
  operationId: number
): StudentOperation {
  const key = `op${operationId}`
  assert(!this[key]) // Do not create a student operation that already exists.
  const studentOperation = new StudentOperation({ operationId })
  this[key] = studentOperation
  return studentOperation
}
