import React from 'react'
import ErrorBoundary from 'common/components/ErrorBoundary'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Student from './student'

import packageJson from '../package.json'

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path='/app/student/*' element={<Student />} />
          <Route path='/' element={<Navigate to='/app/student' replace />} />
          <Route
            path='/app/version'
            element={<div>Version {packageJson.version}</div>}
          />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
