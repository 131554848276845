/* eslint-disable class-methods-use-this */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-this-alias */
// LODASH IMPORTS
import _ from 'lodash'
import q from 'q'
import { PlainObject, assert, newObject, Milliseconds } from './common'

import * as xmDate from './xm-date/client-side'
import { ClientStudent } from './client-student'
import * as at from './account-types'
import * as atc from './account-types-client'
import { Practice, PracticeInterface } from './practice'
import { Quiz, QuizInterface } from './quiz'
import { SigninSession } from './signin-session'
import { AudioPlayer } from './audio/audio-player'
import { ClientError, persistentState, clientLanguage, util } from './helpers'
import { config } from './config/config-shim'
import * as api from './web-server/api-types'
import { ItemsManager } from './items-manager'
import { StudentSigninRval } from './account-types-client'

const logger = console.log

// LATER: create Activity base class and put this there:
const STORAGE_KEY = 'studentSession'

type StudentSessionOptions = {
  rlang?: string
  redirect?: string
  remember?: boolean
}
export type ScreenName =
  | 'welcome'
  | 'quiz_intro'
  | 'quiz'
  | 'quiz_results'
  | 'practice_intro'
  | 'practice'
  | 'practice_results'
  | 'goodbye'
export type StudentSigninSucceeded = {
  session: StudentSession
  student: atc.ClientStudentInstance
  title?: string
  message?: string
}

// CONSTRUCTOR

export class StudentSession {
  // REVIEW: what does lack of JSONification for activity, nextActivity, student imply for saved sessions?
  static singleton: StudentSession

  private activity: Quiz | Practice

  activityTimeLimit: number

  cleanupFn: () => void

  code: string

  elapsed: number

  lastActiveAt: number

  nextActivity: Quiz | Practice

  numVideosWatched: number

  pauseVideoShown = false

  practiceSet: number

  qualities: Array<Array<number>>

  redirect: string

  rlang: string

  sessionTimeLimit: number

  secret: string

  startedAt: number

  student: atc.ClientStudentInstance // TYPESCRIPT: student: ClientStudent;

  welcomed: boolean

  videoUnavailableShown: boolean

  xd: number

  constructor(
    student: atc.ClientStudentInstance,
    state?: StudentSession,
    options?: StudentSessionOptions
  ) {
    // not serialized:
    this.student = student
    _.assign(this, config.studentSession, options)
    // this.sessionTimeLimit
    // this.activityTimeLimit
    this.lastActiveAt = Date.now()
    assert(this.sessionTimeLimit, 'No time limit assigned for session')
    if (state) {
      _.assign(this, state)
      this.activity = reconstituteActivity(state.activity)
      this.nextActivity = reconstituteActivity(state.nextActivity)
    } else {
      this.code = student.code
      this.secret = student.getSecret()
      this.activity = null
      this.elapsed = 0
      this.practiceSet = 0
      this.qualities = []
      this.startedAt = Date.now()
      this.welcomed = false
      this.videoUnavailableShown = false
      this.xd = xmDate.today(student.getTimezoneOffset())
    }
  }

  // CLASS PROPERTIES

  // constructor.singleton

  // CLASS METHODS

  // returns a promise to a student session

  static load(): q.Promise<StudentSession> {
    AudioPlayer.load().precacheStudentSounds()
    if (this.singleton) {
      return q(this.singleton)
    }
    return this.reload()
  }

  private static reload(): q.Promise<StudentSession> {
    const that = this
    let state = persistentState.window.get(STORAGE_KEY) as StudentSession
    if (!state) {
      persistentState.window.remove(STORAGE_KEY)
      state = null
      return null
    }
    const result = ItemsManager.get().loadStudent(
      newObject(state.code, state.secret),
      state.code
    )
    return result.then(function (student) {
      // ensure we don't reload a session from before today.
      if (!student || state.xd !== xmDate.today(student.getTimezoneOffset())) {
        persistentState.window.remove(STORAGE_KEY)
        state = null
        return null
      }
      that.singleton = new StudentSession(student, state)
      return that.singleton
    })
  }

  static rememberedSignIn(
    code: at.AccountCode,
    secret: string,
    options: StudentSessionOptions
  ): q.Promise<StudentSigninSucceeded> {
    const fields = {
      sCode: code,
      sSecret: secret,
    }
    return this.submitSignin(fields, options)
  }

  static classroomSignIn(
    code: at.AccountCode,
    secret: string,
    options: StudentSessionOptions
  ): q.Promise<StudentSigninSucceeded> {
    const fields = {
      sCode: code,
      sSecret: secret,
    }
    return this.submitSignin(fields, options, true)
  }

  static signInWithEmailNameAndPin(
    email: string,
    name: string,
    pin: string,
    options: {
      socialId?: string
      socialProvider?: at.SsoProvider
      redirect?: string
      rlang?: string
      remember?: boolean
    }
  ): q.Promise<StudentSigninSucceeded> {
    const fields = {
      email,
      displayName: name,
      pin,
      socialId: options.socialId,
      socialProvider: options.socialProvider,
    }
    return this.submitSignin(fields, {
      redirect: options.redirect,
      rlang: options.rlang,
      remember: options.remember,
    })
  }

  static signout(): void {
    this.singleton && this.singleton.signout()
    // NOTE: we just remove this key because it's possible we'll propagate a student session in sessionStorage without actually initiating the session.
    // LATER: improve lifecycle so either: key isn't stored or session is initiated upon key storage. Note that TeacherSession loads session when key is first stored.
    persistentState.window.remove(STORAGE_KEY)
  }

  static submitSignin(
    fields:
      | atc.ClientParamsFilter<api.StudentSigninParams>
      | { sCode: at.AccountCode; sSecret: at.StudentSecret },
    options: StudentSessionOptions,
    isClassroomSignin?: boolean
  ): q.Promise<StudentSigninSucceeded> {
    const that = this
    let promise
    if ('sCode' in fields) {
      promise = ClientStudent.signInWithCode(fields, !!isClassroomSignin)
    } else {
      promise = ClientStudent.signIn(fields)
    }
    return promise.then(function (response: StudentSigninRval) {
      const { student } = response
      const signinSession = SigninSession.load()
      if ('sCode' in fields && fields.sCode !== student.code) {
        // code and secret could change because of merge; if so modify signin and signin as new student
        signinSession.removeStudent(fields.sCode)
        signinSession.addStudent(student, true)
      } else if (options.remember) {
        signinSession.addStudent(student, true)
      } else {
        signinSession.addStudent(student, false)
      }
      return that.signInWithStudent(response, options, false)
    })
  }

  static signInWithStudent(
    results: {
      student: atc.ClientStudentInstance
      message?: string
      title?: string
    },
    options: StudentSessionOptions,
    isSso: boolean
  ): StudentSigninSucceeded {
    let err
    const that = this
    const { student } = results
    if (student.isDone()) {
      showDoneMessage(err, student, isSso)
    } else if (!student.isKnownProgram()) {
      // err = errorMessages.newError('unknown-feature');
      err.reason = 'unknown-feature'
      err.dontReport = true
      throw err
    } else if (!student.isValidProgram()) {
      // err = errorMessages.newError('corrupt-operation');
      err.reason = 'corrupt-operation'
      throw err
    } else if (!student.currentStudentOperation().isKnown()) {
      // err = errorMessages.newError('unknown-feature');
      err.reason = 'unknown-feature'
      err.dontReport = true
      throw err
    } else if (!student.currentStudentOperation().isValid()) {
      // err = errorMessages.newError('corrupt-operation');
      err.reason = 'corrupt-operation'
      throw err
    }
    if (results.student.deleted) {
      // student has been deleted for reason other than a merge (e.g. unmerge)
      err = new ClientError(
        `Student ${results.student.code} has been deleted.`,
        'client-unexpected',
        'unexpected'
      )
      err.removeRemembered = true
      throw err
    }
    this.singleton = new this(results.student, null, options)
    return {
      session: that.singleton,
      student: results.student,
      title: results.title,
      message: results.message,
    }
  }

  // INSTANCE METHODS

  clearQuiz(): void {
    this.clearActivity('quiz')
  }

  clearPractice(): void {
    this.clearActivity('practice')
  }

  currentPractice(): Practice {
    if (this.activity.type !== 'practice') {
      throw new ClientError(
        'Attempting to get non-practice activity in practice screen',
        'client-unexpected',
        'unexpected'
      )
    }
    return this.activity
  }

  currentQuiz(): Quiz {
    if (this.activity.type !== 'quiz') {
      throw new ClientError(
        'Attempting to get non-quiz activity in quiz screen',
        'client-unexpected',
        'unexpected'
      )
    }
    return this.activity
  }

  currentState(): { screen: ScreenName; activity?: Practice | Quiz } {
    if (this.student.isDone() && !this.activity) {
      return { screen: 'goodbye' }
    }
    if (!this.welcomed) {
      return { screen: 'welcome' }
    }
    if (this.activity) {
      return {
        screen: this.activity.currentScreen(),
        activity:
          this.activity.type === 'quiz'
            ? this.currentQuiz()
            : this.currentPractice(),
      }
    }
    return { screen: 'goodbye' }
  }

  nextQuestion(): void {
    this.activity.nextQuestion(this.activity.currentQuestion())
  }

  save(): void {
    // LATER: save only data rather than depending on JS to remove functions etc?
    const state = _.omit(this, 'student') as PlainObject
    persistentState.window.set(STORAGE_KEY, state)
  }

  signout(): void {
    AudioPlayer.load().emptyCache()
    delete StudentSession.singleton
    persistentState.window.remove(STORAGE_KEY)
  }

  screenFinished(finishedScreen: ScreenName): Q.Promise<unknown> {
    const that = this
    that.save()
    if (that.activity) {
      that.activity.screenFinished(finishedScreen)
    }
    const deferred = q.defer()
    switch (finishedScreen) {
      case 'welcome':
        that.welcomed = true
        that.activity = that.determineNextActivity()
        deferred.resolve()
        break
      case 'quiz_intro':
        deferred.resolve()
        break
      case 'quiz':
        const results = that.activity.results()
        that.accumulateQuality(results.quality, results.elapsedActive)
        q(
          that.student.submitAssignment(
            results,
            that.quality(),
            that.timeLimitExceeded()
          )
        )
          .then((result) => {
            if (result?.earnedAwards)
              that.activity.saveEarnedAwards(result.earnedAwards)
            that.nextActivity = that.determineNextActivity()
            deferred.resolve()
          })
          .catch((err) => {
            deferred.reject(err)
          })
        break
      case 'quiz_results':
        that.activity = that.nextActivity
        delete that.nextActivity
        deferred.resolve()
        break
      case 'practice_intro':
        deferred.resolve()
        break
      case 'practice':
        const practiceResults = that.activity.results()
        that.accumulateQuality(
          practiceResults.quality,
          practiceResults.elapsedActive
        )
        q(
          that.student.submitAssignment(
            practiceResults,
            that.quality(),
            that.timeLimitExceeded()
          )
        )
          .then((result) => {
            if (result?.earnedAwards)
              that.activity.saveEarnedAwards(result.earnedAwards)
            that.nextActivity = that.determineNextActivity()
            deferred.resolve()
          })
          .catch((err) => {
            deferred.reject(err)
          })
        break
      case 'practice_results':
        that.activity = that.nextActivity
        delete that.nextActivity
        deferred.resolve()
        break
      case 'goodbye':
        const quality = that.quality()
        that.student
          .signout(quality)
          .then(() => {
            that.signout()
            deferred.resolve()
          })
          .catch((err) => {
            deferred.reject(err)
          })
        break
      default:
        deferred.reject(`Unexpected screen ${finishedScreen}.`)
        break
    }
    return deferred.promise
  }

  resetTimeout(cleanupFn?: () => void): void {
    const that = this
    that.lastActiveAt = Date.now()
    if (cleanupFn) {
      this.cleanupFn = cleanupFn
    }
  }

  pauseVideoWasShown(shown?: boolean): boolean {
    if (shown) {
      this.pauseVideoShown = true
    }
    return this.pauseVideoShown
  }

  retrievePractice(): PlainObject {
    return this.retrieveActivity('practice')
  }

  retrieveQuiz(): PlainObject {
    return this.retrieveActivity('quiz')
  }

  storePractice(info): void {
    this.storeActivity('practice', info)
  }

  storeQuiz(info): void {
    this.storeActivity('quiz', info)
  }

  unavailableShown(): void {
    this.videoUnavailableShown = true
  }

  // PRIVATE INSTANCE METHODS

  private accumulateQuality(quality: at.Quality, elapsed: Milliseconds): void {
    if (_.isNaN(elapsed) || !_.isNumber(elapsed) || elapsed < 0) {
      const obj = _.assign({ elapsed }, this.activity)
      logger(obj, "Activity with non-number 'elapsed' attribute")
    } else {
      this.elapsed += elapsed
    }
    logger(
      'Accumulating quality for activity: %s/session elapsed, %s/activity elapsed, %s/activity activeElapsed',
      this.elapsed,
      this.activity.elapsed(),
      this.activity.elapsedActive()
    )
    if (
      _.isNaN(this.elapsed) ||
      !_.isNumber(this.elapsed) ||
      this.elapsed < 0
    ) {
      logger(
        "Elapsed is undefined or very small. We'll probably never quit: session elapsed %s/activity elapsed() %s/activity activeElapsed() %s",
        this.elapsed,
        this.activity.elapsed(),
        this.activity.elapsedActive()
      )
    }
    this.qualities.push([quality, elapsed])
  }

  // student has signed in, or just completed a quiz or a practice.
  // determine the next activity based.
  private determineNextActivity(): Quiz | Practice {
    // LATER: elapsed should not include delays for lack of attention.
    let rval
    const studentOperation = this.student.currentStudentOperation()
    if (!studentOperation || this.timeLimitExceeded()) {
      rval = null
    } else if (!studentOperation.placementIsComplete()) {
      this.checkConsecutiveActivities('placementQuiz')
      rval = Quiz.fromStudentOperation(
        studentOperation,
        true,
        this.activityTimeLimit
      )
    } else if (
      !studentOperation.progressQuizDone(
        xmDate.today(this.student.getTimezoneOffset())
      )
    ) {
      this.checkConsecutiveActivities('progressQuiz')
      rval = Quiz.fromStudentOperation(
        studentOperation,
        false,
        this.activityTimeLimit
      )
    } else {
      this.checkConsecutiveActivities('practice')
      rval = Practice.fromStudentOperation(
        studentOperation,
        this.practiceSet,
        this.activityTimeLimit
      )
      this.practiceSet = this.practiceSet ? 0 : 1 // alternate practice sets.
    }
    return rval
  }

  private timeLimitExceeded(): boolean {
    if (!this.sessionTimeLimit) {
      logger(
        "No session timelimit is set for student %s! This session won't terminate on time.",
        this.student.code
      )
    }
    return this.elapsed > this.sessionTimeLimit
  }

  // REVIEW: quality is only computed on one complete session and not on
  // any other sessions or partial sessions in the day.
  private quality(): at.Quality {
    const totalQuality = _.reduce(
      this.qualities,
      function (memo, entry) {
        return memo + entry[0] * entry[1]
      },
      0
    )
    const totalTime = _.reduce(
      this.qualities,
      function (memo, entry) {
        return memo + entry[1]
      },
      0
    )
    let rval: at.Quality
    if (totalTime === 0) {
      rval = 0
    } else {
      const avgQuality = totalQuality / totalTime
      rval = Math.max(0, Math.min(3, Math.round(avgQuality)))
      const expectedQualities = [1, 2, 3]
      if (expectedQualities.indexOf(rval) < 0) {
        logger(
          this.qualities,
          'Client generated unexpected quality (quality: %d, raw: %d, weighted quality: %d, time: %d); sending quality 1',
          rval,
          totalQuality / totalTime,
          totalQuality,
          totalTime
        )
        rval = 1
      }
    }
    return rval
  }

  // HELPER FUNCTIONS

  private checkConsecutiveActivities(
    type: 'placementQuiz' | 'progressQuiz' | 'practice'
  ) {
    if (this.qualities.length > 4) {
      // we could have up to four activities legitimately;
      // short quiz followed by two full practices followed by a short practice could still be under 300s
      const errObject = {
        xd: xmDate.today(this.student.getTimezoneOffset()),
        progress: this.student.currentStudentOperation().progress,
        qualities: this.qualities,
      }
      logger(
        errObject,
        'Too many activities assigned (%s): (%d activities, %d elapsed, now-startedAt %d, timelimit %d, student %s)',
        type,
        this.qualities.length,
        this.elapsed,
        Date.now() - this.startedAt,
        this.sessionTimeLimit,
        this.student.code
      )
    }
  }

  private clearActivity(type: 'quiz' | 'practice'): void {
    logger('Clearing activity data.')
    delete this[`${type}State`]
    this.save()
  }

  private retrieveActivity(type: 'quiz' | 'practice'): PlainObject {
    return this[`${type}State`]
  }

  private storeActivity(type: 'quiz' | 'practice', info: PlainObject): void {
    this[`${type}State`] = info
    this.save()
  }
}

function reconstituteActivity(
  activity: QuizInterface | PracticeInterface
): Quiz | Practice | undefined {
  let rval
  if (
    activity &&
    !(activity instanceof Quiz) &&
    !(activity instanceof Practice)
  ) {
    if (activity.type === 'quiz') {
      rval = new Quiz(activity)
    } else if (activity.type) {
      rval = new Practice(activity)
    }
  } else {
    rval = activity
  }
  return rval
}

function showDoneMessage(
  err,
  student: atc.ClientStudentInstance,
  isSso: boolean
) {
  if (isSso && student.socialProvider) {
    // don't show this message if the student is clever-only,
    // since we don't allow them to detach it anyway.
    // err = errorMessages.newError('program-finished-sso');
    //   try {
    //     const info = clientLanguage.getResource('errors', 'defaults')['program-finished-sso'];
    //     err.title = info.title;
    //     const subs = {
    //       shownForGoogle: student.socialProvider === 'google' ? 'display: block;' : 'display: none;',
    //       shownForClever: student.isClever() ? 'display: block;' : 'display: none;'
    //     }
    //     err.message = util.prepareTemplate(info.message)(subs);
    //     err.dontReport = true;
    //   } catch (err: any) {
    //     err = errorMessages.newError('program-finished');
    //     err.reason = 'program-finished';
    //     err.dontReport = true;
    //   }
    //   throw err;
    // } else {
    //   err = errorMessages.newError('program-finished');
    //   err.reason = 'program-finished';
    //   err.dontReport = true;
    //   throw err;
    // }
  }
}
