// REQUIREMENTS

import _ from 'lodash'
import dayjs from 'dayjs'

import { PlainObject, Timestamp, inherits } from './common'

import {
  ClassroomClass,
  ClassroomInstance,
  ExpirationStatus,
  ClassroomMap,
  AccountCode,
  StudentMap,
} from './account-types'
import { Account } from './account'
import { Student } from './student'
// NOTE: requiring only 'shared' xm-date lib; don't use server-side lib (which *does* requires Moment) in code that will be shipped to client.
import * as xmDate from './xm-date/client-side'

// EXPORTS

export const Classroom: ClassroomClass = ClassroomConstructor as any

// CONSTANTS

const TWO_WEEKS_IN_MS = 2 * 7 * 24 * 60 * 60 * 1000

// CONSTRUCTOR

const superclass = Account
function ClassroomConstructor(item: any) {
  superclass.call(this, item)
}

// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_RE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: ClassroomClass = <
  { (item: PlainObject): void } & ClassroomClass
>ClassroomConstructor
inherits(constructor, superclass)
const proto: ClassroomInstance = constructor.prototype

// CLASS CONSTANTS

constructor.TYPE = 'classroom'
constructor.PREFIX = 'C'

// CLASS PROPERTIES

// By default, classes should expire on June 30, unless that is less than three months away,
// in which case it should be the following June 30.
constructor.defaultExpirationTimestamp = function (
  this: ClassroomClass
): Timestamp {
  let next = dayjs().month(5).date(30)
  if (next.diff(dayjs(), 'month') < 3) {
    next = next.add(1, 'year')
  }
  return next.valueOf()
}

// CLASS METHODS

// Compare two classes for sorting order.
// Returns:
//   -1 if classroomA should be sorted before classroomB,
//   0 if classroomA and classroomB sort the same,
//   1 if classroomA should be sorted after classroomB.
constructor.compare = function (
  this: ClassroomClass,
  classroomA: ClassroomInstance,
  classroomB: ClassroomInstance
): number {
  if (classroomA === classroomB) {
    return 0
  }

  // Sort missing classrooms below all others
  if (!classroomA) {
    return 1
  }
  if (!classroomB) {
    return -1
  }

  // Sort expired before unexpired
  const aExpirationStatus = classroomA.expirationStatus()
  const bExpirationStatus = classroomB.expirationStatus()
  if (aExpirationStatus !== bExpirationStatus) {
    return aExpirationStatus - bExpirationStatus
  }

  // Sort unhidden before hidden
  const aHidden = classroomA.hidden ? 1 : 0
  const bHidden = classroomB.hidden ? 1 : 0
  if (aHidden !== bHidden) {
    return aHidden - bHidden
  }

  // Sort non-Clever before clever
  const aClever = classroomA.cleverId ? 1 : 0
  const bClever = classroomB.cleverId ? 1 : 0
  if (aClever !== bClever) {
    return aClever - bClever
  }

  // Sort by lowercase name
  // REVIEW: I18N?
  const aName = classroomA.name.toLowerCase()
  const bName = classroomB.name.toLowerCase()
  if (aName < bName) {
    return -1
  }
  if (aName === bName) {
    return 0
  }
  return 1
}

// See comments on proto.expirationStatus.
// Note: this does not return EXPIRED status, because it has no knowledge of the classroom-specific 'expired' value.
constructor.expirationStatus = function (
  this: ClassroomClass,
  expires: Timestamp
): ExpirationStatus {
  const now = xmDate.now()
  if (!expires || now < expires) {
    return ExpirationStatus.NOT_EXPIRED
  }
  if (now < expires + TWO_WEEKS_IN_MS) {
    return ExpirationStatus.VISIBLY_EXPIRED
  }
  return ExpirationStatus.INVISIBLY_EXPIRED
}

constructor.findByName = function (
  this: ClassroomClass,
  classrooms: ClassroomMap,
  name: string
): ClassroomInstance {
  return _.find(classrooms, function (classroom) {
    return classroom.name === name
  })
}

// REVIEW: See also web-server/api-util/api-util.ts/getFirstClassroom and compareClassroomsByName.
constructor.sortedCodes = function (
  this: ClassroomClass,
  cCodes: AccountCode[],
  classrooms: ClassroomMap
): AccountCode[] {
  return cCodes.sort((cCodeA, cCodeB) => {
    return Classroom.compare(classrooms[cCodeA], classrooms[cCodeB])
  })
}

// INSTANCE PROPERTIES

// A classroom can have one of four expiration statuses:
//  NOT_EXPIRED(=0): expiration is in the future.
//  VISIBLY_EXPIRED: within two weeks of expiration date. Still visible in teacher account.
//  INVISIBLY_EXPIRED: more than two weeks past expiration date. Not visible.
//  EXPIRED: more than two weeks past expiration date, and dynamo-scanner has "expired" the class,
//           setting the "expired" field to true.
proto.expirationStatus = function (this: ClassroomInstance): ExpirationStatus {
  if (this.expired) {
    return ExpirationStatus.EXPIRED
  }
  return constructor.expirationStatus(this.expires)
}

// REVIEW: This is a misnomer. Returns true for not-expired or visibly-expired.
proto.isExpired = function (this: ClassroomInstance): boolean {
  const status = this.expirationStatus()
  return (
    status === ExpirationStatus.EXPIRED ||
    status === ExpirationStatus.INVISIBLY_EXPIRED
  )
}

proto.isOrphan = function (this: ClassroomInstance): boolean {
  return !this.hasTeachers()
}

proto.sortedStudentCodes = function (
  this: ClassroomInstance,
  students: StudentMap
): AccountCode[] {
  const sCodes = Object.keys(this.studentCodes())
  return Student.sortedCodes(sCodes, students)
}
