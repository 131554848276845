export const BEGINNING_MULTIPLICATION: any = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  20: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  30: true,
  31: true,
  32: true,
  40: true,
  41: true,
  42: true,
  50: true,
  51: true,
  52: true,
  60: true,
  61: true,
  62: true,
  70: true,
  71: true,
  72: true,
  80: true,
  81: true,
  82: true,
  90: true,
  91: true,
  92: true,
}
export const BEGINNING_DIVISION: any = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  31: true,
  32: true,
  41: true,
  42: true,
  51: true,
  52: true,
  61: true,
  62: true,
  71: true,
  72: true,
  81: true,
  82: true,
  91: true,
  92: true,
}
