// REQUIREMENTS

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { Milliseconds, Timestamp, TimezoneOffset } from '../common'

import * as shared from './shared'

const logger = console.log
dayjs.extend(utc)

// GLOBALS

let clockCorrection: Milliseconds = 0

// SHARED EXPORTS

export const { isIso8601Date } = shared
export const { timestampFromXd } = shared
export const { xdFromTimestamp } = shared

// EXPORTED FUNCTIONS

export function now(): Timestamp {
  return new Date().getTime() + clockCorrection
}

export function firstPossibleTimestamp(xd: shared.XmDate): Timestamp {
  // First timestamp this xd could represent (UTC start of day +14)
  shared.logInvalidXd(xd)
  return dayjs(shared.BASE_DATE)
    .utc()
    .add(xd, 'day')
    .startOf('day')
    .subtract(14, 'hour')
    .valueOf()
}

export function lastPossibleTimestamp(xd: shared.XmDate): Timestamp {
  // Last timestamp this xd could represent (UTC end of day -12)
  return dayjs(shared.BASE_DATE)
    .utc()
    .add(xd, 'day')
    .endOf('day')
    .add(12, 'hour')
    .valueOf()
}

export function dateFromXd(xd: shared.XmDate, formatString?: string): string {
  shared.logInvalidXd(xd)
  return dayjs([2009, 8, 15].join('-')).add(xd, 'day').format(formatString)
}

export function getCorrection(): Milliseconds {
  return clockCorrection
}

export function setCorrection(delta: Milliseconds): void {
  if (!Number.isNaN(delta)) {
    clockCorrection = delta
  } else {
    logger('Attempted to set non-numeric clock correction of %s.', delta)
  }
}

export function today(offset?: TimezoneOffset): shared.XmDate {
  let base
  if (offset) {
    base = dayjs(shared.zeroTimestamp(offset))
  } else {
    base = dayjs([2009, 8, 15].join('-'))
  }
  return dayjs().add(clockCorrection, 'ms').diff(base, 'day')
}

// note: month is zero based, like other JavaScript dates.
export function xdFromYMD(year: number, month: number, day: number) {
  const m = dayjs([year, month + 1, day].join('-'))
  if (!m.isValid()) {
    throw new Error('Invalid date specified.')
  }
  return dayjs([year, month + 1, day].join('-')).diff(
    dayjs([2009, 8, 15].join('-')),
    'day'
  )
}

// YYYYMMDD or YYYY-MM-DD
export function xdFromIso8601Date(ymd: string): shared.XmDate {
  if (!shared.isIso8601Date(ymd)) {
    throw new Error('Expected ISO8601 date format.')
  }
  const noHyphens = ymd.length === 8
  const year = parseInt(ymd.slice(0, 4), 10)
  const month = parseInt(noHyphens ? ymd.slice(4, 6) : ymd.slice(5, 7), 10) - 1 // month is zero based.
  const day = parseInt(noHyphens ? ymd.slice(6, 8) : ymd.slice(8, 10), 10)
  return xdFromYMD(year, month, day)
}

export function YMDFromXd(xd: shared.XmDate): [number, number, number] {
  shared.logInvalidXd(xd)
  const m = dayjs([2009, 8, 15].join('-')).add(xd, 'day')
  return [m.year(), m.month(), m.date()]
}
