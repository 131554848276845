/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
// REQUIREMENTS

import _ from 'lodash'

import q from 'q'

import sha1 from 'sha1' // TODO: lighter-weight sha1 for client
import {
  PlainObject,
  EmailAddress,
  inherits,
  mixin,
  assert,
  Timezone,
} from './common'

import {
  TeacherSigninForCSRval,
  TeacherSigninForCSParams,
  AuthorizeIPAddressRval,
  AuthorizeIPAddressParams,
  ForgetIPAddressParams,
  IPAddressAuthorizationsParams,
  PreSignupParams,
  TeacherSignup2Params,
  TeacherRememberedSigninParams,
  TeacherSigninParams,
  RefreshClassroomSigninsParams,
  EnrollChildParams,
  AddChildFromSigninParams,
  AgreeToTermsParams,
  StudentChangeProgramParams,
  DestroyTeacherParams,
  EditTeacherParams,
  EnrollChildMergeParams,
  TeacherRemoveSsoParams,
  ResetProgramParams,
  ShareClassroomParams,
  PollSynchronizationParams,
  PollSynchronizationRval1,
  PollSynchronizationRval2,
  PollSynchronizationRval4,
  PollSynchronizationRval3,
  RefreshClassroomSigninsRval,
  HomeRefreshParams,
  HomeRefreshRval,
  FinishLicenseSignupParams,
  FinishSignupParams,
  FinishAccessCodeSignupParams,
  UpgradeLicenseParams,
  TeacherRemoveCleverParams,
  RefreshParams,
  PollSynchronizationRval,
} from './web-server/api-types'
import {
  TeacherItem,
  AccountSecret,
  CreateTeacherItem,
  TeacherSigninAspect,
  AccountCode,
  Delta,
  AccountVersion,
  SsoProvider,
} from './account-types'
import {
  ClientTeacherClass,
  ClientTeacherInstance,
  ClientMixinInstance,
  ClientParamsFilter,
  ClientStudentInstance,
  CacheType,
  ClientTeacherMap,
  ClientClassroomMap,
  ClientStudentCollection,
  ChangeStudentProgramParams,
  ClientAccountsMap,
  ClientClassroomInstance,
  FieldsNeededForUpdate,
  TeacherSigninRvalC,
  HomeRefreshClassroomsForCS,
  HomeRefreshTeachersForCS,
  ClientHomeRefreshParams,
  EnrollMergeRval,
  HomeRefreshResult,
  ClientHomeRefreshRval,
  ClientClassroomRefreshRval,
} from './account-types-client'
import { Cache } from './cache'
import { ClientMixin } from './client-mixin'
import { ClientClassroom } from './client-classroom'
import { ClientStudent } from './client-student'
import { StudentOperation } from './student-operation'
import { Program } from './program'
import { Teacher } from './teacher'

const logger = console.log

// EXPORTS

export const ClientTeacher: ClientTeacherClass = ClientTeacherConstructor as any

// CONSTANTS

// CONSTRUCTOR

const superclass = Teacher
function ClientTeacherConstructor(item: TeacherItem) {
  superclass.call(this, item)
  ClientMixin.call(this, item)
}
// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_RE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: ClientTeacherClass = <
  { (item: TeacherItem): void } & ClientTeacherClass
>ClientTeacherConstructor
ClientMixin.referenceDerivedClass('teachers', constructor)
inherits(constructor, superclass)
mixin(constructor, ClientMixin)
const proto: ClientTeacherInstance = constructor.prototype

// CLASS CONSTANTS

constructor.signinFieldsRE =
  /^(code|name|addressedAs|email|isTeacher|version)$|^C|^S/
constructor.secretFieldsRE = /^C|^S/
constructor.typePlural = 'teachers'

// CLASS PROPERTIES

// CLASS METHODS

constructor.signinForCS = function (
  this: ClientTeacherClass,
  email: EmailAddress,
  secret: AccountSecret,
  authorize?: boolean
): q.Promise<TeacherSigninForCSRval & { teacher: ClientTeacherInstance }> {
  const that = this
  const args = {
    email,
    tSecret: secret,
    authorizeIp: authorize || undefined,
  }
  return (<ClientTeacherClass>this).connection
    .emitToServer<TeacherSigninForCSParams>(
      'teacher/signin-for-cs',
      args,
      'blocking'
    )
    .then(function (result: TeacherSigninForCSRval) {
      return _.assign(result, {
        teacher: that.signinCreate(result.teacherSignin),
      })
    })
}

constructor.authorizeIp = function (
  this: ClientTeacherClass,
  email: EmailAddress,
  secret: AccountSecret,
  removalsOnly: boolean
): q.Promise<AuthorizeIPAddressRval & { teacher: ClientTeacherInstance }> {
  const that = this
  const args = {
    email,
    tSecret: secret,
    removalsOnly,
  }
  return (<ClientTeacherClass>this).connection
    .emitToServer<AuthorizeIPAddressParams>(
      'teacher/authorize-ip-address',
      args,
      'blocking'
    )
    .then(function (result: AuthorizeIPAddressRval) {
      return _.assign(result, {
        teacher: that.signinCreate(result.teacherSignin),
      })
    })
}

// OVERRIDES ClientMixin.create
constructor.create = function (
  this: ClientTeacherClass,
  item: any,
  cache?: Cache<ClientMixinInstance>,
  notSynced?: boolean
): any {
  return ClientMixin.create.call(this, item, cache, notSynced)
}

constructor.signinCreate = function (
  this: ClientTeacherClass,
  item: any,
  _notSynced?
): any {
  item = this.filterSigninFields(item)
  return ClientMixin.create.call(this, item, this.caches.teacherSignins)
}

constructor.localCreate = function (this: ClientTeacherClass, item: any): any {
  return ClientMixin.create.call(this, item, this.caches.teachers)
}

constructor.forgetIp = function (this: ClientTeacherClass): q.Promise<void> {
  return (<ClientTeacherClass>(
    this
  )).connection.emitToServer<ForgetIPAddressParams>(
    'teacher/forget-ip-address',
    {},
    'blocking'
  )
}

constructor.getIpAuthorizations = function (
  this: ClientTeacherClass
): q.Promise<{ teachers: ClientTeacherMap; removal: boolean }> {
  const that = this
  const args = {}
  return (<ClientTeacherClass>this).connection
    .emitToServer<IPAddressAuthorizationsParams>(
      'teacher/ip-address-authorizations',
      args,
      'blocking'
    )
    .then(function (results) {
      return {
        teachers: _.mapValues(results.teacherSignins, function (data) {
          return that.create(data, that.caches.teacherSignins)
        }),
        removal: results.removalsAllowed,
      }
    })
}

constructor.hashPassword = function (
  this: ClientTeacherClass,
  email: EmailAddress,
  password: string
): AccountSecret {
  // NOTE: default output type is string, but TypeScript doesn't recognize this.
  return sha1(password + email.toLowerCase()) as string
}

constructor.instantiate = function (
  this: ClientTeacherClass,
  item: any,
  cache?: Cache<ClientMixinInstance>,
  notSynced?: boolean
): any {
  return superclass.instantiate.call(this, item, cache, notSynced)
}

constructor.preSignup = function (
  this: ClientTeacherClass,
  fields: ClientParamsFilter<PreSignupParams>
): q.Promise<void> {
  logger(fields, 'Pre-signup')
  return (<ClientTeacherClass>this).connection.emitToServer<PreSignupParams>(
    'teacher/pre-signup',
    fields,
    'blocking'
  )
}

constructor.signup = function (
  this: ClientTeacherClass,
  fields: ClientParamsFilter<TeacherSignup2Params>
): q.Promise<{
  teacher: ClientTeacherInstance
  shouldFinishSignup: boolean
  student?: ClientStudentInstance
  syncId?: string
}> {
  const that = this
  logger(fields, 'Creating')
  return (<ClientTeacherClass>this).connection
    .emitToServer<TeacherSignup2Params>('teacher/signup2', fields, 'blocking')
    .then(function (result) {
      const teacher = that.create(result.teacher, that.caches.teachers)
      let student
      if (result.student) {
        student = ClientStudent.create(
          result.student,
          ClientStudent.caches.students
        )
      }
      const instantiated = { teacher, student }
      return _.assign(result, instantiated)
    })
}

constructor.signIn = function (
  this: ClientTeacherClass,
  fields:
    | ClientParamsFilter<TeacherRememberedSigninParams>
    | ClientParamsFilter<TeacherSigninParams>,
  cacheType?: CacheType
): q.Promise<TeacherSigninRvalC> {
  const that = this
  let promise
  if ('tCode' in fields) {
    promise = (<ClientTeacherClass>(
      this
    )).connection.emitToServer<TeacherRememberedSigninParams>(
      'teacher/remembered-signin',
      fields,
      'blocking'
    )
  } else {
    promise = (<ClientTeacherClass>(
      this
    )).connection.emitToServer<TeacherSigninParams>(
      'teacher/signin',
      fields,
      'blocking'
    )
  }
  return promise.then(function (result) {
    const cache = (
      cacheType ? that.caches[cacheType] : that.caches.teachers
    ) as Cache<ClientTeacherInstance>
    const teacher = that.create(result.teacher, cache)
    let students
    let classrooms
    let synchronized
    if (result.students) {
      students = _.reduce(
        result.students,
        function (memo, student, code) {
          memo[code] = ClientStudent.create(
            student,
            ClientStudent.caches.students
          )
          return memo
        },
        {}
      )
    }
    if (result.classrooms) {
      classrooms = _.reduce(
        result.classrooms,
        function (memo, classroom, code) {
          memo[code] = ClientClassroom.create(
            classroom,
            ClientClassroom.caches.classrooms
          )
          return memo
        },
        {}
      )
    }
    return _.assign(result, { teacher, students, classrooms, synchronized })
  })
}

constructor.refreshClassroomSignins = function (
  this: ClientTeacherClass,
  teachers: HomeRefreshTeachersForCS,
  classrooms: HomeRefreshClassroomsForCS
): ClientClassroomRefreshRval {
  const that = this
  const cachedTeachers = getCachedItems(teachers.versions, teachers.cache)
  const cachedClassrooms = getCachedItems(classrooms.versions, classrooms.cache)

  const promise = (<ClientTeacherClass>that).connection
    .emitToServer<RefreshClassroomSigninsParams>(
      'teacher/refresh-classroom-signins',
      {
        teacherVersions: teachers.versions,
        classroomVersions: classrooms.versions,
      },
      'blocking'
    )
    .then(function (result: RefreshClassroomSigninsRval) {
      const rval = {
        teachers: {},
        classrooms: {},
        callback: that.callbacks.cacheCallback,
      }
      if (result.teachers) {
        _.forEach(result.teachers, function (teacher) {
          const newTeacher = that.signinCreate(teacher)
          rval.teachers[newTeacher.code] = newTeacher
        })
      }
      if (result.classrooms) {
        _.forEach(result.classrooms, function (classroom) {
          const newClassroom = ClientClassroom.signinCreate(classroom)
          rval.classrooms[newClassroom.code] = newClassroom
        })
      }
      return rval
    })

  return {
    cached: { teachers: cachedTeachers, classrooms: cachedClassrooms },
    promise,
  }
}

constructor.refreshHomeSignins = function (
  this: ClientTeacherClass,
  params: ClientHomeRefreshParams
): ClientHomeRefreshRval {
  const that = this
  const cachedTeachers = getCachedItems(
    params.teachers.versions,
    params.teachers.cache
  )
  const cachedStudents = getCachedItems(
    params.students.versions,
    params.students.cache
  )
  const cachedTeachersForCS = getCachedItems(
    params.teachersForCS.versions,
    params.teachersForCS.cache
  )
  const cachedClassroomsForCS = getCachedItems(
    params.classroomsForCS.versions,
    params.classroomsForCS.cache
  )
  const cached = {
    teacherSignins: cachedTeachersForCS,
    classroomSignins: cachedClassroomsForCS,
    teachers: cachedTeachers,
    students: cachedStudents,
    teacherSigninsFromSameIP: {},
    removalsAllowed: false,
  }
  const req = {
    teacherVersions: params.teachersForCS.versions,
    classroomVersions: params.classroomsForCS.versions,
    teachers: params.teachers.versions,
    students: params.students.versions,
  }
  const promise: q.Promise<HomeRefreshResult> = this.connection
    .emitToServer<HomeRefreshParams>('home-refresh', req, 'blocking')
    .then(function (result: HomeRefreshRval) {
      return <HomeRefreshResult>that.applyRefreshResult(result, req)
    })
  return { cached, promise }
}

constructor.startEnrollment = function (
  this: ClientTeacherClass,
  fields: ClientParamsFilter<EnrollChildParams>
): q.Promise<{
  status: 'signin' | 'already-enrolled'
  student?: ClientStudentInstance
}> {
  return (<ClientTeacherClass>this).connection
    .emitToServer<EnrollChildParams>('teacher/enroll-child', fields, 'blocking')
    .then(function (result) {
      if (result.status === 'already-enrolled') {
        result.student = ClientStudent.create(
          result.student,
          ClientStudent.caches.students
        )
      }
      return result
    })
}

// INSTANCE PROPERTIES

// INSTANCE METHODS

proto.addChildFromSignin = function (
  this: ClientTeacherInstance,
  email: EmailAddress,
  displayName: string,
  pin: AccountSecret
): q.Promise<ClientStudentInstance> {
  const that = this
  const args = {
    displayName,
    pin,
    email,
    ...this.identityArgs(),
  }
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<AddChildFromSigninParams>(
      'teacher/add-child-from-signin',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return ClientStudent.create(result.student, ClientStudent.caches.students)
    })
}

proto.agreeToTerms = function (
  this: ClientTeacherInstance
): q.Promise<ClientTeacherInstance | void> {
  const that = this
  const args = { ...this.identityArgs() }
  const constructor = that.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<AgreeToTermsParams>(
      'teacher/agree-to-terms',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.changeStudentPrograms = function (
  this: ClientTeacherInstance,
  students: ClientStudentCollection,
  fields: ChangeStudentProgramParams
): q.Promise<void> {
  let programId: number
  if ('programId' in fields) {
    programId = parseInt(fields.programId, 10)
  } else {
    const attrs = {
      assessmentOnlyId: parseInt(fields.assessmentOnlyId, 10),
      thresholdId: parseInt(fields.thresholdId, 10),
      mathOperationIdMix: parseInt(fields.mathOperationIdMix, 10),
      problemSetId: parseInt(fields.problemSetId, 10),
    }
    programId = Program.idFromAttributes(attrs)
  }
  const args = {
    studentVersions: _.reduce(
      students,
      function (memo, val) {
        const student = val as ClientStudentInstance
        memo[student.code] = {
          secret: student.getSecret(),
          version: student.version,
        }
        return memo
      },
      {}
    ),
    programId,
    tCode: this.code,
    tSecret: this.getSecret(),
  }
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<StudentChangeProgramParams>(
      'students/change-program',
      args,
      'blocking'
    )
    .then(function (result) {
      applyResultToStudents(students, result)
    })
}

proto.destroy = function (
  this: ClientTeacherInstance,
  secret: AccountSecret
): q.Promise<void> {
  // NOTE: takes secret as a param so in the future if we store e.g. tokens
  // on client rather than secret, we don't run into trouble with this call.
  const args = { tCode: this.code, tSecret: secret }
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection.emitToServer<DestroyTeacherParams>(
    'teacher/destroy',
    args,
    'blocking'
  )
}

proto.edit = function (
  this: ClientTeacherInstance,
  fields: PlainObject
): q.Promise<{ teacher: ClientTeacherInstance } | void> {
  const that = this
  const delta = this.generateDelta(fields)
  if (!delta) {
    return q()
  }
  const args = _.assign({ ...this.identityArgs() }, delta)
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<EditTeacherParams>('teacher/edit', args, 'blocking')
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.finishAccessCodeSignup = function (
  this: ClientTeacherInstance,
  fields
): q.Promise<{ teacher: ClientTeacherInstance }> {
  const that = this
  const args = _.assign({ ...this.identityArgs() }, fields)
  return constructor.connection
    .emitToServer<FinishAccessCodeSignupParams>(
      'teacher/finish-access-code-signup',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.hasActiveLicense = function (this: ClientTeacherInstance): boolean {
  let rval = false
  const that = this
  if (!that.hasLicenseAccess()) {
    return rval
  }
  Object.entries(that.licenseInfos).forEach(([_code, licenseInfo]) => {
    if (licenseInfo.endsAt > Date.now()) {
      rval = true
    }
  })
  Object.entries(that.adminLicenseInfos).forEach(([_code, licenseInfo]) => {
    if (licenseInfo.endsAt > Date.now()) {
      rval = true
    }
  })
  return rval
}

proto.upgradeLicense = function (
  this: ClientTeacherInstance,
  fields
): q.Promise<{ teacher: ClientTeacherInstance }> {
  const that = this
  const args = _.assign({ ...this.identityArgs() }, fields)
  return constructor.connection
    .emitToServer<UpgradeLicenseParams>(
      'teacher/upgrade-license',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.finishLicenseSignup = function (
  this: ClientTeacherInstance,
  fields
): q.Promise<{ teacher: ClientTeacherInstance }> {
  const that = this
  const args = _.assign({ ...this.identityArgs() }, fields)
  return constructor.connection
    .emitToServer<FinishLicenseSignupParams>(
      'teacher/finish-license-signup',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.finishSignup = function (
  this: ClientTeacherInstance,
  fields
): q.Promise<{ teacher: ClientTeacherInstance }> {
  const that = this
  const args = _.assign({ ...this.identityArgs() }, fields)
  return constructor.connection
    .emitToServer<FinishSignupParams>('teacher/finish-signup', args, 'blocking')
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.getCachedClassrooms = function (
  this: ClientTeacherInstance
): ClientClassroomMap {
  const constructor = this.constructor as ClientTeacherClass
  return ClientClassroom.getCachedItems(
    this.classroomCodes(),
    constructor.caches.classrooms
  )
}

proto.isLicenseAdmin = function (this: ClientTeacherInstance): boolean {
  if (_.size(this.adminLicenseInfos) > 0) {
    return true
  }
  return false
}

proto.isLicensed = function (this: ClientTeacherInstance): boolean {
  if (_.size(this.licenseInfos) > 0) {
    return true
  }
  return false
}

proto.mergeChild = function (
  this: ClientTeacherInstance,
  sCodeP: AccountCode,
  sCodeT: AccountCode,
  sSecretT: AccountSecret
): q.Promise<EnrollMergeRval> {
  const that = this
  const sSecretP = that.studentCodes()[sCodeP]
  assert(sSecretP, "Couldn't find child to merge in parent.")
  const args = {
    sCodeT,
    sSecretT,
    sCodeP,
    sSecretP,
    ...this.identityArgs(),
  }
  return (<ClientTeacherClass>that.constructor).connection
    .emitToServer<EnrollChildMergeParams>(
      'teacher/enroll-child-merge',
      args,
      'blocking'
    )
    .then(function (result) {
      if ('teacher' in result || 'teacherDelta' in result) {
        that.applyResult(result)
      }
      result.student = ClientStudent.create(
        result.student,
        ClientStudent.caches.students
      )
      return result
    })
}

proto.getTimezone = function (this: ClientTeacherInstance): Timezone {
  return this.timezoneName || 'America/Los_Angeles'
}

proto.removeClever = function (
  this: ClientTeacherInstance,
  params: { password: string }
): q.Promise<ClientTeacherInstance | void> {
  const that = this
  const args = {
    tSecret: (<ClientTeacherClass>that.constructor).hashPassword(
      this.email,
      params.password
    ),
    tCode: this.code,
    tVersion: this.version,
  }
  const constructor = that.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<TeacherRemoveCleverParams>(
      'teacher/remove-clever',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.removeSSO = function (
  this: ClientTeacherInstance,
  params: { provider: SsoProvider }
): q.Promise<ClientTeacherInstance | void> {
  const that = this
  const args = {
    provider: params.provider,
    ...this.identityArgs(),
  }
  const constructor = that.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<TeacherRemoveSsoParams>(
      'teacher/remove-sso',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.resetStudentPrograms = function (
  this: ClientTeacherInstance,
  students: ClientStudentCollection
): q.Promise<{ studentDeltas: { [code: string]: Delta } }> {
  const args = {
    tCode: this.code,
    tSecret: this.getSecret(),
    sCodes: _.keys(students),
    studentVersions: _.reduce(
      students,
      function (memo, val) {
        const student = val as ClientStudentInstance
        memo[student.code] = {
          secret: student.getSecret(),
          version: student.version,
        }
        return memo
      },
      {}
    ),
  }
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<ResetProgramParams>(
      'students/reset-program',
      args,
      'blocking'
    )
    .then(function (result) {
      // NOTE: the reason we don't use student.applyDelta is we need to
      // reconstruct the StudentOperation object for any operations being reset.
      _.forEach(result.studentDeltas, function (studentDelta, code) {
        _.forEach(studentDelta, function (val, key) {
          if (key.slice(0, 2) === 'op') {
            students[code][key] = new StudentOperation(val)
          } else if (val === null) {
            delete students[code][key]
          } else {
            students[code][key] = val
          }
        })
      })
      return result
    })
}

proto.shareClassroom = function (
  this: ClientTeacherInstance,
  cCode: AccountCode,
  cSecret: AccountSecret
): q.Promise<void> {
  const that = this
  const args = {
    cCode,
    cSecret,
    ...this.identityArgs(),
  }
  const constructor = this.constructor as ClientTeacherClass
  return constructor.connection
    .emitToServer<ShareClassroomParams>(
      'teacher/share-classroom',
      args,
      'blocking'
    )
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.pollSynchronization = function (
  this: ClientTeacherInstance,
  syncId: string,
  versions: ClientAccountsMap,
  deferred?: q.Deferred<PollSynchronizationRval>
): q.Promise<PollSynchronizationRval> {
  const that = this
  if (!deferred && this.synchronizing && this.synchronizing.isPending()) {
    throw new Error(
      'Attempting poll-synchronization when sync is already in progress'
    )
  }
  if (!deferred) {
    deferred = q.defer()
    that.synchronizing = deferred.promise
  }
  const args = {
    tCode: that.code,
    syncId,
  }
  return (<ClientTeacherClass>that.constructor).connection
    .emitToServer<PollSynchronizationParams>(
      'poll-synchronization',
      args,
      'nonblocking'
    )
    .then(function (result: PollSynchronizationRval) {
      // status: in-progress|finished|aborted
      switch (result.status) {
        case 'in-progress':
          result = result as PollSynchronizationRval1
          setTimeout(function () {
            that
              .pollSynchronization(syncId, versions, deferred)
              .fail(function (err) {
                deferred.reject(err)
              })
          }, result.retryDelay)
          break
        case 'succeeded':
          result = result as PollSynchronizationRval2
          const requested = _.extend(
            { teachers: {}, classrooms: {}, students: {} },
            versions
          )
          ;(<ClientTeacherClass>that.constructor).connection
            .emitToServer<RefreshParams>('refresh', requested, 'nonblocking')
            .then((result) => {
              constructor.applyRefreshResult(result, requested)
              deferred.resolve(result)
            })
            .fail(() => {
              deferred.reject(err)
            })
          break
        case 'timeout':
        // FALL THROUGH
        case 'not-found':
          result = result as PollSynchronizationRval4
          deferred.resolve(result)
          break
        case 'failed':
          result = result as PollSynchronizationRval3
          deferred.reject(result)
          break
        default:
          let err = 'Poll-synchronization failure: unknown status'
          deferred.reject(err)
      }
      return result
    })
    .fail(function (err) {
      deferred.reject(err)
      throw err
    })
}

proto.updateSecret = function (
  this: ClientTeacherInstance,
  newSecret: string
): void {
  this.applyDelta({ secret: newSecret })
}

proto.missingFields = function (
  this: ClientTeacherInstance
): undefined | FieldsNeededForUpdate {
  const needUpdate: Partial<FieldsNeededForUpdate> = {}
  if (this.isTeacher && !this.addressedAs) {
    needUpdate.addressedAs = true
  }
  return _.size(needUpdate) > 0 ? needUpdate : undefined
}

proto.classReportUrl = function (
  this: ClientTeacherInstance,
  cCode: AccountCode,
  isApp: boolean
): string {
  // LATER: Handle URLs at controller level. Use getClassrooms so we've got names, then return just the appropriate c/sCode.
  let rval
  if (this.isTeacher) {
    if (cCode) {
      rval = `/teacher/class_report/${cCode}`
    } else {
      const code = this.defaultClassroomCode()
      if (code) {
        rval = `/teacher/class_report/${code}`
      } else {
        rval = '/teacher/add_class'
      }
    }
  } else {
    const constructor = this.constructor as ClientTeacherClass
    const cachedStudents = ClientStudent.getCachedItems(
      this.studentCodes(),
      constructor.getCache('students') as Cache<ClientStudentInstance>
    )
    let sCode
    if (_.size(cachedStudents) > 0) {
      // assume that we've got enough info to choose the first student by name
      sCode = ClientStudent.sort(_.values(cachedStudents))[0].code
    } else {
      // if nothing is cached just pick from code rather than waiting for a full round-trip
      sCode = firstKeyOf(this.studentCodes())
    }
    const showList = isApp && _.size(this.studentCodes()) > 1
    if (sCode && showList) {
      rval = '/teacher/class_report/my_kids'
    } // app only
    else if (sCode) {
      rval = `/teacher/student_report/my_kids/${sCode}`
    } // has one child
    else {
      rval = '/teacher/add_child'
    } // no children
  }
  return rval
}

proto.defaultClassroomCode = function (
  this: ClientTeacherInstance
): AccountCode | undefined {
  let rval
  const availableCodes = this.classroomCodes()
  const constructor = this.constructor as ClientTeacherClass
  let cached = ClientClassroom.getCachedItems(
    availableCodes,
    constructor.getCache('classrooms') as Cache<ClientClassroomInstance>
  )
  const sizeBeforePruning = _.size(cached)
  cached = ClientClassroom.omitHidden(cached)
  if (_.size(cached) > 0) {
    // assume that we've got enough info to choose the first classroom by name
    rval = ClientClassroom.sort(_.values(cached))[0].code
  } else if (sizeBeforePruning === 0) {
    // If cache size before hiding expired/hidden classrooms was *also* 0, the cache was empty and we should show *something*, so just pick from code rather than waiting for a full round-trip
    // Otherwise, assume the classrooms in cache were hidden and don't show anything
    rval = firstKeyOf(availableCodes)
  }
  return rval
}

// INSTANCE METHODS

proto.applyResult = function (
  this: ClientTeacherInstance,
  result: PlainObject
): void {
  if (result.teacher) {
    this.transform(result.teacher)
  } else {
    this.applyDelta(result.teacherDelta)
  }
}

proto.identityArgs = function (this: ClientTeacherInstance): {
  tCode: AccountCode
  tSecret: AccountSecret
  tVersion: AccountVersion
} {
  return {
    tCode: this.code,
    tSecret: this.getSecret(),
    tVersion: this.version,
  }
}

// HELPER FUNCTIONS

function applyResultToStudents(
  students: ClientStudentCollection,
  result: PlainObject
): void {
  _.forEach(result.studentDeltas, function (delta, code) {
    students[code].applyDelta(delta)
  })
  _.forEach(result.students, function (data, code) {
    const recreated = ClientStudent.create(data, ClientStudent.caches.students)
    _.assign(students[code], recreated)
  })
}

function firstKeyOf(object: PlainObject): string | undefined {
  for (const i in object) {
    if (object.hasOwnProperty(i) && typeof i !== 'function') {
      return i
    }
  }
  return undefined
}

function getCachedItems(itemMap, cache) {
  return _.reduce(
    itemMap,
    function (memo, _entry, code) {
      const item = cache.find(code)
      if (item) {
        memo[code] = item
      }
      return memo
    },
    {}
  )
}
