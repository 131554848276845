// NOTE: a few unit tests are available at ~/XtraMath3/test/shared/xm-date

// REQUIREMENTS

import { Timestamp, TimezoneOffset } from '../common'

const logger = console.log

// TYPES

export type XmDate = number

// CONSTANTS

// BASE_DATE used to be 'Sat Aug 15 2009 08:00:00 GMT+0000 (UTC)'/'Sat Aug 15 2009 01:00:00 GMT-0700 (PDT)'
// Due to a previous error, it was inadvertantly pushed back to Sat Aug 15 2009 00:00:00 GMT+0000 (UTC)
// We are leaving this as-is because at this point correcting it would just cause xds to be off by 8 hours once again.

export const BASE_DATE = 1250294400000 // new Date(2009,8-1,15).getTime() in UTC time zone.
export const BASE_DATE_ARRAY = [2009, 8 - 1, 15]
const ONE_DAY = 1000 * 60 * 60 * 24
const ISO_8601_DATE_RE = /^\d{4}-?\d{2}-?\d{2}$/
const XD_RE = /^\d{1,4}$/ //  xds will be >4 digits in Dec 2036.  We expect to have deprecated them by then.

// EXPORTED FUNCTIONS

export function isIso8601Date(ymd: string): boolean {
  return ISO_8601_DATE_RE.test(ymd)
}

export function logInvalidXd(xd: XmDate): void {
  if (!XD_RE.test(xd.toString())) {
    const err = new Error(`Invalid xmDate: ${xd}`)
    logger(err)
  }
}

export function timestampFromXd(xd: XmDate, offset: TimezoneOffset): Timestamp {
  logInvalidXd(xd)
  return zeroTimestamp(offset) + xd * ONE_DAY
}

export function xdFromTimestamp(
  timestamp: Timestamp,
  offset: TimezoneOffset
): XmDate {
  return Math.floor((timestamp - zeroTimestamp(offset)) / ONE_DAY)
}

// PRIVATE FUNCTIONS

export function zeroTimestamp(offset: TimezoneOffset): Timestamp {
  return BASE_DATE - offset * (60 * 1000)
}
