/* eslint-disable no-case-declarations */
import React, { useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'

import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import q from 'q'

import { useStudentContext } from 'common/context/StudentContext'
import { studentSignout } from 'common/utils/signout'

import { config } from 'common/utils/types/config/config-shim'
import ActivitySettings from './activitySettings'

const SECONDS_UNTIL_LOGOUT = config.studentSession.idleLogoutDelay
const IDLE_TIMEOUT = config.studentSession.idleTimeout

const useStudentTask = (type) => {
  const { studentSession } = useStudentContext()

  const elapsedTicks = useRef(0)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [countdownTimer, setCountdownTimer] = useState(0)
  const _betweenQuestions = useRef(false)
  const idleLogoutInterval = useRef(null)
  const _secondsUntilLogout = useRef(SECONDS_UNTIL_LOGOUT)
  const alreadyReported = useRef(false)
  const _questionTimer = useRef(null)
  const _countdownTimer = useRef(null)
  const _delayTimer = useRef(null)
  const _idleTimer = useRef(null)
  const [wrongAnswer, setWrongAnswer] = useState(null)
  const [hasProblemTimeout, setHasProblemTimeout] = useState(false)
  const _studentAnswer = useRef('')
  const [studentAnswer, setStudentAnswer] = useState('')
  const [secondsUntilLogout, setSecondsUntilLogout] = useState(null)
  const [video, setVideo] = useState('')
  const [streak, setStreak] = useState(0)

  const [answered, setAnswered] = useState<'quick' | 'correct' | null>(null)

  const _oneTick = useRef(0)
  const _tickLength = useRef(0)
  const [ticks, setTicks] = useState(0)

  const navigate = useNavigate()

  const [flippedKeypad, setFlippedKeypad] = useState(false)
  const [keypadShown, setKeypadShown] = useState(true)
  const [timerShown, setTimerShown] = useState(false)

  const [hasFinished, setHasFinished] = useState(false)

  const save = () => {
    const { activity } = studentSession.currentState()
    try {
      const state = {
        mode: activity.mode(),
        elapsedTicks: elapsedTicks.current,
        userAnswer: _studentAnswer.current,
        wrongAnswer,
        pauseTime: Date.now(),
      }
      studentSession.storePractice(state)
    } catch (err: any) {
      if (!alreadyReported.current) {
        alreadyReported.current = true
        const info = {
          stack: err.stack,
          errorMessage: err.message,
          pageUrl: window.location.href,
        }
        console.log(info, 'Error occurred trying to save practice state.')
      }
    }
  }

  const fillProblem = () => {
    const question = studentSession.currentState().activity.currentQuestion()
    setCurrentQuestion(question)
    _betweenQuestions.current = false
  }

  const incrementTimer = () => {
    elapsedTicks.current += 1
    setTicks(elapsedTicks.current)
  }

  const decrementTimer = () => {
    elapsedTicks.current -= 1
    setTicks(elapsedTicks.current)
  }

  const countIn = () => {
    if (elapsedTicks.current > 1) {
      decrementTimer()
      _countdownTimer.current = window.setTimeout(function () {
        countIn()
      }, 1000)
    } else {
      decrementTimer()
      stopIdling()
      const { activity } = studentSession.currentState()
      activity.mode('complete')
      nextQuestion()
    }
  }

  const onTimerTick = (ms: number) => {
    const { activity } = studentSession.currentState()
    if (activity.mode() === 'count-in') {
      if (elapsedTicks.current > 1) {
        decrementTimer()
        continueCountdown(1000)
      } else {
        decrementTimer()
        _countdownTimer.current = window.setTimeout(function () {
          nextQuestion()
        }, 300)
      }
    } else if (elapsedTicks.current < 10) {
      incrementTimer()
      continueCountdown(Math.ceil(ms))
    }

    if (elapsedTicks.current >= 10) {
      onProblemTimeout()
    }
  }

  const stopCountdown = () => {
    clearTimeout(_questionTimer.current)
    _questionTimer.current = null
    clearTimeout(_countdownTimer.current)
    _countdownTimer.current = null
  }

  const startCountdown = () => {
    if (type === 'practice') {
      setCounter(3)
      _countdownTimer.current = window.setTimeout(function () {
        _countdownTimer.current = null
        countIn()
      }, 1000)
    } else {
      setCounter(0)
      continueCountdown(_tickLength.current)
    }
  }

  const finished = () => {
    setHasFinished(true)
    q(studentSession.screenFinished(type))
      .then(function () {
        const { screen } = studentSession.currentState()
        if (type === 'quiz') studentSession.clearQuiz()
        else studentSession.clearPractice()
        navigate(`/app/student/${screen}}`)
        // window.location.hash = `/student/${screen}${
        //   studentSession.student.isDone() ? '?done=true' : ''
        // }`
      })
      .catch(function (err) {
        console.log(err)
        // if (err.reason === 'canceled') {
        // Screen.navigateTo('/home/index')
        // return
        // }
        // errorH.handleError(err)
      })
  }

  const nextQuestion = () => {
    const { activity } = studentSession.currentState()
    _betweenQuestions.current = true
    _studentAnswer.current = ''
    setStudentAnswer('')
    setWrongAnswer(null)
    setHasProblemTimeout(false)
    activity.mode('incomplete')
    activity.nextQuestion(activity.currentQuestion())
    if (activity.currentQuestion()) {
      activity.questionStarted()
      fillProblem()
      if (type === 'practice') {
        resumeCountdown(activity.threshold)
      } else startCountdown() // check for quiz
      save()
    } else {
      finished()
    }
  }

  const stopIdling = () => {
    if (_idleTimer.current) {
      window.clearTimeout(_idleTimer.current)
      _idleTimer.current = null
    }
    // console.log('stop idling')
    const { activity } = studentSession.currentState()
    activity.questionActive(activity.answers.length)
    window.clearInterval(idleLogoutInterval.current)
    _secondsUntilLogout.current = SECONDS_UNTIL_LOGOUT
    setSecondsUntilLogout(null)
  }

  const signout = (inactive?: boolean) => {
    stopIdling()
    studentSignout(studentSession, inactive)
  }

  const logoutTick = () => {
    const { activity } = studentSession.currentState()
    if (_secondsUntilLogout.current > 0) {
      _secondsUntilLogout.current -= 1
      setSecondsUntilLogout(_secondsUntilLogout.current)
    } else {
      console.log('logging out due to inactivity.')
      activity.submitAnswer(activity.currentQuestion())
      q(studentSession.screenFinished(type)).then(function () {
        if (type === 'quiz') studentSession.clearQuiz()
        else studentSession.clearPractice()
        signout(true)
      })
    }
  }

  const startLogoutCountdown = () => {
    setSecondsUntilLogout(_secondsUntilLogout.current)
    Sentry.addBreadcrumb({
      category: 'activity',
      message: `Show Signout modal because of inactivity`,
      level: 'info',
    })

    window.clearInterval(idleLogoutInterval.current) // there *shouldn't* be an interval running at this point, but better safe than sorry.
    logoutTick()
    idleLogoutInterval.current = window.setInterval(() => {
      logoutTick()
    }, 1000)
  }

  const dontSignoutContinueClicked = () => {
    Sentry.addBreadcrumb({
      category: 'activity',
      message: `Clicked on Continue in Signout modal`,
      level: 'info',
    })
    stopIdling()
    startIdling()
  }

  const startIdling = () => {
    const { activity } = studentSession.currentState()
    if (!activity.isIdle()) {
      activity.questionIdle()
    }

    _idleTimer.current = window.setTimeout(() => {
      startLogoutCountdown()
    }, IDLE_TIMEOUT)
  }

  const showRightAnswer = (length: number) => {
    let rightAnswer: string
    const { activity } = studentSession.currentState()
    stopIdling()
    setVideo(null)
    startIdling()
    if (length) {
      rightAnswer = activity.currentQuestion()?.c.toString().substr(length)
    } else {
      rightAnswer = activity.currentQuestion()?.c.toString()
    }
    if (_studentAnswer.current !== rightAnswer)
      setWrongAnswer(_studentAnswer.current)
  }

  const onProblemTimeout = () => {
    const { activity } = studentSession.currentState()
    if (type === 'practice') {
      setStreak(0)
    }
    console.log('problem timed out')
    showRightAnswer(_studentAnswer.current.length)
    setHasProblemTimeout(true)
    activity.questionTimedOut()
    activity.mode('timeout')
    save()
  }

  const resumeCountdown = (ms) => {
    _questionTimer.current = window.setTimeout(function () {
      clearTimeout(_questionTimer.current)
      _questionTimer.current = null
      onProblemTimeout()
    }, ms)
  }

  const continueCountdown = (ms) => {
    _questionTimer.current = window.setTimeout(function () {
      window.clearTimeout(_questionTimer.current)
      _questionTimer.current = null
      onTimerTick(ms)
    }, ms)
  }

  const resumeOrRestartCountdown = (state) => {
    const { activity } = studentSession.currentState()
    const resumeTime = Date.now()
    const pausedTicks = Math.round(
      (resumeTime - state.pauseTime) / (activity.threshold / 3)
    )
    const _elapsedTicks = Math.min(state.elapsedTicks + pausedTicks, 10)
    setCounter(_elapsedTicks)
    if (_tickLength.current && elapsedTicks.current < 10) {
      continueCountdown(Math.ceil(_tickLength.current))
    } else {
      activity.mode('timeout')
      onProblemTimeout()
    }
  }

  const start = () => {
    setHasFinished(false)
    setAnswered(null)
    const state =
      type === 'practice'
        ? studentSession.retrievePractice()
        : studentSession.retrieveQuiz()
    const { activity } = studentSession.currentState()
    console.log('start with state: ', state)
    setTimerShown(!studentSession.student.hideTimer)
    if (type === 'practice') setStreak(0)
    if (state) {
      try {
        console.log(
          'Screen is restarting',
          activity,
          activity.currentQuestion()
        )
        activity.mode(state.mode)
        if (activity.currentQuestion()) {
          setWrongAnswer(state.wrongAnswer)
          setStudentAnswer(state.userAnswer.length ? state.userAnswer : '')
          fillProblem()
        }
        // eslint-disable-next-line default-case
        switch (activity.mode()) {
          case 'complete':
            nextQuestion()
            break
          case 'incomplete':
            if (activity.currentQuestion()) {
              if (type === 'practice') {
                const now = Date.now()
                const elapsed = now - activity.currentQuestion().startedAt
                if (elapsed < activity.threshold) {
                  resumeCountdown(activity.threshold - elapsed)
                } else {
                  onProblemTimeout()
                }
              } else {
                resumeOrRestartCountdown(state)
              }
            } else if (type === 'practice') startCountdown()
            else nextQuestion()
            break
          case 'timeout':
            if (activity.currentQuestion()) {
              if (type === 'quiz') setCounter(10)
              setHasProblemTimeout(true)
              showRightAnswer(state.userAnswer && state.userAnswer.length)
            } else if (type === 'practice') startCountdown()
            else nextQuestion()
            break
          case 'wrong':
            if (activity.currentQuestion()) {
              if (type === 'quiz') {
                const _elapsedTicks = Math.floor(
                  activity.currentQuestion().elapsed / (activity.threshold / 3)
                )
                setCounter(_elapsedTicks)
              }
              showRightAnswer(state.userAnswer && state.userAnswer.length)
            } else if (type === 'practice') startCountdown()
            else nextQuestion()
            // setWrongAnswer(true)
            break
        }
      } catch (err) {
        // console.error('Error loading practice state')
        nextQuestion()
      }
    } else if (type === 'practice') startCountdown()
    else {
      setCounter(3)
      activity.mode('count-in')
      continueCountdown(1000)
    }
  }

  const setCounter = (et) => {
    console.log('set counter called', et)
    elapsedTicks.current = et
    setCountdownTimer(et)
    setTicks(elapsedTicks.current)
  }

  const problemFinished = (timeout) => {
    stopIdling()
    const { activity } = studentSession.currentState()
    activity.mode('complete')
    _delayTimer.current = window.setTimeout(function () {
      nextQuestion()
      setAnswered(null)
      clearTimeout(_delayTimer.current)
      _delayTimer.current = null
    }, timeout)
  }

  const answeredRight = () => {
    const { activity } = studentSession.currentState()
    // console.log('answered right')
    if (activity.mode() === 'incomplete') {
      const elapsed = activity.questionAnswered()
      stopCountdown()
      if (type === 'practice') {
        setStreak(studentSession.currentPractice().tally + 1)
      }
      if (elapsed < activity.threshold) {
        // console.log('quick answer')
        setAnswered('quick')
      } else {
        setAnswered('correct')
        // console.log('correct answer')
      }
    }
    problemFinished(500) // sets a time out
  }

  const answeredWrong = (_wrongAnswer: string, partialAnswer) => {
    const { activity } = studentSession.currentState()
    // console.log('answered wrong')
    stopCountdown()
    if (activity.mode() === 'incomplete') {
      activity.questionAnswered(_wrongAnswer)
      activity.mode('wrong')
    }
    if (type === 'practice') {
      setStreak(0)
    }
    setWrongAnswer(_wrongAnswer)
    showRightAnswer(partialAnswer.length)
    _studentAnswer.current = partialAnswer
    save()
  }

  const digitEntered = (d) => {
    const { activity } = studentSession.currentState()
    stopIdling()
    console.log('digit entered', d)

    if (
      activity.mode() === 'complete' ||
      !activity.currentQuestion() ||
      _betweenQuestions.current ||
      hasFinished
    ) {
      return
    }

    const correctAnswer = activity.currentQuestion()?.c.toString()

    _studentAnswer.current += d.toString()

    if (activity.mode() === 'incomplete') {
      const elapsed = Date.now() - activity.currentQuestion().startedAt
      if (type === 'quiz') {
        setCounter(Math.floor(elapsed / _oneTick.current))
        if (elapsedTicks.current > 10) {
          stopCountdown()
          onProblemTimeout()
        }
      } else {
        // if practice
        // eslint-disable-next-line no-lonely-if
        if (elapsed > activity.threshold) {
          stopCountdown()
          onProblemTimeout()
        }
      }
    }
    if (_studentAnswer.current.toString() === correctAnswer) {
      answeredRight()
    } else if (
      _studentAnswer.current.length >= correctAnswer.length ||
      _studentAnswer.current !==
        correctAnswer.substr(0, _studentAnswer.current.length)
    ) {
      // console.log('answered wrong')
      answeredWrong(_studentAnswer.current, '')
    } else {
      // console.log('answered ok so far')
      // eslint-disable-next-line no-lonely-if
      if (remainingAnswer.length > 0) startIdling()
    }
    setStudentAnswer(_studentAnswer.current)
  }

  const remainingAnswer =
    (wrongAnswer || hasProblemTimeout) &&
    currentQuestion?.c.toString().substr(_studentAnswer?.current.length)

  const handleKeyPress = ({ key }) => {
    try {
      const num = parseInt(key, 10)
      if (Number.isNaN(num)) return
    } catch {
      return
    }
    digitEntered(key)
  }

  const debouncedStart = _.debounce(start, 100)

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    if (type === 'quiz') {
      _oneTick.current = activity.threshold / 3
      _tickLength.current = activity.tickLength
    }

    debouncedStart()

    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
      // clean up intervals and timeouts
      clearInterval(idleLogoutInterval.current)
      clearTimeout(_idleTimer.current)
      clearTimeout(_questionTimer.current)
      clearTimeout(_countdownTimer.current)
      clearTimeout(_delayTimer.current)
    }
  }, [studentSession])

  const activitySettings = () => (
    <ActivitySettings
      onFlipKeypad={() => setFlippedKeypad(!flippedKeypad)}
      onToggleTimer={() => setTimerShown(!timerShown)}
      onToggleKeypad={() => setKeypadShown(!keypadShown)}
      flipped={flippedKeypad}
      keypadShown={keypadShown}
      timerShown={timerShown}
    />
  )

  return {
    ActivitySettings: activitySettings,
    countdownTimer,
    streak,
    secondsUntilLogout,
    flippedKeypad,
    video,
    tickLength: _tickLength.current,
    timerShown,
    keypadShown,
    wrongAnswer,
    currentQuestion,
    studentAnswer,
    remainingAnswer,
    dontSignoutContinueClicked,
    digitEntered,
    hasProblemTimeout,
    studentSession,
    hasFinished,
    ticks,
    answeredRight: answered,
  }
}

export default useStudentTask
