// REQUIREMENTS

import _ from 'lodash'

import { Spec, DrawingNode } from './specify-graph'
import { PlainObject } from '../types/common'

// TYPES

export type SvgString = string

export interface SvgBuilder {
  matrixDefs(): string
  close(): SvgString
  header(
    canvasX: number,
    canvasY: number,
    width: number,
    height: number,
    dynamic?: boolean,
    matrix?: boolean
  ): SvgString
  generateSvgPaths(
    points: number[],
    width: number,
    height: number,
    daysInGraph: number
  ): SvgString
  render(spec: Spec): SvgString
}

// EXPORTED INTERFACE

export const svgBuilder: SvgBuilder = {
  close,
  generateSvgPaths,
  header,
  matrixDefs,
  render,
}

// BUILDER FUNCTIONS

function close(): SvgString {
  return '</svg>'
}

function matrixDefs() {
  return (
    '<style>' +
    '.a-smiley{stroke-miterlimit:10;fill:url(#a-smiley);stroke:url(#b-smiley);}.b-smiley{fill:url(#c-smiley);}.d-smiley{opacity:0.5;}' +
    '.a-redx{fill:url(#a-redx);}.b-redx{fill:url(#b-redx);}.d-redx{opacity:0.5;}' +
    '.a-checkmark{fill:url(#a-checkmark);}.b-checkmark{fill:url(#b-checkmark);}.d-checkmark{opacity:0.5;}' +
    '.a-hourglass{fill:url(#a-hourglass);}.b-hourglass{fill:url(#b-hourglass);}.c-hourglass{fill:url(#c-hourglass);}.e-hourglass{opacity:0.5;}' +
    '</style>' +
    // smiley
    '<linearGradient id="a-smiley" x1="50" y1="0.5" x2="50" y2="99.5" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#feea72"/>' +
    '<stop offset="0.37" stop-color="#f8db5a"/>' +
    '<stop offset="1" stop-color="#edbd2a"/>' +
    '</linearGradient>' +
    '<linearGradient id="b-smiley" y1="50" x2="100" y2="50" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#f2cf46"/>' +
    '<stop offset="0.78" stop-color="#e1b730"/>' +
    '<stop offset="0.99" stop-color="#dbb029"/>' +
    '</linearGradient>' +
    '<radialGradient id="c-smiley" cx="50.26" cy="93.21" r="92.91" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0.81" stop-color="#fff" stop-opacity="0"/>' +
    '<stop offset="0.82" stop-color="#fff" stop-opacity="0.03"/>' +
    '<stop offset="0.84" stop-color="#fff" stop-opacity="0.1"/>' +
    '<stop offset="0.85" stop-color="#fff" stop-opacity="0.23"/>' +
    '<stop offset="0.88" stop-color="#fff" stop-opacity="0.41"/>' +
    '<stop offset="0.9" stop-color="#fff" stop-opacity="0.63"/>' +
    '<stop offset="0.92" stop-color="#fff" stop-opacity="0.9"/>' +
    '<stop offset="0.93" stop-color="#fff"/>' +
    '<stop offset="0.94" stop-color="#fff" stop-opacity="0.98"/>' +
    '<stop offset="0.95" stop-color="#fff" stop-opacity="0.93"/>' +
    '<stop offset="0.96" stop-color="#fff" stop-opacity="0.85"/>' +
    '<stop offset="0.97" stop-color="#fff" stop-opacity="0.74"/>' +
    '<stop offset="0.98" stop-color="#fff" stop-opacity="0.59"/>' +
    '<stop offset="0.99" stop-color="#fff" stop-opacity="0.41"/>' +
    '<stop offset="1" stop-color="#fff" stop-opacity="0.2"/>' +
    '<stop offset="1" stop-color="#fff" stop-opacity="0.1"/>' +
    '</radialGradient>' +
    '<g id="smiley" viewBox="0 0 100 100">' +
    '<circle class="d-smiley" cx="51.94" cy="52.83" r="47.17"/>' +
    '<circle class="a-smiley" transform="translate(2.89) scale(0.94 0.94)" cx="50" cy="50" r="49.5"/>' +
    '<path transform="translate(2.89) scale(0.94 0.94)" d="M39.84,41.95c2.07,0,3.75-3.06,3.75-6.83s-1.68-6.83-3.75-6.83-3.74,3.06-3.74,6.83S37.77,41.95,39.84,41.95Zm18.28,0c2.07,0,3.75-3.06,3.75-6.83s-1.68-6.83-3.75-6.83-3.74,3.06-3.74,6.83S56.05,41.95,58.12,41.95ZM82.87,52.29c-1.55-1.26-1.61-2.91-2.12-4.64-.38,2.32-.61,4.59.92,6.09C76,60.84,63.74,69.13,49.49,69.13S23,60.83,17.32,53.76c1.54-1.5,1.31-3.79.93-6.11-.5,1.73-.56,3.38-2.12,4.64-2,1.6-4.36,1.29-6.59,1.08,2,1.09,4.31,1.92,6.21,1.33C20.5,62,33.81,74.48,49.49,74.48s29-12.53,33.74-19.78c1.9.6,4.2-.23,6.23-1.33C87.23,53.58,84.84,53.89,82.87,52.29Z"/>' +
    '<circle class="b-smiley" transform="translate(2.89) scale(0.94 0.94)" cx="50" cy="50" r="49"/>' +
    '</g>' +
    // red-x
    '<linearGradient id="a-redx" x1="44.34" y1="18.85" x2="43.44" y2="95.86" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#f9ac97"/>' +
    '<stop offset="0.71" stop-color="#d6522e"/>' +
    '<stop offset="0.95" stop-color="#ca330a"/>' +
    '</linearGradient>' +
    '<linearGradient id="b-redx" x1="45.34" y1="0.44" x2="42.64" y2="98.34" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#cb4724"/>' +
    '<stop offset="0.24" stop-color="#bd3c19"/>' +
    '<stop offset="0.66" stop-color="#aa2d0a"/>' +
    '<stop offset="0.95" stop-color="#a32705"/>' +
    '</linearGradient>' +
    '<g id="redx" viewBox="0 0 100 100">' +
    '<path class="d-redx" d="M90.64,79.46,68.08,52.71,90.57,26A12.76,12.76,0,0,0,89,8.45,12.35,12.35,0,0,0,71.79,10L51.95,33.56,32.13,10.06A12.33,12.33,0,0,0,14.89,8.45a12.77,12.77,0,0,0-1.53,17.61L35.82,52.71,13.23,79.5a12.77,12.77,0,0,0,1.56,17.58A12.38,12.38,0,0,0,32,95.51L51.95,71.86l19.9,23.61a12,12,0,0,0,17.26,1.6A12.77,12.77,0,0,0,90.64,79.46Z"/>' +
    '<path class="a-redx" transform="translate(8.62) scale(0.94)" d="M43.93,63.57a2.6,2.6,0,0,1,2,.92L69,91.91A7.73,7.73,0,0,0,79.88,93a8.34,8.34,0,0,0,1-11.47l-25.23-30a2.57,2.57,0,0,1,0-3.32L80.81,18.3a8.32,8.32,0,0,0-1-11.42,7.72,7.72,0,0,0-10.86,1l-23,27.34a2.68,2.68,0,0,1-4,0L19,7.94A7.67,7.67,0,0,0,8.08,6.88a8.33,8.33,0,0,0-1,11.47L32.21,48.21a2.57,2.57,0,0,1,0,3.32l-25.27,30A8.34,8.34,0,0,0,8,93a7.76,7.76,0,0,0,10.87-1l23.1-27.46a2.6,2.6,0,0,1,2-.92Z"/>' +
    '<path class="b-redx" transform="translate(8.62) scale(0.94)" d="M74.94,100a12.8,12.8,0,0,1-10-4.8l-21.05-25L22.84,95.24A13.08,13.08,0,0,1,4.61,96.9,13.55,13.55,0,0,1,3,78.27l23.9-28.4L3.09,21.63A13.55,13.55,0,0,1,4.71,3,13,13,0,0,1,23,4.66l21,24.91,21-25A13,13,0,0,1,83.14,3a13.54,13.54,0,0,1,1.65,18.63L61,49.87,84.86,78.23A13.56,13.56,0,0,1,83.24,96.9a12.74,12.74,0,0,1-8.3,3.1Zm-31-36.43a2.6,2.6,0,0,1,2,.92L69,91.91A7.73,7.73,0,0,0,79.88,93a8.34,8.34,0,0,0,1-11.47l-25.23-30a2.57,2.57,0,0,1,0-3.32L80.81,18.3a8.32,8.32,0,0,0-1-11.42,7.72,7.72,0,0,0-10.86,1l-23,27.34a2.68,2.68,0,0,1-4,0L19,7.94A7.67,7.67,0,0,0,8.08,6.88a8.33,8.33,0,0,0-1,11.47L32.21,48.21a2.57,2.57,0,0,1,0,3.32l-25.27,30A8.34,8.34,0,0,0,8,93a7.76,7.76,0,0,0,10.87-1l23.1-27.46a2.6,2.6,0,0,1,2-.92Z"/>' +
    '</g>' +
    // checkmark
    '<linearGradient id="a-checkmark" x1="58.32" y1="-5.47" x2="37.46" y2="82.49" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#8abf47"/>' +
    '<stop offset="1" stop-color="#4f742e"/>' +
    '</linearGradient>' +
    '<linearGradient id="b-checkmark" x1="56.63" y1="0.68" x2="38.09" y2="81.58" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0" stop-color="#b7f088"/>' +
    '<stop offset="0.2" stop-color="#afe77f"/>' +
    '<stop offset="0.49" stop-color="#9dd46a"/>' +
    '<stop offset="0.83" stop-color="#82b74b"/>' +
    '<stop offset="1" stop-color="#74a73a"/>' +
    '</linearGradient>' +
    '<g id="checkmark" viewBox="0 0 100 100">' +
    '<path class="d-checkmark" transform="translate(0 -5.09)" d="M96.92,14.46a10.49,10.49,0,0,0-15.62,0L35.73,65.74,20.66,48.79A10.48,10.48,0,0,0,5,48.79,12.93,12.93,0,0,0,5,65.55L27.92,91.29a10.25,10.25,0,0,0,15.62,0L96.92,31.22A12.94,12.94,0,0,0,96.92,14.46Z"/>' +
    '<path class="a-checkmark" transform="scale(0.98)" d="M34.45,85.61a10.55,10.55,0,0,1-8-3.69L3.14,55.67a13.19,13.19,0,0,1,0-17.09,10.69,10.69,0,0,1,15.94,0L34.45,55.87,80.92,3.56a10.7,10.7,0,0,1,15.94,0,13.2,13.2,0,0,1,0,17.09L42.41,81.93a10.55,10.55,0,0,1-8,3.68Zm-23.34-46a5.86,5.86,0,0,0-4.4,2.08,8.31,8.31,0,0,0,0,10.76L30,78.77a5.82,5.82,0,0,0,8.81,0L93.3,17.49a8.31,8.31,0,0,0,0-10.77,5.82,5.82,0,0,0-8.81,0L36.22,61a2.47,2.47,0,0,1-3.56,0L15.52,41.74a5.88,5.88,0,0,0-4.41-2.08Z"/>' +
    '<path class="b-checkmark" transform="scale(0.98)" d="M11.11,39.66a5.86,5.86,0,0,0-4.4,2.08,8.31,8.31,0,0,0,0,10.76L30,78.77a5.82,5.82,0,0,0,8.81,0L93.3,17.49a8.31,8.31,0,0,0,0-10.77,5.82,5.82,0,0,0-8.81,0L36.22,61a2.47,2.47,0,0,1-3.56,0L15.52,41.74a5.88,5.88,0,0,0-4.41-2.08Z"/>' +
    '</g>' +
    // hourglass
    '<linearGradient id="a-hourglass" x1="31.06" y1="3.99" x2="30.73" y2="52.09" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0.1" stop-color="#8ac8fd"/>' +
    '<stop offset="1" stop-color="#377abf"/>' +
    '</linearGradient>' +
    '<linearGradient id="b-hourglass" x1="31.36" y1="50.78" x2="30.7" y2="96.25" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0.1" stop-color="#81a7cf"/>' +
    '<stop offset="1" stop-color="#1c5896"/>' +
    '</linearGradient>' +
    '<linearGradient id="c-hourglass" x1="31.12" y1="-7.81" x2="30.44" y2="97.93" gradientUnits="userSpaceOnUse">' +
    '<stop offset="0.02" stop-color="#97c3f1"/>' +
    '<stop offset="1" stop-color="#1e5997"/>' +
    '</linearGradient>' +
    '<g id="hourglass" viewBox="0 0 100 100">' +
    '<path class="e-hourglass" d="M80.71,14c0-2.11-.1-4.16-.25-6.18a2.33,2.33,0,0,0-2.27-2.19H25.69a2.33,2.33,0,0,0-2.27,2.19c-.16,2-.26,4.07-.26,6.18,0,17.67,13.73,31.37,20.16,38.8-6.43,7.42-20.16,21.11-20.16,38.8,0,2.1.1,4.16.26,6.18A2.33,2.33,0,0,0,25.69,100H78.18a2.32,2.32,0,0,0,2.27-2.19c.16-2,.26-4.07.26-6.18,0-17.68-13.73-31.38-20.16-38.8C67,45.41,80.71,31.7,80.71,14Z"/>' +
    '<path transform="translate(21.04) scale(0.94)" class="a-hourglass" d="M3.92,96.77H57.58c.07-1.3.11-2.62.11-4,0-19.21-13.37-33.92-19.94-41a2.71,2.71,0,0,1,0-3.66c6.57-7.06,19.94-21.77,19.94-41,0-1.34,0-2.66-.1-4H3.92c-.07,1.3-.11,2.62-.11,4,0,19.2,13.07,33.91,19.64,41a2.71,2.71,0,0,1,0,3.66c-6.57,7-19.64,21.75-19.64,41q0,2,.11,4Z"/>' +
    '<path transform="translate(21.04) scale(0.94)" class="b-hourglass" d="M3.92,96.77H57.58c.07-1.3.11-2.62.11-4,0-19.21-14.88-33.92-21.44-41-1-1-10-1-11,0-6.57,7-21.44,21.75-21.44,41q0,2,.11,4Z"/>' +
    '<path transform="translate(21.04) scale(0.94)" class="c-hourglass" d="M58.8,100H2.7A2.48,2.48,0,0,1,.27,97.68C.11,95.54,0,93.36,0,91.12,0,72.38,14.67,57.86,21.55,50,14.67,42.13,0,27.6,0,8.87,0,6.64.11,4.46.27,2.32A2.48,2.48,0,0,1,2.7,0H58.8a2.48,2.48,0,0,1,2.43,2.33c.16,2.14.27,4.31.27,6.55C61.5,27.6,46.82,42.13,40,50,46.82,57.87,61.5,72.39,61.5,91.13c0,2.23-.11,4.41-.28,6.55A2.47,2.47,0,0,1,58.8,100ZM5,94.94H56.53c.07-1.25.1-2.52.1-3.81,0-18.46-15.45-32.59-21.76-39.36a2.6,2.6,0,0,1,0-3.52c6.31-6.79,21.76-20.92,21.76-39.37,0-1.29,0-2.56-.1-3.81H5c-.07,1.25-.1,2.52-.1,3.81,0,18.44,15.45,32.58,21.76,39.37a2.6,2.6,0,0,1,0,3.52C20.31,58.54,4.86,72.66,4.86,91.12q0,1.93.1,3.81Z"/>' +
    '</g>'
  )
}

function header(
  canvasX: number,
  canvasY: number,
  width: number,
  height: number,
  dynamic: boolean,
  matrix?: boolean
): SvgString {
  let str = `<svg viewBox="0 0 ${canvasX} ${canvasY}`
  if (dynamic) {
    str += `" width="${width}" height="${height}`
  }
  if (matrix) {
    str += '" preserveAspectRatio="xMidYMid'
  }
  str +=
    '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">'
  return str
}

function generateSvgPaths(
  points: number[],
  width: number,
  height: number,
  daysInGraph: number
): SvgString {
  const pathTemplate =
    '<path d="M {{pathData}}"  stroke="#458B00" stroke-linecap="round"' +
    ' stroke-linejoin="round" fill="transparent" fill-opacity="0.0"  stroke-width="12" ></path>'
  const paths: Array<Array<string>> = [[]]

  // reduce to sub arrays that correlate to a path element
  const xStep = (width - 12) / daysInGraph // stroke-width is 6
  let withinWeek = 0
  _.forEach(points, function (point, i: number) {
    const last = _.last(paths)
    if (!point) {
      withinWeek += 1 // doesn't start a new path unless a week gap;
      if (!_.isEmpty(last) && withinWeek >= 14) {
        // two weeks for a less segmented line
        paths.push([])
        withinWeek = 0
      }
    } else {
      const coords = [i * xStep + 6, height - point * (height / 100)].join(',')
      if (i % 2 === 0) {
        _.last(paths)?.push(coords, 'L')
      }
    }
  })
  let svgPaths = ''
  _.forEach(paths, function (lineData) {
    if (!_.isEmpty(lineData)) {
      if (lineData.length === 2) {
        lineData[1] = 'z'
      } else {
        lineData.pop()
      } // removes the last 'L'
      const d = lineData.join(' ')
      svgPaths += pathTemplate.replace('{{pathData}}', d)
    }
  })
  return svgPaths
}

function render(spec: Spec): SvgString {
  // LATER: create symbols here as well?
  return `<g>${createNodes(spec.draw, 0)}</g>`
}

// HELPER FUNCTIONS

function createNodes(array: DrawingNode[], zi: number): SvgString {
  // NOTE: we treat all z-indices <= 0 equivalently.
  const now: any = []
  const later: any = []
  let nextIndex: any
  _.forEach(array, function (info, _key) {
    if (info['z-index'] && info['z-index'] > zi) {
      nextIndex = nextIndex
        ? Math.min(nextIndex, info['z-index'])
        : info['z-index']
      later.push(info)
    } else {
      now.push(createNode(info.node, info.attrs, info.content))
    }
  })
  let str = now.join('')
  if (later.length > 0) {
    str += createNodes(later, nextIndex)
  }
  return str
}

function createNode(
  type: string,
  attrs: PlainObject,
  content: /* TYPESCRIPT: Html */ string | undefined
): SvgString {
  let str = `<${type} ${_.map(attrs, function (val, key) {
    return `${key}="${val}"`
  }).join(' ')}`
  if (content !== undefined) {
    str += `>${content}</${type}>`
  } else {
    str += ' />'
  }
  return str
}
