// REQUIREMENTS

import _ from 'lodash'

import { Locale, assert } from './common'

// TODO: empirically determine groups and weights for 12s facts.
import { statistics } from './operation-statistics'

import { FluencyThreshold } from './account-types'
import {
  BEGINNING_MULTIPLICATION,
  BEGINNING_DIVISION,
} from './operation-constants'

// TYPES

type OperationId = number
type FilterFunc = (i: ProblemIndex) => boolean
type ProblemIndex = number
type ProblemGroups = ProblemIndex[][]
type Weight = number
type Weights = Weight[]

// TYPESCRIPT: Maybe a unique type per bit-vector for type safety?
interface BitVector {
  shift: number
  mask: number
  [value: string]: number
}

export interface OperationAttributes {
  mathOperationId: number
  problemSetId: number
  thresholdId: number
}

// Describes terms.xml operations/summaries structure.
export interface OperationSummary {
  dayPlural: string
  daySingular: string
  invalid: string
  invalidOpHeader: string
  nameTemplate: string
  operationComplete: string
  operationIncomplete: string
  placementComplete: string
  placementIncomplete: string
  unknown: string
  unknownOpHeader: string
}

export interface Problem {
  i: ProblemIndex // identifier
  a: number // first operand
  b: number // second operand
  c: number // answer
  o: string // operator
  s: string // string representation
}

// CONSTANTS

// TODO: Redo these with TypeScript enums

// See constructor documentation for description of id bit fields.
const PROBLEMSET_IDS = bitfield(0, 2, ['beginning', 'regular', 'extended'])
const PROBLEMSET_MASK = PROBLEMSET_IDS.mask
const BEGINNING = PROBLEMSET_IDS.beginning
const REGULAR = PROBLEMSET_IDS.regular
const EXTENDED = PROBLEMSET_IDS.extended

const MATH_OPERATION_IDS = bitvector(2, 4, [
  'addition',
  'subtraction',
  'multiplication',
  'division',
])
const MATH_OPERATION_MASK = MATH_OPERATION_IDS.mask
const ADDITION = MATH_OPERATION_IDS.addition
const SUBTRACTION = MATH_OPERATION_IDS.subtraction
const MULTIPLICATION = MATH_OPERATION_IDS.multiplication
const DIVISION = MATH_OPERATION_IDS.division

const THRESHOLD_IDS = bitfield(6, 3, [
  'twelve',
  'six',
  'three',
  'two',
  'onePointFive',
])
const THRESHOLD_MASK = THRESHOLD_IDS.mask
const TWELVE_SEC = THRESHOLD_IDS.twelve
const SIX_SEC = THRESHOLD_IDS.six
const THREE_SEC = THRESHOLD_IDS.three
const TWO_SEC = THRESHOLD_IDS.two
const ONE_AND_HALF_SEC = THRESHOLD_IDS.onePointFive

// "Assessment only" applies to programs, not operations, but because Gen1 program and operation ids are
// are so closely related, we will put this here with the rest of the id-related declarations.
const ASSESSMENT_ONLY_IDS = bitvector(9, 1, ['assessmentOnly'])

const GENERATION_IDS = bitfield(10, 3, ['zero', 'one'])
const GENERATION_MASK = GENERATION_IDS.mask
const GEN0 = GENERATION_IDS.zero
const GEN1 = GENERATION_IDS.one

const UNUSED_GEN1_ID_BITS_MASK =
  ~PROBLEMSET_MASK & ~MATH_OPERATION_MASK & ~THRESHOLD_MASK & ~GENERATION_MASK

const GEN1_FROM_GEN0: any = {
  1: GEN1 + THREE_SEC + REGULAR + ADDITION,
  2: GEN1 + THREE_SEC + REGULAR + SUBTRACTION,
  3: GEN1 + THREE_SEC + REGULAR + MULTIPLICATION,
  4: GEN1 + THREE_SEC + REGULAR + DIVISION,
  5: GEN1 + TWO_SEC + REGULAR + ADDITION,
  6: GEN1 + TWO_SEC + REGULAR + SUBTRACTION,
  7: GEN1 + TWO_SEC + REGULAR + MULTIPLICATION,
  8: GEN1 + TWO_SEC + REGULAR + DIVISION,
  9: GEN1 + ONE_AND_HALF_SEC + REGULAR + ADDITION,
  10: GEN1 + ONE_AND_HALF_SEC + REGULAR + SUBTRACTION,
  11: GEN1 + ONE_AND_HALF_SEC + REGULAR + MULTIPLICATION,
  12: GEN1 + ONE_AND_HALF_SEC + REGULAR + DIVISION,
  13: GEN1 + SIX_SEC + REGULAR + ADDITION,
  14: GEN1 + SIX_SEC + REGULAR + SUBTRACTION,
  15: GEN1 + SIX_SEC + REGULAR + MULTIPLICATION,
  16: GEN1 + SIX_SEC + REGULAR + DIVISION,
  17: GEN1 + THREE_SEC + BEGINNING + ADDITION,
  18: GEN1 + THREE_SEC + BEGINNING + SUBTRACTION,
  19: GEN1 + THREE_SEC + BEGINNING + MULTIPLICATION,
  20: GEN1 + THREE_SEC + BEGINNING + DIVISION,
  21: GEN1 + SIX_SEC + BEGINNING + ADDITION,
  22: GEN1 + SIX_SEC + BEGINNING + SUBTRACTION,
  23: GEN1 + SIX_SEC + BEGINNING + MULTIPLICATION,
  24: GEN1 + SIX_SEC + BEGINNING + DIVISION,
}

const GEN0_FROM_GEN1 = _.mapValues(_.invert(GEN1_FROM_GEN0), function (value) {
  return parseInt(value, 10)
})

const FACT_GENERATOR: any = {}
FACT_GENERATOR[ADDITION] = additionGenerator
FACT_GENERATOR[SUBTRACTION] = subtractionGenerator
FACT_GENERATOR[MULTIPLICATION] = multiplicationGenerator
FACT_GENERATOR[DIVISION] = divisionGenerator

const FILTERS: any = {}
FILTERS[BEGINNING] = function (i: ProblemIndex): boolean {
  return i < 100 && Operation.rowFromIndex(i) + Operation.colFromIndex(i) <= 10
}
FILTERS[REGULAR] = function (i: ProblemIndex): boolean {
  return i < 100
}
FILTERS[EXTENDED] = function (i: ProblemIndex): boolean {
  return i < 169
}

const CUSTOM_FILTERS = {
  [BEGINNING]: {
    [MULTIPLICATION](i: ProblemIndex): boolean {
      return !!BEGINNING_MULTIPLICATION[i]
    },
    [DIVISION](i: ProblemIndex): boolean {
      return !!BEGINNING_DIVISION[i]
    },
  },
  [REGULAR]: {},
  [EXTENDED]: {},
}

const OPERATION_KEYS = _.invert(MATH_OPERATION_IDS)

const THRESHOLDS: any = {}
THRESHOLDS[TWELVE_SEC] = 12000
THRESHOLDS[SIX_SEC] = 6000
THRESHOLDS[THREE_SEC] = 3000
THRESHOLDS[TWO_SEC] = 2000
THRESHOLDS[ONE_AND_HALF_SEC] = 1500

// EXPORTED CLASS

/*

Fields:
  id (integer)
    Generation 0 Operation IDs: 1-22, except 19, 20. See GEN1_FROM_GEN0, below.
    Generation 1 Operation IDs: see below.
  groups (array of arrays) list of list of fact indexes, grouping facts into difficulty levels.
  threshold (number) milliseconds for a correct answer to be considered fluent. 6000, 3000, 2000, or 1500.
  generator (function) takes a problem index and returns a problem object. See below.
  mathOperationKey (string) a string 'addition', 'subtraction', 'multiplication', or 'division'.
    Used for localized string resource lookups.
  weights (array of number) indexed by fact index giving a scoring weight to the fact.
    All weights add up to 100. Excluded facts have a zero weight.

Problem object:
  i (number) fact indexes
  a (number) first operand
  b (number) second operand
  c (number) answer
  s (string) operand + operator + operand

Gen1 Operation IDs:
  Fields are arranged so a simple numeric comparison will tell you which is the more "advanced" operation.
  bits 0-1 problem set (0 - beginning, 1 - regular, 2 - extended)
  bits 2-5 operation (1 - addition, 2 - subtraction, 4 - multiplication, 8 - division)
  bits 6-8 threshold (0 - unused, 6s, 1 - 3s, 2 - 2s, 3 - 1.5s)
  bit 9 unused (used for assessment-only in gen1 *program* ids)
  bit 10-12 generation
  bit 13+ unused
*/
export class Operation {
  // CLASS CONSTANTS

  public static readonly PROBLEMSET_IDS = PROBLEMSET_IDS

  public static readonly MATH_OPERATION_IDS = MATH_OPERATION_IDS

  public static readonly THRESHOLD_IDS = THRESHOLD_IDS

  public static readonly ASSESSMENT_ONLY_IDS = ASSESSMENT_ONLY_IDS

  public static readonly GENERATION_IDS = GENERATION_IDS

  // CLASS METHODS

  public static idFromAttributes(attrs: OperationAttributes): OperationId {
    const id =
      GEN1 | attrs.mathOperationId | attrs.problemSetId | attrs.thresholdId
    return GEN0_FROM_GEN1[id] || id
  }

  public static fromId = _.memoize(function (id: OperationId): Operation {
    return new Operation(id)
  })

  public static isGen0Id(id: OperationId): boolean {
    return (id & GENERATION_MASK) === GEN0
  }

  public static isGen1Id(id: OperationId): boolean {
    return (id & GENERATION_MASK) === GEN1
  }

  public static isKnownId(id: OperationId): boolean {
    return id != null && (id & GENERATION_MASK) <= GEN1
  }

  // Notes:
  //   Do not call unless isKnownId() returns truthy.
  public static isValidId(id: OperationId): boolean {
    switch (id & GENERATION_MASK) {
      case GEN0:
        return this.isValidGen0Id(id)
      case GEN1:
        return this.isValidGen1Id(id)
      default:
        assert(false) // Don't call isValidId unless isKnownId.
        return false
    }
  }

  /*

  The following mapping allows regular problem sets (e.g. up to 9x9)
  to be a prefix of extended problem sets (e.g. up to 12x12).
  Values in the table are problem indexes.
  Use indexFromRowCol, rowFromIndex, colFromIndex to map.

  row/col 0   1   2   3   4   5   6   7   8   9 |  10  11  12
      +----------------------------------------------------
    0 |   0   1   2   3   4   5   6   7   8   9 | 130 131 132
    1 |  10  11  12  13  14  15  16  17  18  19 | 133 134 135
    2 |  20  21  22  23  24  25  26  27  28  29 | 136 137 138
    3 |  30  31  32  33  34  35  36  37  38  39 | 139 140 141
    4 |  40  41  42  43  44  45  46  47  48  49 | 142 143 144
    5 |  50  51  52  53  54  55  56  57  58  59 | 145 146 147
    6 |  60  61  62  63  64  65  66  67  68  69 | 148 149 150
    7 |  70  71  72  73  74  75  76  77  78  79 | 151 152 153
    8 |  80  81  82  83  84  85  86  87  88  89 | 154 155 156
    9 |  90  91  92  93  94  95  96  97  98  99 | 157 158 159
  10 | 100 101 102 103 104 105 106 107 108 109 | 160 161 162
  11 | 110 111 112 113 114 115 116 117 118 119 | 163 164 165
  12 | 120 121 122 123 124 125 126 127 128 129 | 166 167 168
  */

  // Mapping from row/col to index and back.
  public static indexFromRowCol(r: number, c: number): ProblemIndex {
    assert(r >= 0 && r < 13)
    assert(c >= 0 && c < 13)
    return c < 10 ? r * 10 + c : 130 + r * 3 + (c - 10)
  }

  public static rowFromIndex(i: ProblemIndex): number {
    assert(i >= 0 && i < 169)
    return i < 130 ? Math.floor(i / 10) : Math.floor((i - 130) / 3)
  }

  public static colFromIndex(i: ProblemIndex): number {
    assert(i >= 0 && i < 169)
    return i < 130 ? i % 10 : 10 + ((i - 130) % 3)
  }

  // INSTANCE PROPERTIES

  // TYPESCRIPT: Proper types:
  public id: OperationId

  public filter: FilterFunc

  public groups: ProblemGroups

  public threshold: FluencyThreshold

  public generator: (i: ProblemIndex) => Problem

  public mathOperationKey: string

  public nameKey: string

  public possibleIndexes: ProblemIndex[]

  public weights: Weights

  public getLocalizedName(i18n: /* TYPESCRIPT: */ any, locale?: Locale) {
    // REVIEW: this code is a near-duplicate of code in client-language.js, pdf-mixin.js. Can we factor it all out?
    const nameKey = this.mathOperationId()
    const problemSetKey = this.problemSetId()
    const thresholdKey = this.thresholdId()
    const name = i18n.getResource('mathOperationIdMixes', nameKey, locale) || ''
    const problemSetName =
      i18n.getResource('problemSetIdAdjectives', problemSetKey, locale) || ''
    const thresholdName =
      i18n.getResource('thresholdIdAdjectives', thresholdKey, locale) || ''
    const template =
      i18n.getResource('operations', 'summaries', locale).nameTemplate ||
      i18n.getResource('operations', 'summaries', 'en').nameTemplate
    return template
      .replace('{{mathOperation}}', name)
      .replace('{{problemSet}}', problemSetName)
      .replace('{{threshold}}', thresholdName)
  }

  public getLocalizedMathematicalOperationName(
    i18n: /* TYPESCRIPT: */ any,
    locale: Locale
  ): string {
    return i18n.getTerm(this.mathOperationKey, locale)
  }

  public matrixDimension(): number {
    return (this.gen1Id() & PROBLEMSET_MASK) === EXTENDED ? 13 : 10
  }

  public maxIndex(): number {
    const d = this.matrixDimension()
    return d * d
  }

  public problemSetId(): number {
    return this.gen1Id() & PROBLEMSET_MASK
  }

  public ordination(): number {
    // Used for teacher reporting. Sorts by mathmatical operation, then problem set, then threshold.
    // NOTE: swap mathOperation and problemSet valuation - MathOperation starts at place 2 and we'd like it at place zero, and vice-versa
    const threshold = this.thresholdId() >> THRESHOLD_IDS.shift // fields 0, 1, 2
    const problemSet = this.problemSetId() >> PROBLEMSET_IDS.shift // fields 3, 4
    const mathOperation = this.mathOperationId() >> MATH_OPERATION_IDS.shift // fields 5, 6, 7 ,8
    return (
      (1 << (0 + threshold - 1)) +
      (1 << (3 + problemSet - 1)) +
      (1 << (5 + mathOperation - 1))
    )
  }

  // ----- PRIVATE -----

  // PRIVATE CLASS METHODS

  private static isValidGen0Id(g0Id: OperationId): boolean {
    return _.has(GEN1_FROM_GEN0, g0Id)
  }

  private static isValidGen1Id(g1Id: OperationId): boolean {
    // Ensure it does not have a Gen0 equivalent.
    // For backwards compatibility, we use Gen0 when available.
    if (_.has(GEN0_FROM_GEN1, g1Id)) {
      return false
    }

    // Check for invalid problem set
    const problemSetId = g1Id & PROBLEMSET_MASK
    if (problemSetId > EXTENDED) {
      return false
    }

    // Check for invalid threshold
    const thresholdId = g1Id & THRESHOLD_MASK
    if (thresholdId < TWELVE_SEC || thresholdId > ONE_AND_HALF_SEC) {
      return false
    }

    // Check for invalid math operation (no bits set or more than one bit set)
    const mathOperationId = g1Id & MATH_OPERATION_MASK
    if (!_.has(OPERATION_KEYS, mathOperationId)) {
      return false
    }

    // Ensure no extraneous bits are set
    if ((g1Id & UNUSED_GEN1_ID_BITS_MASK) !== 0) {
      return false
    }

    return true
  }

  // PRIVATE CONSTRUCTOR

  private constructor(id: OperationId) {
    assert(Operation.isKnownId(id) && Operation.isValidId(id))

    this.id = id
    const mathOperationId = this.mathOperationId()
    const mathOperationKey = OPERATION_KEYS[mathOperationId]
    const problemSetId = this.problemSetId()
    const thresholdId = this.thresholdId()

    // Groups should only include facts from the problem set.
    const filter =
      CUSTOM_FILTERS[problemSetId][mathOperationId] || FILTERS[problemSetId]
    let groups = statistics.groups[mathOperationKey]
    groups = _.map(groups, function (group) {
      return _.filter(group, filter)
    })
    groups = _.filter(groups, function (elt) {
      return !_.isEmpty(elt)
    })

    // Construct a sorted list of all possible question indexes.
    const possibleIndexes = _.flatten<ProblemIndex>(groups).sort()

    // Scale the weights so they sum to 100 for the facts in the problem set.
    const opWeights = statistics.weights[mathOperationKey]
    const weights = new Array(this.maxIndex())
    let totalWeight = 0
    for (let i = 0; i < weights.length; i++) {
      const w = filter(i) ? opWeights[i] : 0
      weights[i] = w
      totalWeight += w
    }
    const adjustment = 100 / totalWeight
    for (let i = 0; i < weights.length; i++) {
      weights[i] *= adjustment
    }

    this.filter = filter
    this.groups = groups
    this.threshold = THRESHOLDS[thresholdId]
    this.generator = FACT_GENERATOR[mathOperationId]
    this.mathOperationKey = mathOperationKey
    this.nameKey = this.mathOperationKey // DEPRECATED.
    this.possibleIndexes = possibleIndexes
    this.weights = weights
  }

  // PRIVATE INSTANCE PROPERTY FUNCTIONS

  private gen1Id() {
    return GEN1_FROM_GEN0[this.id] || this.id
  }

  private mathOperationId(): number {
    return this.gen1Id() & MATH_OPERATION_MASK
  }

  private thresholdId(): number {
    return this.gen1Id() & THRESHOLD_MASK
  }
}

// INSTANCE METHODS

// HELPER FUNCTIONS

function bitfield(shift: number, bits: number, values: any): BitVector {
  const maxValues = 1 << bits
  assert(values.length <= maxValues)
  const rval: BitVector = { shift, mask: (maxValues - 1) << shift }
  _.forEach(values, function (value, i: ProblemIndex) {
    rval[value] = i << shift
  })
  return rval
}

function bitvector(shift: number, bits: number, values: any): BitVector {
  assert(values.length <= bits)
  const rval: BitVector = { shift, mask: ((1 << bits) - 1) << shift }
  _.forEach(values, function (value, i: ProblemIndex) {
    rval[value] = 1 << (shift + i)
  })
  return rval
}

// Problem generators. Take a problem index and returns an object containing the operands, operator, answer,
// and a string representation.

function additionGenerator(i: ProblemIndex): Problem {
  const r = Operation.rowFromIndex(i)
  const c = Operation.colFromIndex(i)
  return { i, a: r, b: c, c: r + c, o: '+', s: `${r}+${c}` }
}

function subtractionGenerator(i: ProblemIndex): Problem {
  const r = Operation.rowFromIndex(i)
  const c = Operation.colFromIndex(i)
  return { i, a: r + c, b: c, c: r, o: '-', s: `${r + c}-${c}` }
}

function multiplicationGenerator(i: ProblemIndex): Problem {
  const r = Operation.rowFromIndex(i)
  const c = Operation.colFromIndex(i)
  return { i, a: r, b: c, c: r * c, o: '\u00D7', s: `${r}\u00D7${c}` }
}

function divisionGenerator(i: ProblemIndex): Problem {
  const r = Operation.rowFromIndex(i)
  const c = Operation.colFromIndex(i)
  return {
    i,
    a: r * c,
    b: c,
    c: r,
    o: '\u00F7',
    s: c ? `${r * c}\u00F7${c}` : '',
  }
}
