import q from 'q'
import * as Sentry from '@sentry/react'

import { StudentSession } from './types/student-session'

export const cleanupSession = (
  studentSession: StudentSession,
  forceHome?: boolean,
  popupParams?: any
): q.Promise<void> => {
  return q()
    .then(() => {
      Sentry.setUser(null)
      if (window.location.href.indexOf('student') > -1) {
        const url = `${
          forceHome
            ? '/'
            : `/#/${studentSession.redirect.split('?')[0].replace('?', '')}` ||
              '/'
        }${popupParams ? '?' : ''}${Object.keys(popupParams || {})
          .map((key) => `${key}=${popupParams[key]}`)
          .join('&')}`
        window.location.href = url
      }
    })
    .fail((err) => {
      console.log(err)
    })
}

export const studentSignout = (
  studentSession: StudentSession,
  inactive?: boolean
) => {
  studentSession.signout()
  let options
  if (inactive) {
    options = { popup: '#inactivityPopup' }
  }
  cleanupSession(studentSession, false, options)
}
