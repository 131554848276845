/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-this-alias */
// REQUIREMENTS

// TODO: import { AjaxConnection2 } from '../../clients/app/helpers/ajax-connection2';
import _ from 'lodash'

import q from 'q'
import {
  RefreshRval,
  RefreshParams,
  GetTeachersParams,
  GetRelativesParams,
  HomeRefreshRval,
  HomeRefreshParams,
} from './web-server/api-types'

import { PlainObject, assert, applyDelta } from './common'

import {
  AccountItem,
  AccountCode,
  AccountCollection,
  AccountInstance,
  CodeCollection,
  AccountMap,
  Delta,
  AccountSecret,
} from './account-types'
import {
  ClientMixinClass,
  ClientMixinInstance,
  CacheMap,
  ClientParamsFilter,
  RefreshResult,
  CacheType,
  ClientTeacherInstance,
  ClientClassroomClass,
  ClientStudentClass,
  ClientTeacherClass,
  MultiCacheMap,
  MultiItemMap,
  ClientSyncRval2,
  ClientAccountsMap,
  ChangeEntry,
  PromisedInstance,
  ClientClassroomInstance,
} from './account-types-client'
import { Cache } from './cache'
import { AjaxConnection2 } from './helpers/ajax-connection2'

const constructors: { teachers: any; classrooms: any } = {
  teachers: {},
  classrooms: {},
}

const logger = console.log

// EXPORTS

// eslint-disable-next-line import/prefer-default-export
export const ClientMixin: ClientMixinClass = ClientMixinConstructor as any

// CONSTANTS

const REFRESH_INTERVAL = 1 * 60 * 1000
const previouslyPromised = { teachers: {}, students: {}, classrooms: {} } // TODO: remove

// GLOBAL VARIABLE

const modelCaches = {}

// CONSTRUCTOR

function ClientMixinConstructor(_item: AccountItem, _notSynced: boolean) {
  this.changes = []
}
const constructor: ClientMixinClass = <{ (): void } & ClientMixinClass>(
  ClientMixinConstructor
)
const proto: ClientMixinInstance = constructor.prototype

// CLASS PROPERTIES

// CLASS METHODS

// TYPESCRIPT: it's awkward to inject and save AjaxConnection2 from client. Also it can cause scope issues because we pull in all the client types. Refactor?
constructor.initialize = function (
  this: ClientMixinClass,
  connection: AjaxConnection2,
  callbacks: { cacheCallback: () => void }
): void {
  const that = this
  this.connection = connection
  // note: cacheCallback is injected here to avoid circular dep with cache-mgr.
  this.callbacks = callbacks || {}
  this.caches = modelCaches
  this.ageCutoff = Date.now()
  setInterval(function () {
    that.ageCutoff = Date.now()
  }, REFRESH_INTERVAL)
}

constructor.instantiate = function (
  this: ClientMixinClass,
  _item: AccountItem,
  _cache?: Cache<ClientMixinInstance>,
  _notSynced?: boolean
): ClientMixinInstance {
  throw new Error('Should be overridden')
}

constructor.addCaches = function (
  this: ClientMixinClass,
  caches: CacheMap
): void {
  _.assign(this.caches, caches)
}

constructor.applyRefreshResult = function (
  this: ClientMixinClass,
  result: RefreshRval | HomeRefreshRval,
  initialVersions:
    | ClientParamsFilter<RefreshParams>
    | ClientParamsFilter<HomeRefreshParams>
): RefreshResult {
  const that = this
  const rval = {
    teachers: {},
    students: {},
    classrooms: {},
    teacherSignins: {},
    classroomSignins: {},
    teacherSigninsFromSameIP: {},
    errors: {},
    callback: that.callbacks.cacheCallback,
  }
  _.assign(
    rval,
    _.omit(result, [
      'teacherSignins',
      'teacherVersions',
      'classroomSignins',
      'classroomVersions',
      'students',
      'classrooms',
      'teachers',
    ])
  )
  if (result && 'teacherSignins' in result) {
    _.forEach(result.teacherSignins, function (teacher) {
      const newTeacher = constructors.teachers.signinCreate(teacher)
      rval.teacherSignins[newTeacher.code] = newTeacher
    })
  }
  if (result && 'teacherSigninsFromSameIP' in result) {
    _.forEach(result.teacherSigninsFromSameIP, function (teacher) {
      const newTeacher = constructors.teachers.signinCreate(teacher)
      rval.teacherSigninsFromSameIP[newTeacher.code] = newTeacher
    })
  }
  if ('teacherVersions' in initialVersions) {
    _.forEach(initialVersions.teacherVersions, (teacher) => {
      if (!rval.teacherSignins[(teacher as ClientTeacherInstance).code]) {
        rval.teacherSignins[(teacher as ClientTeacherInstance).code] = teacher
      }
    })
  }
  if (result && 'classroomSignins' in result) {
    _.forEach(result.classroomSignins, function (classroom) {
      const newClassroom = constructors.classrooms.signinCreate(classroom)
      rval.classroomSignins[newClassroom.code] = newClassroom
    })
  }
  if (result && 'classroomVersions' in initialVersions) {
    _.forEach(initialVersions.classroomVersions, (classroom) => {
      if (!rval.classroomSignins[(classroom as ClientClassroomInstance).code]) {
        rval.classroomSignins[(classroom as ClientClassroomInstance).code] =
          classroom
      }
    })
  }
  _.forEach(['students', 'classrooms', 'teachers'], function (type) {
    const rGroup = rval[type]
    const cache = that.caches[type]
    const group = result[type]
    if (group) {
      if (group.deltas) {
        _.forEach(group.deltas, function (delta, code) {
          const item = cache.find(code)
          if (item) {
            const initialVersion =
              initialVersions[type][code] && initialVersions[type][code].version
            if (
              initialVersion &&
              initialVersion !== item.version &&
              item.version !== delta.version &&
              item.version + 1 !== delta.version
            ) {
              logger(
                {
                  initialVersion,
                  currentVersion: item.version,
                  deltaVersion: delta.version,
                },
                'Client applying delta to stale object.'
              )
            }
            item.applyDelta(delta)
            rGroup[code] = item
          } else {
            logger(
              "Couldn't find item %s in %s cache while attempting to apply delta",
              code,
              type
            )
          }
        })
      }
      if (group.items) {
        _.forEach(group.items, function (data, code) {
          const item = constructors[type].create(data, cache)
          rGroup[code] = item
        })
      }
      if (group.errors) {
        _.forEach(group.errors, function (info, code) {
          if (
            info.status === 'not-found' ||
            info.status === 'merged' ||
            info.status === 'access-denied'
          ) {
            cache.remove(code)
          } else {
            logger('Failed to load %s - status %s', type, info.status)
          }
        })
        _.assign(rval.errors, group.errors)
      }
    }
  })
  return rval
}

constructor.getCache = function (
  this: ClientMixinClass,
  type: CacheType
): Cache<ClientMixinInstance> {
  assert(modelCaches[type], `No cache of type ${type}`)
  return this.caches[type]
}

constructor.getRelatives = function (
  this: ClientMixinClass,
  codes: AccountCode[]
): q.Promise<{
  [code: string]: {
    parents?: ClientTeacherInstance[]
    teachers?: ClientTeacherInstance[]
  }
}> {
  // NOTE: the Cache model assumes it is storing account items and I didn't want to violate
  // that assumption, hence the one-off caching here.
  let args
  let route
  let resultKey
  if (this.typePlural === 'classrooms') {
    route = 'classroom/get-teachers'
    args = { cCodes: codes }
    resultKey = 'classroomTeachers'
  } else if (this.typePlural === 'students') {
    route = 'student/get-relatives'
    args = { sCodes: codes }
    resultKey = 'studentRelatives'
  } else {
    const message = `Unrecognized account type ${this.typePlural}during getRelatives call`
    throw new Error(message)
  }
  return (<ClientMixinClass>this).connection
    .emitToServer<GetTeachersParams | GetRelativesParams>(
      route,
      args,
      'nonblocking'
    )
    .then(function (result) {
      return result[resultKey]
    })
}

constructor.referenceDerivedClass = function (
  this: ClientMixinClass,
  key: 'students' | 'classrooms' | 'teachers',
  // eslint-disable-next-line no-shadow
  constructor: ClientClassroomClass | ClientStudentClass | ClientTeacherClass
): void {
  constructors[key] = constructor
}

// TODO: Move sorting to accounts library. See Classroom.sortedCodes & Student.sortedCodes.
constructor.sort = function (
  this: ClientMixinClass,
  objects: AccountCollection,
  field?: string
): AccountInstance[] {
  // @ts-ignore
  return _.sortBy(objects, function (object: AccountInstance) {
    const name = object[field] || object.getName()
    return name.toLowerCase()
  })
}

constructor.create = function (
  this: ClientMixinClass,
  item: AccountItem,
  cache?: Cache<ClientMixinInstance>,
  notSynced?: boolean
): ClientMixinInstance {
  flattenAspects(item)
  cache = cache || this.caches.students
  const exists = cache && cache.find(item.code)
  if (exists) {
    exists.transform(item)
    return exists
  }
  const instance = this.instantiate(item, cache, notSynced)
  instance['@lastUpdated'] = Date.now()
  instance['@lastSynced'] = notSynced ? 0 : Date.now()
  cache.add(instance)
  this.callbacks.cacheCallback()
  return instance
}

// TODO: rename to onlySecretFields, more specific return type
constructor.filterSigninFields = function (
  this: ClientMixinClass,
  item: AccountItem
): AccountItem {
  const that = this
  return _.reduce(
    item,
    function (memo, val, key) {
      if (that.signinFieldsRE.test(key)) {
        if (that.secretFieldsRE && that.secretFieldsRE.test(key) === true) {
          memo[key] = '-'
        } else {
          memo[key] = val
        }
      }
      return memo
    },
    {} as AccountItem
  )
}

constructor.getCachedItems = function (
  this: ClientMixinClass,
  codes: CodeCollection,
  cache?: Cache<ClientMixinInstance>
): AccountMap {
  const that = this
  return _.reduce(
    codes,
    function (memo, _secret, code) {
      const account = that.findInCache(code, cache)
      if (account) {
        memo[account.code] = account
      }
      return memo
    },
    {}
  )
}

constructor.refreshItems = function (
  this: ClientMixinClass,
  refresh: ClientParamsFilter<RefreshParams>
): q.Promise<RefreshResult> {
  const that = this
  const promise: q.Promise<RefreshResult> = (<ClientMixinClass>this).connection
    .emitToServer<RefreshParams>('refresh', refresh, 'nonblocking')
    .then(function (result) {
      return <RefreshResult>that.applyRefreshResult(result, refresh)
    })
  applyCleanupPromises(refresh, promise)
  return promise
}

constructor.syncItems = function (
  this: ClientMixinClass,
  typesToCodes: MultiItemMap,
  caches?: MultiCacheMap,
  refreshCached?: boolean
): ClientSyncRval2 {
  const that = this
  let secretErrors: { teachers: {}; classrooms: {}; students: {} }
  const promises: q.Promise<RefreshResult | PromisedInstance>[] = []
  const alreadyCached = {}
  const refresh: ClientParamsFilter<RefreshParams> = {
    students: {},
    classrooms: {},
    teachers: {},
  }
  let shouldRefresh = false

  _.forEach(typesToCodes, function (codes, type) {
    const cache = (caches && caches[type]) || that.caches[type]
    const cachedOfType = constructors[type].getCachedItems(codes, cache)
    alreadyCached[type] = cachedOfType
    // eslint-disable-next-line no-shadow
    const secretErrors = {}
    _.forEach(codes, function (secret, code) {
      if (previouslyPromised[type][code]) {
        promises.push(previouslyPromised[type][code])
      } else if (!cachedOfType[code]) {
        shouldRefresh = true
        refresh[type][code] = { version: 0, secret }
      } else if (cachedOfType[code]) {
        const item = cachedOfType[code]
        if (!item.secret) {
          const trace = new Error().stack
          logger({ trace }, 'Skipping sync: cached item has no secret.')
          secretErrors[type][code] = { status: 'not-found' } // LATER: remove this once issue with missing secrets in localStorage is resolved
        } else if (
          refreshCached ||
          (item['@lastSynced'] < that.ageCutoff && item.secret === codes[code])
        ) {
          shouldRefresh = true
          refresh[type][code] = { version: item.version, secret: item.secret }
          item['@lastSynced'] = Date.now()
        }
      }
    })
  })

  // TODO: better type definition for refreshItems.
  const deferred: q.Deferred<ClientAccountsMap> = q.defer()
  if (shouldRefresh || _.size(secretErrors) > 0) {
    promises.unshift(that.refreshItems(refresh, previouslyPromised))
  } else {
    promises.unshift(
      q({ teachers: {}, classrooms: {}, students: {}, errors: {} })
    )
  }
  q.all(promises)
    .then(function (resolvedPromises) {
      const changedItems = resolvedPromises[0]
      _.forEach(['teachers', 'classrooms', 'students'], function (type) {
        const items = changedItems[type]
        if (_.size(items) > 0) {
          const { cacheCallback } = constructors[type].callbacks
          if (cacheCallback) {
            cacheCallback()
          }
        }
      })
      const rval = _.merge({}, alreadyCached, secretErrors, changedItems) // LATER: remove secretErrors once issue with missing secrets is resolved
      _.forEach(resolvedPromises.slice(1), function (item) {
        if ('code' in item) {
          const { code } = item
          const { type } = item
          rval[type][code] = item.instance
        }
      })
      deferred.resolve(rval)
    })
    .catch(function (err) {
      deferred.reject(err)
    })
  return { cached: alreadyCached, promise: deferred.promise }
}

// INSTANCE METHODS

proto.applyDelta = function (this: ClientMixinInstance, delta: Delta): void {
  if (_.isEmpty(delta)) {
    return
  }
  const aspects = ['signin', 'account']
  _.forEach(aspects, function (aspect) {
    // flatten any aspects present in delta due to previous model structure
    if (delta[aspect]) {
      assert(false, "3.17 client does not accept 'signin' or 'account' aspect")
      delta = _.assign(delta, delta[aspect])
      delete delta[aspect]
    }
  })
  delta['@lastUpdated'] = Date.now()
  applyDelta(this, delta)
  const constructor = this.constructor as ClientMixinClass
  const { callbacks } = constructor
  if (callbacks.cacheCallback) {
    callbacks.cacheCallback()
  }
}

proto.dehydrateSignin = function (this: ClientMixinInstance): AccountItem {
  const constructor = this.constructor as ClientMixinClass
  assert(
    constructor.signinFieldsRE,
    'No signin fields defined for this object.'
  )
  return constructor.filterSigninFields(this.dehydrate())
}

proto.dehydrate = function (this: ClientMixinInstance): Partial<AccountItem> {
  return _.pickBy<AccountItem>(this, function (prop, name) {
    return typeof prop !== 'function' && !_.startsWith(name, '@')
  })
}

proto.postponeDelta = function (
  this: ClientMixinInstance,
  type: string,
  delta: Delta
): void {
  const rDelta = this.generateRDelta(delta)
  const loggedDelta = _.omitBy(delta, function (_val, key) {
    return /^@/.test(key)
  })
  const change = { type, delta: loggedDelta, rDelta }
  this.changes.unshift(change)
  this.applyDelta(delta)
}

proto.generateRDelta = function (
  this: ClientMixinInstance,
  delta: Delta
): Delta {
  const that = this
  if (_.isEmpty(delta)) {
    return null
  }
  return _.reduce(
    delta,
    function (memo, _value, key) {
      memo[key] = that[key] || null
      return memo
    },
    {}
  )
}

proto.getSecret = function (this: ClientMixinInstance): AccountSecret {
  return this.secret || '-'
}

proto.getTimezoneOffset = function (this: ClientMixinInstance): number {
  // NOTE: this duplicates functionality in server-side i18n.getTimezoneOffset without requiring moment.tz
  // If a browser doesn't have Intl (e.g. IE 10-), we simply assume tz is the local tz.
  let offset
  const date = new Date()
  const timezoneName = this.timezoneName || 'America/Los_.angeles'
  try {
    offset = getTimezoneOffset(timezoneName, date)
  } catch (err) {
    offset = date.getTimezoneOffset()
  }
  return -offset
}

proto.isClever = function (this: ClientMixinInstance): boolean {
  return !!this.cleverId
}
proto.isClasslink = function (this: ClientMixinInstance): boolean {
  return !!this.oneRosterId
}

// TYPESCRIPT: TODO: rename to "squashPostponedDeltas"?
proto.popPostponedDeltas = function (
  this: ClientMixinInstance,
  type: string
): Delta {
  const that = this
  const relevantChanges = _.pickBy(
    that.changes,
    function (change: ChangeEntry) {
      try {
        return change && change.type === type
      } catch (err) {
        logger(
          that.changes,
          'Warning; error occurred popping change. Changes object:'
        )
        return false
      }
    }
  )
  let reversed = _.map(relevantChanges, function (change) {
    if (change) {
      const { rDelta } = change
      that.applyDelta(rDelta)
      return change
    }
    return null
  })
  reversed = _.compact(reversed).reverse()
  return _.reduce(
    reversed,
    function (memo, change) {
      // NOTE: this assumes we can just squash the deltas together.
      // Won't work for things like operations etc.
      if (change) {
        memo = _.assign(memo, change.delta)
      }
      return memo
    },
    {}
  )
}

proto.generateDelta = function (
  this: ClientMixinInstance,
  fieldMap: PlainObject
): Delta {
  // TYPESCRIPT: better typing for return value
  const that = this
  const delta = _.reduce(
    fieldMap,
    function (memo, value, key) {
      const modelKey = key === 'pin' ? 'secret' : key
      const currentValue = that[modelKey]
      if (value !== currentValue) {
        memo[key] = value
      }
      return memo
    },
    {}
  )
  if (_.isEmpty(delta)) {
    return null
  }
  return delta
}

// synchronous

// asynchronous

// REVIEW: naming
proto.transform = function (this: ClientMixinInstance, result): void {
  const that = this
  // variables created on local object, won't ever exist on result but shouldn't be removed:
  const dontRemove = ['changes', '@lastUpdated', '@lastSynced']
  _.forEach(Object.getOwnPropertyNames(this), function (key) {
    if (!result[key] && dontRemove.indexOf(key) < 0) {
      delete that[key]
    }
  })
  _.assign(this, result)
  this['@lastUpdated'] = Date.now()
  this['@lastSynced'] = Date.now()
}

// HELPER FUNCTIONS

function applyCleanupPromises(
  refresh: ClientParamsFilter<RefreshParams | HomeRefreshParams>,
  refreshPromise: q.Promise<any>
) {
  _.forEach(['teachers', 'classrooms', 'students'], function (type) {
    for (const key in refresh[type]) {
      const instanceP = refreshPromise
        .then(function (result: RefreshResult) {
          const rval: PromisedInstance = {
            type: null,
            code: null,
            instance: null,
          }
          if (result[type][key]) {
            rval.type = type
            rval.code = key
            rval.instance = result[type][key]
          } else {
            rval.type = 'errors'
            rval.code = key
            rval.instance = result.errors[key]
          }
          return rval
        })
        .fin(() => {
          delete previouslyPromised[type][key]
        })
      previouslyPromised[type][key] = instanceP
    }
  })
}

function flattenAspects(
  item: AccountItem & { signin?; account? }
): AccountItem {
  if (item.signin || item.account) {
    logger("3.17 client shouldn't accept 'signin' or 'account' aspect")
    _.assign(item, item.signin, item.account)
    delete item.signin
    delete item.account
  }
  return item
}

function parseDate(date_str: string): number[] {
  const us_re = /(\d+).(\d+).(\d+),?\s+(\d+).(\d+)(.(\d+))?/
  date_str = date_str.replace(/[\u200E\u200F]/g, '')
  return [].slice.call(us_re.exec(date_str), 1).map(Math.floor)
}

function diffMinutes(d1: number[], d2: number[]): number {
  let day = d1[1] - d2[1]
  // NOTE: Chrome reports this as "24" for 12am, so normalize to 0.
  const hour = (d1[3] % 24) - (d2[3] % 24)
  const min = d1[4] - d2[4]

  if (day > 15) day = -1
  if (day < -15) day = 1

  return 60 * (24 * day + hour) + min
}

function getTimezoneOffset(tzStr: string, date: Date) {
  const locale = 'en-US'
  const format_options: Intl.DateTimeFormatOptions = {
    hour12: false,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  const utcF = new Intl.DateTimeFormat(locale, {
    timeZone: 'UTC',
    ...format_options,
  })
  const locF = new Intl.DateTimeFormat(locale, {
    timeZone: tzStr,
    ...format_options,
  })

  return diffMinutes(parseDate(utcF.format(date)), parseDate(locF.format(date)))
}
