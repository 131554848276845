// LODASH IMPORTS
import _ from 'lodash'

import { PlainObject } from '../common'
import { Program } from '../program'
import { config } from '../config/config-shim'
import { xmLanguage } from './xm-language-shim'
import { persistentState } from './persistent-state'

// TYPES

// EXPORTS

export const clientLanguage = {
  currentLocale,
  getAwardLanguage,
  getResource,
  getSection,
  programInfo,
  storedLanguage,
  studentOpSummary,
  swapUrlLanguage,
}

// CONSTANTS

const LANGUAGE_KEY = 'lang'

// GLOBAL VARIABLES

let initialized = false

// HELPER FUNCTIONS

function getResource<T>() {
  initialize()
  // return i18n.getResource<T>(section, key)
}

function getSection<T>(section) {
  initialize()
  // return i18n.getSection<T>(section)
}

function getAwardLanguage() {
  // todo: implement award language
}

function storedLanguage(lang?: string) {
  if (!lang) {
    return persistentState.device.getRaw(LANGUAGE_KEY)
  }
  persistentState.device.set(LANGUAGE_KEY, lang) // NOTE: set as JSON because older (non-English language) clients expect it.
  return lang
}

function swapUrlLanguage(url, lang) {
  const path = url.split('.')
  const languages = _.keys(config.i18n.languages)
  if (lang === 'en') {
    // special case, changing TO English (no lang id) domain
    if (languages.indexOf(path[0]) > -1) {
      // we're changing to a different language
      path.splice(0, 1)
    }
  } else if (languages.indexOf(path[0]) < 0) {
    // special case, changing FROM English domain
    path.unshift(lang)
  } else {
    // change out the first element of the path
    path.splice(0, 1, lang)
  }
  return `${config.protocol}//${path.join('.')}`
}

function currentLocale() {
  const full = config.primaryLanguage
  const availableLanguages = _.keys(xmLanguage)
  const fragments = full.split('-')
  let lang = 'en' // LATER: consider other methods to find default: lang from domain, geolocation, etc.
  while (fragments.length > 0) {
    const langCode = fragments.join('-')
    if (_.indexOf(availableLanguages, langCode) > -1) {
      lang = langCode
      break
    } else {
      fragments.pop()
    }
  }
  return lang
}

function initialize() {
  if (initialized) {
    return
  }
  // i18n.initialize(xmLanguage)
  // i18n.changeLocale(config.primaryLanguage)
  initialized = true
}

function programInfo(id) {
  // let namedPrograms: PlainObject = getSection('programs')
  const programId =
    Program.isKnownId(id) && Program.isValidId(id) ? id : 'unknown'
  const rval: PlainObject = {}
  // let info = namedPrograms[programId]
  // if (!info) {
  //   let program = Program.fromId(programId)
  //   rval.custom = true
  //   rval.name = namedPrograms.custom.name
  //   rval.desc = namedPrograms.custom.description
  //   rval.thresholdId = program.thresholdId()
  //   rval.problemSetId = program.problemSetId()
  //   rval.mathOperationIdMix = program.mathOperationIdMix()
  //   rval.isAssessmentOnly = program.isAssessmentOnly()
  // } else {
  //   rval.name = info.name
  //   rval.desc = info.description
  //   rval.premium = info.premium
  // }
  return rval
}

function studentOpSummary(studentOperation, studentName) {
  // let descriptions = i18n.getResource<OperationSummary>(
  //   'operations',
  //   'summaries'
  // )
  const op = studentOperation.operation()
  if (op.nameKey === 'unknown') {
    return ''
  }
  // let operationName = getResource<string>('terms', op.nameKey)
  // let data: PlainObject = {
  //   operationName: operationName,
  //   operationNameCap: _.capitalize(operationName),
  //   numDays: _.size(studentOperation.progress),
  //   finishDate:
  //     studentOperation.finishedAt &&
  //     i18n.formatDayJS(dayjs(studentOperation.finishedAt), 'L'),
  //   placementMastery: studentOperation.placementMastery,
  //   studentName: studentName,
  // }
  // data.days =
  //   data.numDays > 1 ? descriptions.dayPlural : descriptions.daySingular
  // let templateString
  // if (studentOperation.placementAt) {
  //   data.placementDate = i18n.formatDayJS(
  //     dayjs(studentOperation.placementAt),
  //     'L'
  //   )
  //   templateString = descriptions.placementComplete
  //   if (data.finishDate) {
  //     templateString += ' ' + descriptions.operationComplete
  //   } else {
  //     templateString += ' ' + descriptions.operationIncomplete
  //   }
  // } else {
  //   templateString = descriptions.placementIncomplete
  // }
  // return util.prepareTemplate(templateString)(data)
  return studentOperation.operation().nameKey + studentName
}
