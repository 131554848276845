import React from 'react'
import { AppBar, Box, Container } from '@mui/material'

import Logo from 'assets/logo-white.svg'

function Header() {
  return (
    <AppBar position='static'>
      <Container sx={{ py: 2 }}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box component='img' src={Logo} alt='XtraMath Logo' height={50} />
        </Box>
      </Container>
    </AppBar>
  )
}

export default Header
