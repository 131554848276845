import React, { useEffect, useMemo, useState } from 'react'
import {
  Menu,
  MenuItem,
  Fab,
  Box,
  ButtonGroup,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import Keypad from 'assets/keypad.svg'
import HideKeypad from 'assets/hide_keypad.svg'
import Timer from 'assets/timer.svg'
import HideTimer from 'assets/hide_timer.svg'
import KeypadLeft from 'assets/keypad_left.svg'
import KeypadRight from 'assets/keypad_right.svg'

import { useStudentContext } from 'common/context/StudentContext'

function ActivitySettings({
  onToggleKeypad,
  onFlipKeypad,
  onToggleTimer,
  flipped = false,
  keypadShown = true,
  timerShown,
}: {
  onToggleKeypad: () => void
  onFlipKeypad: () => void
  onToggleTimer: () => void
  flipped: boolean
  keypadShown: boolean
  timerShown?: boolean
}) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { studentSession } = useStudentContext()
  const [activityType, setActivityType] = useState<'practice' | 'quiz' | null>(
    null
  )

  useMemo(() => {
    if (!studentSession || activityType) return
    const { activity } = studentSession.currentState()
    setActivityType(activity.type)
  }, [studentSession])

  return (
    <ButtonGroup>
      {!isSm && (
        <Button
          color='secondary'
          variant='contained'
          sx={{ width: 80, height: 52 }}
          onClick={onToggleKeypad}
          aria-label='Toggle Keypad'
        >
          <Box
            component='img'
            alt='Icon of a keypad'
            src={keypadShown ? HideKeypad : Keypad}
            height={40}
          />
        </Button>
      )}
      {!isSm && ( // had to repeat condition because button group wouldn't group them
        <Button
          variant='contained'
          color='secondary'
          sx={{ width: 80, height: 52 }}
          disabled={!keypadShown}
          onClick={onFlipKeypad}
          aria-label='Flip Keypad'
        >
          <Box
            component='img'
            alt='Icon of a keypad with an arrow'
            src={flipped ? KeypadRight : KeypadLeft}
            height={40}
          />
        </Button>
      )}
      {activityType === 'quiz' && (
        <Button
          variant='contained'
          color='secondary'
          sx={isSm ? { width: 51, height: 37 } : { width: 80, height: 52 }}
          onClick={onToggleTimer}
          aria-label='Toggle Timer'
        >
          <Box
            component='img'
            alt='Icon of a timer'
            src={timerShown ? HideTimer : Timer}
            height={isSm ? 30 : 40}
          />
        </Button>
      )}
    </ButtonGroup>
  )
}

ActivitySettings.defaultProps = {
  timerShown: true,
}

export default ActivitySettings
