interface StatisticsType {
  groups: {
    [key: string]: Array<number[]>
  }
  weights: {
    [key: string]: number[]
  }
}

export const statistics: StatisticsType = {
  groups: {
    addition: [
      [1, 2, 5, 9, 10, 11, 20, 40, 50],
      [0, 3, 4, 6, 7, 8, 14, 22, 30, 33, 41, 44, 60, 70, 80, 90],
      [12, 13, 15, 16, 17, 18, 21, 31, 51, 55, 61, 66, 71, 81],
      [19, 23, 29, 32, 77, 91, 92],
      [24, 25, 42, 45, 52, 54, 56, 62, 65, 72, 88, 99],
      [26, 27, 28, 34, 35, 36, 37, 38, 39, 43, 48, 53, 63, 64, 73, 82, 83, 93],
      [46, 47, 49, 59, 69, 74, 84, 89, 94, 95, 96, 98],
      [57, 58, 67, 68, 75, 76, 78, 79, 85, 86, 87, 97],
      [100, 103, 114, 121, 137, 138, 144, 158, 165],
      [105, 108, 118, 129, 130, 139, 143, 150, 160],
      [102, 109, 111, 112, 145, 147, 148, 159, 166],
      [116, 119, 123, 127, 134, 140, 151, 152, 164],
      [117, 122, 126, 128, 132, 135, 156, 163, 168],
      [101, 104, 120, 124, 125, 131, 136, 154],
      [106, 107, 113, 115, 142, 155, 157, 162],
      [110, 133, 141, 146, 149, 153, 161, 167],
    ],
    subtraction: [
      [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 41, 50, 55, 70, 80, 90,
        91,
      ],
      [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 31, 51, 60, 71, 81],
      [23, 28, 32, 33, 37, 44, 46, 61, 64, 73, 82],
      [24, 25, 26, 42, 52, 53, 62, 66, 72, 92, 99],
      [27, 34, 35, 43, 45, 54, 56, 63, 65, 77, 84, 88, 93, 94, 95],
      [29, 36, 38, 39, 47, 48, 57, 59, 69, 74, 75, 76, 83, 86, 89, 96, 97, 98],
      [49, 58, 67, 68, 78, 79, 85, 87],
      [100, 103, 114, 121, 137, 138, 144, 158, 165],
      [105, 108, 118, 129, 130, 139, 143, 150, 160],
      [102, 109, 111, 112, 145, 147, 148, 159, 166],
      [116, 119, 123, 127, 134, 140, 151, 152, 164],
      [117, 122, 126, 128, 132, 135, 156, 163, 168],
      [101, 104, 120, 124, 125, 131, 136, 154],
      [106, 107, 113, 115, 142, 155, 157, 162],
      [110, 133, 141, 146, 149, 153, 161, 167],
    ],
    multiplication: [
      [0, 2, 3, 4, 5, 6, 7, 8, 9, 20, 30, 40, 50, 60, 70],
      [
        1, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 31, 41, 51, 61, 71,
        80, 81, 90, 91,
      ],
      [32, 52],
      [23, 24, 25, 26, 27, 33, 42, 62, 72],
      [29, 35, 53, 55, 82, 92],
      [28, 34, 43, 45, 54, 66, 73, 95, 99],
      [37, 44, 46, 56, 59, 63, 64, 65, 75, 77, 85],
      [36, 38, 39, 47, 57, 58, 67, 69, 74, 76, 78, 83, 87, 88, 89, 93, 96, 98],
      [48, 49, 68, 79, 84, 86, 94, 97],
      [100, 103, 114, 121, 137, 138, 144, 158, 165],
      [105, 108, 118, 129, 130, 139, 143, 150, 160],
      [102, 109, 111, 112, 145, 147, 148, 159, 166],
      [116, 119, 123, 127, 134, 140, 151, 152, 164],
      [117, 122, 126, 128, 132, 135, 156, 163, 168],
      [101, 104, 120, 124, 125, 131, 136, 154],
      [106, 107, 113, 115, 142, 155, 157, 162],
      [110, 133, 141, 146, 149, 153, 161, 167],
    ],
    division: [
      [6, 8, 9, 7, 4, 2, 3, 5, 11, 1],
      [51, 41, 16, 71, 14, 17, 81, 18, 19, 31],
      [61, 91, 52, 55, 26, 27, 29, 25, 24, 35],
      [37, 56, 99, 45, 21, 54, 22, 66, 53, 15],
      [28, 13, 12, 57, 62, 73, 58, 72, 65, 39],
      [59, 42, 23, 89, 32, 34, 98, 75, 82, 46],
      [33, 43, 85, 95, 77, 88, 67, 44, 48, 97],
      [79, 76, 64, 86, 87, 63, 84, 47, 68, 78],
      [69, 92, 93, 49, 36, 38, 96, 83, 74, 94],
      [103, 114, 121, 137, 138, 144, 158, 165],
      [105, 108, 118, 129, 130, 139, 143, 150, 160],
      [102, 109, 111, 112, 145, 147, 148, 159, 166],
      [116, 119, 123, 127, 134, 140, 151, 152, 164],
      [117, 122, 126, 128, 132, 135, 156, 163, 168],
      [101, 104, 124, 125, 131, 136, 154],
      [106, 107, 113, 115, 142, 155, 157, 162],
      [133, 141, 146, 149, 153, 161, 167],
    ],
  },
  weights: {
    addition: [
      0.4, 0.26, 0.31, 0.36, 0.38, 0.3, 0.39, 0.39, 0.37, 0.33, 0.29, 0.3, 0.58,
      0.62, 0.51, 0.59, 0.64, 0.61, 0.59, 0.84, 0.31, 0.59, 0.41, 0.83, 1.16,
      1.06, 1.16, 1.14, 1.18, 0.88, 0.37, 0.63, 0.86, 0.52, 1.34, 1.28, 1.23,
      1.38, 1.2, 1.23, 0.36, 0.48, 1.06, 1.32, 0.55, 1.12, 1.48, 1.37, 1.39,
      1.52, 0.29, 0.57, 1.02, 1.17, 1.09, 0.68, 1.15, 1.71, 1.84, 1.54, 0.43,
      0.62, 1.04, 1.15, 1.41, 1.14, 0.65, 1.84, 1.99, 1.67, 0.38, 0.58, 1.07,
      1.27, 1.38, 1.68, 1.88, 0.9, 1.91, 1.88, 0.38, 0.55, 1.14, 1.15, 1.38,
      1.83, 1.96, 1.96, 1.14, 1.72, 0.35, 0.82, 0.83, 1.17, 1.46, 1.49, 1.59,
      1.87, 1.74, 1.03, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
    ],
    subtraction: [
      0.24, 0.41, 0.46, 0.44, 0.46, 0.4, 0.44, 0.46, 0.45, 0.43, 0.46, 0.6,
      0.73, 0.75, 0.66, 0.75, 0.8, 0.76, 0.73, 0.63, 0.48, 0.64, 0.76, 0.88,
      1.08, 1.12, 1.12, 1.24, 0.84, 1.31, 0.52, 0.72, 0.91, 0.93, 1.25, 1.21,
      1.34, 0.94, 1.54, 1.47, 0.53, 0.64, 1.05, 1.2, 0.93, 1.18, 0.93, 1.48,
      1.45, 1.62, 0.48, 0.67, 1.05, 1.12, 1.17, 0.6, 1.25, 1.49, 1.58, 1.42,
      0.58, 0.78, 1.14, 1.27, 0.98, 1.26, 0.99, 1.56, 1.6, 1.51, 0.55, 0.76,
      1.15, 0.92, 1.51, 1.39, 1.52, 1.13, 1.67, 1.56, 0.55, 0.69, 0.83, 1.35,
      1.27, 1.51, 1.54, 1.59, 1.14, 1.45, 0.52, 0.58, 1.13, 1.24, 1.29, 1.2,
      1.33, 1.43, 1.36, 1.01, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
    ],
    multiplication: [
      0.18, 0.27, 0.23, 0.25, 0.24, 0.24, 0.24, 0.24, 0.25, 0.26, 0.27, 0.33,
      0.5, 0.5, 0.47, 0.43, 0.52, 0.53, 0.53, 0.48, 0.23, 0.5, 0.56, 0.87, 0.94,
      0.85, 0.91, 1.0, 1.17, 1.13, 0.24, 0.51, 0.81, 0.79, 1.21, 1.05, 1.65,
      1.41, 1.78, 1.7, 0.24, 0.48, 0.85, 1.22, 1.32, 1.22, 1.55, 1.68, 1.79,
      1.83, 0.25, 0.45, 0.82, 1.0, 1.19, 1.05, 1.47, 1.67, 1.68, 1.52, 0.25,
      0.55, 0.86, 1.57, 1.47, 1.4, 1.18, 1.61, 1.66, 1.68, 0.24, 0.54, 0.96,
      1.29, 1.65, 1.58, 1.58, 1.29, 1.5, 1.79, 0.25, 0.57, 1.15, 1.7, 1.72, 1.5,
      1.67, 1.42, 1.43, 1.56, 0.26, 0.54, 1.05, 1.67, 1.73, 1.43, 1.66, 1.78,
      1.54, 1.18, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
    ],
    division: [
      0.0, 0.32, 0.32, 0.31, 0.29, 0.35, 0.27, 0.29, 0.27, 0.25, 0.0, 0.4, 0.71,
      0.8, 0.64, 0.87, 0.63, 0.63, 0.65, 0.71, 0.0, 0.75, 0.98, 1.27, 0.97,
      0.82, 0.87, 0.8, 1.1, 0.95, 0.0, 0.8, 1.22, 1.4, 1.35, 0.93, 1.59, 0.93,
      1.68, 1.32, 0.0, 0.73, 1.31, 1.42, 1.48, 1.04, 1.43, 1.53, 1.47, 1.77,
      0.0, 0.71, 0.85, 1.13, 1.02, 0.96, 0.99, 1.15, 1.15, 1.32, 0.0, 0.84,
      1.24, 1.68, 1.45, 1.28, 0.97, 1.47, 1.68, 1.65, 0.0, 0.77, 1.33, 1.23,
      1.82, 1.38, 1.53, 1.41, 1.5, 1.47, 0.0, 0.8, 1.42, 1.86, 1.53, 1.45, 1.58,
      1.56, 1.38, 1.2, 0.0, 0.79, 1.65, 1.61, 1.76, 1.44, 1.66, 1.41, 1.34,
      0.98, 0.0, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 0.0, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 0.0, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
      1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2,
    ],
  },
}
