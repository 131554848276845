// LODASH IMPORTS
import _ from 'lodash'
import AWARD_DESCRIPTIONS from 'common/constants/awardDescriptions'
import * as at from './types/account-types'
import * as atc from './types/account-types-client'
import * as awardSorter from './awardSorter'

import { config } from './types/config/config-shim'

const logger = console

// OTHER IMPORTS

// EXPORTS
export const awardPanel = {
  initialize,
  launch,
  loadAwards,
  show,
  hide,
  activityImages,
  awardsImagesURLs,
}

// used to enable clickable elements after launch/show animations. Animation timings can be found in awards.styl
const LAUNCH_DELAY = 4500 // ms
const SHOW_DELAY = 800 // ms

// GLOBALS
let $awardsHolder
let initialized
type AwardInfo = atc.ClientAwardInfo & { first: boolean }
let storedAwards: AwardInfo[] = []
let closeCb: () => void

function initialize() {
  if (!initialized) {
    // $awardsHolder = $('#awardsHolder')
    // hasher.replaceImagePaths($awardsHolder)
    initialized = true
  }
}

function loadAwards(awardTypes: at.AwardType[]): AwardInfo[] {
  const awards = _.map(awardTypes, (award) => {
    const info = awardSorter.getAwardInfo(award)
    const { teacher: tdesc, student: sdesc } = getDescriptions(award)
    return {
      tdesc,
      sdesc,
      first: false,
      ...info,
    }
  })
  awards[0].first = true
  return awards
}

function launch(awardTypes: at.AwardType[], cb?: () => void) {
  closeCb = cb || null
  // setupEvents($awardsHolder)
  storedAwards = loadAwards(awardTypes)
  // $('.trophy', $awardsHolder).removeClass('current')
  // let $firstTrophy = $('.trophy.first', $awardsHolder)
  // if ($firstTrophy.length < 1) {
  // $('.trophy', $awardsHolder).first().addClass('first current')
  // $firstTrophy = $('.trophy.first', $awardsHolder)
  // }
  // setTrophyImage($firstTrophy, storedAwards[0].imgId)
  // updateDescription(storedAwards[0].sdesc)
  $awardsHolder.show().addClass('animate splash blockEvents')
  setTimeout(() => {
    $awardsHolder.removeClass('blockEvents')
  }, LAUNCH_DELAY)
}

function show() {
  // NOTE: this should only be called once the awards have already been populated with launch();
  // usually when the user asks for a set of awards to be shown again.
  $awardsHolder.show().addClass('animate')
  setTimeout(() => {
    $awardsHolder.removeClass('blockEvents')
  }, SHOW_DELAY)
}

function hide() {
  $awardsHolder.hide()
  // $('.trophy', $awardsHolder).removeClass('dismissed')
  // teardownEvents($awardsHolder)
}

// function setTrophyImage($trophy, imgId) {
//   let ext = config.pngMode ? '@2x.png' : '.svg'
//   let imageLocation =
//     config.resourceLocation + 'images/trophies/' + imgId + '_stage' + ext
//   if (storedAwards.length == 1) {
//     $('.caretl, .caretr').addClass('hidden')
//   } else if (storedAwards[0].first) {
//     $('.caretl', $awardsHolder).addClass('hidden')
//     $('.caretr', $awardsHolder).removeClass('hidden')
//   } else if (storedAwards[1].first) {
//     $('.caretr', $awardsHolder).addClass('hidden')
//     $('.caretl', $awardsHolder).removeClass('hidden')
//   } else {
//     $('.caretr, .caretl').removeClass('hidden')
//   }
//   $trophy.prop('src', imageLocation)
// }

function getDescriptions(awardType: at.AwardType): {
  teacher: string
  student: string
} {
  const resource = <{ teacher: string; student: string }>(
    AWARD_DESCRIPTIONS[awardType]
  )
  if (!resource) {
    logger.warn('No description found for award: %s', awardType)
    return { teacher: '', student: '' }
  }
  return resource
}

function activityImages(awardList: at.AwardType[]) {
  const ext = config.pngMode ? '@2x.png' : '.svg'
  const awards = _.map(awardList, (award) => {
    return awardSorter.getAwardInfo(award)
  })
  let awardImageHtml = _.map(awards.slice(0, 3), (awardInfo) => {
    const imageLocation = `${config.resourceLocation}images/trophies/${awardInfo.imgId}${ext}`
    return `<img src="${imageLocation}"/>`
  }).join('')
  if (awards.length > 3) {
    awardImageHtml += `<img src="${config.resourceLocation}images/${config.primaryLanguage}/more${ext}"/>`
  }
  return awardImageHtml
}

function awardsImagesURLs(awardList: at.AwardType[]): string[] {
  const ext = config.pngMode ? '@2x.png' : '.svg'
  const awards = _.map(awardList, (award) => {
    return awardSorter.getAwardInfo(award)
  })

  const resourceLocation =
    config.resourceLocation[config.resourceLocation.length - 1] === '/'
      ? config.resourceLocation
      : `${config.resourceLocation}/`
  const awardImages = _.map(awards.slice(0, 3), (awardInfo) => {
    return `${resourceLocation}images/trophies/${awardInfo.imgId}${ext}`
  })
  if (awards.length > 3) {
    awardImages.push(
      `${resourceLocation}images/${config.primaryLanguage}/more${ext}`
    )
  }
  return awardImages
}
