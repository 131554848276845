import React, { useState, useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'

import { useStudentContext } from 'common/context/StudentContext'
import Video from 'student/common/video'
import { PaperContainer } from 'student/common/taskUI'
import { studentSignout } from 'common/utils/signout'

const VIDEOS = {
  0: '0002',
  1: '0004',
  2: '0006',
}

const VIDEO_CATEGORY = 'numGoodbye'

function Goodbye() {
  const { studentSession } = useStudentContext()
  const [video, setVideo] = useState<string>('')

  useEffect(() => {
    if (!studentSession) return
    const videoSrc = VIDEOS[studentSession.student.getVideo(VIDEO_CATEGORY)]
    setVideo(videoSrc)
  }, [studentSession])

  const handleContinue = () => {
    Sentry.addBreadcrumb({
      category: 'breadcrumb',
      message: 'Clicked on sign out in Goodbye',
      level: 'info',
      data: {
        student: studentSession?.student,
        activity: studentSession.currentState()?.activity,
      },
    })

    if (video) studentSession.student.watchedVideo(VIDEO_CATEGORY)
    studentSession
      ?.screenFinished('goodbye')
      .then(() => studentSignout(studentSession))
  }

  return (
    <Container>
      <PaperContainer sx={{ height: '100%', px: 2 }}>
        <Box textAlign='center'>
          <Typography variant='h2' sx={{ fontWeight: 600, mt: 3 }}>
            XtraMath Session Complete!
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontSize: { xs: '1rem', md: '1.5rem' },
              mt: 2,
              maxWidth: { xs: '100%', md: '70%' },
              mx: 'auto',
            }}
          >
            Great job on your XtraMath today! Your math is getting better each
            time. Remember to sign out before you go. See you next time!
          </Typography>
          <Box
            sx={{
              position: { xs: 'relative', md: 'absolute' },
              bottom: '0',
              width: '100%',
              textAlign: 'center',
              mt: { xs: 3, md: 0 },
            }}
            mb={-1}
          >
            <Video
              video={video}
              sx={{
                width: { xs: '100%', md: '40%' },
              }}
            />
          </Box>
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
          aria-label='Sign out'
        >
          Sign Out
        </Button>
      </Box>
    </Container>
  )
}

export default Goodbye
