import React, { useEffect, useState } from 'react'

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import Welcome from 'student/welcome'
import Goodbye from 'student/goodbye'

import QuizIntro from 'student/quizIntro'
import Quiz from 'student/quiz'
import QuizResults from 'student/quizResults'

import PracticeIntro from 'student/practiceIntro'
import Practice from 'student/practice'
import PracticeResults from 'student/practiceResults'
import { useStudentContext } from 'common/context/StudentContext'

const WEB_TITLES = {
  welcome: 'Welcome to XtraMath',
  goodbye: 'Goodbye!',
  quiz_intro: 'Quiz Intro',
  quiz_results: 'Quiz Results',
  practice_intro: 'Practice Intro',
  practice_results: 'Practice Results',
  quiz: 'Quiz Activity',
  practice: 'Practice Activity',
}

function StudentTaskRouter() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { studentSession } = useStudentContext()

  useEffect(() => {
    setLoading(true)
    if (!studentSession) return

    const { screen } = studentSession.currentState()
    const currentScreen = location.pathname.split('/').pop()
    const title = WEB_TITLES[screen]
    document.title = title

    if (screen !== currentScreen) navigate(`/app/student/${screen}`)
    setLoading(false)
  }, [location, studentSession])

  if (loading) return null

  return (
    <Routes>
      <Route
        path='/'
        element={<Navigate to='/app/student/welcome' replace />}
      />
      <Route path='/welcome' element={<Welcome />} />
      <Route path='/goodbye' element={<Goodbye />} />
      <Route path='/quiz_intro' element={<QuizIntro />} />
      <Route path='/quiz_results' element={<QuizResults />} />
      <Route path='/quiz' element={<Quiz />} />
      <Route path='/practice_intro' element={<PracticeIntro />} />
      <Route path='/practice_results' element={<PracticeResults />} />
      <Route path='/practice' element={<Practice />} />
    </Routes>
  )
}

export default StudentTaskRouter
