import _ from 'lodash'

import q from 'q'

import { assert, EmailAddress, newObject, makeRandomString } from './common'

import { ClientTeacher } from './client-teacher'
import { ClientClassroom } from './client-classroom'
import { ClientStudent } from './client-student'
import * as at from './account-types'
import * as atc from './account-types-client'

import { ClientError, persistentState } from './helpers'
import { config } from './config/config-shim'
import {
  TeacherSignup2Params,
  UserNotice,
  ClientUserNotice,
} from './web-server/api-types'
import { ItemsManager } from './items-manager'

const logger = console.log

export type TeacherSigninSucceeded = {
  session: TeacherSession
  teacher: atc.ClientTeacherInstance
  termsChanged?: boolean
  shouldFinishSignup: boolean
  title?: string
  message?: string
  userNotices?: UserNotice[]
  cCodeSelected?: string
  isAdmin?: boolean
}

// CONSTANTS

const STORAGE_KEY = 'teacherSession'
const SESSION_TIMEOUT = config.teacherSession.timeout * 1000

// GLOBAL VARIABLES

// CONSTRUCTOR

// do not call constructor directly.
// instead call class methods.
export class TeacherSession {
  static singleton: TeacherSession

  teacher: atc.ClientTeacherInstance

  timeoutId: number

  userNotices: ClientUserNotice[]

  constructor(teacher: atc.ClientTeacherInstance) {
    // this.code
    // this.timeoutId;
    // this.singleton
    this.teacher = teacher
  }

  // CLASS PROPERTIES

  // CLASS METHODS

  // returns a promise to a teacher session
  static load(forceRefresh?: boolean): q.Promise<TeacherSession> {
    const that = this
    if (that.singleton && !forceRefresh) {
      return q(that.singleton)
    }
    const state = persistentState.window.get(STORAGE_KEY, true)
    if (!state) {
      return null
    }
    return this.refreshSession(state.code, state.secret).then(
      function (session) {
        return session
      }
    )
  }

  static ensureSignedOut(): void {
    this.signout()
    persistentState.window.remove(STORAGE_KEY)
  }

  static signInWithEmailAndPassword(
    email: EmailAddress,
    password: string,
    socialId?: at.SsoId,
    socialProvider?: at.SsoProvider
  ): q.Promise<TeacherSigninSucceeded> {
    const that = this
    let passwordHash: at.AccountSecret
    if (password) {
      passwordHash = ClientTeacher.hashPassword(email, password)
    }
    const fields = {
      email,
      tSecret: passwordHash,
      socialId,
      socialProvider,
    }
    return ClientTeacher.signIn(fields).then(function (results) {
      return that.signInWithTeacher(results, false)
    })
  }

  static signout(): void {
    this.singleton && this.singleton.signout(true)
  }

  static refreshSession(
    code: at.AccountCode,
    secret: at.TeacherSecret
  ): q.Promise<TeacherSession> {
    const that = this
    return ItemsManager.get()
      .loadTeacher(newObject(code, secret), code)
      .then(function (teacher) {
        if (teacher.getSecret() !== secret) {
          teacher.updateSecret(secret)
        }
        that.singleton = new TeacherSession(teacher)
        const session = that.singleton

        return session
      })
      .fail(function (err: ClientError) {
        if (err.status === 'access-denied') {
          const navParams = { returnHome: true, popup: undefined }
          // navParams.popup = {
          //   header: errorMessages.title2('password-changed'),
          //   message: errorMessages.message2('password-changed'),
          // }
          // Screen.navigateTo('/signin/teacher_other', null, navParams)
          err.dontShow = true
          err.dontReport = true
        }
        throw err
      })
  }

  static signInWithCredentials(
    code: at.AccountCode,
    secret: at.TeacherSecret,
    socialId?: at.SsoId,
    socialProvider?: at.SsoProvider,
    admin?: boolean
  ): q.Promise<TeacherSigninSucceeded> {
    const that = this
    const fields = {
      tCode: code,
      tSecret: secret,
      admin,
      socialId,
      socialProvider,
    }
    return ClientTeacher.signIn(fields).then(function (results) {
      return that.signInWithTeacher(results, false)
    })
  }

  static signInWithTeacher(
    results: atc.TeacherSigninRvalC | atc.TeacherSignup2RvalC,
    suppressRedirection: boolean
  ): TeacherSigninSucceeded {
    const that = this
    const { teacher } = results
    if (
      this.singleton &&
      this.singleton.teacher.code !== results.teacher.code
    ) {
      logger('Starting teacher session when different session already exists.')
      this.singleton.signout()
    }
    that.singleton = new TeacherSession(teacher)
    const session = that.singleton

    session.save()
    if (results.syncId) {
      const initialState = {
        teachers: newObject(teacher.code, teacher),
        classrooms: {},
        students: {},
      }
      const initialClassroomCount = _.size(teacher.classroomCodes())
      results.teacher.pollSynchronization(results.syncId, initialState)
      results.teacher.synchronizing
        .then(function () {
          // LATER: eventually we just won't allow Clever sync for teachers without license, so remove this check.
          if (teacher.hasActiveLicense()) {
            // domH.closePopup('#customPopup')
            const changePages =
              !suppressRedirection &&
              initialClassroomCount === 0 &&
              _.size(teacher.classroomCodes()) > initialClassroomCount
            if (changePages) {
              // Screen.navigateTo(session.classReportUrl())
            }
          }
        })
        .fail(function (err) {
          logger(err)
        })
    }
    const rval = {
      ...results,
      session: that.singleton,
      isAdmin: !!Object.keys(results.teacher.adminLicenseInfos)
        .map((k) => results.teacher.adminLicenseInfos[k])
        .find(
          (l) => l.licenseType === 'school' || l.licenseType === 'district'
        ),
    }
    return rval
  }

  static signup(
    originalFields: Omit<TeacherSignup2Params, 'client'> & { password: string },
    suppressRedirection: boolean
  ): q.Promise<
    | TeacherSigninSucceeded
    | {
        teacher: atc.ClientTeacherInstance
        student?: atc.ClientStudentInstance
        syncId?: string
      }
  > {
    const that = this
    const fields = _.clone(originalFields)
    if (fields.password) {
      fields.secret = ClientTeacher.hashPassword(fields.email, fields.password)
      delete fields.password
    }
    return ClientTeacher.signup(fields).then(function (results) {
      if (results.student) {
        return results
      } // we just added a new parent during enrollment, just return the results

      return that.signInWithTeacher(results, suppressRedirection)
    })
  }

  // INSTANCE METHODS

  addNewClassroom(fields) {
    const that = this
    assert(that.teacher, 'addClassroom after signout.')
    return ClientClassroom.createInTeacher(fields, that.teacher).then(
      function (classroom) {
        // NOTE: After discussion with Erika: teacher emails are often deactivated/full
        // over the summer, resulting in bouncing email addresses that are still good for the accademic year.
        // We'll disable the bouncing flag when a teacher adds a new classroom so we can try them again.
        return classroom
      }
    )
  }

  addNewStudent(fields, classroom) {
    const that = this
    return ClientStudent.createInClassroom(fields, that.teacher, classroom)
  }

  classReportUrl(cCode?) {
    if (this.teacher.isTeacher && cCode) {
      return this.teacher.classReportUrl(cCode, config.view === 'tablet')
    }
    return this.teacher.classReportUrl(null, config.view === 'tablet')
  }

  onTimedOut = _.throttle(
    function (cleanupFn) {
      const that = this
      that.stopTimeout()
      that.timeoutId = window.setTimeout(function () {
        cleanupFn()
        that.signout()
      }, SESSION_TIMEOUT)
    },
    5000,
    { leading: true, trailing: false }
  )

  addNewChild(fields) {
    const that = this
    assert(that.teacher, 'addNewChild after signout.')
    return ClientStudent.createInParent(fields, that.teacher)
  }

  syncChildren(sCode?: at.AccountCode): q.Promise<atc.ClientAccountsMap> {
    assert(this.teacher, 'syncChildren after signout.')
    return ItemsManager.get().syncClassroomsAndChildren(this.teacher, sCode)
  }

  // returns a promise for codes to classrooms.
  syncClassrooms(cCode?: at.AccountCode): q.Promise<atc.ClientAccountsMap> {
    return ItemsManager.get().syncClassrooms(this.teacher, cCode)
  }

  // returns a promise for codes to students for the specified classroom.
  syncClassroomStudent(
    cCode: at.AccountCode,
    sCode: at.AccountCode
  ): q.Promise<atc.ClientStudentInstance> {
    return this.syncClassroomsAndStudents(cCode, sCode).then(function ({
      students,
    }) {
      if (!students[sCode]) {
        const err = new ClientError('', 'client-unexpected', 'no-student')
        throw err
      }
      return students[sCode]
    })
  }

  // returns a promise for codes to students for the specified classroom.
  syncClassroomsAndChildren(
    sCode?: at.AccountCode
  ): q.Promise<atc.ClientAccountsMap> {
    assert(this.teacher, 'syncClassroomsAndChildren after signout.')
    return ItemsManager.get().syncClassroomsAndChildren(this.teacher, sCode)
  }

  // returns a promise for codes to students for the specified classroom.
  syncClassroomsAndStudents(
    code: at.AccountCode,
    sCode?: at.AccountCode
  ): q.Promise<atc.ClientAccountsMap> {
    assert(this.teacher, 'syncClassroomsAndChildren after signout.')
    return ItemsManager.get().syncClassroomsAndStudents(
      this.teacher,
      code,
      sCode
    )
  }

  getUserNotices(): UserNotice[] | void {
    return this.userNotices
  }

  deleteStoredNotice(noticeId: string) {
    const i = _.findIndex(this.userNotices, (notice) => {
      return notice.notificationId === noticeId
    })
    this.userNotices.splice(i, 1)
  }

  storeUserNotices(notices: UserNotice[]): void {
    const clientNotices: ClientUserNotice[] = notices.map(
      (notice: UserNotice) => {
        return {
          notificationId: makeRandomString('0123456789', 12),
          continue: ' ',
          ...notice,
        }
      }
    )
    this.userNotices = (this.userNotices || []).concat(clientNotices)
  }

  save(): void {
    persistentState.window.set(STORAGE_KEY, {
      code: this.teacher.code,
      secret: this.teacher.getSecret(),
    })
    persistentState.window.cloneSessionStorage() // update teacher sessions in other windows if they exist.
  }

  signout(skipBroadcast?: boolean): void {
    // REVIEW: Either remove assertions about session modification after signout, or
    // ensure that we're cleaning up before we sign out.
    delete TeacherSession.singleton
    this.stopTimeout()
    if (!skipBroadcast) {
      persistentState.device.set('signoutTeacherSession', Date.now())
    }
    persistentState.window.remove(STORAGE_KEY)
  }

  stopTimeout(): void {
    clearTimeout(this.timeoutId)
  }
}

// PRIVATE METHODS

// HELPER FUNCTIONS
