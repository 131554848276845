import _ from 'lodash'
// LODASH IMPORTS
import q from 'q'
import { PlainObject, newObject } from './common'
import * as atc from './account-types-client'
import { ClientClassroom } from './client-classroom'
import { ClientTeacher } from './client-teacher'
import { Cache } from './cache'
import * as at from './account-types'
import { itemsSaver, persistentState } from './helpers'
import { StudentSession, StudentSigninSucceeded } from './student-session'
import { TeacherSession, TeacherSigninSucceeded } from './teacher-session'
import { ItemsManager } from './items-manager'

const logger = console.log

type CsInfoRval = {
  teachers: atc.ClientTeacherMap
  classrooms: atc.ClientClassroomMap
  emptyTeachers: atc.ClientTeacherMap
}

// CONSTANTS

// CONSTRUCTOR

export class SigninSession {
  static singleton: SigninSession

  // constructor() {}

  static load(): SigninSession {
    if (!this.singleton) {
      this.singleton = new this()
    }
    return this.singleton
  }

  // INSTANCE METHODS

  defaultSigninPage(): 'classroom' | 'student' | 'teacher' {
    if (this.hasTeachersForCS()) {
      return 'classroom'
    }
    if (this.hasStudents()) {
      return 'student'
    }
    if (this.hasTeachers()) {
      return 'teacher'
    }
    return 'student'
  }

  getStudents(): q.Promise<at.AccountCollection> {
    return q(itemsSaver.getSaved('students', 'savedStudents'))
  }

  syncStudentsOrTeachers(
    modelsToSync: Array<'teachers' | 'students'>
  ): q.Promise<{
    students: atc.ClientStudentMap
    teachers: atc.ClientTeacherMap
  }> {
    const request = _.reduce(
      modelsToSync,
      (memo, type) => {
        const storageKey = `saved${_.capitalize(type)}` as
          | 'savedStudents'
          | 'savedTeachers'
        const codeMap = itemsSaver.rememberedCodes(storageKey)
        _.forEach(codeMap, function (secret, code) {
          if (!secret) {
            logger({ type, code }, 'Removing saved item with no secret')
            itemsSaver.remove(storageKey, code)
          }
        })
        memo[type] = codeMap
        return memo
      },
      { teachers: {}, students: {}, classroomVersions: {}, teacherVersions: {} }
    )
    const update = ItemsManager.get().fetchSignins(request)
    update.promise.then(function (result) {
      _.forEach(modelsToSync, (type) => {
        const updated = result[type]
        const { errors } = result
        const storageKey = `saved${_.capitalize(type)}`
        if (errors) {
          _.forEach(errors, function (info, code) {
            if (info.status === 'not-found') {
              itemsSaver.remove(storageKey, code)
            }
          })
        }
        _.forEach(updated, function (item) {
          itemsSaver.storeCredentials(type, item)
        })
      })
    })

    if (
      _.size(update.cached.students) < 1 &&
      _.size(update.cached.teachers) < 1
    ) {
      return update.promise.then(function (synced) {
        return {
          students: synced.students || {},
          teachers: synced.teachers || {},
        }
      })
    }
    return q({
      students: update.cached.students || {},
      teachers: update.cached.teachers || {},
    })
  }

  // sCodes is object with student codes as keys. Values are ignored.
  syncStudents(): q.Promise<atc.ClientStudentCollection | {}> {
    if (this.hasStudents()) {
      return this.syncStudentsOrTeachers(['students']).then((res) => {
        return res.students
      })
    }
    return q({})
  }

  getTeacher(code: string): q.Promise<atc.ClientTeacherInstance> {
    return this.getTeachers().then(function (teachers) {
      return teachers[code]
    })
  }

  signinRememberedStudent(
    code: at.AccountCode,
    redirect
  ): q.Promise<
    StudentSigninSucceeded | { status: string; students?: at.AccountCollection }
  > {
    const that = this
    return this.getStudents().then(function (students) {
      const student = students[code]
      if (!student) {
        return that.getStudents().then(function (students) {
          return { status: 'list-changed', students }
        })
      }
      return StudentSession.rememberedSignIn(code, student.getSecret(), {
        redirect,
      }).catch(function (err) {
        if (err.status === 'canceled') {
          return { status: 'canceled', students: null }
        }
        // REVIEW: this sCode error should be an input-error, but are currently just suppressing reporting on the server
        // so we don't have to deal with old client compatibility and otherwise leaving this as-is.
        if (err.remove) {
          that.removeStudent(code)
          // errorH.handleError(err)
          return that.getStudents().then(function (students) {
            return { status: 'list-changed', students }
          })
        }
        throw err
      })
    })
  }

  signinRememberedTeacher(
    code
  ): q.Promise<
    TeacherSigninSucceeded | { status: string; teachers?: at.AccountCollection }
  > {
    const that = this
    return that.getTeacher(code).then(function (teacher) {
      if (!teacher) {
        return that.getTeachers().then((teachers) => {
          return { status: 'list-changed', teachers }
        })
      }
      return TeacherSession.signInWithCredentials(
        code,
        teacher.getSecret()
      ).catch(function (err) {
        if (err.status === 'canceled') {
          return { status: 'canceled', teachers: null }
        }
        // REVIEW: this tCode error should be an input-error, but are currently just suppressing reporting on the server
        // so we don't have to deal with old client compatibility and otherwise leaving this as-is.
        if (err.remove) {
          that.removeTeacher(code)
          // errorH.handleError(err)
          return that.getTeachers().then(function (teachers) {
            return { status: 'list-changed', teachers }
          })
        }
        throw err
      })
    })
  }

  syncTeachers(): q.Promise<atc.ClientTeacherCollection | {}> {
    if (this.hasTeachers()) {
      return this.syncStudentsOrTeachers(['teachers']).then((res) => {
        return res.teachers
      })
    }
    return q({})
  }

  getTeachers(): q.Promise<at.AccountCollection> {
    return q(itemsSaver.getSaved('teachers', 'savedTeachers'))
  }

  getClassroomSignins(): {
    teachers: atc.ClientTeacherMap
    classrooms: atc.ClientClassroomMap
    emptyTeachers: atc.ClientTeacherMap
  } {
    const teacherSignins = ItemsManager.get()
      .getCache<Cache<atc.ClientTeacherInstance>>('teacherSignins')
      .contents()
    const classroomSignins = ItemsManager.get()
      .getCache<Cache<atc.ClientClassroomInstance>>('classroomSignins')
      .contents()
    return csInfo(teacherSignins, classroomSignins)
  }

  syncClassroomSignin(code: string): atc.ClientSyncRval {
    // let that = this;
    const studentsCache =
      ItemsManager.get().getCache<Cache<atc.ClientStudentInstance>>(
        'studentSignins'
      )
    const classroomsCache =
      ItemsManager.get().getCache<Cache<atc.ClientClassroomInstance>>(
        'classroomSignins'
      )
    const result = ClientClassroom.refreshSignin(
      code,
      classroomsCache,
      studentsCache
    )
    const { cached } = result
    result.promise
      .then((asyncResult) => {
        asyncResult.callback()
      })
      .catch(function (err) {
        // REVIEW: If we get a "request-error cCode incorrect" back, then the classroom no longer exists.
        //         Doesn't our caller need to know about that?
        logger(err)
      })
    return {
      cached: { students: cached.students, classroom: cached.classroom },
      promise: result.promise,
    }
  }

  syncClassroomSignins(): {
    cached: CsInfoRval
    promise: atc.ClientSyncPromise
  } {
    const itemManager = ItemsManager.get()
    const tCodes = itemsSaver.rememberedCodes('savedTeachersForCS')
    const cachedTeachers = ItemsManager.get().getCached(
      'teacherSignins',
      tCodes
    ) as atc.ClientTeacherMap
    const teachersRequest = _.reduce(
      tCodes,
      (memo, _secret, code) => {
        const savedTeacher = cachedTeachers[code]
        if (!savedTeacher) {
          memo.versions[code] = { version: 0 }
        } else {
          memo.versions[code] = { version: savedTeacher.version }
        }
        return memo
      },
      {
        versions: {},
        cache:
          itemManager.getCache<Cache<atc.ClientTeacherInstance>>(
            'teacherSignins'
          ),
      }
    )
    const classroomsRequest = _.reduce(
      cachedTeachers,
      (memo, teacher, _tCode) => {
        const classrooms = itemManager.getCached(
          'classroomSignins',
          teacher.classroomCodes()
        )
        _.forEach(classrooms, (classroom, cCode) => {
          memo.versions[cCode] = { version: classroom ? classroom.version : 0 }
        })
        return memo
      },
      {
        versions: {},
        cache:
          itemManager.getCache<Cache<atc.ClientClassroomInstance>>(
            'classroomSignins'
          ),
      }
    )
    const result = ClientTeacher.refreshClassroomSignins(
      teachersRequest,
      classroomsRequest
    )
    const { cached } = result
    const deferred = q.defer()
    result.promise
      .then(function (asyncResult) {
        asyncResult.classrooms = ClientClassroom.omitHidden(
          asyncResult.classrooms
        )
        // REVIEW: the callback passed out of refreshClassroomSignins triggers the cache update event, but
        // is there a cleaner way to get to it AFTER we've added the classrooms?
        asyncResult.callback && asyncResult.callback()
        deferred.resolve({
          teachers: _.assign({}, cached.teachers, asyncResult.teachers),
          classrooms: asyncResult.classrooms,
        })
      })
      .catch(function (err) {
        deferred.reject(err)
      })
    return {
      cached: csInfo(cached.teachers, cached.classrooms),
      promise: deferred.promise,
    }
  }

  getStudentSigninsForCS(code: string): q.Promise<atc.ClientStudentMap> {
    return q
      .all([
        ItemsManager.get().getCache('classroomSignins').find(code),
        ItemsManager.get().getCache('studentSignins'),
      ])
      .spread(function (classroom, studentCache) {
        const sCodes = classroom.studentCodes()
        const students = _.mapValues(sCodes, function (_secret, code) {
          return studentCache.find(code)
        })
        return students
      })
  }

  getTeachersForCS(
    getIpTeachers: boolean
  ): q.Promise<{ teachers: atc.ClientTeacherCollection; ipIsSaved: boolean }> {
    const that = this
    const teachersPromise = q().then(() => {
      const saved = itemsSaver.getSaved('teacherSignins', 'savedTeachersForCS')
      if (_.size(saved) > 0) {
        return q(saved)
      }
      return that.syncClassroomSignins().promise.then(function (rval) {
        // teachers haven't yet been cached, fetch them
        return rval.teachers
      })
    })
    const ipTeachersPromise = getIpTeachers
      ? ClientTeacher.getIpAuthorizations()
      : q({})
    return q
      .all([teachersPromise, ipTeachersPromise])
      .spread(function (teachers, ipResults) {
        _.forEach(teachers, function (teacher, _key) {
          teacher.storedLocally = true
        })
        const ipIsSaved = _.size(ipResults.teachers) > 0 || ipResults.removal
        return { teachers: _.defaults(teachers, ipResults.teachers), ipIsSaved }
      })
  }

  addStudent(student: atc.ClientStudentInstance, save?: boolean): void {
    if (save) {
      itemsSaver.storeCredentials('savedStudents', student)
    }
  }

  hasStudents(): boolean {
    const rval = !_.isEmpty(persistentState.device.get('savedStudents'))
    return rval
  }

  hasStudent(code: string): boolean {
    const students = persistentState.device.get('savedStudents')
    return !!(students && students[code])
  }

  removeStudent(code: string): void {
    // note: object is still cached (and later watched)
    itemsSaver.remove('students', code)
    persistentState.device.update('savedStudents', newObject(code, null))
  }

  addTeacher(teacher: atc.ClientTeacherInstance, save?: boolean): void {
    if (save) {
      itemsSaver.storeCredentials('savedTeachers', teacher)
    }
  }

  hasTeacher(code: string): boolean {
    const teachers = persistentState.device.get('savedTeachers')
    return !!(teachers && teachers[code])
  }

  hasTeachers(): boolean {
    const rval = !_.isEmpty(persistentState.device.get('savedTeachers'))
    return rval
  }

  removeTeacher(code: string): void {
    itemsSaver.remove('teachers', code)
    persistentState.device.update('savedTeachers', newObject(code, null))
  }

  addTeacherForCS(teacherData: PlainObject): void {
    const teacher = ClientTeacher.signinCreate(teacherData)
    itemsSaver.storeTeacherSigninForCS(teacher)
  }

  hasTeacherForCS(code: string): boolean {
    return !!itemsSaver.getSaved('teacherSignins', 'savedTeachersForCS')[code]
  }

  hasTeachersForCS(): boolean {
    const teacherSignins = ItemsManager.get()
      .getCache('teacherSignins')
      .contents()
    return !_.isEmpty(teacherSignins)
  }

  removeTeacherForCS(code: string): void {
    // note: object is still cached (and later watched)
    itemsSaver.remove('teacherSignins', code)
    persistentState.device.update('savedTeachersForCS', newObject(code, null))
  }

  removeAllTeachersForCS(): void {
    const that = this
    const keys = _.keys(
      itemsSaver.getSaved('teacherSignins', 'savedTeachersForCS')
    )
    _.forEach(keys, function (code) {
      that.removeTeacherForCS(code)
    })
  }
}

// HELPERS

function csInfo(
  teacherSignins: atc.ClientTeacherMap,
  classroomSignins: atc.ClientClassroomMap
): CsInfoRval {
  const emptyTeachers: atc.ClientTeacherMap = {}
  const teachers: atc.ClientTeacherMap = _.reduce(
    teacherSignins,
    function (memo, teacher, code) {
      if (_.size(classroomSignins) === 0) {
        if (_.size(teacher.classroomCodes()) === 0) {
          emptyTeachers[code] = teacher
        } else {
          memo[code] = teacher
        }
      } else {
        const teachersClassrooms = _.reduce(
          teacher.classroomCodes(),
          function (memo, _secret, code) {
            const classroom = classroomSignins[code.toString()]
            if (classroom) {
              memo[code.toString()] = classroom
            }
            return memo
          },
          {}
        )
        const classrooms: atc.ClientClassroomMap =
          ClientClassroom.omitHidden(teachersClassrooms)
        if (_.size(classrooms) === 0) {
          emptyTeachers[code] = teacher
        } else {
          memo[code] = teacher
        }
      }
      return memo
    },
    {}
  )
  return {
    teachers,
    classrooms: ClientClassroom.omitHidden(classroomSignins),
    emptyTeachers,
  }
}
