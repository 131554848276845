import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useStudentContext } from 'common/context/StudentContext'
import { Practice } from 'common/utils/types/practice'
import Video from 'student/common/video'

import ThumbsUp from 'assets/student/thumbs_up.svg'

import { PaperContainer } from 'student/common/taskUI'
import EarnedAwards from 'student/common/earnedAwards'

const VIDEOS = {
  0: '0028',
  1: '0030',
  2: '0032',
}

const VIDEO_CATEGORY = 'numPracticeR'

function PracticeResults() {
  const theme = useTheme()
  const { studentSession } = useStudentContext()
  const [opName, setOpName] = useState<string>('')
  const [video, setVideo] = useState<string>('')
  const [longestStreak, setLongestStreak] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (!studentSession) return
    const { activity } = studentSession.currentState()

    const practice = activity as Practice
    setLongestStreak(practice.longestStreak)

    const operationName = _.capitalize(activity.operation().mathOperationKey)
    setOpName(operationName)

    const videoSrc = VIDEOS[studentSession.student.getVideo(VIDEO_CATEGORY)]
    setVideo(videoSrc)
  }, [studentSession])

  const handleContinue = () => {
    Sentry.addBreadcrumb({
      category: 'breadcrumb',
      message: 'Clicked on continue in practice results',
      level: 'info',
      data: {
        student: studentSession?.student,
        activity: studentSession.currentState().activity,
      },
    })
    if (video) studentSession.student.watchedVideo(VIDEO_CATEGORY)
    studentSession.screenFinished('practice_results').then(() => {
      const { screen } = studentSession.currentState()
      navigate(`/app/student/${screen}`)
    })
  }

  return (
    <Container>
      <EarnedAwards />
      <PaperContainer sx={{ px: 2, py: { xs: 2, md: 0 } }}>
        <Box py={2}>
          <Typography variant='h3' sx={{ fontWeight: 600 }}>
            Practice: Results
          </Typography>
          <Typography
            variant='subtitle1'
            sx={{
              fontSize: { xs: '1rem', md: '1.5rem' },
              my: 2,
            }}
          >
            Good job finishing your <b>{opName}</b> practice! Check out the
            board to see your streak, which shows how many correct answers
            you&apos;ve gotten one after the other.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'end', mt: 'auto' }}>
          <Box
            flex='0 0 50%'
            mb={-1}
            textAlign='center'
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Video video={video} />
          </Box>
          <Box
            sx={{ flex: { xs: '1', md: '0 0 50%' } }}
            my='auto'
            height='100%'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              sx={{
                width: { xs: '100%', md: '450px' },
                height: { xs: 'auto', md: '300px' },
                borderRadius: '10px',
                p: 3,
                border: `11px solid ${theme.palette.secondary.main}`,
                mx: 'auto',
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  fontSize: { xs: '1.7rem', md: '3.5rem' },
                  textDecoration: 'underline',
                  fontFamily: "'Nothing You Could Do', cursive",
                }}
              >
                Your results
              </Typography>
              <Box
                sx={{
                  mt: 4,
                  fontSize: { xs: '1.7rem', md: '3rem' },
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: "'Nothing You Could Do', cursive",
                }}
              >
                Streak: {longestStreak}
                <Box
                  component='img'
                  alt='Icon for Thumbs Up'
                  src={ThumbsUp}
                  height={40}
                  width={40}
                  ml={1}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </PaperContainer>
      <Box textAlign='center'>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          sx={{ mt: 3 }}
          onClick={handleContinue}
          aria-label='Continue'
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default PracticeResults
