// LODASH IMPORTS
import _ from 'lodash'

import q from 'q'

import { PlainObject } from '../common'
import * as qe from '../extensions/q'
import { config } from '../config/config-shim'
import { SoundName, PlaybackOptions } from './audio-player'

const logger = console.log

export class WebAudio {
  private context: AudioContext

  private soundMap: PlainObject

  private buffers: PlainObject

  // CONSTRUCTOR

  constructor(map) {
    const BaseContext = window.AudioContext || window.webkitAudioContext
    if (!BaseContext) {
      if (config.useSFX) {
        logger(
          'Attempted to instantiate web audio on non-supported platform. Disabling'
        )
      }
      config.useSFX = false
    }
    this.context = config.useSFX && new BaseContext()
    this.buffers = {}
    this.soundMap = map
  }

  // CLASS METHODS

  // INSTANCE METHODS

  precacheStudentSounds(): q.Promise<unknown> | false {
    if (!config.useSFX) {
      return false
    }
    const that = this
    const soundNames = _.keys(_.omit(this.soundMap, 'app-load'))
    return qe.forEach(soundNames, function (name) {
      if (that.buffers[name]) {
        return undefined
      }
      return that.getBuffer(name).then(function (buffer) {
        that.saveBuffer(buffer, name)
      })
    })
  }

  emptyCache(): unknown {
    if (!config.useSFX) {
      return false
    }
    const that = this
    return _.forEach(_.keys(this.soundMap), function (name) {
      delete that.buffers[name]
    })
  }

  initialize(): q.Promise<void> {
    return q()
  }

  playSound(sound: SoundName, options?: PlaybackOptions) {
    if (!config.useSFX) {
      return false
    }
    const that = this
    const cache = !(options && options.dontCache)
    const delay = (options && options.delay) || 0
    return q(that.getCachedSound(sound, cache)).then(function (buffer) {
      logger('Playing %s in %d ms', sound, delay)
      setTimeout(function () {
        that.playBuffer(buffer)
      }, delay)
    })
  }

  // PRIVATE METHODS

  private getBuffer(soundName: SoundName): q.Promise<AudioBuffer> {
    const url = config.resourceLocation + this.soundMap[soundName]
    const deferred = q.defer<AudioBuffer>()
    if (!url) {
      logger('Skipping fetch for unknown sound: %s', soundName)
      deferred.reject()
    } else {
      const req = new XMLHttpRequest()
      req.open('GET', url, true)
      req.responseType = 'arraybuffer'
      const that = this
      req.onload = function () {
        that.context.decodeAudioData(
          req.response,
          function (buffer) {
            deferred.resolve(buffer)
          },
          function (err) {
            deferred.reject(err)
          }
        )
      }
      req.send()
    }
    return deferred.promise
  }

  private getCachedSound(
    sound: SoundName,
    cache: boolean
  ): q.Promise<AudioBuffer> {
    const that = this
    const buffer = that.buffers[sound]
    if (buffer) {
      return buffer
    }
    logger('Fetching uncached sound: %s', sound)
    return that.getBuffer(sound).then(function (buffer: AudioBuffer) {
      if (cache) {
        that.saveBuffer(buffer, sound)
      }
      return buffer
    })
  }

  private playBuffer(buffer: AudioBuffer): void {
    const source = this.context.createBufferSource()
    source.buffer = buffer
    source.connect(this.context.destination)
    source.start(0)
  }

  private saveBuffer(buffer: AudioBuffer, key: SoundName): void {
    this.buffers[key] = buffer
  }
}

// HELPER FUNCTIONS
