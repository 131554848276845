import { PlainObject } from '../common'

export class ClientError extends Error {
  info: { [key: string]: string | boolean | number | PlainObject }

  dontReport?: boolean

  dontShow?: boolean

  status?: string

  reason: string

  retry?: string

  timeout?: string

  title?: string

  errors?: {
    [status: string]: {
      status?: string
      title?: string
      message?: string
      value?: string
    }
  }

  constructor(m: string, status: string, reason: string, info?: PlainObject) {
    super(m)
    this.status = status
    this.reason = reason
    this.info = info || {}
  }
}
