/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
// REQUIREMENTS

import _ from 'lodash'
import dayjs from 'dayjs'
import inheritsLib from 'inherits' // lighter weight than util.inherits for browser.
// import * as sprintLib from 'sprint'; // lighter weight than util.format for browser.

// TYPES

export type EmailAddress = string
export type FileDescriptor = number
export type FilePath = string
export type IpAddress = string
export type JsonString = string
export type Locale = string
export type Milliseconds = number // Interval in milliseconds
export type PaperSize = 'letter' | 'a4'
export type Seconds = number // Interval in seconds.
export type StackTrace = string
export type Timestamp = number // Milliseconds since Unix epoch
export type TimestampSec = number // Seconds since Unix epoch
export type Timezone = string
export type TimezoneOffset = number // offset is IANA, in minutes
export type Uri = string
export type YyyyMmDd = string // string in YYYY-MM-DD format

export interface PlainObject {
  [key: string]: any
}

// CONSTANTS

// NODE_ENV values considered "production"
const PRODUCTION_ENVIRONMENTS = {
  production: true,
  xtramath: true,
  xtrabeta: true,
}

// Constant identifiers are all caps.
const CONSTANT_IDENTIFIER_RE = /^[A-Z0-9_]+$/ // all uppercase

// Unicode consortium's line boundaries:
// http://www.unicode.org/reports/tr18/#Line_Boundaries
const LINE_BOUNDARIES = /\r\n|[\n\v\f\r\x85\u2028\u2029]/

// used by formatTimeInterval:
const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = 60 * 60
const SECONDS_PER_DAY = 24 * 60 * 60

// for invertedTimestamp
const MAX_TIMESTAMP = 9 * 10 ** 12 // Wednesday, March 14, 2255 4:00:00 PM
const CEILING_TIMESTAMP = 10 * 10 ** 12 // Saturday, November 20, 2286 5:46:40 PM

// PUBLIC FUNCTIONS

export function assert(val: any, format?: string, ...formatArgs: any[]): void {
  if (!val) {
    // let sprintArgs = [ format, ...formatArgs ];
    // let msg = sprint.apply(sprintLib, sprintArgs);
    throw new Error(`ASSERTION FAILED: ${format}`)
  }
}

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// REVIEW: Perhaps this belongs in the accounts library.
// applyDelta is like extend, except if a value is exactly null then
// it is deleted from the target. We use null rather than, say, undefined,
// because it survives JSON serialization.
export function applyDelta<T>(obj: T, ...deltas: PlainObject[]): T {
  _.forEach(deltas, function (delta) {
    if (!delta) {
      return
    }
    _.forEach(delta, function (val, key) {
      if (val === null) {
        delete obj[key]
      } else {
        obj[key] = val
      }
    })
  })
  return obj
}

export function decodeBase64FromUrl(str: string): string {
  // from https://jsfiddle.net/magikMaker/7bjaT/;
  // decodes url-friendly base64 url:
  // * '-' and '_' are replaced with '+' and '/' and also it is padded with '='
  // Modified because padding calculation from that fiddle is incorrect.
  // encoded base64 strings will be padded to a multiple of 4
  // https://stackoverflow.com/questions/4080988/why-does-base64-encoding-require-padding-if-the-input-length-is-not-divisible-by/18518605#18518605
  // len%4 == 0 -> 0
  // len%4 == 1 -> 3
  // len%4 == 2 -> 2
  // len%4 == 2 -> 1
  const padding = str.length % 4 && 4 - (str.length % 4)
  str = `${str}===`.slice(0, str.length + padding)
  return str.replace(/-/g, '+').replace(/_/g, '/')
}

export function encodeBase64ForUrl(str: string): string {
  // from https://jsfiddle.net/magikMaker/7bjaT/
  // Makes base64 url friendly by replacing reserved characters and removing "=" padding.
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

// If val is an array then return val.
// If val is null or undefined then return an empty array.
// Otherwise, return a singleton array containg the value.
// TYPESCRIPT: Stronger type?
export function ensureArray(val: any): any[] {
  switch (typeOf(val)) {
    case 'array':
      return val
    case 'null':
      return []
    case 'undefined':
      return []
    default:
      return [val]
  }
}

export function environment(): string {
  // TODO: browser version?
  if (isBrowser()) {
    // TODO:
    throw new Error('xm-environment not yet implemented for browser.')
  } else {
    return process.env.NODE_ENV || 'development'
  }
}

// http://jehiah.cz/a/guide-to-escape-sequences
export function escapeHtml(s: string): string {
  return s
    .replace('&', '&amp;')
    .replace('"', '&quot;')
    .replace("'", '&#39;')
    .replace('>', '&gt;')
    .replace('<', '&lt;')
}

// TYPESCRIPT: Eliminate this function by converting to ES6 classes.
export function inherits(subclass, superclass) {
  inheritsLib(subclass, superclass)
  // LATER: would prefer to set up the prototype chain for class "objects"
  // like this, but __proto__ is deprecated: subclass.__proto__ = superclass;
  for (const key in superclass) {
    if (key === 'super_') {
      continue
    }
    const value = superclass[key]
    // Copy functions and constants to the subclass.
    // We could copy by-reference objects and arrays, too, but if the whole object were replaced
    // then the base class and subclass would be out of sync.
    if (typeof value === 'function' || CONSTANT_IDENTIFIER_RE.test(key)) {
      subclass[key] = value
    }
  }
}

export function formatTimeInterval(seconds: number) {
  seconds = Math.round(seconds)
  const days = Math.floor(seconds / SECONDS_PER_DAY)
  seconds %= SECONDS_PER_DAY
  const hours = Math.floor(seconds / SECONDS_PER_HOUR)
  seconds %= SECONDS_PER_HOUR
  const minutes = Math.floor(seconds / SECONDS_PER_MINUTE)
  seconds %= SECONDS_PER_MINUTE
  return dayjs()
    .startOf('day')
    .add(days, 'day')
    .add(hours, 'hour')
    .add(minutes, 'minute')
    .add(seconds, 'second')
    .format('H:mm:ss')
  // if (days!==0) { return sprint("%dd %dh %dm %ds", days, hours, minutes, seconds); }
  // else if (hours!==0) { return sprint("%dh %dm %ds", hours, minutes, seconds); }
  // else if (minutes!==0) { return sprint("%dm %ds", minutes, seconds); }
  // else { return sprint("%ds", seconds); }
}

export function invertedTimestamp(t?: Timestamp): string {
  // An "inverted timestamp" is a 13-character string represention of a timestamp, where later
  // timestamps sort lexicographically before earlier timestamps.
  // Its useful if you want to keep things in reverse chronological order.
  // (e.g. S3 keys where if you enumerate the keys you will get the newest entries first.)
  if (!t) {
    t = Date.now()
  }
  // After MAX_TIMESTAMP the inverted timestamp will not be 13 characters. Need to zero pad.
  // Won't be an issue until year 2255.
  assert(t < MAX_TIMESTAMP)
  return (CEILING_TIMESTAMP - t).toString()
}

export function isBrowser(): boolean {
  return typeof window !== 'undefined'
}

export function isProduction(): boolean {
  return PRODUCTION_ENVIRONMENTS.hasOwnProperty(environment())
}

export function endOfSchoolYear(): Timestamp {
  const d = new Date()
  const currentMonth = d.getMonth()
  let year = d.getFullYear()
  if (currentMonth >= 6) {
    year += 1
  }
  return new Date(year, 6, 1).getTime()
}

export function startOfSchoolYear(): Timestamp {
  const d = new Date()
  const currentMonth = d.getMonth()
  let year = d.getFullYear()
  if (currentMonth < 6) {
    year -= 1
  }
  return new Date(year, 6, 1).getTime()
}

export function makeRandomString(chars: string, length: number): string {
  let rval = ''
  for (let i = 0; i < length; i++) {
    rval += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return rval
}

export function mixin(subclass: any, mixinClass: any) {
  // copy class methods
  let key
  let value
  for (key in mixinClass) {
    if (mixinClass.hasOwnProperty(key)) {
      value = mixinClass[key]
      if (key !== 'super_' && typeof value === 'function') {
        subclass[key] = value
      }
    }
  }

  // copy instance methods
  for (key in mixinClass.prototype) {
    if (mixinClass.prototype.hasOwnProperty(key)) {
      value = mixinClass.prototype[key]
      if (key !== 'super_' && typeof value === 'function') {
        subclass.prototype[key] = value
      }
    }
  }
}

// e.g. nestedObject(a, b, c, d) returns object { a: { b: { c: d }}}
// TYPESCRIPT: First n-1 parameters must be strings, last parameter can be any.
export function nestedObject(...args: any[]): PlainObject {
  if (args.length < 2) {
    throw new Error('Must have at least 2 arguments.')
  }
  if (args.length === 2) {
    return newObject(args[0], args[1])
  }
  const rest = Array.prototype.slice.call(args, 1)
  return newObject(args[0], nestedObject(...rest))
}

// code must be specified.
// message may be omitted.
// details may be omitted independently of message. If specified it must be an object.
// TYPESCRIPT: Don't do the parameter shuffling. If caller wants to omit the message
//             but include details, then explicitly pass undefined for the message.
export function newError(
  code: string,
  message?: string | PlainObject,
  details?: PlainObject
) {
  if (typeOf(message) === 'object') {
    details = <PlainObject>message
    message = undefined
  }
  if (details && (_.has(details, 'code') || _.has(details, 'message'))) {
    throw new Error('newError: details may not contain code or message.')
  }
  const err = new Error(<string | undefined>message || code) as any
  err.code = code
  _.assign(err, details)
  return err
}

// TYPESCRIPT: How do we declare that every other parameter must be a string?
//             Probably better to eliminate or create a more type-safe version.
export function newObject(...args: any[]): PlainObject {
  const rval: PlainObject = {}
  for (let i = 0; i < args.length; i += 2) {
    rval[args[i]] = args[i + 1]
  }
  return rval
}

export function parseQueryArguments(url: string): PlainObject {
  let rval: PlainObject = {}
  const splitUrl = url.split(/\?(.+)?/)
  if (splitUrl.length > 1) {
    if (!splitUrl[1]) {
      // there's nothing following the "?".
      return rval
    }
    const paramsToProcess = splitUrl[1].split('?')
    const splitParams = paramsToProcess[0].split('&')
    rval = _.reduce(
      splitParams,
      function (memo, param, i: number) {
        const splitParam = param.split(/=(.+)?/)
        if (i === splitParams.length - 1) {
          const tail = paramsToProcess[1] ? `?${paramsToProcess[1]}` : ''
          memo[decodeURIComponent(splitParam[0])] = decodeURIComponent(
            splitParam[1] + tail
          )
        } else {
          memo[decodeURIComponent(splitParam[0])] = decodeURIComponent(
            splitParam[1]
          )
        }
        return memo
      },
      rval
    )
  }
  return rval
}

export function plainObjectFromError(err: any): PlainObject {
  const rval: any = {
    message: err.message,
    stack: err.stack,
  }
  for (const prop in err) {
    if (err.hasOwnProperty(prop) && typeof err[prop] !== 'function') {
      rval[prop] = err[prop]
    }
  }
  return rval
}

export function splitIntoLines(text: string): string[] {
  return text.split(LINE_BOUNDARIES)
}

export function stackTrace(): StackTrace {
  let rval: string
  try {
    throw new Error('StackTrace')
  } catch (err: any) {
    rval = err.stack
  }
  return rval
}

export function timestampFromInvertedTimestamp(it: string): Timestamp {
  return CEILING_TIMESTAMP - parseInt(it, 10)
}

// from: http://javascript.crockford.com/remedial.html.
export function typeOf(value: any): string {
  let s: string = typeof value
  if (s === 'object') {
    if (value) {
      if (value instanceof Array) {
        s = 'array'
      } else if (value instanceof RegExp) {
        s = 'regexp'
      }
    } else {
      s = 'null'
    }
  }
  return s
}

// inverse of lodash's _.keyBy.
// takes a collection of keys and a function.
// creates an object with those keys mapping to the results of calling the function on that key.
// this is typically used for converting an array of keys to an object with those keys.
// note that if you pass in an object for the collection then the keys of the returned object
// are going to come from the *values* of the collection object, not the keys of the collection object.
// for that, use mapValues instead.
export function valueBy(collection: any, fn: Function, initial: any) {
  return _.reduce(
    collection,
    function (memo, value, indexOrKey, collection) {
      memo[value] = fn(value, indexOrKey, collection)
      return memo
    },
    initial || {}
  )
}

export function zeroFill(n: number, width: number, base?: number): string {
  return _.padStart(n.toString(base || 10), width, '0')
}
