// REQUIREMENTS

import _ from 'lodash'

import q from 'q'
import { PlainObject, inherits, mixin, newObject } from './common'

import {
  // ClassroomSigninAspect,
  ClassroomItem,
  // CreateClassroomItem,
  AccountCode,
  AccountSecret,
  Delta,
} from './account-types'
import {
  ClientClassroomClass,
  CreateClassroomFields,
  ClientTeacherInstance,
  ClientClassroomMap,
  ClientStudentMap,
  ClientSyncRval,
  ClientClassroomInstance,
  ClientStudentInstance,
} from './account-types-client'
import {
  CreateClassroomParams,
  RefreshClassroomSigninParams,
  ReadClassroomParams,
  UpdateChecklistParams,
  EditClassroomParams,
  RemoveClassroomParams,
  GetSelectedStudentsParams,
  SelectStudentParams,
} from './web-server/api-types'
import { Cache } from './cache'
import { ClientMixin } from './client-mixin'
import { Classroom } from './classroom'
import { ClientStudent } from './client-student'

// EXPORTS

export const ClientClassroom: ClientClassroomClass =
  ClientClassroomConstructor as any

// CONSTANTS

const SELECTION_TIMEOUT = 4 * 60 * 60 * 1000

// CONSTRUCTOR

const superclass = Classroom
function ClientClassroomConstructor(item: ClassroomItem) {
  superclass.call(this, item)
  ClientMixin.call(this, item)
}
// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_.rE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: ClientClassroomClass = <
  { (item: ClassroomItem): void } & ClientClassroomClass
>ClientClassroomConstructor
ClientMixin.referenceDerivedClass('classrooms', constructor)
inherits(constructor, superclass)
mixin(constructor, ClientMixin)
const proto: ClientClassroomInstance = constructor.prototype

// CLASS CONSTANTS

constructor.signinFieldsRE = /^(code|name|expires|hidden|version)$|^S|^T|^D/
constructor.secretFieldsRE = /^S/
constructor.typePlural = 'classrooms'

// CLASS PROPERTIES

// CLASS METHODS

// OVERRIDES ClientMixin.create
constructor.create = function (
  this: ClientClassroomClass,
  item: any,
  cache?: Cache<ClientClassroomInstance>,
  notSynced?: boolean
): any {
  item = _.assign({}, item)
  return ClientMixin.create.call(this, item, cache, notSynced)
}

constructor.localCreate = function (
  this: ClientClassroomClass,
  item: any
): any {
  return ClientMixin.create.call(this, item, this.caches.classrooms)
}

constructor.signinCreate = function (
  this: ClientClassroomClass,
  item: any
): any {
  item = this.filterSigninFields(item)
  return ClientMixin.create.call(this, item, this.caches.classroomSignins)
}

constructor.createInTeacher = function (
  this: ClientClassroomClass,
  fields: CreateClassroomFields,
  teacher: ClientTeacherInstance
): q.Promise<ClientClassroomInstance> {
  const that = this
  const req = _.assign(
    {
      tCode: teacher.code,
      tSecret: teacher.getSecret(),
      tVersion: teacher.version,
    },
    fields
  )
  return that.connection
    .emitToServer<CreateClassroomParams>(
      'teacher/create-classroom',
      req,
      'blocking'
    )
    .then(function (results) {
      teacher.applyResult(results)
      return that.create(results.classroom, that.caches.classrooms)
    })
}

constructor.omitExpired = function (
  this: ClientClassroomClass,
  classrooms: ClientClassroomMap
): ClientClassroomMap {
  // omits *any* classroom that shouldn't be rendered.
  return _.omitBy(classrooms, function (classroom) {
    return classroom.isExpired()
  })
}

constructor.omitHidden = function (
  this: ClientClassroomClass,
  classrooms: ClientClassroomMap
): ClientClassroomMap {
  // omits *any* classroom that shouldn't be rendered.
  return _.omitBy(classrooms, function (classroom) {
    return classroom.isExpired() || !!classroom.hidden
  })
}

constructor.pickHidden = function (
  this: ClientClassroomClass,
  classrooms: ClientClassroomMap
): ClientClassroomMap {
  // omits *any* classroom that shouldn't be rendered.
  return _.omitBy(classrooms, function (classroom) {
    return !classroom.hidden
  })
}

// constructor.getClassroomWithStudentData = function(this: ClientClassroomClass, codes: CodeCollection, cache?: Cache<ClientClassroomInstance>, offline?): ClientClassroomMap {
//   let that = this;
//   // REVIEW: Why isn't "missing" used?
//   let missing = [];
//   if (!offline) {
//     // TODO: get data from changes/classroom2_signin and use as initial rval
//   }
//   let rval = _.reduce(codes, function(memo, code){
//     let account = that.findInCache(code, cache);
//     if (account) {  memo[account.code] = account; }
//     else { missing.push(code); }
//     return memo;
//   }, {});
//   return rval;
// };

constructor.instantiate = function (
  this: ClientClassroomClass,
  item: any,
  cache?: Cache<ClientClassroomInstance>,
  notSynced?: boolean
): any {
  return superclass.instantiate.call(this, item, cache, notSynced)
}

constructor.readForShare = function (
  this: ClientClassroomClass,
  cCode: AccountCode,
  cSecret: AccountSecret
): q.Promise<ClientClassroomInstance> {
  const that = this
  const req = { classroomCredentials: newObject(cCode, cSecret) }
  return that.connection
    .emitToServer<ReadClassroomParams>('classroom/read', req, 'nonblocking')
    .then(function (result) {
      return that.create(result.classrooms[cCode])
    })
}

constructor.refreshSignin = function (
  this: ClientClassroomClass,
  code: AccountCode,
  classroomCache: Cache<ClientClassroomInstance>,
  studentCache: Cache<ClientStudentInstance>
): ClientSyncRval {
  const that = this
  const cachedClassroom = classroomCache.find(code)
  const cachedStudents = {}
  const studentVersions = cachedClassroom
    ? _.mapValues(cachedClassroom.studentCodes(), (secret, code) => {
        const student = studentCache.find(code)
        if (student) {
          cachedStudents[code] = student
        }
        return { version: 0, secret }
      })
    : {} // force getting new version because we don't update version with 'busy', 'absent' attributes.
  const deferred = q.defer()

  const req = {
    cVersion: cachedClassroom ? cachedClassroom.version : 0,
    studentVersions,
    cCode: code,
  }
  that.connection
    .emitToServer<RefreshClassroomSigninParams>(
      'classroom/refresh-signin',
      req,
      'nonblocking'
    )
    .then(function (result) {
      const rval = {
        students: result.students
          ? _.mapValues(result.students, function (data) {
              return ClientStudent.signinCreate(data)
            })
          : {},
        classroom: _.isEmpty(result.classroom)
          ? cachedClassroom
          : that.signinCreate(result.classroom), // NOTE: matching classroom version means server returns {}, so just return cached state.
        callback: that.callbacks.cacheCallback,
      }
      deferred.resolve(rval)
    })
    .catch(function (err) {
      deferred.reject(err)
    })
  return {
    cached: { classroom: cachedClassroom, students: cachedStudents },
    promise: deferred.promise,
  }
}

// INSTANCE METHODS

proto.updateChecklist = function (
  this: ClientClassroomInstance,
  item: string,
  val?: boolean
): q.Promise<ClientClassroomInstance | void> {
  // works like most form submits, but ignores errors and doesn't wait
  // for callback.
  const that = this
  val = val === undefined ? true : val
  const coerced = !!this[item]
  if (val === coerced) {
    return q()
  }
  const args = {
    cCode: this.code,
    cSecret: this.getSecret(),
    cVersion: this.version,
  }
  args[item] = val
  const delta = that.generateDelta(newObject(item, val))
  return (<ClientClassroomClass>that.constructor).connection
    .emitToServer<UpdateChecklistParams>(
      'classroom/update-checklist',
      args,
      'nonblocking'
    )
    .then(function () {
      that.applyDelta(delta)
    })
}

proto.edit = function (
  this: ClientClassroomInstance,
  fields: PlainObject,
  teacher: ClientTeacherInstance
): q.Promise<void> {
  const that = this
  const delta = this.generateDelta(fields)
  if (!delta) {
    return q()
  }
  const args = _.assign(
    {
      tCode: teacher.code,
      tSecret: teacher.getSecret(),
      cCode: that.code,
      cVersion: that.version,
    },
    delta
  )
  return (<ClientClassroomClass>that.constructor).connection
    .emitToServer<EditClassroomParams>('classroom/edit', args, 'blocking')
    .then(function (result) {
      that.applyResult(result)
      return result
    })
}

proto.hide = function (
  this: ClientClassroomInstance,
  teacher: ClientTeacherInstance
): q.Promise<void> {
  return this.changeVisibility(teacher, false)
}

proto.show = function (
  this: ClientClassroomInstance,
  teacher: ClientTeacherInstance
): q.Promise<void> {
  return this.changeVisibility(teacher, true)
}

// PRIVATE: this just exists to reduce duplication in proto.show and proto.hide. It shouldn't be called from outside this context.
proto.changeVisibility = function (
  this: ClientClassroomInstance,
  teacher: ClientTeacherInstance,
  shown: boolean
): q.Promise<void> {
  const that = this
  const fields = {
    hidden: !shown,
    cCode: that.code,
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
    cVersion: that.version,
  }
  return (<ClientClassroomClass>that.constructor).connection
    .emitToServer<EditClassroomParams>('classroom/edit', fields)
    .then(function (results) {
      that.applyResult(results)
      return results
    })
}

// proto.syncStudents = function(this: ClientClassroomInstance, refresh: boolean, sCode?: AccountCode): q.Promise<ClientAccountsMap> {
//   let results = ClientClassroom.syncItems({ students: this.studentCodes() }, undefined, refresh);
//   if (results.cached.students && results.cached.students[sCode]) {
//     return q(results.cached);
//   } else {
//     return results.promise;
//   }
// };

proto.removeFromTeacher = function (
  this: ClientClassroomInstance,
  teacher: ClientTeacherInstance
): q.Promise<void> {
  const that = this
  const fields = {
    cCode: that.code,
    tCode: teacher.code,
    tSecret: teacher.getSecret(),
    tVersion: teacher.version,
  }
  return (<ClientClassroomClass>that.constructor).connection
    .emitToServer<RemoveClassroomParams>(
      'teacher/remove-classroom',
      fields,
      'blocking'
    )
    .then(function (results) {
      // NOTE: we currently don't have a classroomDelta, but will need one for offline
      // operation. Asana task: https://app.asana.com/0/98084285153221/111089789544059
      teacher.applyResult(results)
      that.applyResult(results)
      return results
    })
}

proto.selectedStudents = function (
  this: ClientClassroomInstance,
  myDeviceCode: string
): q.Promise<ClientStudentMap> {
  const that = this
  const fields = { cCode: that.code }
  return (<ClientClassroomClass>that.constructor).connection
    .emitToServer<GetSelectedStudentsParams>(
      'classroom/get-selected-students',
      fields,
      'nonblocking'
    )
    .then(function (result) {
      return _.reduce(
        result.devices,
        function (memo, info, deviceCode) {
          if (
            info &&
            deviceCode !== myDeviceCode &&
            result.serverTime - info.timestamp < SELECTION_TIMEOUT
          ) {
            memo[info.code] = deviceCode
          }
          return memo
        },
        {}
      )
    })
}

proto.selectStudent = function (
  this: ClientClassroomInstance,
  dCode: string,
  sCode?: AccountCode
): q.Promise<void> {
  const fields = {
    cCode: this.code,
    dCode,
    sCode: undefined,
  }
  if (sCode) {
    fields.sCode = sCode
  }
  const constructor = this.constructor as ClientClassroomClass
  return constructor.connection.emitToServer<SelectStudentParams>(
    'classroom/select-student',
    fields,
    'nonblocking'
  )
}

proto.isEmpty = function (this: ClientClassroomInstance): boolean {
  return _.isEmpty(this.studentCodes())
}

proto.applyResult = function (
  this: ClientClassroomInstance,
  result: { classroom?: ClassroomItem; classroomDelta: Delta }
): void {
  if (result.classroom) {
    this.transform(result.classroom)
  } else {
    this.applyDelta(result.classroomDelta)
  }
}
