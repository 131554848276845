/* eslint-disable no-restricted-syntax */
import * as _ from 'lodash'
import { assert } from './common'
import {
  Activity,
  ActivityInterface,
  InitialParams,
  Question,
} from './activity'
import * as at from './account-types'
import {
  StudentOperation,
  answersQuality,
  answerCategory,
} from './student-operation'

const logger = console.log
// TYPES

type ScreenName = 'quiz_intro' | 'quiz' | 'quiz_results'

interface QuizParams extends InitialParams {
  questionGroups: any // number[][]
  placement: boolean
}

export interface QuizInterface extends ActivityInterface {
  type: 'quiz'
  placement: boolean
  questionLevel: 0
  questionGroups: Array<Array<number>>
}

// EXPORTED CLASS

// do not call constructor directly; use class methods.
export class Quiz extends Activity implements QuizInterface {
  type: 'quiz'

  placement: boolean

  questionLevel: 0

  questionGroups: Array<Array<number>>

  constructor(state: QuizInterface | QuizParams) {
    super(state)
    // this.operationId
    // this.placement is true if this is a placement quiz, otherwise it is a progress quiz.
    // this.questionGroups
    // this.timeLimit in seconds
    _.assign(this, state)
    if (!this.type) {
      this.type = 'quiz'
      this.tickLength = Math.ceil(this.threshold / 3)
      this.maxQuestionTime = 10 * this.tickLength
      this.questionLevel = 0
    } else {
      assert(this.type === 'quiz')
    }
  }

  // CLASS METHODS

  static fromStudentOperation(
    studentOperation: StudentOperation,
    placement: boolean,
    timeLimit: number
  ): Quiz {
    const { operationId } = studentOperation
    const questionGroups = studentOperation.quizQuestionGroups(placement)
    return new Quiz({
      operationId,
      placement,
      questionGroups,
      timeLimit,
    })
  }

  // INSTANCE PROPERTIES

  answerIcons(): { [index: number]: at.AnswerCategory } {
    const answers = this.answersForResults()
    const rval: any = {}
    for (const answer of answers) {
      rval[answer[0]] = answerCategory(answer, this.threshold)
    }
    return rval
  }

  currentScreen(): ScreenName {
    if (!this.startedAt) {
      return 'quiz_intro'
    }
    if (!this.finishedAt) {
      return 'quiz'
    }
    return 'quiz_results'
  }

  introTitle(): 'Placement Quiz' | 'Progress Quiz' {
    return this.placement ? 'Placement Quiz' : 'Progress Quiz'
  }

  // eslint-disable-next-line class-methods-use-this
  introSubtitle(): string {
    // return this.operation().getLocalizedName(i18n)
    return 'subtitle brah'
  }

  results(): at.AssignmentProps {
    const activityId = this.placement
      ? at.ActivityId.PlacementQuiz
      : at.ActivityId.ProgressQuiz
    const answers = this.answersForResults()
    const quality = answersQuality(
      activityId,
      answers,
      this.elapsedActive(),
      this.threshold
    )
    const rval: at.AssignmentProps = {
      activityId,
      elapsed: this.elapsed(),
      elapsedActive: this.elapsedActive(),
      pauses: this.pauses,
      operationId: this.operationId,
      answers,
      quality,
    }
    return rval
  }

  // INSTANCE METHODS

  submitAnswer(answer: Question) {
    this.questionActive(this.answers.length)
    this.answers.push(answer)
    this.addElapsedTime(answer)
  }

  // submit the answer to the previous question and get the next question.
  nextQuestion(answer?: Question): void {
    if (answer) {
      this.submitAnswer(answer)
    }
    let timeRemains = true
    if (this.elapsedActive() > this.timeLimit) {
      timeRemains = false
    }
    let question = null
    if (timeRemains) {
      const operation = this.operation()
      const { generator } = operation
      if (
        !answer ||
        this.findNextQuestionLevel(!answer.wrong && !answer.timeout)
      ) {
        const currentGroup = this.questionGroups[this.questionLevel]
        if (!currentGroup || _.isEmpty(currentGroup)) {
          // NOTE: this is expected. If we've exhausted the questionGroups entirely the questionLevel may be -1 or questionGroups.length, meaning currentGroup is undefined, or currentGroup may be an empty array
          question = null
        } else {
          const questionIndex = currentGroup.shift()
          question = generator(questionIndex)
        }
        if (operation.mathOperationKey === 'division' && question.b === 0) {
          logger(
            'Generating divide-by-zero question in %s quiz: %d',
            this.placement ? 'placement' : 'progress',
            question.i
          )
        }
        if (this.elapsedActive() > this.timeLimit + 10) {
          const obj = {
            once: true,
            answers: this.answers,
            startedAt: this.startedAt,
            diff: (Date.now() - this.startedAt) / 1000,
            activeElapsed: this.elapsedActive(),
          }
          logger(obj, 'Quiz with too much time spent in questions')
        }
      }
    }
    this.currentQuestion(question)
  }

  // PRIVATE INSTANCE METHODS

  findHarderQuestionLevel(): boolean {
    this.questionLevel++
    while (
      this.questionLevel < this.questionGroups.length &&
      this.questionGroups[this.questionLevel].length === 0
    ) {
      this.questionLevel++
    }
    return this.questionLevel < this.questionGroups.length
  }

  findEasierQuestionLevel(): boolean {
    this.questionLevel--
    while (
      this.questionLevel >= 0 &&
      this.questionGroups[this.questionLevel].length === 0
    ) {
      this.questionLevel--
    }
    return this.questionLevel >= 0
  }

  findNextQuestionLevel(harder: boolean): boolean {
    if (harder) {
      return this.findHarderQuestionLevel() || this.findEasierQuestionLevel()
    }
    return this.findEasierQuestionLevel() || this.findHarderQuestionLevel()
  }
}

// HELPER FUNCTIONS
