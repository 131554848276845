import { PlainObject } from './common'

type ComponentName = string | undefined

export enum LogLevel {
  Error = 0,
  Warning = 1,
  Summary = 2,
  Info = 3,
  Debug = 4,
  Hide = 5,
}

export interface LogEntry {
  c?: ComponentName
  l?: LogLevel
  m?: string /* logged message */
  o?: PlainObject /* logged object */

  [key: string]: any // Additional information that may be on the entry.

  // TODO: be explicit about what fields may be in an entry:
  // Express middleware uses the following keys:
  // a - action (method GET, POST, etc.)
  // b - browser (userAgent)
  // i - IP Address
  // r - responseTime
  // s - status
  // u - url
  // Monitor uses:
  // intervalId
  // intervalLength
}
