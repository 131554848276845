import _ from 'lodash'

import { newObject, PlainObject } from '../common'
import { persistentState } from './persistent-state'
import * as at from '../account-types'
import { CodesToSecrets, ItemsManager } from '../items-manager'

const logger = console.log
type CacheTypes =
  | 'teachers'
  | 'classrooms'
  | 'students'
  | 'teacherSignins'
  | 'classroomSignins'
  | 'studentSignins'
type SaveTypes = 'savedStudents' | 'savedTeachers' | 'savedTeachersForCS'

export const itemsSaver = {
  getSaved,
  remove,
  saveAllItems,
  storeCredentials,
  storeSignin,
  storeTeacherSigninForCS,
  rememberedCodes,
}

function getSaved(type: CacheTypes, storageKey: SaveTypes): at.AccountMap {
  return ItemsManager.get().getCached(type, rememberedCodes(storageKey))
}

function storeTeacherSigninForCS(object) {
  const data = newObject(object.code, '-')
  persistentState.device.update('savedTeachersForCS', data)
}

function storeCredentials(type, object) {
  const data = newObject(object.code, object.secret)
  persistentState.device.update(type, data)
}

function storeSignin(type, object) {
  const data = newObject(object.code, object.dehydrateSignin())
  persistentState.device.update(type, data)
}

function remove(type, code) {
  ItemsManager.get().getCache(type).remove(code)
  unstore(type, code)
}

function unstore(type, code) {
  const data = newObject(code, null)
  persistentState.device.update(type, data)
}

function rememberedCodes(type: SaveTypes): CodesToSecrets {
  let data = persistentState.device.get(type) || {}
  const message = `Stored signin data for ${type} is in unexpected format. If this error persists please try clearing your saved data.`
  if (_.isEmpty(data) && type === 'savedTeachersForCS') {
    // LATER: in this case we should clear 'teacherSignins', 'studentSignins', 'classroomSignins' from localStorage
    // once our transition to 'savedTeachersForCS' [3.23.57] has been deployed for a while.
    data = _.mapValues(
      persistentState.device.get('teacherSignins'),
      (item, _key) => {
        storeTeacherSigninForCS(item)
        return '-'
      }
    )
  } else if (!isCodeSecretMap(data)) {
    throw new Error(message)
  }
  return data
}

function saveAllItems(type) {
  try {
    _.forEach(
      ['teacherSignins', 'classroomSignins', 'studentSignins'],
      (key) => {
        _.forEach(getSaved(type, type), function (item) {
          storeSignin(key, item)
        })
      }
    )
  } catch (err) {
    logger(err, 'Error occurred while saving item to storage')
  }
}

// PRIVATE METHODS

function isCodeSecretMap(map: PlainObject): map is CodesToSecrets {
  let rval = true
  _.forEach(map, (val, key) => {
    if (typeof val !== 'string') {
      rval = false
    }
  })
  return rval
}
