import _ from 'lodash'
import { Operation } from './operation'
import * as at from './account-types'
// import { ScreenName } from './student-session';
import { Milliseconds } from './common'

type Mode = 'count-in' | 'timeout' | 'incomplete' | 'wrong' | 'complete'

const logger = console.log

// CONSTANTS

// TYPES

// CONSTRUCTORS
export type Question = {
  c: number
  startedAt: number
  idleAt?: number
  elapsed: number
  a: number
  b: number
  o: string
  i: number
  wrong: string
  timeout: boolean
}

export interface InitialParams {
  operationId: number
  timeLimit: number // seconds
}

export interface ActivityInterface {
  activeElapsed: Milliseconds // ms
  pauses: at.Pauses
  maxQuestionTime: number
  type: 'practice' | 'quiz'
  readonly threshold: number
  answers: Array<Question>
  startedAt: number
  finishedAt: number
  operationId: number
  timeLimit: number
  activeQuestion: Question
  tickLength: number

  currentMode: Mode
  userAnswer: number
  wrongAnswer: number
  pauseTime: number
  elapsedTicks: number
}

// EXPORTED CLASS

export abstract class Activity implements ActivityInterface {
  activeElapsed: Milliseconds

  pauses: at.Pauses = {}

  maxQuestionTime: number

  type: 'practice' | 'quiz'

  answers: Array<Question>

  readonly threshold: at.FluencyThreshold

  earnedAwards?: at.AwardType[]

  startedAt: number

  finishedAt: number

  operationId: number

  timeLimit: number

  activeQuestion: Question

  tickLength: number

  currentMode: Mode

  userAnswer: number

  wrongAnswer: number

  pauseTime: number

  elapsedTicks: number

  constructor(state: ActivityInterface | InitialParams) {
    this.activeElapsed = 3000 // start with time for count-in accounted for
    _.assign(this, state)
    // this.operationId
    // this.questionIds
    // this.timeLimit in seconds
    const operation = this.operation()
    this.threshold = operation.threshold
    if (!this.answers) {
      this.answers = []
    }
  }

  // INSTANCE PROPERTIES

  // abstract currentScreen(): ScreenName
  abstract currentScreen(): any

  currentQuestion(question?: any): any {
    if (question !== undefined) {
      this.activeQuestion = question
    }
    return this.activeQuestion
  }

  // LATER: subtract inattention.
  elapsed(): number {
    const rval = Math.round((this.finishedAt - this.startedAt) / 1000)
    return rval
  }

  elapsedActive(): number {
    return Math.round(this.activeElapsed / 1000)
  }

  isIdle(): boolean {
    return this.activeQuestion && !!this.activeQuestion.idleAt
  }

  mode(mode?: Mode): Mode {
    if (mode) {
      this.currentMode = mode
    }
    return this.currentMode
  }

  operation(): Operation {
    return Operation.fromId(this.operationId)
  }

  questionAnswered(wrongAnswer?: string): number {
    const elapsed = Date.now() - this.activeQuestion.startedAt
    this.activeQuestion.elapsed = elapsed
    if (wrongAnswer) {
      this.activeQuestion.wrong = wrongAnswer
    }
    return elapsed
  }

  questionIdle(): void {
    this.activeQuestion.idleAt = Date.now()
  }

  questionActive(answersIndex: number): void {
    if (this.activeQuestion && this.activeQuestion.idleAt) {
      const elapsed = Date.now() - this.activeQuestion.idleAt
      if (this.pauses[answersIndex]) {
        this.pauses[answersIndex] += elapsed
      } else {
        this.pauses[answersIndex] = elapsed
      }
      delete this.activeQuestion.idleAt
    }
  }

  questionStarted(): void {
    this.activeQuestion.startedAt = Date.now()
  }

  questionTimedOut(): void {
    this.activeQuestion.timeout = true
  }

  abstract results(): at.AssignmentProps
  // INSTANCE METHODS

  addElapsedTime(answer: Question): void {
    // NOTE: it doesn't seem like "|| 0" should be necessary here, but I (SRN) am leaving it because it was there previously.
    // Perhaps sometimes answer.startedAt is undefined, resulting in the calculation resolving to NaN? Once we get things better typed
    // hopefully we can be confident that all operands are numbers and remove it.
    const elapsed =
      Math.min(
        Math.max(Date.now() - answer.startedAt, 0),
        this.maxQuestionTime
      ) || 0
    this.activeElapsed += elapsed
  }

  // submit the answer to the previous question and set the next question.
  abstract nextQuestion(answer: Question): void

  saveEarnedAwards(awards: at.AwardType[]) {
    this.earnedAwards = awards
  }

  screenFinished(screen: any): void {
    switch (screen) {
      case 'quiz_intro':
      case 'practice_intro':
        this.startedAt = this.startedAt || Date.now()
        break
      case 'quiz':
      case 'practice':
        this.finishedAt = this.finishedAt || Date.now()
        break
      case 'quiz_results':
      case 'practice_results':
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const err = new Error()
        logger(
          {
            activity: this.type,
            screen,
            startedAt: this.startedAt,
            finishedAt: this.finishedAt,
            stack: err.stack,
          },
          `Unexpected screen at ${this.type} screenFinished`
        )
        break
    }
  }

  // PRIVATE INSTANCE PROPERTIES

  answersForResults(): at.Answer[] {
    return _.map(this.answers, convertActivityAnswerToServerAnswer)
  }
}

// HELPER FUNCTIONS

function convertActivityAnswerToServerAnswer(question: Question): at.Answer {
  // An activity answer (ct.Question) is an object with various fields.
  // A server answer is a more compact representation in an array of length 1, 2, or 3.
  const rval: at.Answer = [question.i]
  if (question.elapsed) {
    rval.push(question.elapsed)
    if (question.wrong) {
      rval.push(question.wrong)
    }
  }
  return rval
}
