/*

Gen1 Program IDs:
  Fields are arranged so a simple numeric comparison will tell you which is the more "advanced" operation.
  bits 0-1 problem set (0 - beginning, 1 - regular, 2 - extended)
  bits 2-5 operation (1 - addition, 2 - subtraction, 4 - multiplication, 8 - division)
  bits 6-8 threshold (0 - unused, 1- 6s, 2 - 3s, 3 - 2s, 4 - 1.5s)
  bit 9 assessment-only
  bit 10-12 generation
  bit 13+ unused

*/

// REQUIREMENTS

import _ from 'lodash'

import { assert } from './common'

import { Operation } from './operation'
import { OperationId, StudentGrade } from './account-types'

// TYPES

// REVIEW: Does TypeScript have support for bit vectors?
type AssessmentOnlyId = number // TYPESCRIPT: list values;
type OperationIdVector = number // bit vector // TYPESCRIPT: list values;
type ProblemSetId = number // TYPESCRIPT: reconcile operation.ts // TYPESCRIPT: list values;
type ProgramIdG0 = number // Generation 0
type ProgramIdG1 = number // Generation 1
type ProgramId = ProgramIdG0 | ProgramIdG1
type ThresholdId = number // TYPESCRIPT: reconcile operation.ts // TYPESCRIPT: list values;

interface ProgramAttributes {
  mathOperationIdMix: OperationIdVector
  problemSetId: ProblemSetId
  thresholdId: ThresholdId
  assessmentOnlyId: AssessmentOnlyId
}

// CONSTANTS

const { PROBLEMSET_IDS } = Operation
const PROBLEMSET_MASK = PROBLEMSET_IDS.mask
const BEGINNER = PROBLEMSET_IDS.beginning
const REGULAR = PROBLEMSET_IDS.regular

const { MATH_OPERATION_IDS } = Operation
const MATH_OPERATION_MASK = MATH_OPERATION_IDS.mask
const ADDITION = MATH_OPERATION_IDS.addition
const SUBTRACTION = MATH_OPERATION_IDS.subtraction
const MULTIPLICATION = MATH_OPERATION_IDS.multiplication
const DIVISION = MATH_OPERATION_IDS.division

const { THRESHOLD_IDS } = Operation
const THRESHOLD_MASK = THRESHOLD_IDS.mask
const SIX_SEC = THRESHOLD_IDS.six
const THREE_SEC = THRESHOLD_IDS.three
const TWO_SEC = THRESHOLD_IDS.two
const ONE_AND_HALF_SEC = THRESHOLD_IDS.onePointFive

const { ASSESSMENT_ONLY_IDS } = Operation

const { GENERATION_IDS } = Operation
const GENERATION_MASK = GENERATION_IDS.mask
const GEN0 = GENERATION_IDS.zero
const GEN1 = GENERATION_IDS.one

const UNUSED_GEN1_ID_BITS_MASK =
  ~PROBLEMSET_MASK &
  ~MATH_OPERATION_MASK &
  ~THRESHOLD_MASK &
  ~ASSESSMENT_ONLY_IDS.mask &
  ~GENERATION_MASK

interface NumberDictionary {
  [key: number]: number
}
const GEN1_FROM_GEN0: NumberDictionary = {
  1: GEN1 + THREE_SEC + REGULAR + ADDITION,
  2: GEN1 + THREE_SEC + REGULAR + ADDITION + SUBTRACTION,
  3: GEN1 + THREE_SEC + REGULAR + ADDITION + SUBTRACTION + MULTIPLICATION,
  4:
    GEN1 +
    THREE_SEC +
    REGULAR +
    ADDITION +
    SUBTRACTION +
    MULTIPLICATION +
    DIVISION,
  // no 5:
  6: GEN1 + THREE_SEC + REGULAR + SUBTRACTION,
  7: GEN1 + THREE_SEC + REGULAR + MULTIPLICATION,
  8: GEN1 + THREE_SEC + REGULAR + DIVISION,
  9: GEN1 + THREE_SEC + REGULAR + MULTIPLICATION + DIVISION,
  // no 10-13
  14:
    GEN1 +
    TWO_SEC +
    REGULAR +
    ADDITION +
    SUBTRACTION +
    MULTIPLICATION +
    DIVISION,
  15:
    GEN1 +
    ONE_AND_HALF_SEC +
    REGULAR +
    ADDITION +
    SUBTRACTION +
    MULTIPLICATION +
    DIVISION,
  16: GEN1 + SIX_SEC + REGULAR + ADDITION,
  17: GEN1 + SIX_SEC + REGULAR + SUBTRACTION,
  18: GEN1 + SIX_SEC + REGULAR + MULTIPLICATION,
  19: GEN1 + SIX_SEC + REGULAR + DIVISION,
  20:
    GEN1 +
    SIX_SEC +
    REGULAR +
    ADDITION +
    SUBTRACTION +
    MULTIPLICATION +
    DIVISION,
  21: GEN1 + TWO_SEC + REGULAR + MULTIPLICATION + DIVISION,
  22: GEN1 + ONE_AND_HALF_SEC + REGULAR + MULTIPLICATION + DIVISION,
  23: GEN1 + THREE_SEC + BEGINNER + ADDITION,
  24: GEN1 + THREE_SEC + BEGINNER + SUBTRACTION,
  25: GEN1 + THREE_SEC + BEGINNER + ADDITION + SUBTRACTION,
  26: GEN1 + SIX_SEC + BEGINNER + ADDITION,
  27: GEN1 + SIX_SEC + BEGINNER + SUBTRACTION,
  28: GEN1 + SIX_SEC + BEGINNER + ADDITION + SUBTRACTION,
  29: GEN1 + THREE_SEC + BEGINNER + MULTIPLICATION,
  30: GEN1 + THREE_SEC + BEGINNER + DIVISION,
  31: GEN1 + SIX_SEC + BEGINNER + MULTIPLICATION,
  32: GEN1 + SIX_SEC + BEGINNER + DIVISION,
}

const GEN0_IDS = _.reduce(
  GEN1_FROM_GEN0,
  (memo: any, _val, key) => {
    memo.push(parseInt(key, 10))
    return memo
  },
  []
)

const BASIC_GEN1_IDS = [1101, 1117, 1150, 1214, 1725]

const GEN0_FROM_GEN1 = _.mapValues(_.invert(GEN1_FROM_GEN0), function (value) {
  return parseInt(value, 10)
})

const SORTED_MATH_MATH_OPERATION_IDS = [
  ADDITION,
  SUBTRACTION,
  MULTIPLICATION,
  DIVISION,
]

/* CONSTRUCTOR

Don't call the constructor directly. Call Program.fromId instead.

Fields:
  id (integer)
    Generation 0 Operation IDs: 1-22, except 19, 20. See GEN1_FROM_GEN0, below.
    Generation 1 Operation IDs: see below.
  operationIds (array) list of operation ids
  hidden* (boolean) DEPRECATED.

Gen1 Program IDs:
  See operation.js Gen1 program ids, plus:
  LATER: bit 9 assessment only
*/
export class Program {
  // CLASS PROPERTIES

  public static defaultIdForGrade(grade: StudentGrade): ProgramId {
    let id
    switch (grade) {
      case 0:
        id = GEN0 + 26 // Kindergarten: beginning addition six sec
        break
      case 1:
        id = GEN0 + 28 // 1st grade: beginning addition and subtraction six sec
        break
      case 2:
        id = GEN1 + SIX_SEC + REGULAR + ADDITION + SUBTRACTION // 2nd grade: regular addition and subtraction
        break
      case 3:
        id = GEN1 + SIX_SEC + REGULAR + ADDITION + SUBTRACTION + MULTIPLICATION // 3rd grade: regular addition, subtraction, and multiplication
        break
      default:
        id =
          GEN1 +
          SIX_SEC +
          REGULAR +
          ADDITION +
          SUBTRACTION +
          MULTIPLICATION +
          DIVISION // 4th grade+: regular addition, subtraction, multiplication, and division
    }
    return GEN0_FROM_GEN1[id] || id
  }

  public static idFromAttributes(attrs: ProgramAttributes): ProgramId {
    const id =
      GEN1 |
      attrs.mathOperationIdMix |
      attrs.problemSetId |
      attrs.thresholdId |
      attrs.assessmentOnlyId
    return GEN0_FROM_GEN1[id] || id
  }

  public static isKnownId(id: ProgramId): boolean {
    return id != null && (id & GENERATION_MASK) <= GEN1
  }

  // Notes:
  //   Do not call unless isKnownId() returns truthy.
  public static isValidId(id: ProgramId): boolean {
    switch (id & GENERATION_MASK) {
      case GEN0:
        return this.isValidGen0Id(id)
      case GEN1:
        return this.isValidGen1Id(id)
      default:
        return false
    }
  }

  public static thresholdId(g1Id: ProgramIdG1): ThresholdId {
    return g1Id & THRESHOLD_MASK
  }

  // CLASS METHODS

  public static fromId = _.memoize(function (id: ProgramId): Program {
    return new Program(id)
  })

  // INSTANCE PROPERTIES

  public id: ProgramId

  public operationIds: OperationId[]

  // INSTANCE PROPERTY METHODS

  public isAssessmentOnly(): boolean {
    return !!(this.gen1Id() & Operation.ASSESSMENT_ONLY_IDS.assessmentOnly)
  }

  public mathOperationIdMix(): OperationIdVector {
    return Program.mathOperationIdMix(this.gen1Id())
  }

  public problemSetId(): ProblemSetId {
    return Program.problemSetId(this.gen1Id())
  }

  public thresholdId(): ThresholdId {
    return Program.thresholdId(this.gen1Id())
  }

  // ----- PRIVATE -----

  // PRIVATE CLASS METHODS

  private static gen1Id(id: ProgramId): ProgramIdG1 {
    return GEN1_FROM_GEN0[id] || id
  }

  public static isFreeProgram(id: ProgramId): boolean {
    return BASIC_GEN1_IDS.indexOf(id) > -1 || GEN0_IDS.indexOf(id) > 0
  }

  public static mathOperationIdMix(g1Id: ProgramIdG1): OperationIdVector {
    return g1Id & MATH_OPERATION_MASK
  }

  public static problemSetId(g1Id: ProgramIdG1): ProblemSetId {
    return g1Id & PROBLEMSET_MASK
  }

  private static isValidGen0Id(g0Id: ProgramIdG0): boolean {
    return _.has(GEN1_FROM_GEN0, g0Id)
  }

  private static isValidGen1Id(g1Id: ProgramIdG1): boolean {
    // Ensure it does not have a Gen0 equivalent.
    // For backwards compatibility, we use Gen0 when available.
    if (_.has(GEN0_FROM_GEN1, g1Id)) {
      return false
    }

    // Ensure at least one mathematical operation is specified.
    const opIds = this.operationIdsFromProgramId(g1Id)
    if (opIds.length === 0) {
      return false
    }

    // Ensure all operation ids are valid.
    // This will also check that our problem set and threshold values are OK.
    const operationIdsOk = _.every(opIds, function (opId) {
      return Operation.isValidId(opId)
    })
    if (!operationIdsOk) {
      return false
    }

    // Ensure no extraneous bits are set
    if ((g1Id & UNUSED_GEN1_ID_BITS_MASK) !== 0) {
      return false
    }

    return true
  }

  private static operationIdsFromProgramId(id: ProgramId): OperationId[] {
    const rval: Array<number> = []
    const g1Id = this.gen1Id(id)
    const attrs = {
      problemSetId: this.problemSetId(g1Id),
      thresholdId: this.thresholdId(g1Id),
    } as any
    const mathOperationIdMix = this.mathOperationIdMix(g1Id)
    _.forEach(SORTED_MATH_MATH_OPERATION_IDS, function (mathOperationId) {
      if (mathOperationIdMix & mathOperationId) {
        attrs.mathOperationId = mathOperationId
        rval.push(Operation.idFromAttributes(attrs))
      }
    })
    return rval
  }

  // PRIVATE CONSTRUCTOR

  private constructor(id: ProgramId) {
    assert(
      id && Program.isKnownId(id) && Program.isValidId(id),
      'Attempting to instantiate invalid program (id %s).',
      id
    )

    this.id = id
    this.operationIds = Program.operationIdsFromProgramId(id)
  }

  // PRIVATE INSTANCE PROPERTIES

  private gen1Id(): ProgramIdG1 {
    return Program.gen1Id(this.id)
  }
}
