import {
  Theme,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#819e9e',
    },
    secondary: {
      main: '#ffc641',
      300: '#F6E793',
      light: '#FFC74154',
    },
    common: {
      black: '#121212',
      white: '#fff',
    },
    error: {
      main: '#AA1D1D',
    },
    grey: {
      700: '#737373',
      300: '#ADADAD',
      A100: '#f9f9f9',
      100: '#F1EEEE',
      50: '#D1D1D1',
    },
    success: {
      main: '#71B13B',
      contrastText: '#fff',
    },
  },
  typography: {
    allVariants: {
      color: '#121212', // Set default color for all typography to common.black
    },
    //   h1: {
    //     fontSize: '2.125rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '1.5rem',
    //     },
    //   },
    //   h2: {
    //     fontSize: '1.75rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '1.25rem',
    //     },
    //   },
    //   h3: {
    //     fontSize: '1.5rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '1.125rem',
    //     },
    //   },
    //   h4: {
    //     fontSize: '1.25rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '1rem',
    //     },
    //   },
    //   h5: {
    //     fontSize: '1rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.875rem',
    //     },
    //   },
    //   h6: {
    //     fontSize: '0.875rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.75rem',
    //     },
    //   },
    //   body1: {
    //     fontSize: '1rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.875rem',
    //     },
    //   },
    //   body2: {
    //     fontSize: '0.875rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.75rem',
    //     },
    //   },
    //   subtitle1: {
    //     fontSize: '1rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.875rem',
    //     },
    //   },
    //   subtitle2: {
    //     fontSize: '0.875rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.75rem',
    //     },
    //   },
    //   caption: {
    //     fontSize: '0.75rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.625rem',
    //     },
    //   },
    //   overline: {
    //     fontSize: '0.625rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.5rem',
    //     },
    //   },
    //   button: {
    //     fontSize: '0.875rem',
    //     '@media (max-width:600px)': {
    //       fontSize: '0.75rem',
    //     },
    //   },
  },
}

const createdTheme: Theme = createTheme(themeOptions)

export const theme = responsiveFontSizes(createdTheme)
