import sprint from 'sprint'
import * as q from 'q'
import _ from 'lodash'

import { LogEntry, LogLevel } from './types/log-entry'
import { config } from './types/config/config-shim'
import { aap, clientIdentity } from './types/helpers'

function sendError(entry: LogEntry): q.Promise<XMLHttpRequest> {
  const client = _.assign(
    {
      code: clientIdentity.clientCode(),
      device: clientIdentity.deviceCode(),
    },
    config.clientDetails
  )
  if (window.device) {
    _.assign(client, {
      osVersion: window.device.version || '',
      model: window.device.model || '',
      manufacturer: window.device.manufacturer || '',
    })
  }
  entry = _.assign(
    {
      client,
      userAgent: navigator.userAgent,
    },
    entry
  )
  const url = '/api/client_error_report'
  const data = JSON.stringify(entry)

  // NOTE: this query structure is duplicated in ajax-connection2.js
  const options: { timeout: number } = {
    timeout: 30 * 1000,
  }
  return aap.post(url, data, options)
}

function report(
  level: LogLevel,
  obj: object,
  message?: string,
  args?: any[]
): void {
  const entry: LogEntry = { o: obj }
  if (level !== LogLevel.Info) {
    entry.l = level
  }
  if (message) {
    if (args && args.length > 0) {
      message = sprint(message, ...args)
    }
    entry.m = message
  }
  sendError(entry)
}

export default report
