/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-multi-assign */
// REQUIREMENTS

import dayjs from 'dayjs'
import _ from 'lodash'

import { AnnotatedMatrix, StudentOperation } from '../types/student-operation'

import { ProgressGraph } from '../types/account-types'

import { Operation } from '../types/operation'

import { PlainObject, Locale } from '../types/common'
import { XmDate } from '../types/xm-date/shared'

// TYPES

export type GridType = 'extended' | 'regular' | 'progress'

interface ColorEntry {
  stroke: string
  fill: /* TYPESCRIPT: color rgb */ string
}

type ColorTable = ColorEntry[]

export interface DrawingNode {
  attrs: PlainObject // Element attributes
  content?: /* TYPESCRIPT: Html */ string
  node: string // HTML tag name
  'z-index'?: number
}

interface Grid {
  columns: number[]
  rows: number[]
  shift: number
}

export interface MatrixOptions {
  dynamic?: boolean
  division?: boolean
  matrixType?: GridType
  pdf?: boolean
  practice?: /* TYPESCRIPT: */ any
  quiz?: /* TYPESCRIPT: */ any
  standalone?: boolean
  whiteBg?: boolean
}

export interface ProgressOptions {
  locale?: Locale
  yAxis?: number[]
  pdf?: boolean
}

interface Size {
  height: number
  innerHeight: number
  innerWidth: number
  width: number
}

interface TextMeasurement {
  font: number
  xOffset: number
  yOffset: number
}

interface TextMeasurements {
  [width: number]: { [rowlength: number]: TextMeasurement }
}

// CONSTANTS

const BACKGROUNDS = ['#C1C1C1', '#DFDFDF']
const DAYS_IN_PROGRESS_GRAPH = 62
const FONT_FAMILY = 'HelveticaNeue, Arial, sans-serif'
const REFERENCE_SIZE = 920

const COLORS_KEY = [
  '#CCCCCC', // gray
  '#E74223', // red
  '#FFCC00', // yellow
  '#90C843', // green
]

const TEXT_MEASUREMENTS: TextMeasurements = {
  410: {
    11: { font: 13, xOffset: 3, yOffset: 3 },
    14: { font: 10, xOffset: 3.5, yOffset: 2.5 },
  },
  260: {
    11: { font: 8, xOffset: 1.5, yOffset: 1.5 },
    14: { font: 7, xOffset: 0.5, yOffset: 0.5 },
  },
  219: {
    11: { font: 7, xOffset: 12, yOffset: 6 },
    14: { font: 5, xOffset: 9.5, yOffset: 6 },
  },
  175: {
    11: { font: 5, xOffset: 9, yOffset: 6 },
    14: { font: 4, xOffset: 7.5, yOffset: 6 },
  },
}

function addPoint(
  x: number,
  y: number,
  height: number,
  cx: number,
  point: Array<number>,
  r /* adius */ : number
): DrawingNode {
  const cy = ((100 - point[1]) / 100) * height
  const fill = COLORS_KEY[point[2]]
  return {
    node: 'circle',
    attrs: {
      cx: x + cx,
      cy: y + cy,
      r,
      stroke: 'none',
      'stroke-width': 0,
      fill,
    },
  }
}

function getDates(xdate: XmDate) {
  let currentDate = dayjs([2009, 8, 15].join('-')).add(xdate, 'day')
  let month = currentDate.month()
  const results = [[currentDate.clone(), 0]]
  for (let i = 0; i < DAYS_IN_PROGRESS_GRAPH; i++) {
    currentDate = currentDate.add(1, 'day')
    const m = currentDate.month()
    if (m !== month) {
      results.push([currentDate.clone(), i])
      month = m
    }
  }
  return results
}

function preProcess(data: any) {
  // assumes sorted by [ point[0] ]
  const rval = Array(DAYS_IN_PROGRESS_GRAPH)
  const lastXdate = data[data.length - 1][0]
  const firstXdate = Math.max(
    data[0][0],
    lastXdate - DAYS_IN_PROGRESS_GRAPH + 1
  )
  let count = 0
  for (let i = data.length - 1; i >= 0; i--) {
    const index = data[i][0] - firstXdate
    rval[index] = data[i]
    count += 1
    if (count === DAYS_IN_PROGRESS_GRAPH) {
      break
    }
  }
  return [rval, getDates(firstXdate)]
}

function textNode(
  content: string | undefined,
  size: string | number,
  x: number,
  y: number,
  options: any
) {
  const rval = {
    node: 'text',
    content,
    attrs: {
      'font-size': size,
      'text-anchor': 'left',
      x,
      y,
      'font-family': FONT_FAMILY,
      stroke: 'none',
      'stroke-width': 0,
      fill: '#444444',
    },
  }
  _.assign(rval.attrs, options)
  return rval
}

export class Spec {
  // CONSTRUCTOR

  public constructor(sizeX: number, sizeY: number, gridType?: GridType) {
    this.size = {
      width: sizeX,
      height: sizeY,
      innerWidth: sizeX,
      innerHeight: sizeY,
    }
    this.scale = sizeX / REFERENCE_SIZE
    if (gridType) this.gridType = gridType
    this.draw = []
  }

  // PROPERTIES

  public backdrop!: boolean

  public draw: DrawingNode[]

  public grid!: Grid

  public gridSpacing!: number

  public paper!: Size

  // METHODS

  public addBackdrop(): Spec {
    this.backdrop = true
    const strokeWidth = 10 * this.scale
    this.size.innerWidth = this.size.width - strokeWidth
    this.size.innerHeight = this.size.height - strokeWidth
    this.push({
      node: 'rect',
      attrs: {
        x: 5 * this.scale,
        y: 5 * this.scale,
        height: this.size.innerHeight,
        width: this.size.innerWidth,
        fill: '#FFFFFF',
        stroke: '#e8e8e8',
        'stroke-width': strokeWidth,
      },
    })
    return this
  }

  public addPaper(): Spec {
    // BLUE CONTAINING SQUARE
    // The measurements are down the *center* of the stroke, so offset initial dimensions by 1/2 stroke width, and
    // reduce graphSize of rect by 2*(1/2 stroke width)

    const strokeWidth = 7 * this.scale
    // eslint-disable-next-line no-multi-assign
    const shift = (this.shift = this.backdrop
      ? 50 * this.scale + strokeWidth / 2
      : strokeWidth / 2) // 10 px padding + half stroke width
    const width = this.size.innerWidth - shift * 2
    const height = this.size.innerHeight - shift * 2
    const innerWidth = width - strokeWidth * 2
    const innerHeight = height - strokeWidth * 2
    this.paper = { height, innerHeight, innerWidth, width }
    this.push({
      node: 'rect',
      attrs: {
        x: shift + 5 * this.scale,
        y: shift + 5 * this.scale,
        height: this.paper.height - strokeWidth / 2,
        width: this.paper.width - strokeWidth / 2,
        fill: '#FFFFFF',
        stroke: '#6ecdc5',
        'stroke-width': strokeWidth,
      },
    })
    return this
  }

  public setGrid(): Spec {
    // GRAY GRIDLINES:
    // lines of length (total area-2x - stroke-width)
    // lines occur every (rect area/cells) px ignoring stroke width - in this case 900/13 = 69px [ cell-outer ] or 900/15 = 60px
    // squares will be (rect area/12 - (2*1/2 stroke width) in area - in this case 68x68px [ cell-inner ]

    let numCol: number
    let numRow: number
    let initialSpacing: number
    let spacing: number
    let squareSize: number
    if (this.gridType === 'extended') {
      // 15 cells = 14 lines
      numCol = numRow = 14
      this.gridSpacing = squareSize =
        (this.paper.innerWidth + this.paper.width) / 2 / (numCol + 1)
      initialSpacing = squareSize
    } else if (this.gridType === 'regular') {
      // 12 cells - 11 lines
      numCol = numRow = 11
      this.gridSpacing = squareSize =
        (this.paper.innerWidth + this.paper.width) / 2 / (numCol + 1)
      initialSpacing = squareSize
    } else {
      // 13.5 cells - 13 lines
      numCol = numRow = 13
      this.gridSpacing = squareSize =
        (this.paper.innerWidth + this.paper.width) / 2 / numCol
      initialSpacing = squareSize / 2
    }
    let i: number
    const offset = initialSpacing
    // vertical
    const shift = (this.shift || 0) + 4 * this.scale // same offset as containing square + width of square stroke.  LATER: factor this so we don't need magic numbers.
    this.grid = {
      rows: new Array(numRow),
      columns: new Array(numCol),
      shift,
    }
    for (i = 0; i < numCol; i++) {
      spacing = i * squareSize + offset
      this.grid.columns[i] = Math.floor(shift + spacing)
    }
    // horizontal
    for (i = 0; i < numRow; i++) {
      spacing = i * squareSize + offset
      this.grid.rows[i] = Math.floor(shift + spacing)
    }
    return this
  }

  public addGridlines(): Spec {
    const sizeY = this.paper.height - 7 * this.scale
    for (let i = 0; i < this.grid.columns.length; i++) {
      const col = this.grid.columns[i]
      this.push({
        node: 'line',
        attrs: {
          x1: col,
          y1: Math.floor(this.grid.shift + 4 * this.scale),
          x2: col,
          y2: Math.floor(this.grid.shift + sizeY),
          stroke: '#d6d6d6',
          'stroke-width': 4 * this.scale,
        },
      })
    }
    // horizontal
    // eslint-disable-next-line no-unused-expressions
    this.paper.width - 7 * this.scale
    for (let i = 0; i < this.grid.rows.length; i++) {
      const row = this.grid.rows[i]
      this.push({
        node: 'line',
        attrs: {
          x1: Math.floor(this.grid.shift + 4 * this.scale),
          y1: row,
          x2: Math.ceil(this.grid.shift + sizeY),
          y2: row,
          stroke: '#d6d6d6',
          'stroke-width': 4 * this.scale,
        },
      })
    }
    return this
  }

  public addMatrix(
    studentOperation: StudentOperation,
    options: MatrixOptions
  ): Spec {
    const safeOptions = options
    const colors: ColorTable = [
      { stroke: '#689b37', fill: '#85b644' }, // green
      { stroke: '#e8c16a', fill: '#f7d986' }, // yellow
      { stroke: '#999999', fill: '#B3B3B3' }, // gray
      { stroke: '#E6E6E6', fill: '#FFFFFF' }, // white
    ]
    const strokeWidth = this.gridSpacing * 0.08
    const borderSize = strokeWidth / 2 || 1
    const fm = studentOperation.factMatrix()
    if (options.pdf) {
      const operation = studentOperation.operation()
      if (!operation.problemSetId()) {
        safeOptions.whiteBg = true
      } // forces matrix background to be white. Slower, but necessary for simplified matrices, because otherwise background color fills empty half of matrix.
      else if (operation.mathOperationKey === 'division') {
        safeOptions.division = true
      }
      this.addReportMatrix(fm, borderSize, strokeWidth, colors, safeOptions)
    } else {
      this.addClientMatrix(fm, borderSize, strokeWidth, colors, safeOptions)
    }
    return this
  }

  public addProgressGraph(data: ProgressGraph, options: ProgressOptions): Spec {
    const processed = preProcess(data)
    const locale = options.locale || 'en'
    const points = processed[0]
    const months = processed[1]
    const startLoc = this.locateMatrixCell(1, 1)
    const startX = startLoc[0]
    const startY = startLoc[1]
    const endLoc = this.locateMatrixCell(11, 11)
    const endX = endLoc[0] + 1
    const endY = endLoc[1]
    const chartWidth = endX - startX
    const chartHeight = endY - startY
    const pointRadius = Math.max(chartHeight / 65, 2)
    const xtick = chartWidth / points.length
    const yLabels = options.yAxis || [100, 80, 60, 40, 20, 0]
    const ytick = chartHeight / (yLabels.length - 1)
    let textSize: number
    if (options.pdf) {
      textSize = 30 * this.scale
    } else {
      textSize = 35 * this.scale
    }
    // start svg

    let tick: number
    let fill: string
    let i: number
    for (i = 0; i <= months.length; i++) {
      // draw chartArea backgrounds
      const month = months[i]
      if (month && month[1] === 0) {
        tick = 0
      } else {
        fill = BACKGROUNDS[i % 2]
        const days = (month && month[1]) || DAYS_IN_PROGRESS_GRAPH
        this.push({
          node: 'rect',
          attrs: {
            x: tick * xtick + startX,
            y: startY,
            width: (days - tick) * xtick,
            height: chartHeight,
            fill,
            stroke: 'none',
            'stroke-width': 0,
            'fill-opacity': 0.5,
          },
        })
        tick = month && month[1]
      }
    }

    // x axis
    this.push({
      node: 'rect',
      attrs: {
        x: startX,
        y: endY,
        height: 1,
        width: chartWidth,
        stroke: 'none',
        'stroke-width': 0,
        fill: '#B8B8B8',
      },
    })
    // y axis
    this.push({
      node: 'rect',
      attrs: {
        x: startX,
        y: startY,
        height: chartHeight + 1,
        width: 1,
        stroke: 'none',
        'stroke-width': 0,
        fill: '#B8B8B8',
      },
    })

    // draw points
    const zeroX = startX + 1.5
    for (i = 0; i < points.length; i++) {
      if (points[i]) {
        this.push(
          addPoint(
            zeroX,
            startY,
            chartHeight,
            i * xtick,
            points[i],
            pointRadius
          )
        )
      }
    }
    // draw y-axis labels
    let ySpacer = textSize / 2
    if (options.pdf) {
      ySpacer = 13
    } else if (ySpacer < 3.5) {
      ySpacer = 3.5
    }
    for (i = 0; i < yLabels.length; i++) {
      this.push({
        node: 'text',
        content: yLabels[i].toString(),
        attrs: {
          'font-size': textSize,
          'text-anchor': 'end',
          'node-width': 20,
          x: startX - ySpacer,
          y: i * ytick + startY + textSize / 2,
          'font-family': FONT_FAMILY,
          stroke: 'none',
          'stroke-width': 0,
          fill: '#444444',
        },
      })
    }
    // draw x-axis labels
    const xSpacer = textSize / 3 || 1
    for (i = 0; i < months.length; i++) {
      let diff: number
      let monthLoc: number
      if (months[i + 1]) {
        // this is why you don't write code at 2am
        diff = (months[i + 1][1] - months[i][1]) / 2
        monthLoc = (months[i][1] + diff) * xtick + startX
      } else {
        diff = (DAYS_IN_PROGRESS_GRAPH - months[i][1]) / 2
        monthLoc = (months[i][1] + diff) * xtick + startX
      }
      this.push({
        node: 'text',
        content: dayjs(months[i][0]).format('MMM').toUpperCase(), // i18n.formatDayJS(months[i][0], 'MMM', locale).toUpperCase(),
        attrs: {
          'font-size': textSize,
          'text-anchor': 'middle',
          x: monthLoc,
          y: endY + textSize + xSpacer,
          'node-width': 20,
          'font-family': FONT_FAMILY,
          stroke: 'none',
          'stroke-width': 0,
          fill: '#444444',
        },
      })
    }
    // end svg
    return this
  }

  // ----- PRIVATE -----

  // PRIVATE PROPERTIES

  private size: Size

  private scale: number

  private gridType?: GridType

  private shift?: number

  // PRIVATE METHODS

  private addClientMatrix(
    matrix: AnnotatedMatrix,
    borderSize: number,
    strokeWidth: number,
    colors: ColorTable,
    options: MatrixOptions
  ): Spec {
    const that = this
    const textMeasurements = that.getTextMeasurements()
    const textSize = textMeasurements.font
    let practiceQuestions: any
    const iconIds = ['#smiley', '#checkmark', '#redx', '#hourglass']
    const answerIcons =
      options.quiz && options.quiz.answerIcons ? options.quiz.answerIcons() : {}
    if (options.practice) {
      practiceQuestions = _.invert(options.practice.questionIds)
    }
    // fudge factor for text offsets as cell width scales differently from pt
    _.forEach(matrix, function (entries, row) {
      _.forEach(entries, function (entry, col) {
        if (entry) {
          const colorInfo = colors[entry.q]
          const loc = that.locateMatrixCell(col, row)
          const loc2 = that.locateMatrixCell(col + 1, row + 1)
          const width = loc2[0] - loc[0] - borderSize
          const height = loc2[1] - loc[1] - borderSize
          const x = loc[0] + borderSize
          const y = loc[1] + borderSize
          const qid = Operation.indexFromRowCol(row, col)
          const rect = {
            node: 'rect',
            attrs: {
              x,
              y,
              height,
              width,
              'stroke-width': strokeWidth,
              stroke: colorInfo.stroke,
              fill: colorInfo.fill,
            },
          } as any
          if (practiceQuestions && !practiceQuestions[qid]) {
            rect.attrs.class = 'fadeOut'
          }
          that.push(rect)
          let reducedOpacity: number
          if (entry.q === 0 || entry.q === 3) {
            reducedOpacity = 0.4
          }
          const startX: number = loc[0] + borderSize
          const operator = textNode(
            entry.o,
            Math.floor(1.3 * textSize),
            startX + textMeasurements.xOffset,
            loc[1] + (height - textMeasurements.yOffset),
            { 'text-anchor': 'start', 'fill-opacity': reducedOpacity || 1 }
          ) as any
          const op1 = textNode(
            entry.a.toString(),
            textSize,
            startX + width - textMeasurements.xOffset,
            loc[1] + textMeasurements.yOffset + textSize,
            { 'text-anchor': 'end', 'fill-opacity': reducedOpacity || 1 }
          ) as any
          const op2 = textNode(
            entry.b.toString(),
            textSize,
            startX + width - textMeasurements.xOffset,
            loc[1] + height - textMeasurements.yOffset,
            { 'text-anchor': 'end', 'fill-opacity': reducedOpacity || 1 }
          ) as any
          if (practiceQuestions && !practiceQuestions[qid]) {
            op1.attrs.class = 'fadeOut'
            op2.attrs.class = 'fadeOut'
            operator.attrs.class = 'fadeOut'
          }
          that.push(op1)
          that.push(op2)
          that.push(operator)
          const iconId = answerIcons[qid]
          if (iconIds[iconId]) {
            const scale = 0.4 * that.scale
            that.push({
              node: 'use',
              'z-index': 1,
              attrs: {
                'xlink:href': iconIds[iconId],
                x: (x + width * 0.65) / scale,
                y: (y + height * 0.65) / scale,
                transform: `scale(${scale})`,
              },
            })
          }
        }
      })
    })
    return this
  }

  private addReportMatrix(
    matrix: AnnotatedMatrix,
    borderSize: number,
    strokeWidth: number,
    colors: ColorTable,
    options: MatrixOptions
  ): Spec {
    const that = this
    let lineGap: number
    let bgColor: any
    const textMeasurements = this.getTextMeasurements()
    const textSize = textMeasurements.font
    if (options.pdf) {
      const categoryCounts = { 0: 0, 1: 0, 2: 0, 3: 0 }
      _.forEach(matrix, function (entries, _row) {
        _.forEach(entries, function (entry, _col) {
          if (entry) {
            categoryCounts[entry.q]++
          }
        })
      })
      if (options.whiteBg) {
        bgColor = { category: 3 }
      } else {
        bgColor = _.reduce(
          categoryCounts,
          function (memo, count, category) {
            const memo2 = memo
            if (count > memo.count) {
              memo2.count = count
              memo2.category = parseInt(category, 10)
            }
            return memo2
          },
          { count: 0, category: 0 }
        )
      }
      const startLoc = options.division
        ? that.locateMatrixCell(1, 0)
        : that.locateMatrixCell(0, 0)
      const endLoc = that.locateMatrixCell(matrix.length, matrix.length)
      this.push({
        node: 'rect',
        attrs: {
          x: startLoc[0] + borderSize,
          y: startLoc[1] + borderSize,
          height: endLoc[1] - startLoc[1] - borderSize,
          width: endLoc[0] - startLoc[0] - borderSize,
          fill: colors[bgColor.category].fill,
        },
      })
    }

    _.forEach(matrix, function (entries, row) {
      _.forEach(entries, function (entry, col) {
        if (entry) {
          const colorInfo = colors[entry.q]
          const loc = that.locateMatrixCell(col, row)
          const loc2 = that.locateMatrixCell(col + 1, row + 1)
          const width = loc2[0] - loc[0] - borderSize
          const height = loc2[1] - loc[1] - borderSize
          const x = loc[0] + borderSize
          const y = loc[1] + borderSize
          let rect: DrawingNode
          if (!bgColor || entry.q !== bgColor.category) {
            rect = {
              node: 'rect',
              attrs: {
                x,
                y,
                height,
                width,
                'stroke-width': strokeWidth,
                stroke: colorInfo.stroke,
                fill: colorInfo.fill,
              },
            }
            that.push(rect)
          }
          const startX = loc[0]
          let spacer: string
          if (textSize === 6) {
            lineGap = lineGap || -((15.3 - width) / 2.3)
            spacer = ' '
          } else {
            lineGap = -1
            spacer = ''
          }
          const text = textNode(
            `${entry.a}\n${entry.o}${spacer}${entry.b}`,
            textSize,
            startX + textMeasurements.xOffset,
            loc[1] + textMeasurements.yOffset,
            { 'text-anchor': 'end', lineGap }
          )
          that.push(text)
        }
      })
    })
    return this
  }

  private getTextMeasurements(): TextMeasurement {
    const premeasured =
      TEXT_MEASUREMENTS[this.size.width] &&
      TEXT_MEASUREMENTS[this.size.width][this.grid.rows.length]
    if (!premeasured) {
      throw new Error(
        `Expected text measurements for matrix (width ${this.size.width}px/rows ${this.grid.rows.length})`
      )
    }
    return premeasured
  }

  private locateMatrixCell(col: number, row: number): [number, number] {
    if (!this.grid) {
      throw new Error("Can't locate cell - no grid.")
    }
    return [this.grid.columns[col], this.grid.rows[row]]
  }

  private push(arg: DrawingNode): void {
    this.draw.push(arg)
  }
}
