import _ from 'lodash'
import { assert } from './common'

import { StudentOperation, answersQuality } from './student-operation'
import * as at from './account-types'

import {
  Activity,
  ActivityInterface,
  InitialParams,
  Question,
} from './activity'

const logger = console.log
type ScreenName = 'practice_intro' | 'practice' | 'practice_results'

const SCHEDULE = [
  [0, 2],
  [2, 2],
  [4, 3],
  [6, 4],
  [8, 4],
]

// percentages selected so approx 50% of quizzes would be 'good', 33% would be 'fair', and 17% poor.
// REVIEW: Should SECONDS_PER_QUESTION be adjusted depending on timer threshold?
// CONSTRUCTORS

interface PracticeParams extends InitialParams {
  questionIds: number[]
}

export interface PracticeInterface extends ActivityInterface {
  type: 'practice'
  consecutiveCorrect: Array<number>
  longestStreak: number
  tally: number
  questionIds: Array<number>
}

export class Practice extends Activity implements PracticeInterface {
  type: 'practice'

  consecutiveCorrect: Array<number>

  longestStreak: number

  tally: number

  questionIds: Array<number>

  constructor(state: PracticeInterface | PracticeParams) {
    super(state)
    _.assign(this, state)
    // this.operationId
    // this.questionIds
    // this.timeLimit in seconds
    if (!this.type) {
      this.type = 'practice'
      this.consecutiveCorrect = _.fill(
        new Array(this.operation().maxIndex()),
        0
      )
      this.longestStreak = 0
      this.tally = 0
      this.maxQuestionTime = this.threshold + 5000
    } else {
      assert(this.type === 'practice')
    }
  }

  // CLASS METHODS

  static fromStudentOperation(
    studentOperation: StudentOperation,
    set,
    timeLimit
  ): Practice {
    const { operationId } = studentOperation
    const questionIds = studentOperation.practiceQuestionIds(set)
    return new Practice({
      operationId,
      questionIds,
      timeLimit,
    })
  }

  // INSTANCE PROPERTIES

  currentScreen(): ScreenName {
    if (!this.startedAt) {
      return 'practice_intro'
    }
    if (!this.finishedAt) {
      return 'practice'
    }
    return 'practice_results'
  }

  results(): at.AssignmentProps {
    const answers = this.answersForResults()
    const activityId = at.ActivityId.Practice
    const quality = answersQuality(
      activityId,
      answers,
      this.elapsedActive(),
      this.threshold
    )
    const rval: at.AssignmentProps = {
      activityId,
      elapsed: this.elapsed(),
      elapsedActive: this.elapsedActive(),
      pauses: this.pauses,
      operationId: this.operationId,
      answers,
      quality,
      tally: this.longestStreak,
      // LATER: report pauses e.g. [[30,60],[120,80]],
    }
    return rval
  }

  // INSTANCE METHODS

  submitAnswer(answer: Question): void {
    this.questionActive(this.answers.length)
    this.addElapsedTime(answer)
    this.answers.push(answer)
    if (answer.elapsed && !answer.wrong) {
      this.consecutiveCorrect[answer.i]++
      this.tally++
      this.longestStreak = Math.max(this.longestStreak, this.tally)
    } else {
      this.consecutiveCorrect[answer.i] = 0
      this.tally = 0
    }

    // put the question back
    const cc = this.consecutiveCorrect[answer.i]
    const i = Math.min(cc, SCHEDULE.length - 1)
    const range = SCHEDULE[i]
    const position = range[0] + Math.floor(range[1] * Math.random())
    this.questionIds.splice(position, 0, answer.i)
  }

  // submit the answer to the previous question and get the next question.
  nextQuestion(answer?: Question): void {
    if (answer) {
      this.submitAnswer(answer)
    }
    let timeRemains = true
    if (this.elapsedActive() > this.timeLimit) {
      timeRemains = false
    }
    let question = null
    if (timeRemains) {
      const questionId = this.questionIds.shift()
      const operation = this.operation()
      question = operation.generator(questionId)
      if (operation.mathOperationKey === 'division' && question.b === 0) {
        logger('Generating divide-by-zero question in practice: %d', question.i)
      }
      if (this.elapsedActive() > this.timeLimit + 10) {
        const obj = {
          once: true,
          answers: this.answers,
          startedAt: this.startedAt,
          diff: (Date.now() - this.startedAt) / 1000,
          activeElapsed: this.elapsedActive(),
        }
        logger(obj, 'Practice with too much time spent in questions')
      }
    }
    this.currentQuestion(question)
  }

  // PRIVATE INSTANCE PROPERTIES
}

// PRIVATE INSTANCE METHODS

// HELPER FUNCTIONS
