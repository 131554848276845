/* eslint-disable no-restricted-syntax */
// REQUIREMENTS

import _ from 'lodash'

import { assert } from './common'

import {
  AccountClass,
  SsoProvider,
  AccountInstance,
  AccountItem,
  CodeType,
  AccountCollection,
  CodeSecretMap,
  AccountPrefix,
  AccountCode,
  AccountSecret,
  OAuthId,
  Delta,
  CodeMap,
  AccountsFromSimpleCacheOptions,
  AccountDependencies,
  DeviceCodeMap,
  AccountType,
  SsoId,
} from './account-types'
import { Cache } from './cache'

const logger = console.log

// CONSTANTS

// REVIEW: Use Map instead of object?
export const OAUTH_PROVIDER_TO_PREFIX: {
  [provider: /* SsoProvider */ string]: string
} = {
  amazon: 'A',
  apple: 'P',
  clever: 'C',
  facebook: 'F',
  google: 'G',
  classlink: 'L',
}
// REVIEW: Use Map instead of object?
const OAUTH_PREFIX_TO_PROVIDER: { [prefix: string]: SsoProvider } = {
  A: 'amazon',
  P: 'apple',
  C: 'clever',
  F: 'facebook',
  G: 'google',
  L: 'classlink',
}

// CONSTRUCTOR

function AccountConstructor(this: AccountInstance, item: AccountItem) {
  _.assign(this, item)
}

// EXPORTS
export const Account: AccountClass = AccountConstructor as any

// NOTE: this cast means typechecking is broken for expected properties (e.g. a constructor without CODE_RE will pass typechecking).
// Unknown properties and known props with incorrect types will still be handled correctly..
const constructor: AccountClass = <
  { (item: AccountItem): void } & AccountClass
>AccountConstructor
const proto: AccountInstance = AccountConstructor.prototype

// CLASS CONSTANTS
constructor.CODE_RE = /^[A-Z0-9]{8}$/

// CLASS METHODS

// e.g. collects all of the classroom codes from a collection of teachers.
constructor.accountCodes = function (
  this: AccountClass,
  codeType: CodeType,
  accounts: AccountCollection,
  initialCodes?: CodeSecretMap
): CodeSecretMap {
  return _.reduce(
    accounts,
    function (memo, account) {
      return _.assign(memo, account[codeType]())
    },
    initialCodes || {}
  )
}
constructor.classroomCodes = _.partial(
  constructor.accountCodes,
  'classroomCodes'
)
constructor.studentCodes = _.partial(constructor.accountCodes, 'studentCodes')
constructor.teacherCodes = _.partial(constructor.accountCodes, 'teacherCodes')
constructor.parentCodes = constructor.teacherCodes

constructor.accountValuesOf = function (
  this: AccountClass,
  prefix: AccountPrefix,
  account: AccountInstance,
  initialCodes?: CodeSecretMap
): CodeSecretMap {
  return _.reduce(
    account,
    function (memo: CodeSecretMap, value: any, key: string) {
      if (key.charAt(0) === prefix) {
        const code: AccountCode = key.slice(1)
        memo[code] = <AccountSecret>value
      }
      return memo
    },
    initialCodes || {}
  )
}

constructor.deltaFromOAuthId = function (
  this: AccountClass,
  oAuthId: OAuthId | undefined
): Delta | undefined {
  if (!oAuthId) {
    return undefined
  }
  const prefix = oAuthId.charAt(0)
  const provider = OAUTH_PREFIX_TO_PROVIDER[prefix]
  if (!provider) {
    throw new Error(`Unknown OAuth provider for prefix: ${prefix}`)
  }
  const id = oAuthId.slice(1)
  switch (provider) {
    case 'apple':
      return { appleId: id }
    case 'clever':
      return { cleverId: id }
    default:
      return { socialProvider: provider, socialId: id }
  }
}

constructor.instantiate = function (
  this: AccountClass,
  item: AccountItem,
  cache?: Cache<AccountInstance>,
  _notSynced?: boolean
): AccountInstance {
  // NOTE: This will construct the inherited class, not this one.
  const account = new this(item)
  if (cache) {
    cache.add(account)
  }
  return account
}

constructor.findInCache = function (
  this: AccountClass,
  code: AccountCode,
  cache?: Cache<AccountInstance>
): AccountInstance | undefined {
  return cache && cache.find(code)
}

constructor.accountsFromSimpleCache = function <M>(
  this: AccountClass,
  codeType: CodeType,
  accounts: AccountCollection,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  initial = initial || {}
  for (const account of accounts instanceof Array
    ? accounts
    : _.values(accounts)) {
    account.accountsFromSimpleCache(codeType, cache, initial, options)
  }
  return initial
}

constructor.classroomsFromSimpleCache = function <M>(
  this: AccountClass,
  accounts: AccountCollection,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'classroomCodes',
    accounts,
    cache,
    initial,
    options
  )
}

constructor.studentsFromSimpleCache = function <M>(
  this: AccountClass,
  accounts: AccountCollection,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'studentCodes',
    accounts,
    cache,
    initial,
    options
  )
}

constructor.teachersFromSimpleCache = function <M>(
  this: AccountClass,
  accounts: AccountCollection,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'teacherCodes',
    accounts,
    cache,
    initial,
    options
  )
}

constructor.prefixedCode = function (
  this: AccountClass,
  code: AccountCode,
  prefix?: AccountPrefix
): string {
  return (prefix || this.PREFIX) + code
}

// PRIVATE INSTANCE METHODS

// PRIVATE CLASS METHODS

// INSTANCE PROPERTIES

proto.accountCodes = function (
  this: AccountInstance,
  prefix: AccountPrefix,
  initialCodes?: CodeSecretMap
): CodeSecretMap {
  const constructor: AccountClass = </* TYPESCRIPT: */ AccountClass>(
    this.constructor
  )
  return constructor.accountValuesOf(prefix, this, initialCodes)
}
proto.classroomCodes = _.partial(proto.accountCodes, 'C')
proto.expiredClassroomCodes = _.partial(proto.accountCodes, 'X')
proto.studentCodes = _.partial(proto.accountCodes, 'S')
proto.childCodes = proto.studentCodes
proto.teacherCodes = _.partial(proto.accountCodes, 'T')
proto.parentCodes = proto.teacherCodes

proto.dependencies = function (this: AccountInstance): AccountDependencies {
  return {
    sCodes: this.studentCodes(),
    cCodes: this.classroomCodes(),
    tCodes: this.teacherCodes(),
    xCodes: this.expiredClassroomCodes(),
  }
}

proto.deviceCodes = function (
  this: AccountInstance,
  initialCodes?: DeviceCodeMap
): DeviceCodeMap {
  const constructor: AccountClass = </* TYPESCRIPT: */ AccountClass>(
    this.constructor
  )
  return </* TYPESCRIPT: */ any>(
    constructor.accountValuesOf('D', this, </* TYPESCRIPT: */ any>initialCodes)
  )
}

proto.getName = function (this: AccountInstance): string {
  return (
    (</* TYPESCRIPT: */ any>this).displayName ||
    (</* TYPESCRIPT: */ any>this).name ||
    (</* TYPESCRIPT: */ any>this).email ||
    ''
  )
}

// Returns true iff the account has related accounts of the specified type.
proto.hasCodes = function (
  this: AccountInstance,
  prefix: AccountPrefix
): boolean {
  return _.some(this, function (_value, key) {
    return key.charAt(0) === prefix
  })
}
proto.hasChildren = _.partial(proto.hasCodes, 'S')
proto.hasClassrooms = _.partial(proto.hasCodes, 'C')
proto.hasExpiredClassrooms = _.partial(proto.hasCodes, 'X')
proto.hasParents = _.partial(proto.hasCodes, 'T')
proto.hasStudents = _.partial(proto.hasCodes, 'S')
proto.hasTeachers = _.partial(proto.hasCodes, 'T')

proto.hasAccountCode = function (
  this: AccountInstance,
  prefix: AccountPrefix,
  code: AccountCode
): boolean {
  return _.has(this, prefix + code)
}
proto.hasClassroomCode = _.partial(proto.hasAccountCode, 'C')
proto.hasStudentCode = _.partial(proto.hasAccountCode, 'S')
proto.hasChildCode = proto.hasStudentCode
proto.hasTeacherCode = _.partial(proto.hasAccountCode, 'T')

// e.g. returns true if a teacher has a specified classroom.
proto.hasAccount = function (
  this: AccountInstance,
  prefix: AccountPrefix,
  account: AccountInstance
): boolean {
  return this.hasAccountCode(prefix, account.code)
}
proto.hasClassroom = _.partial(proto.hasAccount, 'C')
proto.hasStudent = _.partial(proto.hasAccount, 'S')
proto.hasChild = proto.hasStudent
proto.hasTeacher = _.partial(proto.hasAccount, 'T')

// Returns true if the account is of the specified type.
proto.isAccountType = function (
  this: AccountInstance,
  type: AccountType
): boolean {
  const constructor = </* TYPESCRIPT: */ AccountClass>this.constructor
  return constructor.TYPE === type
}
proto.isClassroomAccount = _.partial(proto.isAccountType, 'classroom')
proto.isStudentAccount = _.partial(proto.isAccountType, 'student')
proto.isTeacherAccount = _.partial(proto.isAccountType, 'teacher')

// Returns the number of related accounts of the specified type.
proto.numCodes = function (
  this: AccountInstance,
  prefix: AccountPrefix
): number {
  return _.reduce(
    this,
    function (count, _value, key) {
      return key.charAt(0) === prefix ? count + 1 : count
    },
    0
  )
}
proto.numChildren = _.partial(proto.numCodes, 'S')
proto.numClassrooms = _.partial(proto.numCodes, 'C')
proto.numExpiredClassrooms = _.partial(proto.numCodes, 'X')
proto.numParents = _.partial(proto.numCodes, 'T')
proto.numStudents = _.partial(proto.numCodes, 'S')
proto.numTeachers = _.partial(proto.numCodes, 'T')

proto.oAuthId = function (this: AccountInstance): OAuthId | undefined {
  const provider = this.ssoProvider()
  if (!provider) {
    return undefined
  }
  const prefix = OAUTH_PROVIDER_TO_PREFIX[provider]
  assert(prefix)
  const id = this.ssoId()
  assert(id)
  return `${prefix}${id}`
}

// REVIEW: classroom.prefixedCode could choose 'C' or 'X' prefix depending on whether the classroom is expired.
proto.prefixedCode = function (
  this: AccountInstance,
  prefix?: AccountPrefix
): string {
  const constructor = </* TYPESCRIPT: */ AccountClass>this.constructor
  return constructor.prefixedCode(this.code, prefix)
}

// REVIEW: Does this property make sense when accounts may have multiple SSO IDs and providers?
proto.ssoId = function (this: AccountInstance): SsoId | undefined {
  // REVIEW: Should Apple take precedence over Google, Facebook, Amazon?
  return this.oneRosterId || this.cleverId || this.appleId || this.socialId
}

// REVIEW: Does this property make sense when accounts may have multiple SSO IDs and providers?
proto.ssoProvider = function (this: AccountInstance): SsoProvider | undefined {
  // REVIEW: Should Apple take precedence over Google, Facebook, Amazon?
  if ((</* TYPESCRIPT: */ any>this).cleverId) {
    return 'clever'
  }
  if ((</* TYPESCRIPT: */ any>this).appleId) {
    return 'apple'
  }
  if ((</* TYPESCRIPT: */ any>this).oneRosterId) {
    return 'classlink'
  }
  if ((</* TYPESCRIPT: */ any>this).socialId) {
    return (</* TYPESCRIPT: */ any>this).socialProvider
  }
  return undefined
}

// returns a bare object with the same information but without functions or metadata.
proto.bare = function (this: AccountInstance): AccountItem {
  return _.pickBy(this, function (value, key) {
    return typeof value !== 'function' && !_.startsWith(key, '@')
  }) as AccountItem
}

// PRIVATE INSTANCE PROPERTIES

// INSTANCE METHODS

proto.accountsFromSimpleCache = function <M>(
  this: AccountInstance,
  codeType: CodeType,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  initial = initial || {}
  options = options || {}
  const codes = this[codeType]()
  return _.reduce(
    codes,
    function (memo, _value, code) {
      const account = cache[code]
      if (account) {
        memo[code] = account
      } else {
        const message = `${codeType.slice(
          0,
          -5
        )} not found in simple cache: ${code}`
        if (options.oblivious) {
          if (!options.noWarn) {
            logger(message)
          }
        } else {
          throw new Error(message)
        }
      }
      return memo
    },
    initial
  )
}

proto.classroomsFromSimpleCache = function <M>(
  this: AccountInstance,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'classroomCodes',
    cache,
    initial,
    options
  )
}

proto.expiredClassroomsFromSimpleCache = function <M>(
  this: AccountInstance,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'expiredClassroomCodes',
    cache,
    initial,
    options
  )
}

proto.studentsFromSimpleCache = function <M>(
  this: AccountInstance,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'studentCodes',
    cache,
    initial,
    options
  )
}

proto.teachersFromSimpleCache = function <M>(
  this: AccountInstance,
  cache: CodeMap<M>,
  initial?: CodeMap<M>,
  options?: AccountsFromSimpleCacheOptions
): CodeMap<M> {
  return this.accountsFromSimpleCache<M>(
    'teacherCodes',
    cache,
    initial,
    options
  )
}

// PRIVATE INSTANCE METHODS

// HELPER FUNCTION
