import React, { useEffect } from 'react'
import {
  Box,
  Container,
  LinearProgress,
  CircularProgress,
  useMediaQuery,
} from '@mui/material'
import * as Sentry from '@sentry/react'

import HourGlass from 'assets/student/hourglass.svg'

import useStudentTask from 'student/common/useStudentTask'
import Countdown from 'student/common/countdown'
import SignoutModal from 'student/common/signoutModal'

import { useStudentContext } from 'common/context/StudentContext'

import TimerBar from 'student/common/timerBar'

import {
  ANSWER_ICONS,
  CalculatorButton,
  OperationErrorText,
  OperationText,
  PaperContainer,
} from '../common/taskUI'

function Quiz() {
  const { disableAnimation } = useStudentContext()
  const isLandscape = useMediaQuery('(orientation: landscape)')

  const {
    timerShown,
    tickLength,
    secondsUntilLogout,
    flippedKeypad,
    keypadShown,
    wrongAnswer,
    ActivitySettings,
    hasProblemTimeout,
    currentQuestion,
    studentAnswer,
    remainingAnswer,
    countdownTimer,
    digitEntered,
    dontSignoutContinueClicked,
    studentSession,
    hasFinished,
    answeredRight,
  } = useStudentTask('quiz')

  const isCountIn =
    studentSession?.currentState().activity.currentMode === 'count-in'

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'activity',
      message: 'Started activity',
      level: 'info',
    })
  }, [])

  return (
    <Container>
      {isCountIn && <Countdown timer={countdownTimer} />}
      <PaperContainer
        sx={{ height: { xs: '85vh', md: 'auto !important' }, p: 2 }}
      >
        {hasFinished && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box textAlign={flippedKeypad ? 'right' : 'left'}>
          <ActivitySettings />
        </Box>
        <SignoutModal
          secondsUntilLogout={secondsUntilLogout}
          onContinue={dontSignoutContinueClicked}
        />
        {hasFinished ? (
          <Box textAlign='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              gap: 4,
              flexDirection: flippedKeypad ? 'row-reverse' : 'row',
            }}
          >
            <Box
              flex='1'
              sx={{
                // eslint-disable-next-line no-nested-ternary
                textAlign: keypadShown
                  ? flippedKeypad
                    ? 'left'
                    : 'right'
                  : 'center',
                px: { xs: 0, md: 3 },
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: flippedKeypad || !keypadShown ? '301px' : 'auto',
                  textAlign: 'right',
                  // eslint-disable-next-line no-nested-ternary
                  mr: keypadShown ? (flippedKeypad ? 'auto' : '85px') : 'auto',
                  // eslint-disable-next-line no-nested-ternary
                  ml: keypadShown ? (flippedKeypad ? '85px' : 'auto') : 'auto',
                  position: 'relative',
                }}
              >
                {wrongAnswer && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      display: 'flex',
                      flexDirection: { xs: 'column-reverse', md: 'column' },
                      ...(flippedKeypad
                        ? { left: '-20%' }
                        : { right: { xs: '-25%', sm: '-10%' } }),
                    }}
                  >
                    <OperationErrorText
                      sx={{
                        fontSize: wrongAnswer?.length === 3 ? '40px' : '64px',
                      }}
                    >
                      {wrongAnswer}
                    </OperationErrorText>
                    {!hasProblemTimeout && (
                      <OperationErrorText>
                        <Box
                          component='img'
                          src={ANSWER_ICONS.wrong}
                          alt='Icon for Wrong Answer'
                          height={40}
                          width={40}
                        />
                      </OperationErrorText>
                    )}
                  </Box>
                )}
                {answeredRight && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: { xs: '30%', md: '20%' },
                      display: 'flex',
                      flexDirection: 'column',
                      ...(flippedKeypad
                        ? { left: '-20%' }
                        : { right: { xs: '-25%', sm: '-10%' } }),
                    }}
                  >
                    <Box
                      component='img'
                      src={ANSWER_ICONS[answeredRight]}
                      alt='Icon for Right Answer'
                      height={40}
                      width={40}
                    />
                  </Box>
                )}
                {hasProblemTimeout && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: { xs: '30%', md: '20%' },
                      display: 'flex',
                      flexDirection: 'column',
                      ...(flippedKeypad
                        ? { left: '-20%' }
                        : { right: { xs: '-25%', sm: '-10%' } }),
                    }}
                  >
                    <Box
                      component='img'
                      src={HourGlass}
                      alt='Icon of an Hourglass'
                      height={40}
                      width={40}
                    />
                  </Box>
                )}
                {currentQuestion && (
                  <Box>
                    <Box sx={{ px: 4 }}>
                      <OperationText>{currentQuestion?.a}</OperationText>
                    </Box>
                    <Box
                      sx={{
                        pb: 2,
                        mb: 2,
                        display: 'inline',
                        borderBottom: { xs: '6px solid', md: '13px solid' },
                        borderColor: 'common.black',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          px: 4,
                        }}
                      >
                        <OperationText
                          sx={{
                            mr: 3,
                          }}
                        >
                          {currentQuestion?.o}
                        </OperationText>
                        <OperationText>{currentQuestion?.b}</OperationText>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{ px: 4, mt: 3, display: 'flex', justifyContent: 'end' }}
                >
                  <OperationText
                    sx={
                      remainingAnswer &&
                      !studentAnswer && { height: '0 !important' }
                    }
                  >
                    {studentAnswer}
                  </OperationText>
                  {remainingAnswer ? (
                    <OperationText
                      className={disableAnimation ? '' : 'pulsate'}
                      sx={{ color: disableAnimation ? '#9D33D7' : 'grey.50' }}
                    >
                      {remainingAnswer}
                    </OperationText>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
            {keypadShown && (
              <Box
                sx={{
                  display: 'grid',
                  marginLeft: 'auto',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gridTemplateRows: 'repeat(4, 1fr)',
                  gap: { xs: 1, md: 2 },

                  backgroundColor: isLandscape
                    ? 'transparent'
                    : { xs: 'grey.50', md: 'transparent' },
                  position: isLandscape
                    ? 'relative'
                    : { xs: 'fixed', md: 'relative' },
                  p: { xs: 2, md: 0 },
                  bottom: 0,
                  left: 0,
                  zIndex: 2,
                  width: isLandscape ? 'auto' : { xs: '100%', md: 'auto' },
                }}
              >
                {[7, 8, 9, 4, 5, 6, 1, 2, 3, 0].map((val) => (
                  <CalculatorButton
                    key={val}
                    onClick={() => digitEntered(val)}
                    onTouchStart={() => digitEntered(val)}
                    onTouchEndCapture={(e) => e.preventDefault()}
                    disabled={hasFinished}
                    sx={val === 0 && { gridColumn: '1 / span 2' }}
                  >
                    {val}
                  </CalculatorButton>
                ))}
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: { xs: 'start', md: 'flex-end' },
            justifyContent: 'center',
          }}
        >
          {!isCountIn && (
            <Box sx={{ pt: { xs: 2, md: 3 } }}>
              {!hasFinished && (
                <Box display={timerShown ? 'block' : 'none'}>
                  <TimerBar
                    tickLength={tickLength}
                    currentQuestion={currentQuestion}
                    isStopped={!!wrongAnswer || !!answeredRight}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PaperContainer>
    </Container>
  )
}

export default Quiz
