import React from 'react'
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material'

import Close from 'assets/close.svg'

function Modal({ title, open, onClose, children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog onClose={onClose} open={open} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Box component='img' src={Close} alt='Close' height={16} />
      </IconButton>
      {children}
    </Dialog>
  )
}

export default Modal
