import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

function Countdown({ timer }: { timer: number }) {
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => setCount(count - 1), 1000) // Decrease count every second
    }
  }, [count])

  useEffect(() => {
    setCount(timer)
  }, [timer])

  if (count === 0) return null

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '100vw',
        height: '100vh',
        display: 'flex', // Make it a flex container
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
      }}
    >
      <Typography
        key={count} // force re-render
        sx={{
          fontWeight: 600,
          fontSize: '150px',
          color: 'common.white',
          animation: 'fade-in-out 1s ease-in-out forwards',
          '@keyframes fade-in-out': {
            '0%': { opacity: 0, transform: 'scale(0.8)' },
            '50%': { opacity: 1, transform: 'scale(1.2)' },
            '100%': { opacity: 0, transform: 'scale(1)' },
          },
        }}
      >
        {count > 0 ? count : 'Go!'}
      </Typography>
    </Box>
  )
}

export default Countdown
