/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import q from 'q'
import _ from 'lodash'

import { aap } from './ajax-as-promised'
import { ClientError } from './client-error'
import { clientIdentity } from './client-identity'
import { clientLanguage } from './client-language'
import { config } from '../config/config-shim'

import { PlainObject } from '../common'

import * as xmDate from '../xm-date/client-side'
import { util } from './util'
import { ApiRequest } from './client-types'
import { Api2ParamsBase, ClientStruct } from '../web-server/api-types'

const logger = console.log

// CONSTANTS

const REPORTED_XHR_FIELDS = [
  'response',
  'readyState',
  'responseType',
  'status',
  'statusText',
]

// GLOBAL VARIABLES

// REVIEW: copy-pasted from Cordova network-information(1.3.3) plugin's definitions,
// because navigator.connection doesn't seem to contain static "Connection" defs as expected when we
// transpile with TypeScript. It'd be better to just use navigator.connection.Connection[connectionType] if we could.
const CONNECTION_TYPES = {
  UNKNOWN: 'unknown',
  ETHERNET: 'ethernet',
  WIFI: 'wifi',
  CELL_2G: '2g',
  CELL_3G: '3g',
  CELL_4G: '4g',
  CELL: 'cellular',
  NONE: 'none',
}

// CONSTRUCTOR

// don't call constructor directly. Call connect instead.
export class AjaxConnection2 {
  url: string

  version: string

  target: 'android' | 'ios' | 'web'

  realtime: boolean

  constructor(url, clientConfig) {
    this.url = url
    this.version = clientConfig.version
    this.target = clientConfig.target
    this.realtime = false
  }

  // CLASS METHODS

  static connect(url, clientConfig): AjaxConnection2 {
    return new this(url, clientConfig)
  }

  static formatXhrError(xhr: XMLHttpRequest, route: string): ClientError {
    let err
    // connection error
    // LATER: check for different types of connection errors
    // LATER: and respond appropriately including a possibly retry.

    // NOTE: breaking a connection results in the same object whether it's broken via disabling the client's connection
    // (e.g. iPad wireless cuts out) or the server going down.  The jqXHR has attributes like the following:
    // jqXHR.state() > 'rejected'
    // jqXHR.statusText == 'error' || 'abort' || 'parsererror' || 'timeout'
    // jqXHR.responseText == ''
    // jqXHR.status == 0
    let reason
    let retryable
    let warn
    if (xhr.status === 502) {
      reason = 'bad-gateway'
    } else if (xhr.status === 0 && xhr.readyState === 0) {
      reason = 'ajax-abort'
      warn = false
    } else {
      switch (xhr.statusText) {
        case 'timeout':
          reason = 'ajax-timeout'
          retryable = true
          warn = false
          break
        case 'abort':
          reason = 'ajax-abort'
          warn = false
          break
        case 'parsererror':
          reason = 'ajax-parsererror'
          break
        default:
          retryable = true
          if (!isConnected()) {
            reason = 'offline'
          } else {
            reason = 'ajax-error'
          }
      }
    }
    const warnData: PlainObject = _.reduce(
      REPORTED_XHR_FIELDS,
      function (memo, key) {
        memo[key] = xhr[key] && xhr[key].toString()
        return memo
      },
      {}
    )
    warnData.route = route
    if (retryable) {
      err.retry = true
    }
    if (warn) {
      logger(warnData, 'Client XHR failed')
    }
    return err
  }

  // INSTANCE METHODS

  clientInfo(): ClientStruct {
    return {
      code: clientIdentity.clientCode(),
      device: clientIdentity.deviceCode(),
      locale: clientLanguage.currentLocale(),
      target: this.target,
      version: this.version,
    }
  }

  emitToServer<T extends Api2ParamsBase>(
    method: string,
    args: ApiRequest<T>,
    block?: 'blocking' | 'nonblocking',
    forceRetryable?: boolean
  ): q.Promise<any> {
    // NOTE: this query structure is duplicated in error-report-emitter.js
    const url = `${this.url}/api2/${method}`
    const options: { timeout: number } = {
      timeout: 30 * 1000,
    }
    args.client = this.clientInfo()
    const requestData = JSON.stringify(args)
    // return requestHelper.assist(
    // function () {
    return aap.post(url, requestData, options).then(
      function (xhr) {
        const data = xhr.response
        let result
        try {
          result = ensureJson(data)
        } catch (jsonErr) {
          let err = new ClientError(
            'Failed to parse response as JSON.',
            'client-unexpected',
            'json-parse-failure'
          )
          err = addErrorInfo(err, xhr, args)
          throw err
        }
        if (result === null || typeof result !== 'object') {
          let err = new ClientError(
            'Empty response data.',
            'client-unexpected',
            'empty-response'
          )
          err = addErrorInfo(err, xhr, args)
          err.info.result = result
          throw err
        }
        if (result.serverTime) {
          let clockDelta = result.serverTime - new Date().getTime()
          if (_.isNaN(clockDelta)) {
            logger('Non-numeric clockDelta after %s, defaulting to 0', method)
            clockDelta = 0
          }
          xmDate.setCorrection(clockDelta)
          const xd = xmDate.today()
          if (xd < 0) {
            const err = new ClientError(
              'xd<0)',
              'client-unexpected',
              'invalid-xd'
            )
            err.info.timestamp = Date.now()
            err.info.xd = xd
            err.info.clockDelta = clockDelta
            logger(err, 'xd<0 after clockCorrection application')
          }
        }
        return result
      },
      function (xhr: XMLHttpRequest) {
        // attempt to parse the response text as error JSON
        // REVIEW: some sort of sanity check on the JSON, e.g. should have string 'status' field.
        const serverErr = ensureJson(xhr.response)
        let newErr
        if (serverErr) {
          newErr = _.assign(
            new ClientError(
              serverErr.message || '',
              serverErr.status,
              serverErr.reason
            ),
            _.omit(serverErr, 'message')
          )
          newErr.dontReport = true
        } else if (xhr instanceof Error) {
          // rethrow error thrown by us, probably by requestHelper
          throw xhr
        } else {
          newErr = AjaxConnection2.formatXhrError(xhr, url)
        }
        throw newErr
      }
    )
    // },
    // block,
    // method,
    // forceRetryable
    // )
  }
}

function ensureJson(response): PlainObject {
  if (typeof response === 'string') {
    response = JSON.parse(response)
  }
  return response
}

function addErrorInfo(
  err: ClientError,
  xhr: XMLHttpRequest,
  requestData: PlainObject
): ClientError {
  err.info.xhrStatus = xhr.status
  err.info.response = xhr.response
  if (xhr.responseType === 'text') {
    err.info.responseText = xhr.responseText
  }
  err.info.responseType = xhr.responseType
  err.info.responseUrl = xhr.responseURL
  err.info.request = requestData
  return err
}

export function isConnected() {
  // Note: Connection is a Cordova global.
  const connectionInfo = (navigator as any).connection
  return (
    !config.cordova.enabled ||
    (connectionInfo && connectionInfo.type !== CONNECTION_TYPES.NONE)
  )
}
