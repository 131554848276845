export const clientIdentity = { clientCode, deviceCode }

// CONSTANTS

const NODE = typeof window === 'undefined'
const DEVICE_CODE_STORAGE_KEY = 'deviceCode'
const CODE_CHARS = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'

// GLOBALS

let cCode
let dCode

// EXPORTED FUNCTIONS

function clientCode() {
  if (!cCode) {
    cCode = makeRandomString(CODE_CHARS, 8)
  }
  return cCode
}

function deviceCode() {
  if (!dCode && !NODE) {
    try {
      dCode = localStorage.getItem(DEVICE_CODE_STORAGE_KEY)
    } catch (err) {
      // ignore
    }
  }
  if (!dCode) {
    dCode = makeRandomString(CODE_CHARS, 8)
    if (!NODE) {
      // Safari throws an exception setting localStorage in private browsing mode.
      try {
        localStorage.setItem(DEVICE_CODE_STORAGE_KEY, dCode)
      } catch (err) {
        // ignore
      }
    }
  }
  return dCode
}

// NOTE: duplicated in shared/common
function makeRandomString(chars: string, length: number): string {
  let rval = ''
  for (let i = 0; i < length; i++) {
    rval += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return rval
}
