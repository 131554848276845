// REQUIREMENTS

import { Locale, Seconds, Uri, Milliseconds } from '../common'
// import { Hashes } from '../build-helpers/build-types';

// TYPES

type Environment = 'development' | 'test' | 'xtramath'
type GitHash = string
type Target = 'web' | 'ios' | 'android'
type View = 'web' | 'tablet'

// REVIEW: How does this relate to shared/app-framework/index.ts/Config types?

export interface ClientDetailsConfig {
  gitHash: string
  version: string
  target: Target
}

export interface Config {
  cdn0: string // TYPESCRIPT: cdn0.xtramath.org. Not a URI exactly.
  clientDetails: ClientDetailsConfig
  cordova: CordovaConfig
  environment: Environment
  htmlHash: GitHash
  i18n: I18NConfig
  ios7?: boolean
  isPhone?: boolean
  // hashes: Hashes;
  jsHash: GitHash
  jsSuffix: string
  logging: { defaultLevel: any }
  oauth: OAuthConfig
  oldAndroid?: boolean
  useNewTeacher?: boolean
  paypalId: string
  platform: Target
  pngMode?: boolean
  primaryLanguage: Locale
  protocol: 'http' | 'https'
  resetOnPause: boolean
  resourceLocation: string // TYPESCRIPT: Uri?
  serverLocation: string // TYPESCRIPT: Uri?
  stripeKey: string
  studentSession: StudentSessionConfig
  teacherSession: TeacherSessionConfig
  useSFX?: boolean
  view: View
}

interface CordovaConfig {
  enabled: boolean
}

interface I18NConfig {
  html: I18NHtmlConfig
  languages: I18NLanguagesConfig
}

interface I18NHtmlConfig {
  translatedShared: string[] // TYPESCRIPT:
}

interface I18NLanguagesConfig {
  [locale: string]: I18NLocaleConfig
}

interface I18NLocaleConfig {
  name: string
  subfolder: string
  primary: boolean
  useMomentLocale: Locale
  decimalComma: boolean
  inherits: Locale[]
}

interface OAuthConfig {
  [provider: string]: OAuthProviderConfig
}

interface OAuthProviderConfig {
  url: Uri
  redirectUri: Uri
  provider: string // TYPESCRIPT:
  apptarget: 'web'
}

interface StudentSessionConfig {
  activityTimeLimit: Seconds
  sessionTimeLimit: Seconds
  timeout: Seconds
  idleLogoutDelay: Seconds
  idleTimeout: Milliseconds
}

interface TeacherSessionConfig {
  timeout: Seconds
}

// EXPORTED GLOBAL

// eslint-disable-next-line dot-notation
export const config: Config = window['XM3ClientConfig']
