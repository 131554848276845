/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-this-alias */
import q from 'q'
import _ from 'lodash'
import { AjaxConnection2, ClientError, EventEmitter } from './helpers'
import { Cache } from './cache'
import * as at from './account-types'
import * as atc from './account-types-client'
import { ClientTeacher } from './client-teacher'
import { ClientClassroom } from './client-classroom'
import { ClientStudent } from './client-student'
import {
  ClientAccountsMap,
  ClientParamsFilter,
  HomeRefreshResult,
  RefreshResult,
  PromisedInstance,
  ClientSyncRval2,
} from './account-types-client'
import {
  HomeRefreshParams,
  RefreshParams,
  RefreshRval,
} from './web-server/api-types'

const logger = console.log

const REFRESH_AFTER = 1 * 60 * 1000
const previouslyPromised = { teachers: {}, students: {}, classrooms: {} }
const constructors = {
  teachers: ClientTeacher,
  classrooms: ClientClassroom,
  students: ClientStudent,
  teacherSignins: ClientTeacher,
  studentSignins: ClientStudent,
  classroomSignins: ClientClassroom,
}

type AnyCache = ClassroomCache | StudentCache | TeacherCache
type ClassroomCache = Cache<atc.ClientClassroomInstance>
type StudentCache = Cache<atc.ClientStudentInstance>
type TeacherCache = Cache<atc.ClientTeacherInstance>
interface PrepareRequestRval {
  shouldRefresh: boolean
  promises: RefreshPromises
  alreadyCached: ClientSyncRval2['cached']
}
interface PrepareRequestRval1 extends PrepareRequestRval {
  request: ClientParamsFilter<RefreshParams>
}
interface PrepareRequestRval2 extends PrepareRequestRval {
  request: ClientParamsFilter<HomeRefreshParams>
}
type FetchRvals = RefreshResult | HomeRefreshResult | PromisedInstance | {}
type RefreshPromises = q.Promise<FetchRvals>[]
type CacheKeys =
  | 'teachers'
  | 'students'
  | 'classrooms'
  | 'teacherSignins'
  | 'classroomSignins'
  | 'studentSignins'
type ItemTypesArray = Array<CacheKeys>
export type CodesToSecrets = { [code: string]: at.AccountSecret }
type TypesToCodesToSecrets1 = {
  teachers: CodesToSecrets
  classrooms: CodesToSecrets
  students: CodesToSecrets
}
type TypesToCodesToSecrets2 = {
  teachers: CodesToSecrets
  students: CodesToSecrets
  teacherVersions: CodesToSecrets
  classroomVersions: CodesToSecrets
}

const CacheKeys: CacheKeys[] = [
  'teachers',
  'students',
  'classrooms',
  'teacherSignins',
  'classroomSignins',
  'studentSignins',
]
let emitter

export class ItemsManager {
  private static instance: ItemsManager

  connection: AjaxConnection2

  teachers = Cache.create<atc.ClientTeacherInstance>()

  classrooms = Cache.create<atc.ClientClassroomInstance>()

  students = Cache.create<atc.ClientStudentInstance>()

  studentSignins = Cache.create<atc.ClientTeacherInstance>() // LATER: type as signin aspect

  teacherSignins = Cache.create<atc.ClientTeacherInstance>() // LATER: type as signin aspect

  classroomSignins = Cache.create<atc.ClientClassroomInstance>() // LATER: type as signin aspect

  private constructor(connection: AjaxConnection2) {
    this.connection = connection
  }

  static start(connection: AjaxConnection2): EventEmitter {
    if (ItemsManager.instance) {
      throw new ClientError(
        'Attempted to start ItemsManager more than once',
        'client-unexpected',
        'unexpected'
      )
    }
    logger('Starting ItemsManager.')
    ItemsManager.instance = new ItemsManager(connection)
    const cacheMap: atc.CacheMap = this.getAllCaches()
    const cachesForTypes = {
      teacher: cacheMap,
      classroom: _.pick(cacheMap, [
        'students',
        'studentSignins',
        'classrooms',
        'classroomSignins',
      ]),
      student: _.pick(cacheMap, ['students', 'studentSignins']),
    }
    _.forEach(
      {
        teacher: ClientTeacher,
        classroom: ClientClassroom,
        student: ClientStudent,
      },
      function (model, type) {
        model.initialize(connection, { cacheCallback: emitUpdates })
        model.addCaches(cachesForTypes[type])
      }
    )

    emitter = new EventEmitter()
    return emitter
  }

  static getAllCaches(): atc.CacheMap {
    return _.reduce(
      CacheKeys,
      (memo: atc.CacheMap, cacheName: CacheKeys) => {
        memo[cacheName] = this.instance.getCache(cacheName)
        return memo
      },
      {}
    )
  }

  static get(): ItemsManager {
    if (!ItemsManager.instance) {
      throw new Error(
        'Attempted to get ItemsManager singleton before initialization.'
      )
    }
    return ItemsManager.instance
  }

  static getEmitter(): EventEmitter {
    if (!emitter) {
      logger('Attempted to get cache emitter when none exists')
    }
    return emitter
  }

  addItemsToCaches(
    itemTypes: ItemTypesArray,
    alreadyCached: atc.ClientAccountsMap,
    resolvedPromises: FetchRvals[]
  ) {
    const that = this
    const changedItems = resolvedPromises[0] as
      | atc.RefreshResult
      | atc.HomeRefreshResult
      | {}
    const caches: { [key: string]: AnyCache } = {}
    _.forEach(itemTypes, function (type) {
      caches[type] = that.getCache(type)
      const items = changedItems[type]
      if (_.size(items) > 0) {
        const { cacheCallback } = constructors[type].callbacks
        if (cacheCallback) {
          cacheCallback()
        }
      }
    })
    const rval = _.merge({}, alreadyCached, changedItems)
    _.forEach(resolvedPromises.slice(1), function (item) {
      item = item as PromisedInstance
      if ('code' in item) {
        const { type } = item
        if (type !== 'errors') {
          rval[type][item.code] = item.instance
          caches[type].add(item.instance as any) // TYPESCRIPT: better type
        }
      }
    })
    return rval
  }

  fetch(toFetch: TypesToCodesToSecrets1): atc.ClientSyncRval2 {
    logger('ItemsManager fetch!')
    const that = this
    const deferred: q.Deferred<ClientAccountsMap> = q.defer()
    const { shouldRefresh, promises, request, alreadyCached } =
      this.prepareRequest(toFetch)
    if (shouldRefresh) {
      promises.unshift(this.refreshItems(request as RefreshParams))
    } else {
      promises.unshift(q({}))
    }
    q.all(promises)
      .then(function (resolvedPromises) {
        const itemTypes: ItemTypesArray = ['teachers', 'classrooms', 'students']
        deferred.resolve(
          that.addItemsToCaches(itemTypes, alreadyCached, resolvedPromises)
        )
      })
      .catch(function (err) {
        deferred.reject(err)
      })
    return { cached: alreadyCached, promise: deferred.promise }
  }

  fetchSignins(toFetch: TypesToCodesToSecrets2): atc.ClientSyncRval2 {
    const that = this
    const deferred: q.Deferred<ClientAccountsMap> = q.defer()
    const { shouldRefresh, promises, request, alreadyCached } =
      this.prepareRequest(toFetch)
    if (shouldRefresh) {
      promises.unshift(
        this.refreshHomeSignins(request as HomeRefreshParams).promise
      )
    } else {
      promises.unshift(q({}))
    }
    q.all(promises)
      .then(function (resolvedPromises) {
        const itemTypes: ItemTypesArray = [
          'teachers',
          'students',
          'teacherSignins',
          'classroomSignins',
        ]
        deferred.resolve(
          that.addItemsToCaches(itemTypes, alreadyCached, resolvedPromises)
        )
      })
      .catch(function (err) {
        deferred.reject(err)
      })
    return { cached: alreadyCached, promise: deferred.promise }
  }

  getCache<T extends AnyCache>(type: CacheKeys) {
    return this[type] as T
  }

  getCached(name: CacheKeys, codes: CodesToSecrets): at.AccountMap {
    const cache = this.getCache(name)
    return _.reduce(
      codes,
      (memo, _secret, code) => {
        const item = cache.find(code)
        if (item) {
          memo[code] = item
        }
        return memo
      },
      {}
    )
  }

  loadStudent(
    codesToSecrets: CodesToSecrets,
    sCode: at.AccountCode
  ): q.Promise<atc.ClientStudentInstance> {
    const results = this.fetch({
      students: codesToSecrets,
      teachers: {},
      classrooms: {},
    })
    if (results.cached.students && results.cached.students[sCode]) {
      return q(results.cached.students[sCode])
    }
    return results.promise.then(function (map) {
      return map.students[sCode]
    })
  }

  loadTeacher(
    codesToSecrets: CodesToSecrets,
    tCode: at.AccountCode
  ): q.Promise<atc.ClientTeacherInstance> {
    const results = this.fetch({
      students: {},
      teachers: codesToSecrets,
      classrooms: {},
    })
    if (results.cached.teachers && results.cached.teachers[tCode]) {
      return q(results.cached.teachers[tCode])
    }
    return results.promise.then(function (map) {
      const error = map.errors && map.errors[tCode]
      if (error) {
        const err = new Error(error.message)
        ;(err as any).status = error.status
        throw err
      }
      return map.teachers[tCode]
    })
  }

  prepareRequest(
    toFetch: TypesToCodesToSecrets1 | TypesToCodesToSecrets2
  ): PrepareRequestRval1 | PrepareRequestRval2 {
    const that = this
    const alreadyCached = {}
    const promises: RefreshPromises = []
    let shouldRefresh = false
    const cutoff = Date.now() - REFRESH_AFTER
    let request
    if ('teacherVersions' in toFetch) {
      toFetch = toFetch as TypesToCodesToSecrets2
      request = _.mapValues(toFetch, (_val, _key) => {
        return {}
      }) as PrepareRequestRval2['request']
    } else {
      toFetch = toFetch as TypesToCodesToSecrets1
      request = _.mapValues(toFetch, (_val, _key) => {
        return {}
      }) as PrepareRequestRval1['request']
    }
    if ('teacherVersions' in toFetch) {
      /* empty */
    } else {
      request = request as PrepareRequestRval1['request']
    }

    _.forEach(toFetch, function (map, fetchKey) {
      let type: CacheKeys
      if (fetchKey === 'teacherVersions') {
        type = 'teacherSignins'
      } else if (fetchKey === 'classroomVersions') {
        type = 'classroomSignins'
      } else {
        type = fetchKey as 'teachers' | 'classrooms' | 'students'
      }
      const cache: AnyCache = that.getCache(type)
      if (!alreadyCached[type]) {
        alreadyCached[type] = {}
      }
      _.forEach(map, function (secret, code) {
        if (previouslyPromised[type][code]) {
          promises.push(previouslyPromised[type][code])
          return
        }
        const item = cache.find(code)
        if (!item) {
          shouldRefresh = true
          request[type][code] = { version: 0, secret }
        } else {
          alreadyCached[type][code] = item
          if (item['@lastSynced'] < cutoff && item.secret === secret) {
            shouldRefresh = true
            request[type][code] = { version: item.version, secret: item.secret }
            item['@lastSynced'] = Date.now()
          }
        }
      })
    })
    return { promises, shouldRefresh, request, alreadyCached }
  }

  refreshItems(
    refresh: ClientParamsFilter<RefreshParams>
  ): q.Promise<RefreshResult> {
    const promise: q.Promise<RefreshResult> = this.connection
      .emitToServer<RefreshParams>('refresh', refresh, 'nonblocking')
      .then(function (result: RefreshRval) {
        return <RefreshResult>ClientTeacher.applyRefreshResult(result, refresh)
      })
    applyCleanupPromises(refresh, promise)
    return promise
  }

  refreshHomeSignins(params: ClientParamsFilter<HomeRefreshParams>): {
    cached: HomeRefreshResult
    promise: q.Promise<HomeRefreshResult>
  } {
    const that = this
    const teachers = {
      versions: params.teachers,
      cache: that.getCache<Cache<atc.ClientTeacherInstance>>('teachers'),
    }
    const students = {
      versions: params.students,
      cache: that.getCache<Cache<atc.ClientStudentInstance>>('students'),
    }
    const teachersForCS = {
      cache: that.getCache<Cache<atc.ClientTeacherInstance>>('teacherSignins'),
      versions: params.teacherVersions,
    }
    const classroomsForCS = {
      cache:
        that.getCache<Cache<atc.ClientClassroomInstance>>('classroomSignins'),
      versions: params.classroomVersions,
    }
    const rval = ClientTeacher.refreshHomeSignins({
      teachers,
      students,
      teachersForCS,
      classroomsForCS,
    })
    applyCleanupPromises(params, rval.promise)
    return rval
  }

  syncClassroomStudents(
    classroom: atc.ClientClassroomInstance,
    sCode?: at.AccountCode
  ) {
    // LATER: remove entirely and replace with generic batch call
    const results = this.fetch({
      students: classroom.studentCodes(),
      classrooms: {},
      teachers: {},
    })
    if (results.cached.students && results.cached.students[sCode]) {
      return q(results.cached)
    }
    return results.promise
  }

  syncClassrooms(teacher: atc.ClientTeacherInstance, sCode?: at.AccountCode) {
    // LATER: remove entirely and replace with generic batch call
    const results = this.fetch({
      students: {},
      classrooms: teacher.classroomCodes(),
      teachers: {},
    })
    if (results.cached.students && results.cached.students[sCode]) {
      return q(results.cached)
    }
    return results.promise
  }

  syncClassroomsAndChildren(
    teacher: atc.ClientTeacherInstance,
    sCode?: at.AccountCode
  ) {
    // LATER: remove entirely and replace with generic batch call
    const results = this.fetch({
      students: teacher.studentCodes(),
      classrooms: teacher.classroomCodes(),
      teachers: {},
    })
    if (results.cached.students && results.cached.students[sCode]) {
      return q(results.cached)
    }
    return results.promise
  }

  syncClassroomsAndStudents(
    teacher: atc.ClientTeacherInstance,
    cCode: at.AccountCode,
    sCode?: at.AccountCode
  ): q.Promise<ClientAccountsMap> {
    const that = this
    const classroom: atc.ClientClassroomInstance | undefined =
      this.getCache<ClassroomCache>('classrooms').find(cCode)
    const sCodes = classroom && classroom.studentCodes()
    // sync everything we've got
    const results = ClientTeacher.syncItems(
      { classrooms: teacher.classroomCodes(), students: sCodes },
      undefined,
      false
    )
    // check for changes to classroom's student list
    // and sync classroom's students again if we need to.
    results.promise
      .then(function (rval) {
        const classroom = rval.classrooms[cCode]
        const newCodes = classroom.studentCodes()
        const codesUnion = _.assign({}, sCodes, newCodes)
        let resync
        _.forEach(codesUnion, function (secret, code) {
          /* student added || student removed || secret changed */
          resync =
            !sCodes ||
            !sCodes[code] ||
            !newCodes[code] ||
            secret !== sCodes[code]
        })
        if (resync) {
          return that.syncClassroomStudents(classroom, sCode).then(function ({
            students,
          }) {
            rval.students = students
            return rval
          })
        }
        return rval
      })
      .catch(function (err) {
        throw err
      })
    if (sCode && results.cached.students && results.cached.students[sCode]) {
      return q(results.cached)
    }
    if (
      !sCode &&
      results.cached.classrooms &&
      results.cached.classrooms[cCode]
    ) {
      return q(results.cached)
    }
    return results.promise
  }
}

// HELPER FUNCTIONS

function applyCleanupPromises(
  refresh: ClientParamsFilter<RefreshParams | HomeRefreshParams>,
  refreshPromise: q.Promise<any>
) {
  _.forEach(['teachers', 'classrooms', 'students'], function (type) {
    for (const key in refresh[type]) {
      const instanceP = refreshPromise
        .then(function (result: RefreshResult) {
          const rval: PromisedInstance = {
            type: null,
            code: null,
            instance: null,
          }
          if (result[type][key]) {
            rval.type = type
            rval.code = key
            rval.instance = result[type][key]
          } else {
            rval.type = 'errors'
            rval.code = key
            rval.instance = result.errors[key]
          }
          return rval
        })
        .fin(() => {
          delete previouslyPromised[type][key]
        })
      previouslyPromised[type][key] = instanceP
    }
  })
}

// PRIVATE METHODS

// NOTE:
// I've considered handling updates by emitting all cache information with
// update - means controller methods to update view won't need to get the objects again.
// Unfortunately we don't know which objects the controller needs to re-render at this point,
// so I've left things as is for the time being.  It's possible that the controller could let
// us know what it's watching, but that seems more complex to me than just doing sync fetches from the
// cache whenever we may need to re-render.

let lastUpdateCheck = Date.now()
let emitUpdates = _.throttle(
  function () {
    if (!emitter) {
      return
    }
    let emit = false
    const caches = ItemsManager.getAllCaches()
    const updated = _.reduce(
      caches,
      function (memo, cache, key) {
        const update = updatesSince(cache, lastUpdateCheck)
        if (update) {
          memo[key] = update
          emit = true
        }
        return memo
      },
      {}
    )
    if (emit) {
      emitter.emit('updates', updated)
    }
    lastUpdateCheck = Date.now()
  },
  250,
  { trailing: true }
)

function updatesSince(cache, lastUpdated) {
  const rval = _.some(cache.contents(), function (cached, _key) {
    const lastItemUpdate = cached && cached['@lastUpdated']
    return lastItemUpdate >= lastUpdated
  })
  return rval
}
