import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import Header from './layout/Header'

function ErrorFallback() {
  return (
    <>
      <Header />
      <Container>
        <Box mt={4}>
          Sorry, something went wrong! Please try again in a few minutes
        </Box>
      </Container>
    </>
  )
}

export default ErrorFallback
