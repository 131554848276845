// TODO: oblivious option.

// TODO: support chaining

// REQUIREMENTS

import q from 'q'

import { PlainObject } from '../common'

// TYPES

// TYPESCRIPT: Parameterized type. Array of type T
type ArrayCollection = any[]
type ArrayCollectionPromise = q.Promise<ArrayCollection>

// TYPESCRIPT: Parameterized type. Object mapping strings to type T.
// TYPESCRIPT: What about Sets and Maps? Should just be something iterable.
type ObjectCollection = PlainObject
type ObjectCollectionPromise = q.Promise<ObjectCollection>

type Collection = ObjectCollection | ArrayCollection
type CollectionPromise = q.Promise<Collection>

type ArrayCallback = (value: any, index: number, col: Collection) => any
type ObjectCallback = (value: any, key: string, col: Collection) => any
type Callback = ArrayCallback | ObjectCallback
type MemoArrayCallback<T> = (
  memo: T,
  value: any,
  index: number,
  col: Collection
) => any
type MemoObjectCallback<T> = (
  memo: T,
  value: any,
  key: string,
  col: Collection
) => any
type MemoCallback<T> = MemoArrayCallback<T> | MemoObjectCallback<T>

export interface ForEachOptions {
  parallel?: number // 1. Number to process in parallel. (parallel only in starting directory)
}

// CONSTANTS

// EXPORTED FUNCTIONS

// like _.each but replaces context/this argument with an options argument.
// calls fn(element, index, list) for an array-like collection or fn(value, key, list) for an object collection.
// fn can return a value or promise.
// waits for returned value to be resolved before executing fn on the next element.
// options:
//   parallel: number of callback functions to execute in parallel. defaults to 1.
// returns a promise that is resolved to the collection.
// TODO: make this interruptible
export function forEach(
  collection: Collection,
  fn: Callback,
  options?: ForEachOptions
): CollectionPromise {
  // TYPESCRIPT: Why isn't there a type error on this?
  if (!collection) {
    return q(null)
  }

  const deferred = q.defer()

  const parallel = (options && options.parallel) || 1
  let { length } = collection as any
  const arrayLike = length === +length
  let collectionKeys
  if (!arrayLike) {
    collectionKeys = Object.keys(collection)
    length = collectionKeys.length
  }

  let next = 0
  let running = 0

  const launch = function () {
    const val: any = arrayLike
      ? collection[next]
      : collection[collectionKeys[next]]
    const key: string | number = arrayLike ? next : collectionKeys[next]
    running++
    next++
    // delay(1) to prevent "process.nextTick detected" warnings.
    // See https://github.com/kriskowal/q/issues/541
    // TYPESCRIPT: Eliminate cast.
    return q
      .delay(1)
      .then(function () {
        return (<any>fn)(val, key, collection)
      })
      .then(
        function (_results) {
          running--
          if (next < length) {
            launch()
          } else if (running === 0) {
            deferred.resolve(collection)
          }
        },
        function (err) {
          next = length // prevent any others from being dispatched.
          deferred.reject(err)
        }
      )
  }

  if (length === 0) {
    deferred.resolve(collection)
  } else {
    while (next < length && running < parallel) {
      launch()
    }
  }

  return deferred.promise
}

// REVIEW: how does this interact with all of the options.
// REVIEW: e.g. parallel will possibly reorder items, oblivious will remove items from the resulting array, etc.
// REVIEW: what about sparse arrays?
export function map(
  collection: Collection,
  fn: Callback,
  options?: ForEachOptions
): ArrayCollectionPromise {
  return reduce(
    collection,
    function (memo, value, key, collection) {
      return q.fcall(fn, value, key, collection).then(function (results) {
        memo.push(results)
        return memo
      })
    },
    [],
    options
  )
}

// Takes an array of keys and returns an object with the array entries used as keys
// with the values being the resolved value of the mapping function.
export function mapArrayToObject(
  array: ArrayCollection,
  fn: Callback,
  options?: ForEachOptions
): ObjectCollectionPromise {
  return reduce(
    array,
    function (memo, value, index, array) {
      return q.fcall(fn, value, index, array).then(function (results) {
        memo[value] = results
        return memo
      })
    },
    {},
    options
  )
}

export function mapValues(
  collection: Collection,
  fn: Callback,
  options?: ForEachOptions
): ObjectCollectionPromise {
  return reduce(
    collection,
    function (memo, value, key, collection) {
      return q.fcall(fn, value, key, collection).then(function (results) {
        memo[key] = results
        return memo
      })
    },
    {},
    options
  )
}

// REVIEW: What the heck does this do??? Are we using it?
export function objAll(obj) {
  return mapValues(obj, function (value) {
    return value
  })
}

// WARNING: reduce with parallel>1 could have unexpected results
// because you don't know what order memo is updated in.
// Only use the parallel option if, e.g, the callback is always returning the same object.
export function reduce<T>(
  collection: Collection,
  fn: MemoCallback<T>,
  memo: T,
  options?: ForEachOptions
) {
  return forEach(
    collection,
    function (a, b, c) {
      return q.fcall(fn, memo, a, b, c).then(function (results) {
        memo = results
        return memo
      })
    },
    options
  ).then(function () {
    return memo
  })
}

// Takes a function that returns a promise or value.
// Executes the function repeatedly as long as it returns exactly true
// or returns a promise fulfilled to exactly true.
// It will execute the function until it:
// --Throws an exception
// --Returns a rejected promise
// --Returns a value other than exactly true
// --Returns a promise that is fulfilled with a value other than exactly true
// --An interrupt occurs
// Additional arguments are passed to the function.
// LATER: this should be interruptible.
// TODO: have an option for a delay between iterations.
// TODO: have a limit on number of repetitions or time. throw exception if limit is reached.
// REVIEW: Tighten the types of the callback function.
export function repeat<T>(fn: () => any, ...args: any[]): q.Promise<T> {
  const deferred = q.defer<T>()
  function nextCycle() {
    q(fn)
      .fapply(args)
      .then(
        function (val) {
          if (val === true) {
            setImmediate(nextCycle)
          } else {
            deferred.resolve(val as any /* TYPESCRIPT: */)
          }
        },
        function (err) {
          deferred.reject(err)
        }
      )
      .done()
  }
  nextCycle()
  return deferred.promise
}
