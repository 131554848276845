import _ from 'lodash'

import q from 'q'

import { isConnected } from './ajax-connection2'
import { clientLanguage } from './client-language'
import { ClientError } from './client-error'

const logger = console.log
// TYPES

type Hooks = { showWorkIndicator: () => void; hideWorkIndicator: () => void }

// RETRYABLE FLOW:

// +-------------------+
// |   instantiate     |
// +-------------------+
//          |
//          |
//          |    +-----------------------------------------------------------------------------------+
//          v    v                                                                                   |
// +-------------------+                                                                             |
// |   'executing'     +------------------------------------------------------+                      |
// +-------------------+                    |                                 |                      |
//          |                               |                                 |                      |
//          |                               |                                 |                      |
//          |success                        |error                            |not connected/        |
//          |                               |                                 |timeout field         |retry
//          v                               v                                 v                      |
// +-------------------+   No  +------------------------+         +-------------------------+        |
// |    'finished'     +<------+      retryable?        |         |       'waiting'         |        |
// +-------------------+       +------------------------+         +-------------------------+        |
//          ^   ^                         |                                |      |                  |
//          |   |                         |              cancel            |      |                  |
//          |   +----------------------------------------------------------+      | retry            |
//          |                             |                                       |                  |
//          |                             |                                       |                  |
//          |                             |  yes       +--------------------------v----------+       |
//          |                             +----------->+                                     |       |
//          |                                          |              Retry UI               |       |
//          +------------------------------------------+    (Connection status affects UI)   +-------+
//                              cancel                 |                                     |
//                                                     +-------------------------------------+
//                                                                    | ^
//                                                                    | |
//                                                                    v |
//                                                        +-----------------------------+
//                                                        |                             |
//                                                        |  connect/disconnect event   |
//                                                        |                             |
//                                                        +-----------------------------+

// CONSTANTS

const BACKOFF = 2

const CANCELABLE = {
  executing: true,
  waiting: true,
  offline: true,
  error: true,
}

const RETRYABLE = {
  error: true,
  waiting: true,
}

const RETRY_CAP = 10
const SHOW_SPINNER_AFTER = 2500 // delay before spinner shown
const SHOW_POPUP_AFTER = 5000 // delay before popup shown

const STATE_FUNCTIONS = {
  error: {
    enter(err) {
      // this.updatePopup(errorMessages.message2(err), errorMessages.title2(err));
      logger(err)
      // TODO: should we be throwing from here?
      throw err
    },
    exit() {
      // exit
    },
    abort() {
      this.transitionTo('finished', abortedError())
    },
    retry() {
      if (this.retries > RETRY_CAP) {
        this.transitionTo('finished', abortedError('canceled-retries'))
      } else {
        this.transitionTo('executing')
      }
    },
  },
  executing: {
    enter() {
      const that = this
      that.updatePopup()
      // NOTE: only leave the 'executing' state by resolving fnDeferred.
      // e.g. cancel button rejects fnDeferred with cancel error.
      that.fnDeferred = q.defer()
      const { fnDeferred } = that
      q.fcall(that.fn).then(
        function (results) {
          fnDeferred.resolve(results)
        },
        function (err) {
          fnDeferred.reject(err)
        }
      )

      q(fnDeferred.promise).then(
        function (results) {
          that.transitionTo('finished', null, results)
        },
        function (err) {
          if (err.timeout) {
            that.transitionTo('waiting', err)
          } else if (that.forceRetry) {
            that.transitionTo('error', err)
          } else {
            that.transitionTo('finished', err)
          }
        }
      )
    },
    exit() {
      clearTimeout(this.shortWaitTimeout)
      delete this.shortWaitTimeout
      clearTimeout(this.longWaitTimeout)
      delete this.longWaitTimeout
      delete this.fnDeferred
    },
    abort() {
      this.fnDeferred && this.fnDeferred.reject(abortedError())
    },
    retry() {
      logger(
        'Retry button pressed in %s state for method %s.',
        this.state,
        this.name
      )
    },
  },
  finished: {
    enter(err, results) {
      this.hidePopup()
      this.unbindHandlers()
      this.hooks.hideWorkIndicator()
      if (err) {
        this.rvalDeferred.reject(err)
      } else {
        this.rvalDeferred.resolve(results)
      }
    },
    exit() {
      // exit
    },
    abort() {
      this.transitionTo('finished', abortedError())
    },
    retry() {
      logger(
        'Retry button pressed in %s state for method %s.',
        this.state,
        this.name
      )
    },
  },
  waiting: {
    enter(err) {
      const that = this
      that.retries++
      // that.updatePopup(errorMessages.message2(err), errorMessages.title2(err), clientLanguage.getResource('#retryableOpPopup', 'subsequentRetry'));
      const waitFor = err.timeout || BACKOFF ** that.retries * 1000
      const retryAt = Date.now() + waitFor
      let countdown = Math.round((retryAt - Date.now()) / 1000)
      // var $timer = $('.untilRetry', that.$popup);
      // $timer.text(countdown.toString());
      that.retryInterval = setInterval(function () {
        if (countdown <= 0) {
          STATE_FUNCTIONS.waiting.retry.call(that)
          return
        }
        countdown--
        // $timer.text(countdown.toString());
      }, 1000)
    },
    exit() {
      clearInterval(this.retryInterval)
      delete this.retryInterval
    },
    abort() {
      this.transitionTo('finished', abortedError())
    },
    retry() {
      if (this.retries > RETRY_CAP) {
        this.transitionTo('finished', abortedError('canceled-retries'))
      }
      this.transitionTo('executing')
    },
  },
}

export class RetryableOperation {
  cbOnline: () => void

  cbOffline: () => void

  cancelText: string

  fn: () => any

  forceRetry: boolean

  hooks: Hooks

  longWaitTimeout: number

  name: string

  promise: q.Promise<any>

  retries: number

  rvalDeferred: q.Deferred<any>

  shortWaitTimeout: number

  state: 'error' | 'executing' | 'finished' | 'waiting'

  showLong: boolean

  // CONSTRUCTOR

  constructor(fn: () => any, hooks: Hooks, name: string, forceRetry?: boolean) {
    this.fn = fn
    this.name = name
    this.rvalDeferred = q.defer()
    this.promise = this.rvalDeferred.promise
    this.forceRetry = forceRetry
    this.retries = 0
    this.hooks = hooks

    // this.cbOnline = logger.wrapEventHandler(_.bind(this.onOnline, this))
    // this.cbOffline = logger.wrapEventHandler(_.bind(this.onOffline, this))

    this.bindHandlers()
  }

  // CLASS METHODS

  static create(fn, hooks, name, forceRetry?: boolean): RetryableOperation {
    const rval = new this(fn, hooks, name, forceRetry)
    rval.transitionTo('executing', true)
    return rval
  }

  // INSTANCE METHODS

  abort(): void {
    STATE_FUNCTIONS[this.state].abort.call(this)
  }

  hidePopup(): void {
    // put popup behind all other layers
    // domH.closePopup('#retryableOpPopup')
  }

  showPopup(): void {
    // LATER: would be better to use domH.openPopup, but we don't want to show the background yet.
    // this.$popup.show()
    // if (document.activeElement) {
    //   ;($(document.activeElement).get(0) as HTMLElement).blur()
    // }
  }

  transitionTo(state: keyof typeof STATE_FUNCTIONS, ...args): void {
    if (this.state) {
      STATE_FUNCTIONS[this.state].exit.call(this)
    }
    this.state = state
    const rest = { ...args }
    // STATE_FUNCTIONS[state].enter.call(this, rest)
  }

  updatePopup(errMsg, errTitle): void {
    // error, offline, executing, waiting
    const that = this
    if (errTitle) {
      // $('h1.error', that.$popup).text(errTitle)
    }
    // $('.message', that.$popup).html(errMsg)
    let popupState
    const failureStates = ['waiting', 'error']
    if (failureStates.indexOf(that.state) >= 0 && !isConnected()) {
      popupState = 'offline'
    } else {
      popupState = that.state
    }
    const otherStates = _.without(
      ['error', 'offline', 'executing', 'waiting'],
      popupState
    )
    _.forEach(otherStates, function (state) {
      // $('.' + state, that.$popup).hide()
    })
    // $('.' + that.state, that.$popup).show()

    if (CANCELABLE[that.state]) {
      // $('.cancelRetry', that.$popup).show()
    } else {
      // $('.cancelRetry', that.$popup).hide()
    }

    if (RETRYABLE[that.state]) {
      // $('.retryNow', that.$popup).show()
    } else {
      // $('.retryNow', that.$popup).hide()
    }

    if (that.state === 'executing' && !that.showLong) {
      that.showLong = true
      that.hooks.showWorkIndicator()
      // $('.long, .executing', that.$popup).hide()
      // that.$popup.addClass('transparent')
      that.shortWaitTimeout = window.setTimeout(function () {
        that.hooks.hideWorkIndicator()
        // that.$popup.removeClass('transparent')
        // that.$popup.addClass('short')
        // that.$bg.show()
        // $('.executing', that.$popup).show()
      }, SHOW_SPINNER_AFTER)
      that.longWaitTimeout = window.setTimeout(
        _.bind(that.longWaitInterface, that),
        SHOW_POPUP_AFTER
      )
    } else if (that.state === 'executing') {
      // that.$bg.show()
      that.longWaitInterface()
    } else {
      // that.$bg.show()
      that.hooks.hideWorkIndicator()
      // that.$popup.removeClass('short transparent')
    }

    // let hidden = that.$popup.css('visibility') === 'hidden'
    // let invisible = !domUtil.isVisible(that.$popup)
    // if (hidden || invisible) {
    // that.showPopup()
    // }
  }

  longWaitInterface(): void {
    this.hooks.hideWorkIndicator()
    // this.$popup.removeClass('short transparent')
    // this.$popup.popup( 'option', 'overlayTheme', 'x' );
    // $('.long', this.$popup).show()
    // this.$bg.show()
  }

  // EVENT HANDLERS

  onCancelButton(event: Event): void {
    event.preventDefault()
    STATE_FUNCTIONS[this.state].abort.call(this)
  }

  onOnline(_event?): void {
    if (this.state === 'error' || this.state === 'waiting') {
      this.transitionTo('executing')
    }
  }

  onOffline(_event?): void {
    // this.updatePopup(
    // errorMessages.message2({ reason: 'offline' }),
    // errorMessages.title2({ reason: 'offline' })
    // )
  }

  unbindHandlers(_event?): void {
    try {
      window.removeEventListener('offline', this.cbOffline)
      window.removeEventListener('online', this.cbOnline)
      // $('.retryNow', this.$popup).off('click')
      // $('.cancelRetry', this.$popup).off('click')
    } catch (err) {
      logger(err)
    }
  }

  bindHandlers(_event?): void {
    try {
      window.addEventListener('online', this.cbOnline)
      window.addEventListener('offline', this.cbOffline)
      // $('.cancelRetry', this.$popup).on(
      //   'click',
      //   logger.wrapEventHandler(_.bind(this.onCancelButton, this))
      // )
      // $('.retryNow', this.$popup).on(
      //   'click',
      //   logger.wrapEventHandler(_.bind(this.onRetryButton, this))
      // )
    } catch (err) {
      logger(err)
    }
  }

  onRetryButton(event): void {
    if (event) {
      event.preventDefault()
    }
    this.retries = 0
    STATE_FUNCTIONS[this.state].retry.call(this)
  }
}

// HELPER FUNCTIONS

function abortedError(reason?: string) {
  reason = reason || 'canceled'
  // let err = errorMessages.newError(reason)
  // err.reason = reason
  // err.dontReport = true
  // return err
}
